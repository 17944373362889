import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-policy-info',
  templateUrl: './policy-info.component.html',
  styleUrls: ['./policy-info.component.scss']
})
export class PolicyInfoComponent {
  /**
   * The instance of the {HomePolicyResponse} input property.
   */
  @Input() policy: any;

  /**
   * Home icons
   */
  readonly IconType = {
    CONDO_ICON_H04: '04',
    CONDO_ICON_H06: '06'
  };

 /**
  * The policy type.
  */
  readonly PolicyType = {
    AUTO: 'AUTOP',
    HOME: 'HOME'
  };

  /**
   * Initializes a new instance of the {PolicyInfoComponent}.
   */
  constructor() { }

  /**
   * Gets full name for auto policy holder
   * @return The full name of the auto policy holder
   */
  getPolicyHolderFullName(): string {
    if (this.policy.policyHolderFirstName && this.policy.policyHolderSurName) {
      return this.policy.policyHolderFirstName + ' ' + this.policy.policyHolderSurName;
    }
    else{
      return "";
    }
  }

  /**
   * Gets policy information title.
   * @return The policy information title.
   */
  getPolicyInfoTitle(): string {
    if (this.policy.policyType === this.PolicyType.HOME) {
      return 'Home Policy';
    } else if (this.policy.policyType === this.PolicyType.AUTO) {
      return 'Auto Policy';
    }
    throw new Error('Unknown policy type.');
  }

  /**
  * Changes home icon based on policy type
  * @return The icon CSS class for the policy type.
  */
  displayIcon(): any {
    if (this.policy.policyType === this.PolicyType.AUTO) {
      return {'icon-car-primary-option': true };
    }
    switch (this.policy.policyTypeCd) {
      case this.IconType.CONDO_ICON_H04:
      case this.IconType.CONDO_ICON_H06:
        return { 'icon-condominium': true };
      default:
        return { 'icon-house': true };
    }
  }
}
