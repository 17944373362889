<div class="auto-lienholder col-md-12">
  <div class="col-md-12">
    <div class="col-md-8 col-xs-12 auto-lienholder-heading">
      <span *ngIf="hasAnyLienholder() && !checkActionIsSuccessOrFailure()" [ngClass]="{'no-lienholder': !hasAnyLienholder()}">{{vehicle.financialInstitution}}</span>
      <span *ngIf="!hasAnyLienholder() && !checkActionIsSuccessOrFailure()" >There are no Lienholders listed on this policy.</span>
    </div>
    <div class="col-md-4 col-xs-12 auto-lienholder-button-panel">
      <a id="add-btn" class="btn btn-link" [ngClass]="setButtonStateDisplay(buttonState.CAN_ADD)" (click)="onAddLiendholderClick()"
         data-dl='{"event": "mousedown","event_value": "auto","event_id": "add","da_track":"true"}'
        title="Add">Add</a> |
      <a id="update-btn" class="btn btn-link" [ngClass]="setButtonStateDisplay(buttonState.CAN_UPDATE)" (click)="onUpdateLienholderClick()"
         data-dl='{"event": "mousedown","event_value": "auto","event_id": "update","da_track":"true"}'
        title="Update">Update</a> |
      <a id="delete-btn" class="btn btn-link" [ngClass]="setButtonStateDisplay(buttonState.CAN_DELETE)" (click)="onDeleteLienholderClick()" title="Delete"
         data-dl='{"event": "mousedown","event_value": "auto","event_id": "delete","da_track":"true"}'>Delete</a>
    </div>
  </div>
  <div id="noLienHolder" *ngIf="!hasAnyLienholder() && !checkActionIsSuccessOrFailure()" class="col-md-12 lienholder-info">

    <div id="collision-coverage-wrapper1" class="col-md-12" *ngIf="collisionCoverage">
      <div class="col-md-4 col-xs-12 auto-lienholder-label"><span>Collision Deductible:</span></div>
      <div class="col-md-8 col-xs-12 auto-lienholder-value">{{collisionCoverage.coverageLowerLimit | currency}}</div>
    </div>
    <div id="comprehensive-coverage-wrapper1" class="col-md-12" *ngIf="comprehensiveCoverage">
      <div class="col-md-4 col-xs-12 auto-lienholder-label"><span>Comprehensive Deductible:</span></div>
      <div class="col-md-8 col-xs-12 auto-lienholder-value">{{comprehensiveCoverage.coverageLowerLimit | currency}}</div>
    </div>
  </div>
  <div *ngIf="hasAnyLienholder()  && !checkActionIsSuccessOrFailure()" class="col-md-12 lienholder-info">
    <div id="loan-number-wrapper" class="col-md-12" *ngIf="vehicle.loanNumber">
      <div class="col-md-4 col-xs-12 auto-lienholder-label"><span>Loan Number:</span></div>
      <div class="col-md-8 co-xs-12 auto-lienholder-value">{{vehicle.loanNumber}}</div>
    </div>
    <div id="lease-financed-wrapper" class="col-md-12" *ngIf="vehicle.ownershipCd">
      <div class="col-md-4 col-xs-12 auto-lienholder-label"><span>Leased or Financed:</span></div>
      <div class="col-md-8 col-xs-12 auto-lienholder-value">{{getOwnershipType()}}</div>
    </div>
    <div id="financial-institution-wrapper" class="col-md-12" *ngIf="vehicle.financialInstitution">
      <div class="col-md-4 col-xs-12 auto-lienholder-label"><span>Company Name:</span></div>
      <div class="col-md-8 col-xs-12 auto-lienholder-value">{{vehicle.financialInstitution}}</div>
    </div>
    <div id="address-main-wrapper" class="col-md-12" *ngIf="vehicle && vehicle.ownershipCd">
      <div class="col-md-4 col-xs-12 auto-lienholder-label"><span>Address:</span></div>
      <div class="col-md-8 col-xs-12 auto-lienholder-value">
        <span *ngIf="vehicle.othFinInstStreet">{{vehicle.othFinInstStreet}}<br></span>
        <span *ngIf="vehicle.othFinInstCity">{{vehicle.othFinInstCity}}, </span>
        <span *ngIf="vehicle.othFinInstState">{{vehicle.othFinInstState}} </span>
        <span *ngIf="vehicle.othFinInstZip">{{vehicle.othFinInstZip}}</span>
      </div>
    </div>
    <div id="collision-coverage-wrapper" class="col-md-12" *ngIf="collisionCoverage">
      <div class="col-md-4 col-xs-12 auto-lienholder-label"><span>Collision Deductible:</span></div>
      <div class="col-md-8 col-xs-12 auto-lienholder-value">{{collisionCoverage.coverageLowerLimit | currency}}</div>
    </div>
    <div id="comprehensive-coverage-wrapper" class="col-md-12" *ngIf="comprehensiveCoverage">
      <div class="col-md-4 col-xs-12 auto-lienholder-label"><span>Comprehensive Deductible:</span></div>
      <div class="col-md-8 col-xs-12 auto-lienholder-value">{{comprehensiveCoverage.coverageLowerLimit | currency}}</div>
    </div>
    <div class="line-row">
      <hr class="auto-line"/>
    </div>
  </div>
</div>

<app-lienholder-form [vehicle]="vehicle"
  [policy]="policy"
  [action]="currentAction"
  (cancelEvent)="onCancelled($event)"
  (failure)="onFailure($event)"
  (success)="onSuccess($event)">
</app-lienholder-form>
