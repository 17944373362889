import { PolicyInfoModel } from './home/policy-info.model';
import { TransactionInfoRequest } from './home/transaction-info-request.model';
import { RecipientInfoModel } from './home/receipient-info.model';

export class BillingDetailsInvoiceReceiptRequestModel {
    transactionInfo: TransactionInfoRequest | any;
    policyInfo: PolicyInfoModel | any;
    recipient: RecipientInfoModel | any;

    constructor(
        transactionInfo?: TransactionInfoRequest,
        policyInfo?: PolicyInfoModel,
        recipient?: RecipientInfoModel
    ) {
        this.transactionInfo = transactionInfo;
        this.policyInfo = policyInfo;
        this.recipient = recipient;
    }
}
