import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment-wrapper';
import { BillingDetailsInvoiceReceiptRequestModel } from '../../shared/api/billing-details-invoice-receipt-request.model';
import { BillingDetailsInvoiceReceiptResponseModel } from '../../shared/api/billing-details-invoice-receipt-response.model';

@Injectable({
    providedIn: 'root',
})
export class BillingInvoiceReceiptService {
    /**
     * The API endpoint that will be changed for each environmet.
     */
    apiBaseEndpoint = environment.API_ENDPOINT;

    /**
     * The home declaration copy requst resouce URI.
     */
    billingInvoiceReceiptResource = environment.EXPRESS_API_INVOICE;

    /**
     * The policy type which can be 'HOME'
     */
    readonly PolicyType = {
        HOME: 'HOME'
    };

    /**
     * Initializes new instance of the {HttpClient}
     * @param http The instance of the {HttpClient
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Makes a restful call to the home declaration service to send fax or email requested by the user.
     * @param request The instance of the {BillingDetailsInvoiceReceiptRequestModel}.
     * @returns The populated instance of the {BillingDetailsInvoiceReceiptResponseModel}.
     */
   BillingInvoiceReceiptRequest(request: BillingDetailsInvoiceReceiptRequestModel): Observable<BillingDetailsInvoiceReceiptResponseModel> {
        return this.http.post<BillingDetailsInvoiceReceiptResponseModel>
            (this.getApiEndpoint(this.billingInvoiceReceiptResource, this.PolicyType.HOME),
            request, { headers: this.getCommonHttpHeaders() })
            .pipe(catchError(this.handleError));
    }

    /**
     * Gets common HTTP request headers properties.
     */
    private getCommonHttpHeaders(): HttpHeaders {
        return new HttpHeaders({})
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Access-Control-Allow-Origin', '*');
    }

    /**
     * Gets the full API resource URI.
     * @param resource The resource relative path without leading slash.
     * @param policyType to determine if request is for HOME
     * @returns The full API resource URI.
     */
    private getApiEndpoint(resource: string, policyType: string): string {
        if (policyType === this.PolicyType.HOME) {
            return `${this.apiBaseEndpoint}${resource}`;
        } else {
            throw new Error('Unable to determine policy type.');
        }
    }

    /**
     * Handles HTTP service call error.
     * @param error The instance of the {HttpErrorResponse}.
     */
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            //console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
