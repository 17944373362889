import * as ENVIRONMENT from './environment';
import * as ENVIRONMENT_QA from './environment.qa';
import * as ENVIRONMENT_LTQ from './environment.ltq';
import * as ENVIRONMENT_PROD from './environment.prod';
import * as ENVIRONMENT_LTI from './environment.lti';
import * as ENVIRONMENT_INT from './environment.int';
import * as ENVIRONMENT_LOCAL from './environment.local';
import * as ENVIRONMENT_PTE from './environment.pte';
import * as ENVIRONMENT_STI from './environment.sti';
import * as ENVIRONMENT_STQ from './environment.stq';

export class EnvironmentWrapper {

  /**
   * Gets environment file.
   */
  static getEnvironmentFile(): any {
    const url = document.location.href;
    if (url.indexOf('//qa-lender') !== -1) {
      return ENVIRONMENT_QA.environment;
    } else if (url.indexOf('//ltq-lender') !== -1) {
      return ENVIRONMENT_LTQ.environment;
    } else if (url.indexOf('//lti-lender') !== -1) {
      return ENVIRONMENT_LTI.environment;
    } else if (url.indexOf('//dev-lender') !== -1) {
      return ENVIRONMENT_INT.environment;
    } else if (url.indexOf('//lender') !== -1) {
      return ENVIRONMENT_PROD.environment;
    } else if (url.indexOf('//localhost') !== -1) {
      return ENVIRONMENT_INT.environment;
    } else if (url.indexOf('//pte-lender') !== -1) {
      return ENVIRONMENT_PTE.environment;
    } else if (url.indexOf('//sti-lender') !== -1) {
      return ENVIRONMENT_STI.environment;
    } else if (url.indexOf('//stq-lender') !== -1) {
      return ENVIRONMENT_STQ.environment;
    } else if (url.indexOf('//int-lender') !== -1) {
      return ENVIRONMENT_INT.environment;
    }
    return ENVIRONMENT.environment;
  }
}
export let environment: any = EnvironmentWrapper.getEnvironmentFile();

