//JWTChange
import { environment } from '../../../environments/environment-wrapper';
import { throwError as observableThrowError, throwError } from 'rxjs';
import { HttpResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationRequest } from './authenticate.model';

@Injectable()
export class AuthenticateService {
  constructor(private http: HttpClient) {
}

  /* Exception handler */
  private handleError(error: HttpResponse<any> | any) {
    //console.error('AuthenticateService error>>' + error);
    return throwError(error);
  }

  /**
  * Authenticate user transaction
  * @param authenticationRequest
  */
  authenticate(authenticationRequest: AuthenticationRequest) {
    const body = JSON.stringify(authenticationRequest);
    return this.http.post(environment.API_DP_AUTH_ROOT + environment.API_DP_PARAMS_FIRST + environment.API_DP_AUTH_RECAPTCHA_PARAM, body);
  }

  /**
  * Authenticate Lvp auto user transaction
  * @param authenticationRequest
  */
  authenticateLvpAuto(authenticationRequest: AuthenticationRequest) {
    const body = JSON.stringify(authenticationRequest);
    return this.http.post(environment.API_DP_AUTH_ROOT + environment.API_DP_AUTH_TOKEN_PARAM, body);
  }

  /**
  * Get whether recaptcha is ON or OFF
  * @param
  */
  getRecaptchaState() {
    return this.http.get(environment.API_ENDPOINT + environment.RECAPTCHA_ENABLED_URL);
  }

}
