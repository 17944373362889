import { Injectable } from '@angular/core';
import {Location} from '@angular/common';
import { TealiumDataService } from './tealium-data.service';


@Injectable()
export class EventListenerService {

  locationurl: Location;
  constructor(
    private tealiumdatasvc: TealiumDataService,
    private location: Location) {
    (<any>window).addEventListener('change', this.trackEvent.bind(this));
    (<any>window).addEventListener('mousedown', this.trackEvent.bind(this));
    this.locationurl = location;
  }

  // Setting up event data object based on type of event
  trackEvent(event:any) {
    let eventID = '';
    let eventvalue = '';
    let eventTrack  = true;
    if ((!event.target.attributes['data-dl'])) {
      try {
        const element = event.target;
        let elemType = element.localName;
        if ((event.type === 'mousedown' && (elemType === 'a' || elemType === 'button' ||
            elemType === 'div' || elemType === 'i')) || (event.type === 'change')) {
          if (elemType === 'input') {
            elemType = 'Input Field Click - (' + element.type + ')';
            eventID = element.id;
            eventvalue = element.value;
          }

          if (elemType === 'a') {
            elemType = 'Link Click';
            eventID = element.innerHTML;
            eventvalue = element.href;
          }
          if (elemType === 'button') {
            elemType = 'Button Click';
            eventID = element.id;
            eventvalue = element.innerHTML;

          }
          if (elemType === 'select') {
            elemType = 'Select Click - (' + element.type + ')';
            eventID = element.id;
            if (element.type === 'select-multiple') {
              for (let i = 0; i < element.options.length; i++) {
                if (element.options[i].selected) {
                  eventvalue += element.options[i].innerHTML + '|';
                }
              }
            } else {
              eventvalue = (element.options[element.selectedIndex]).innerHTML;
            }

          }
          if (elemType === 'div' || elemType === 'i') {
            const accClick = element.className;
            if (accClick.indexOf('accordion-track') !== -1) {
              elemType = 'Link Click';
              eventID = '-';
              eventvalue = window.location.href;
            } else {
              eventTrack   =   false;
            }
          }
          const data = {
            event_parent: 'Page Tag',
            event_type: elemType,
            event_id: eventID,
            event_value: eventvalue,
          };
          if  (eventTrack  ===  true) {
            this.tealiumdatasvc._appTrack(data,  element,  null);
          }
        }


      } catch (e:any) {
        console.error('Tealium error : ', e.message);
      }
    }
  }
}
