import { HomeAddressRequest } from './home-address-request.model';

export class TransactionInfoRequest {
  role: string | any;
  policyNumber: string | any;
  lineOfInsurance: string | any;
  changeEffectiveDate: string | any;
  businessUnit: string | any;
  marketSegment: string | any;
  lienHolderFirstName: string | any;
  lienHolderLastName: string | any;
  producerCode: string | any;
  firstName: string | any;
  lastName: string | any;
  lob: string | any;
  policyStartDate: string | any;
  policyExpiryDate: string | any;
  address: HomeAddressRequest | any;
  payor: string | any;
  businessSegment: string | any;
  actionRequested: string | any;
  messageDeveliveryType: string | any;
  distributionSegment: string | any;

  constructor(role?: string,
              policyNumber?: string,
              lineOfInsurance?: string,
              changeEffectiveDate?: string,
              businessUnit?: string,
              marketSegment?: string,
              lienHolderFirstName?: string,
              lienHolderLastName?: string,
              producerCode?: string,
              firstName?: string,
              lastName?: string,
              lob?: string,
              policyStartDate?: string,
              policyExpiryDate?: string,
              dwellingAddress?: HomeAddressRequest,
              payor?: string,
              businessSegment?: string,
              actionRequested?: string,
              messageDeveliveryType?: string,
              distributionSegment?: string) {
    this.role = role;
    this.policyNumber = policyNumber;
    this.lineOfInsurance = lineOfInsurance;
    this.changeEffectiveDate = changeEffectiveDate;
    this.businessUnit = businessUnit;
    this.marketSegment = marketSegment;
    this.lienHolderFirstName = lienHolderFirstName;
    this.lienHolderLastName = lienHolderLastName;
    this.producerCode = producerCode;
    this.firstName = firstName;
    this.lastName = lastName;
    this.lob = lob;
    this.policyStartDate = policyStartDate;
    this.policyExpiryDate =  policyExpiryDate;
    this.address = dwellingAddress;
    this.payor = payor;
    this.businessSegment = businessSegment;
    this.actionRequested = actionRequested;
    this.messageDeveliveryType = messageDeveliveryType;
    this.distributionSegment = distributionSegment;
  }
}
