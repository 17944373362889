import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WebStorageModule } from 'ngx-store';

import { AppComponent } from './app.component';
import { NgxBootstrapModule } from './shared/ngx-boostrap.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { LayoutsModule } from './layouts/layouts.module';
import { LvpFormsModule } from './forms/lvp-forms.module';
import { ErrorsModule } from './errors/errors.module';
import { PolicyModule } from './policy/policy.module';

import { TealiumModule } from './services/tealium/tealium.module';
import { TealiumUtagService } from './services/tealium/utag.service';
import { Router, RoutesRecognized } from '@angular/router';
import { RegistrationComponent } from './registration/registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountSecurityComponent } from './account-security/account-security.component';
import { EditAccountComponent } from './edit-account/edit-account.component';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PasswordComponent } from './account-security/password/password.component';
import { ContactInformationComponent } from './account-security/contact-information/contact-information.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { AuthenticateService } from './services/auth/authenticate.service';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SearchPolicyComponent } from './search-policy/search-policy.component';
import { DisassociationEmailComponent } from './disassociation-verification-email/disassociation-verification-email.component';
import { DisassociationPostVerificationComponent } from './disassociation-post-verification/disassociation-post-verification.component';
import { DisassociationEmailExpiryComponent } from './disassociation-email-expiry/disassociation-email-expiry.component';

@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    AccountSecurityComponent,
    EditAccountComponent,
    PasswordComponent,
    ContactInformationComponent,
    DisassociationEmailComponent,
    DisassociationPostVerificationComponent,
    DisassociationEmailExpiryComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgxBootstrapModule,
    CommonModule,
    WebStorageModule.forRoot(),
    SharedModule,
    AppRoutingModule,
    LayoutsModule,
    LvpFormsModule,
    ErrorsModule,
    PolicyModule,
    TealiumModule.forRoot(),
    ReactiveFormsModule,
    NgxTypeaheadModule,
    NgxCaptchaModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    //JWTChange
    AuthenticateService,
    CookieService
  ],
  exports: [
    SearchPolicyComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  /**
   * Subscribe to route events to track page views
   */
  constructor(private router: Router, private tealium: TealiumUtagService) {
    /**
     * Subscribed to route event. Set details required to load tealium script then call tealium.view() to track page view event."
     */
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized && event.url !== '/policy-details') {
        this.tealium.setDetails();
        this.tealium.view();
      }
    });
  }
}
