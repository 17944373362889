<div class="container-fluid">
  <div class="content">
  <h1 class="text-center">Edit Account Info</h1>
  <div class="form-class">
    <form [formGroup]="editAccount">
      <div class="form-group">
        <label class="control-label">User ID</label>
        <output>{{userId}}</output>
      </div><br>
      <div class="form-group has-feedback">
        <label class="control-label" [class.label-error]="isFormFieldValid('firstName')
                  || isFormFieldEntered('firstName')">First Name</label>
                  <!--[ngModel]="firstName" -->
        <input type="text" class="form-control gbx-unmasked" [ngClass]="{'border': (isFormSubmitting && isFormFieldEntered('firstName'))}"
               name="firstName" formControlName="firstName" (blur) = "onFieldError('firstName')" data-dl ='{"event":"change","event_parent" : "Page Tag","event_type" : "Input Field Click – (text)" ,"event_id" : "firstName","event_value" :"firstName", "da_track" : "true"}'>
        <div *ngIf="isFormFieldEntered('firstName')">
          <i class="icon icon-attention-alt"></i>
          <span class="text-danger">
          Please enter a first name.
        </span>
        </div>
        <div *ngIf="isFormFieldValid('firstName')">
          <i class="icon icon-attention-alt"></i>
          <span class="text-danger">
          Please enter valid first name
        </span>
        </div>
      </div>
    <div class="form-group has-feedback">
      <label class="control-label" [class.label-error]="isFormFieldValid('lastName')
                  || isFormFieldEntered('lastName')">Last Name</label>
                  <!--[ngModel]="lastName"-->
      <input type="text" class="form-control gbx-unmasked" [ngClass]="{'border': (isFormSubmitting && isFormFieldEntered('lastName'))}"
            name="lastName" formControlName="lastName" (blur) = "onFieldError('lastName')" data-dl ='{"event":"change","event_parent" : "Page Tag","event_type" : "Input Field Click – (text)" ,"event_id" : "lastName","event_value" :"lastName", "da_track" : "true"}'>
      <div *ngIf="isFormFieldEntered('lastName')">
        <i class="icon icon-attention-alt"></i>
        <span class="text-danger">
            Please enter a last name.
          </span>
      </div>
      <div *ngIf="isFormFieldValid('lastName')">
        <i class="icon icon-attention-alt"></i>
        <span class="text-danger">
          Please enter valid last name
        </span>
      </div>
    </div>
    <div class="form-group has-feedback">
      <label class="control-label" [class.label-error]="isFormFieldValid('emailId')
                  || isFormFieldEntered('emailId') || invalidEmail || invalidDomain">Email Address</label>
                  <!--[ngModel]="emailId" -->
      <input type="text" class="form-control gbx-unmasked" (keyup)="checkDomainError()" [ngClass]="{'border': (isFormSubmitting && isFormFieldEntered('emailId')) || invalidDomain || invalidEmail}"
             name="emailId" formControlName="emailId" (blur) = "onFieldError('emailId')" data-dl ='{"event":"change","event_parent" : "Page Tag","event_type" : "Input Field Click – (text)" ,"event_id" : "emailId","event_value" :"emailId", "da_track" : "true"}'>		  
      <div  *ngIf="isFormFieldEntered('emailId') || isFormFieldValid('emailId')">
        <i class="icon icon-attention-alt"></i>
        <span class="text-danger">
            Please enter a valid email.
          </span>
      </div>
      <div  *ngIf="invalidDomain">
        <i class="icon icon-attention-alt"></i>
          <span class="text-danger">
            Please enter an email with your lender domain (example: name@lenderdomain.com).
          </span>
      </div>
      <div  *ngIf="invalidEmail">
        <i class="icon icon-attention-alt"></i>
        <span class="text-danger">
            Sorry, that’s an invalid email address. Please enter another (example: name@lenderdomain.com)).
          </span>
      </div>
    </div>
    </form>
  </div>
    <hr>
  <div class="row">
    <div class="col-xs-6" id="cancel">
      <a routerLink="/account-security" data-dl= '{"event_parent": "Page Tag","event":"mousedown","event_type":"Link Click","event_id":"Cancel","event_value":"Cancel","da_track":"true"}'>Cancel</a>
    </div>
    <div class="col-xs-3 col-xs-offset-3">
      <button (click)="saveEditAccount()" type="submit" class="btn btn-primary btn-md" data-dl='{"event_parent":"Page Tag","event_type":"Button Click","event_id":"Save_Edit_Profile","event_value":"Save","event":"mousedown","da_track":"true"}'>
        Save
      </button>
    </div>
  </div>
    </div>
  </div>
