<div *ngIf="displayMortgagees()">
  <div *ngIf="shouldDisplayEmptyMortgagee()">
    <app-mortgagee [mortgagee]="defaultMortgagee" 
      [mortgagees]="mortgagees" 
      [buttonState]="mortgageeButtonStates[0]" 
      [message]="noMorgageeMessage"
      [totalMortgagees]="mortgagees.length"
      [isInFlight]="homePolicyResponse.inFlight"
      [datesLocked]="checkPolicyDatesLocked()">
    </app-mortgagee>
  </div>
  <div class="row" *ngFor="let mortgagee of mortgagees; index as i">
    <app-mortgagee [mortgagee]="mortgagee" 
      [mortgagees]="mortgagees" 
      [buttonState]="mortgageeButtonStates[calculateButtonStateIndex(i)]"
      [message]="noMorgageeMessage"
      [totalMortgagees]="mortgagees.length"
      [isInFlight]="homePolicyResponse.inFlight"
      [datesLocked]="checkPolicyDatesLocked()">
    </app-mortgagee>
  </div>
</div>

<app-success [showSuccess]="hasResponseSuccessStatus()" [message]="successMessage">
</app-success>

<app-error [errorMessage]="errorMessage">
</app-error>


