<div class="action-message" *ngIf="showSuccess">
  <div><i class="icon icon-check-mark"></i></div>
  <div *ngIf="message" class="message">
    {{message}}
  </div>
  <div *ngIf="!message" class="message">
    <h3>Success!</h3>
  </div>
  <div class="row">
    <div class="col-md-6 col-xs-12 coverage-button"> 
      <button class="btn btn-default col-md-5 col-xs-12 pull-right"
      (click)="onViewCoveragesClick()">View Coverages</button>
    </div>
    <div class="col-md-6 col-xs-12 request-button">
      <button class="btn btn-primary col-md-5 col-xs-12"
      (click)="onViewDocumentsClick()">Get Documents</button>
    </div>
  </div>
</div>
