<div class="col-md-12">
  <div class="panel panel-primary">
    <div class="panel-heading">
      <div class="row">
        <div class="col-md-2 col-xs-2 icon-box">
          <i class="icon" [ngClass]="displayPanelIcon()"></i>
        </div>
        <div class="col-md-8 col-xs-8 heading-box">
          <h3 class="panel-title">{{ getPanelTitle() }}</h3>
          <p class="panel-subtitle">{{ getPanelSubtitle() }}</p>
        </div>
        <div class="col-md-2 col-xs-2 toggle-box pull-right">
          <div class="toggle-box-content" (click)="togglePanelVisibility()">
            <i class="icon" [ngClass]="displayCaret()"></i>
            <span class="toggle-title">{{ currentToggleTitle }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-body" *ngIf="isPanelBodyExpanded">
      <app-auto-lienholder [vehicle]="vehicle"
        [buttonState]="buttonState"
        [policy]="policy"
        [address]="address">
      </app-auto-lienholder>
    </div>
  </div>
</div>
