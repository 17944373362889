import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment-wrapper';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class DisassociationEmailExpiryService {

  apiBaseEndpoint = environment.API_ENDPOINT;
  checkTokenExpiryResource = environment.LENDER_DISASSOCIATION_DECRYPT_API;
  resendEmailResource = environment.LENDER_DISASSOCIATION_MAIL_API;


  constructor(
    private cookieService: CookieService,
    private http: HttpClient
  ) { }


  checkTokenExpiry() {
    const payload = {
      token: this.cookieService.get('token')
    }
    console.log('Payload', payload);
    const endpoint = this.getApiEndpoint(this.checkTokenExpiryResource);
    return this.http.post(endpoint, payload);
  }

  resendVerificationEmail() {
    const endpoint = this.getApiEndpoint(this.resendEmailResource);
    return this.http.get(endpoint);
  }

  private getApiEndpoint(resource: string) {
    return `${this.apiBaseEndpoint}${resource}`;
  }
}
