import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment-wrapper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditAccountService {

  private informationUpdated = new BehaviorSubject(false);
  public informationUpdatedStream = this.informationUpdated.asObservable();

  saveEditAccountResource = environment.SAVE_EDIT_ACCOUNT;
  apiBaseEndpoint = environment.API_ENDPOINT;

  constructor( private http: HttpClient) { }

  set(flag: any): void {
    this.informationUpdated.next(flag);
  }

  saveEditAccount(editAccountValue : any) {
    return this.http.post(this.getApiEndpoint(this.saveEditAccountResource), editAccountValue);
  }

  private getApiEndpoint(resource: string) {
    return `${this.apiBaseEndpoint}${resource}`;
  }
}
