<div class="background">
    <div class="background-image">
      <div class="container">
        <div class="card" *ngIf="flag">
          <img alt="The Hartford" class="img-responsive" src="https://ts0.hfdstatic.com/higux/dist/images/logo.svg">
          <h3 class="text-center" id="verify">Your Email Verification was Successful</h3><br>
          <p class="clarifying-text Gotham3r">
            Your account has been successfully relinked<br><br>
          </p>
          <div class="text-center">
            <button class="btn btn-primary btn-lg" (click)="policySearch()">
              <span class="text-center" id="Continue" data-dl ='{"event":"mousedown","event_parent": "Page Tag","event_type" :"Link Click" ,"event_id" : "Continue", "event_value" : "Continue","da_track" : "true"}'>Continue</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  