import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TealiumModule } from '../services/tealium/tealium.module';
import { TealiumUtagService } from '../services/tealium/utag.service';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContentComponent } from './content/content.component';
import { ContainerComponent } from './container/container.component';
import { ErrorsModule } from '../errors/errors.module';
import { LvpFormsModule } from '../forms/lvp-forms.module';
import { RegistrationFooterComponent } from './footer/registration-footer/registration-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppModule } from '../app.module';
import { SearchPolicyComponent } from '../search-policy/search-policy.component';

@NgModule({
  imports: [
    RouterModule,
    ErrorsModule,
    LvpFormsModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    CommonModule,
    TealiumModule.forRoot()
  ],
  declarations: [
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    ContainerComponent,
    ContainerComponent,
    RegistrationFooterComponent,
    SearchPolicyComponent
  ],
  exports: [
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    ContainerComponent,
    RegistrationFooterComponent,
    SearchPolicyComponent
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class LayoutsModule {}
