import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ScrollToFirstInvalidDirective } from './directives/scroll-to-first-invalid.directive';
import { BlockCopyPasteDirective } from  './directives/block-copy-paste.directive';

@NgModule({
  imports: [HttpClientModule],
  declarations: [ScrollToFirstInvalidDirective, BlockCopyPasteDirective],
  exports: [ScrollToFirstInvalidDirective, BlockCopyPasteDirective],
  providers: []
})
export class SharedModule {}
