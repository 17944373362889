<div class="background">
    <div class="background-image">
      <div class="container">
        <div class="card">
          <img alt="The Hartford" class="img-responsive" src="https://ts0.hfdstatic.com/higux/dist/images/logo.svg">
          <h3 class="text-center" id="verify">This Link has Expired</h3><br>
          <p class="clarifying-text Gotham3r">
           Ask us to resend your verification email to get a new link<br><br>
          </p>
          <div class="text-center">
            <button class="btn btn-primary btn-lg" (click)="resendDisassociationEmail()">
              <span class="text-center" id="resend" data-dl ='{"event":"mousedown","event_parent": "Page Tag","event_type" :"Link Click" ,"event_id" : "Resend_Verification_Email", "event_value" : "Resend Verification Email","da_track" : "true"}'>Resend Verification Email</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  