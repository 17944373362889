import { Component, OnInit } from '@angular/core';
import { DisassociationEmailExpiryService } from './disassociation-email-expiry.service';
import { environment } from '../../environments/environment-wrapper';

@Component({
  selector: 'app-disassociation-email-expiry',
  templateUrl: './disassociation-email-expiry.component.html',
  styleUrls: ['./disassociation-email-expiry.component.scss']
})
export class DisassociationEmailExpiryComponent implements OnInit {

  url: string | any;
  env = environment;
  payload: any;

  constructor(
    private disassociationEmailExpiryService: DisassociationEmailExpiryService
  ) { }

  ngOnInit() {
    console.log("Inside disassociation email expiry");
  }

  resendDisassociationEmail() {
    console.log('Inside Resend Email::resendDisassociationEmail');
    return this.disassociationEmailExpiryService.resendVerificationEmail().subscribe(
      (response: any) => {
        console.log(response);
      }
    );
  }

}
