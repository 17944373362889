export class LienholderInfoRequest {
  address: string | any;
  city: string | any;
  stateProv: string | any;
  postalCode: string | any;
  lienHolderName: string | any;
  loanNumber: string | any;
  address2: string | any;

  constructor(address?: string, city?: string, stateProv?: string,
    lienholderName?: string,
    loanNumber?: string,
    address2?: string) {
    this.address = address;
    this.city = city;
    this.stateProv = stateProv;
    this.lienHolderName = lienholderName;
    this.address2 = address2;
    this.loanNumber = loanNumber;
  }
}
