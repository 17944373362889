<footer class="footer footer-thin" id="footer">
  <div class="container">
    <div class="row content-row" id="legalTextBottom">
      <div class="col-xs-4 copyright" id="copyright">{{copyrightText}}</div>
      <div id="contact-us-wrapper" class="col-xs-8">
        <ul class="links list-inline" id="contact-us">
          <li><a id="legalFooter" href="{{legalNoticeLink}}" target="_blank">Legal Notice</a></li>
          <li><a id="securityFooter" href="{{securityStatement}}" target="_blank">Security Statement</a></li>
          <li><a id="accessibilityFooter" href="{{statementLink}}" target="_blank">Accessibility Statement</a></li>
          <li><a id="privacyFooter" href="{{privacyPolicyLink}}" target="_blank">Privacy Policy</a></li>
          <li><a id="caPrivacyChoices" [href]="caPrivacyChoices" target="_blank" data-dl='{"da_track":"false"}'>Your California
            Privacy Choices <span class="cpra-icon"></span></a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
