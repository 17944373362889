import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss']
})
/**
 * The form field error component.
 */
export class FieldErrorComponent {

  /**
   * The error message to be displayed.
   */
  @Input() errorMessage: string | undefined;

  /**
   * The boolean value indicates whether the form field has an error.
   */
  @Input() hasError: boolean | undefined;

  /**
   * The name of the field that has an error
   */
  @Input() fieldName: string | undefined;
}
