<div class="col-xs-12 col-sm-12 col-md-12 home-policy-label">
  <h4>
    <b>Auto Lender Dashboard</b>
  </h4>
  <p>Use your dashboard to:</p>
  <ul>
    <li>
      <p>Verify Policy and Vehicle Coverages</p>
    </li>
    <li>
      <p>Request Declaration Pages</p>
    </li>
  </ul>
  <app-policy-info [policy]="policy"></app-policy-info>
</div>

<div *ngFor="let vehicle of policy.vehicleList; index as i">
    <app-auto-panel
      [isPanelBodyExpanded]="false"
      [policyType]="policy.policyType"
      [buttonState]="buttonStates[i]"
      [vehicle]="vehicle"
      [policy]="policy"
      [address]="policy.addressDTO">
    </app-auto-panel>
  </div>
<app-panel [panelTitle]="PanelTitle.POLICY_COVERAGES"
           [isPanelBodyVisible]="DefaultPanelView.POLICY_COVERAGES"
           [panelBody]="policyCoverages"
           [policyTypeCode]="policy.policyType">
</app-panel>
<app-panel [panelTitle]="PanelTitle.DOC_REQ"
  [isPanelBodyVisible]="DefaultPanelView.DOC_REQ"
  [panelBody]="autoDocRequests"
  [policyTypeCode]="policy.policyType">
</app-panel>
