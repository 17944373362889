import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment-wrapper';
import { ActivatedRoute } from '@angular/router';
import { SearchPolicyService } from '../search-policy/search-policy.service';
import { EditAccountService } from '../edit-account/edit-account.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-security',
  templateUrl: './account-security.component.html',
  styleUrls: ['./account-security.component.scss']
})
export class AccountSecurityComponent implements OnInit, OnDestroy {

  headerMsg: String = 'Account & Security';
  alertMessage: String | undefined;
  contactInformation: { Name: string, userId: string, email: string } | any;
  name: string | undefined;
  userId: string | undefined;
  email: string | undefined;
  showAlert: boolean | undefined;
  env = environment;
  private Subscribe:Array<Subscription> = [];
  
  constructor(
    private editAccountservice: EditAccountService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private searchPolicyService: SearchPolicyService
  ) { }


  ngOnInit() {
    if (this.cookieService.get('CIAMSession') === '') {
      window.location.href = this.env.CIAM_LOGIN;
    }
    this.Subscribe.push(this.editAccountservice.informationUpdatedStream.subscribe(
      flag => {
        if (flag) {
          this.alertMessage = 'Thank you. Your Information is up to date.';
          this.showAlert = true;
        }
      }
    ));
    this.editAccountservice.set(false);
    this.alertSuccess();
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.Subscribe.forEach(sub => sub.unsubscribe());
  }
  
  onChange(event: string | any) {
      window.location.href = event;
  }

  getUserInfo() {
    this.Subscribe.push(this.searchPolicyService.getLoggedInUser().subscribe(
      (response:any) => {
        this.name = response['body']['firstName'] + ' ' + response['body']['lastName'];
        this.userId = response['body']['ciamUserName'];
        this.email = response['body']['email'];
        this.contactInformation = { Name: this.name, userId: this.userId, email: this.email};
        sessionStorage.clear();
        sessionStorage.setItem('firstName', response['body']['firstName']);
        sessionStorage.setItem('lastName', response['body']['lastName']);
        sessionStorage.setItem('ciamUserName', response['body']['ciamUserName']);
        sessionStorage.setItem('email', response['body']['email']);
      }));
  }

  private alertSuccess(): void {
    if (this.isPasswordUpdate()) {
      this.alertMessage = 'Thank you. Your password is up to date.';
      this.showAlert = true;
    }
  }

  private isPasswordUpdate() {
    return this.route.snapshot.queryParamMap.get('passwordUpdate') === 'success';
  }
}
