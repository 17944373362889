<div class="row coverage-details">
  <table class="table">
    <thead>
      <tr>
        <th class="col-xs-4">{{headingTitle1}}</th>
        <th class="col-xs-8">{{headingTitle2}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let coverage of coverages">
        <td>
          <span class="data-prefix-xs">{{headingTitle1}}<em class="mobile-colon">:</em></span>
          <span class="row-data">{{coverage.coverageDescription}}
            <ng-template #toolTipTemplateRef>
              <h3>What Is Additional Limits of Liability?</h3>
              <div>Additional Limits of Liability (also known as Replacement Cost Coverage A)
                  provides extra coverage when the amount of your loss exceeds the Coverage A limit on your policy. In the event of a total loss,
                  Additional Limits of Liability provides extra coverage up to 125% or 150% of Coverage A.</div>
            </ng-template>
                <span id="addLimitCoverageTooltip" *ngIf="displayAdditionalLimitsCoverage(coverage)" [tooltip]="toolTipTemplateRef">
                  <i class="icon icon-info"></i>
                </span>
          </span>
        </td>
        <td>
          <span class="data-prefix-xs">{{headingTitle2}}<em class="mobile-colon">:</em></span>
          <!-- <span class="row-data" id="lower-limit" *ngIf="displayCoverage(coverage)">{{coverage.coverageLowerLimit |  currency :'USD':true:'1.0-0'}}</span>
          <span class="row-data" id="upper-limit" *ngIf="coverage.coverageUpperLimit"> / {{coverage.coverageUpperLimit |  currency :'USD':true:'1.0-0'}}</span> -->
          <span class="row-data" id="lower-limit" *ngIf="displayCoverage(coverage)">{{coverage.coverageLowerLimit |  currency :'USD':'symbol-narrow':'1.0-0'}}</span>
          <span class="row-data" id="upper-limit" *ngIf="coverage.coverageUpperLimit"> / {{coverage.coverageUpperLimit |  currency :'USD':'symbol-narrow':'1.0-0'}}</span>
          <span class="row-data" id="wind-hail-coverage" *ngIf="displayWindHailCoverage(coverage)">{{coverage.coverageLowerLimit | number:'1.0-0'}}% x Dwelling Coverage</span>
          <!-- <span class="row-data" id="wind-hail-number" *ngIf="displayWindHailNumber(coverage)">{{coverage.coverageLowerLimit |  currency :'USD':true:'1.0-0'}}</span> -->
          <span class="row-data" id="wind-hail-number" *ngIf="displayWindHailNumber(coverage)">{{coverage.coverageLowerLimit |  currency :'USD':'symbol-narrow':'1.0-0'}}</span>
          <span class="row-data" id="add-limit-coverage" *ngIf="displayAdditionalLimitsCoverage(coverage)">{{coverage.coverageLowerLimit}} x Dwelling Coverage</span>
          <span class="row-data" id="no-coverage" *ngIf="displayNoCoverage(coverage)">{{coverage.coverageLowerLimit}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
