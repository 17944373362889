export class VehicleRequest {
  vehicleModel: string | any;
  vehicleMake: string | any;
  vinNumber: string | any;
  vehicleyear: string | any;
  
  constructor(vehicleModel?: string,
    vehicleMake?: string,
    vinNumber?: string,
    vehicleYear?: string) {
    this.vehicleModel = vehicleModel;
    this.vehicleMake = vehicleMake;
    this.vinNumber = vinNumber;
    this.vehicleyear = vehicleYear;
  }
}
