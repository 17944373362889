<div class="col-md-12 col-xs-12 billing-details">
  <h3>
    <strong>Billing Details</strong>
  </h3>
  <p *ngIf="displayNoBillingInfoMessage()">We’re sorry, your billing information is not available right now. Please contact us.</p>
  <p *ngIf="displayBillingAmountMessage()">These amounts may not reflect recent payments, charges, or credits. Amounts may include installment fees, except where
    prohibited by law.</p>
  <div *ngIf="!hidePremiums">
    <div class="col-md-12 col-xs-12">
      <div class="billing-row total-premium">
        <label>Total Policy Premium:</label>
        <span *ngIf="homePolicyResponse.policyPremium">{{homePolicyResponse.policyPremium | currency}}</span>
      </div>
      <div class="billing-row current-balance">
        <label>Current Balance:</label>
        <span *ngIf="homePolicyResponse.billing.billingInfo.balanceInfo.totalDueAmt">{{homePolicyResponse.billing.billingInfo.balanceInfo.totalDueAmt | currency}}</span>
      </div>
    </div>
    <div *ngIf="displayPaidInFullReceiptButton()" class="paid-in-full-receipt-button-div">
      <div class="action-message-failure" *ngIf="showFailureMessagePaidInFullReceipt">
        <div>
          <i class="icon icon-attention"></i>
        </div>
        <div class="message">We're Sorry...</div>
        <p>The system is currently unavailable. Please try again in an hour.</p>
      </div>
      <div class="success-view-coverages-submit-another-div" *ngIf="hidePaidInFullButtonAfterSubmission">
        <div>
          <i class="icon icon-check-mark"></i>
        </div>
        <div class="message">Success!</div>
        <p>
          We'll send your documents within an hour.
        </p>
        <div class="div-view-coverages-submit-another-button">
          <div class="div-view-coverages-button">
            <button (click)="onViewCoveragesButtonClick()" class="btn btn-primary btn-block col-md-2 col-xs-2 view-coverages-button"
              data-dl='{"event": "mousedown","event_id": "view coverages","da_track":"true"}'>
              View Coverages
            </button>
          </div>
          <div class="gap-view-coverages-submit-another"></div>
          <div class="div-submit-another-button">
            <button (click)="onSubmitAnotherButtonForReceiptClick()" class="btn btn-primary btn-block col-md-2 col-xs-2 submit-another-button"
              data-dl='{"event": "mousedown","event_id": "submit another","da_track":"true"}'>
              Submit Another
            </button>
          </div>
        </div>
      </div>
      <button *ngIf="!hidePaidInFullButtonAfterSubmission" (click)="onPaidInFullButtonClick()" class="btn btn-primary btn-block col-md-2 col-xs-2 paid-in-full-button"
        data-dl='{"event": "mousedown","event_id": "Home Policy","da_track":"true"}'>
        Get A Paid In Full Receipt
      </button>
      <div *ngIf="isPaidReceiptFormVisible">
        <app-paid-in-full-receipt-form [homePolicy]="homePolicyResponse" [mortgagee]="mortgagee" [policyType]='PolicyType.HOME' (hasErrors)="onBillingReceiptHasErrors($event)"
          (cancel)="onPaidInFullReceiptFormCancel($event)" (submit)="onPaidInFullReceiptFormSubmit($event)">
        </app-paid-in-full-receipt-form>
      </div>
    </div>
    <div *ngIf="displayGetInvoiceButton()" class="get-invoice-button-div">
      <div class="action-message-failure" *ngIf="showFailureMessageGetInvoice">
        <div>
          <i class="icon icon-attention"></i>
        </div>
        <div class="message">We're Sorry...</div>
        <p>The system is currently unavailable. Please try again in an hour.</p>
      </div>
      <div class="success-view-coverages-submit-another-div" *ngIf="hideGetInvoiceButtonAfterSubmission">
        <div>
          <i class="icon icon-check-mark"></i>
        </div>
        <div class="message">Success!</div>
        <p>
          We'll send your documents within an hour.
        </p>
        <div class="div-view-coverages-submit-another-button">
          <div class="div-view-coverages-button">
            <button (click)="onViewCoveragesButtonClick()" class="btn btn-primary btn-block col-md-2 col-xs-2 view-coverages-button"
              data-dl='{"event": "mousedown","event_id": "view coverages","da_track":"true"}'>
              View Coverages
            </button>
          </div>
          <div class="gap-view-coverages-submit-another"></div>
          <div class="div-submit-another-button">
            <button (click)="onSubmitAnotherButtonForInvoiceClick()" class="btn btn-primary btn-block col-md-2 col-xs-2 submit-another-button"
              data-dl='{"event": "mousedown","event_id": "submit another","da_track":"true"}'>
              Submit Another
            </button>
          </div>
        </div>
      </div>
      <button *ngIf="!hideGetInvoiceButtonAfterSubmission" (click)="onGetInvoiceButtonClick()" class="btn btn-primary btn-block col-md-2 col-xs-2 get-invoice-button"
        data-dl='{"event": "mousedown","event_id": "Home Policy","da_track":"true"}'>
        Get An Invoice
      </button>
      <div *ngIf="isGetInvoiceFormVisible">
        <app-get-an-invoice-form [homePolicy]="homePolicyResponse" [mortgagee]="mortgagee" [policyType]='PolicyType.HOME' (hasErrors)="onGetInvoiceHasErrors($event)"
          (cancel)="onGetInvoicetFormCancel($event)" (submit)="onGetInvoiceFormSubmit($event)">
        </app-get-an-invoice-form>
      </div>
    </div>
  </div>
  <div class="line-row">
    <hr class="billing-line" />
  </div>
  <div class="col-sm-12 col-md-12 col-xs-12 declarations-page">
    <div class="action-message-failure" *ngIf="showFailureMessage">
      <div>
        <i class="icon icon-attention"></i>
      </div>
      <div class="message">We're Sorry...</div>
      <p>The system is currently unavailable. Please try again in an hour.</p>
    </div>
    <div class="hide-dec-message" *ngIf="hideDecPageAfterSubmission">
      <div>
        <i class="icon icon-check-mark"></i>
      </div>
      <div class="message">Success!</div>
      <p>
        We'll send your documents within an hour.
      </p>
    </div>
    <div class="declarations-page-description"
			*ngIf="!hideDecPageAfterSubmission">
			<h3>
				<strong>Declarations Page</strong>
			</h3>
			<p *ngIf="displayMessageforHidingDecPageButton()">{{displayMessage}}</p>
			<div *ngIf="!isDecPageCopyButtonDisable">
				<p>The Declarations Page is a summary of the policy. It includes
					policy limits, coverages, effective dates, and policyholder
					information.</p>

				<button (click)="onGetDeclarationsPageCopyClick()"
					class="btn btn-primary btn-block col-md-2 col-xs-2 get-declaration-button"
					data-dl='{"event": "mousedown","event_id": "Home request declaration page","da_track":"true"}'>
					Get Declarations Page Copy</button>

				<div *ngIf="isDecFormVisible">
					<app-declaration-request-form [homePolicy]="homePolicyResponse"
						[policyType]='PolicyType.HOME' (hasErrors)="onHasErrors($event)"
						(cancel)="onDecFormCancel($event)"
						(submit)="onDecFormSubmit($event)">
					</app-declaration-request-form>
				</div>
			</div>
		</div>
  </div>
</div>
