import { Component, Input, OnDestroy } from '@angular/core';
import { MortgageeDetailsComponent } from '../mortgagee-details/mortgagee-details.component';
import { PolicyCoveragesComponent } from '../policy-coverages/policy-coverages.component';
import { BillingDocRequestsComponent } from '../billing-doc-requests/billing-doc-requests.component';
import { HomePolicyResponse } from '../../shared/api/home-policy-response.model';
import { AlertsService } from '../../alerts/alerts.service';
import { Subscription } from 'rxjs';
import { Policy } from '../../shared/policy.constants';

/**
 * The {HomePolicyComponent} represents the home policy component.
 */
@Component({
  selector: 'app-home-policy',
  templateUrl: './home-policy.component.html',
  styleUrls: ['./home-policy.component.scss'],
  providers: [AlertsService]
})
export class HomePolicyComponent implements OnDestroy {

  /**
   * The instance of the {HomePolicyResponse} input property.
   */
  @Input() policy: HomePolicyResponse | any;

  /**
   * The mortgagee details component.
   */
  mortgageeDetails:any = MortgageeDetailsComponent;

  /**
   * The policy coverages component.
   */
  policyCoverages:any = PolicyCoveragesComponent;

  /**
   * The billing and documents requests component.
   */
  billingDocRequests:any = BillingDocRequestsComponent;

  /**
   * The panel titles.
   */
  PanelTitle = {
    MORTGAGEE_DETAILS: 'Mortgagee Details',
    POLICY_COVERAGES: 'Policy Coverages',
    BILL_DOC_REQ: 'Billing & Documents'
  };

  /**
   * The defualt panel collapsible view values.
   */
  DefaultPanelView = {
    MORTGAGEE_DETAILS: true,
    POLICY_COVERAGES: false,
    BILL_DOC_REQ: false
  };

  /**
   * Home icons
   */
  IconType = {
    CONDO_ICON_H04: '04',
    CONDO_ICON_H06: '06'
  };

  /**
   * The reactive subscription.
   */
  subscription: Array<Subscription> = [];

  /**
   * Initializes a new instance of the {HomePolicyComponent}.
   * @param The instance of the {AlertsService} represents the alerts service.
   */
  constructor(private alertsService: AlertsService) {
    this.subscribeToPanelViewState();
  }

  /**
   * Subscribes to panel view state.
   */
  private subscribeToPanelViewState(): void {
    this.subscription.push(this.alertsService.panelViewState.subscribe(viewState => {
      setTimeout(() => {
        switch (viewState.panelName) {
          case Policy.PanelNames.POLICY_DETAILS:
            this.DefaultPanelView = {
              MORTGAGEE_DETAILS: false,
              POLICY_COVERAGES: viewState.isVisible,
              BILL_DOC_REQ: false
            };
            break;
          case Policy.PanelNames.DOCUMENT_REQUEST:
            this.DefaultPanelView = {
              MORTGAGEE_DETAILS: false,
              POLICY_COVERAGES: false,
              BILL_DOC_REQ: viewState.isVisible
            };
            break;
        }
      }, 0);
    }));
  }

  /**
   * Changes home icon based on policy type
   */
  displayHomeIcon() {
    switch (this.policy?.policyTypeCd) {
      case this.IconType.CONDO_ICON_H04:
      case this.IconType.CONDO_ICON_H06:
        return { 'icon-condominium': true };
      default:
        return { 'icon-house': true };
    }
  }

  /**
   * Cleans up resources.
   */
  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
