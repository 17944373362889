import { HomeAddressRequest } from './home-address-request.model';

export class MortgageeRequest {
  index: string | any;
  actionRequested: string | any;
  mortgageeName: string | any;
  mortgageeNameRemainder: string | any;
  loanNumber: string | any;
  address: HomeAddressRequest | any;
  isPayor: string | any;
  constructor(index?: string,
    actionRequested?: string,
    mortgageeName?: string,
    mortgageeNameRemainder?: string,
    loanNumber?: string,
    address?: HomeAddressRequest,
    isPayor?: string) {
    this.index = index;
    this.actionRequested = actionRequested;
    this.mortgageeName = mortgageeName;
    this.mortgageeNameRemainder = "";
    this.loanNumber = loanNumber;
    this.address = address;
    this.isPayor = isPayor;
  }
}
