/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  AUTO_SERVICE_URI: 'service/lvpservice/auto?target_env=stq&client_id=1160',
  ECM_ADD_LIENHOLDER_SERVICE_URI: 'service/lvpservice/addLienholder?target_env=stq&client_id=1160',
  ECM_UPDATE_LIENHOLDER_SERVICE_URI: 'service/lvpservice/updateLienholder?target_env=stq&client_id=1160',
  ECM_DELETE_LIENHOLDER_SERVICE_URI: 'service/lvpservice/deleteLienholder?target_env=stq&client_id=1160',
  ECM_REQUEST_DECLARATION_SERVICE_URI: 'service/lvpservice/requestDeclaration?target_env=stq&client_id=1160',
  ECM_FINANCIAL_INSTITUTION_RESOURCE: 'service/lvpservice/refdata?target_env=stq&client_id=1160',
  API_ENDPOINT: 'https://qa-api.thehartford.com/pl/polservice/',
  PEGA_API_ENDPOINT: 'https://qa-api.thehartford.com/pl/polservice/',
  AUTO_ENDPOINT: 'https://qa-api.thehartford.com/pl/polservice/',
  LENDER_REGISTRATION_API: 'express/api/lvp/lenderRegistration?target_env=stq&client_id=1160',
  LENDER_RESEND_VERIFICATION_MAIL_API: 'express/api/lvp/resendEmail?target_env=stq&client_id=1160',
  CIAM_LOGIN: 'https://account-qa.thehartford.com/agent/login',
  EDIT_PASSWORD: 'https://account-qa.thehartford.com/agent/updatePassword?appid=LVP&goto=https%3A%2F%2Fstq-lender.thehartford.com%2Fverify%2F%23%2Faccount-security',
  REDIRECT_URL: 'https://account-saml-qa.thehartford.com/openam/saml2/jsp/idpSSOInit.jsp?metaAlias=/Agents/idpduckcreek&spEntityID=https%3A%2F%2Fsystemtest4-lenderverification.thehartford.com%2Fsp',
  EXPRESS_API_AMEND: 'express/api/amend?target_env=stq&client_id=1160',
  EXPRESS_API_HOMEPOLICY: 'express/api/homePolicy?target_env=stq&client_id=1160',
  EXPRESS_API_DECPAGE: 'express/api/decPage?target_env=stq&client_id=1160',
  EXPRESS_API_INVOICE: 'express/api/invoice?target_env=stq&client_id=1160',
  UPDATE_PROFILE_RESOURCE: 'express/api/lvp/updateProfile?target_env=stq&client_id=1160',
  NEWCO_RESOURCE: 'express/api/lvp/lenderPolicySearch?target_env=stq&client_id=1160',
  PROFILE_API: 'express/api/lvp/getProfileName?target_env=stq&client_id=1160',
  COMPANYLIST_RESOURCE: 'express/api/lvp/getCompanies?target_env=stq&client_id=1160',
  INVALD_SEARCH_ATTEMPT: 'express/api/lvp/invalidSearchAttempt?target_env=stq&client_id=1160',
  SAVE_EDIT_ACCOUNT: 'express/api/lvp/updateAccountInfo?target_env=stq&client_id=1160',
  //JWT Changes
  INVISIBLE_RECAPTCHA_SITE_KEY: '6LcPoE4hAAAAAFHaDaeCzchXeCnRHvCOt-uPfaWa',
  API_DP_AUTH_ROOT: 'https://qa-api.thehartford.com/authorize',
  API_DP_AUTH_TOKEN_PARAM: '?target_env=stq&client_id=1160&responsetype=token&scope=csc',
  API_DP_AUTH_RECAPTCHA_PARAM: '&responsetype=token&target_env=stq',
  API_DP_PARAMS_FIRST: '?client_id=1161&recaptcha=invisible&scope=lvp',
  RECAPTCHA_ENABLED_URL: 'express/api/lvp/recaptcha?target_env=stq&client_id=1160',
  LOGGER_EXPRESS: 'https://qa-api.thehartford.com/pl/polservice/express/api/log',
  CA_PRIVACY_CHOICES: 'https://qawww.thehartford.com/data-privacy-opt-out-form',
  LENDER_DISASSOCIATION_MAIL_API: 'express/api/lvp/disassociationEmail?target_env=stq&client_id=1160',
  RELINK_DISASSOCIATED_CIAM: 'express/api/lvp/relinking?target_env=stq&client_id=1160',
  LENDER_DISASSOCIATION_DECRYPT_API:'express/api/lvp/decryptToken?target_env=stq&client_id=1160',
  IS_VALID_CIAM: 'express/api/lvp/isValidCiamSession?target_env=stq&client_id=1160',
  IS_VALID_TOKEN_CIAM: 'express/api/lvp/validateTokenCiamSession?target_env=stq&client_id=1160'
};
