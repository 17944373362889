import { Component, Input, OnChanges } from '@angular/core';
import { Mortgagee } from '../mortgagee.model';
import { Policy } from '../../shared/policy.constants';
/**
 * The {MortgageeComponent} represents the information for a mortgagee.
 */
@Component({
  selector: 'app-mortgagee',
  templateUrl: './mortgagee.component.html',
  styleUrls: ['./mortgagee.component.scss']
})
export class MortgageeComponent implements OnChanges {
  /**
   * The instance of {HomePolicyResponse} as a component input.
   */
  @Input() mortgagee: Mortgagee | any;

  /**
   * The collection of the {Mortgagee} represents all mortgagees of this policy.
   */
  @Input() mortgagees: Mortgagee[] | any;

  /**
   * The button state of the current mortgagee as a component input.
   */
  @Input() buttonState: any;

  /**
   * The mortgagee message to be displayed in case there is no mortgagees.
   */
  @Input() message: string | any;

  /**
   * The total number of the mortgagees.
   */
  @Input() totalMortgagees: number | any;

  /**
   * The boolean value indicates whether the effective dates are locked.
   */
  @Input() datesLocked: boolean | any;

  /**
   * The boolean value indicates whether a change is in progress.
   */
  @Input() isInFlight: boolean | any;

  /**
   * The current user action.
   */
  currentAction: string | any;

  /**
   * Initializes a new instance of the {MortgageeComponent}.
   */
  constructor() { }

  /**
   * Checks whether the no-mortgagee message shall be displayed.
   */
  shouldDisplayMessage(): boolean {
    return !this.mortgagee && !(
      this.currentAction === Policy.Action.ADD
      || this.currentAction === Policy.Action.CLOSE
      || this.currentAction === Policy.Action.DELETE
      || this.currentAction === Policy.Action.UPDATE
      || this.currentAction === Policy.Action.REPLACE
      || this.currentAction === Policy.Action.SUCCESS
      || this.currentAction === Policy.Action.ADD_SECOND
    );
  }
  /**
   * Handles on component change event. At this point of the lifecycle
   * we have the {HomePolicyResponse} availble for inspection.
   */
  ngOnChanges(): void {
  }

  /**
   * Gets mortgagee escrowed.
   */
  getMortgageeEscrowed() {
    return this.mortgagee?.isEscrowed ? 'Yes' : 'No';
  }

  /**
   * Gets mortgagee heading.
   */
  getMortgageHeading() {
    if (!this.mortgagee) { return; }
    switch (this.mortgagee.position) {
      case Policy.MortgageePosition.FIRST_MORTGAGEE:
        return Policy.MortgageeHeading.FIRST_MORTGAGEE;
      case Policy.MortgageePosition.SECOND_MORTGAGEE:
        return Policy.MortgageeHeading.SECOND_MORTGAGEE;
    }
    return; 
  }

  /**
   * Formats the mortgagee buttons.
   */
  formatMortgageeButtons() {
    return {
      'mortgagee-buttons': !this.mortgagee
    };
  }

  /**
   * Sets the button color to gray if its state is disabled.
   * @param state The button state.
   */
  setButtonStateDisplay(state: boolean) {
    if (!state) {
      return {
        'disabled': true
      };
    }
    return {
      'disabled': false
    };
  }

  /**
   * Sets current action.
   */
  setAction(action: string): void {
    this.currentAction = action;
  }

  /**
   * Handles the add mortagee click event.
   */
  onAddClick(): void {
    this.setAction(Policy.Action.ADD);
  }

  /**
   * Handles the update mortgagee click event.
   */
  onUpdateClick(): void {
    this.setAction(Policy.Action.UPDATE);
  }

  /**
   * Handles the replace mortgagee click event.
   */
  onReplaceClick(): void {
    this.setAction(Policy.Action.REPLACE);
  }

  /**
   * Handles the delete mortgagee click event.
   */
  onDeleteClick(): void {
    this.setAction(Policy.Action.DELETE);
  }

  /**
   * Handles cancel event.
   * @param action The user action.
   */
  onUserAction(action: string): void {
    this.setAction(action);
  }
}
