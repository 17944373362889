import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, ValidatorFn, FormControl } from '@angular/forms';
import { FormField } from './form-field.model';

@Injectable({
  providedIn: 'root',
})
/**
 * The LVP form service that provides ability to create and manipulate form.
 */
export class LvpFormService {

  constructor() { }

  /**
   * Creates a form by first creating the controls and add them to the form.
   * @param form The instance of the FormGroup.
   * @param formBuilder The instance of the FormBuilder.
   * @param formFields The instances of the FormField.
   */
  createForm(form: FormGroup, formBuilder: FormBuilder, formFields: FormField[]): FormGroup {
    form = this.initializeForm(form, formBuilder);
    formFields.forEach(field => {
      this.registerControl(form, formBuilder, field.controlName, field.defaultValue, field.validators);
    });
    return form;
  }

  /**
   * Initializes a new instance of the `FormGroup`.
   * @param form The instance of the `FormGroup`.
   * @param formBuilder The instance of the `FormBuilder`.
   * @returns The updated instance of the `FormGroup`.
   */
  private initializeForm(form: FormGroup, formBuilder: FormBuilder): FormGroup {
    if (!(form instanceof FormGroup)) {
      return formBuilder.group({});
    }
    return form;
  }

  /**
   * Registers a control by first creating it and then adding it to the form.
   * @param form The instance of the FormGroup representing the form.
   * @param formBuilder The instance of the FormBuilder provides factory methods to create the form.
   * @param controlName The control name.
   * @param defaultValue The control default value.
   * @param validators The array of validators for the given control.
   * @returns The updated instance of the FormGroup.
   */
  registerControl(form: FormGroup,
    formBuilder: FormBuilder,
    controlName: string,
    defaultValue: string,
    validators: any[]): FormGroup {
      const control = this.createControl(formBuilder, defaultValue, validators);
      if (!control) { throw new Error('The invalid conttrol object.'); }
      return this.addControl(form, controlName, control);
    }

  /**
   * Create a form control using the form builder and the given the control information.
   * @param formBuilder The instance of the FormBuilder.
   * @param defaultValue The control default value.
   * @param validators The list of validators of the control.
   */
  createControl(formBuilder: FormBuilder,
    defaultValue: string,
    validators: ValidatorFn[]): AbstractControl {
    return formBuilder.control(defaultValue, validators);
  }

  /**
   * Gets the value of the a form control.
   * @param form The instance of the FormGroup.
   * @param controlName The control name.
   * @returns The value of the given control.
   */
  getControlValue(form: FormGroup, controlName: string): any {
    return form.get(controlName)?.value;
    //return form.get(controlName).value;
  }

  /**
   * Adds a form control to a form.
   * @param form The instance of the FormGroup representing the form.
   * @param controlName The control name.
   * @param control The control.
   * @returns The instance of the FormGroup.
   */
  addControl(form: FormGroup, controlName: string, control: AbstractControl): FormGroup {
    form.addControl(controlName, control);
    //form.get(controlName).updateValueAndValidity();
    form.get(controlName)?.updateValueAndValidity();
    return form;
  }

  /**
   * Removes a control from a form.
   * @param form The instance of the FormGroup.
   * @param controlName The control name.
   * @returns The instance of the FormGroup.
   */
  removeControl(form: FormGroup, controlName:string): FormGroup {
    form.removeControl(controlName);
    form.updateValueAndValidity();
    return form;
  }

  /**
   * Sets a value and some options to a form control.
   * @param form The instance of the FormGroup.
   * @param controlName The form control name.
   * @param value The value to be set to the control.
   * @param options The option values to be set to the control.
   * @returns The updated instance of the FormGroup.
   */
  setControlValue(form: FormGroup, controlName: string, value: any, options?: Object): FormGroup {
    // form.get(controlName).setValue(value, options);
    // form.get(controlName).updateValueAndValidity();
    form.get(controlName)?.setValue(value, options);
    form.get(controlName)?.updateValueAndValidity();
    return form;
  }
}

