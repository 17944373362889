import { Component } from '@angular/core';
import { environment } from 'src/environments/environment-wrapper';

@Component({
  selector: 'app-registration-footer',
  templateUrl: './registration-footer.component.html',
  styleUrls: ['./registration-footer.component.scss']
})
export class RegistrationFooterComponent {
  todaysDate: Date = new Date();
  privacyPolicyLink = 'https://www.thehartford.com/online-privacy-policy';
  legalNoticeLink = 'https://www.thehartford.com/legal-notice';
  contactUs = 'https://www.thehartford.com/contact-the-hartford';
  statementLink = 'https://www.thehartford.com/accessibility-statement';
  copyrightText = '© 2022 The Hartford';
  caPrivacyChoices = environment.CA_PRIVACY_CHOICES;

}
