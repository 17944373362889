import { Component, Input, Output, OnChanges, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { FormField } from '../../shared/form/form-field.model';
import { ValidationPatterns } from '../../shared/form/validation-patterns';
import { LvpFormService } from '../../shared/form/lvp-form.service';
import { LvpFormValidationService } from '../../shared/form/lvp-form-validation.service';
import { LienholderRequest } from '../../shared/api/auto-policy/lienholder-request.model';
import { LienholderInfoRequest } from '../../shared/api/auto-policy/lienholder-info-request.model';
import { Vehicle } from '../../shared/api/auto-policy/vehicle.model';
import { VehicleRequest } from '../../shared/api/auto-policy/vehicle-request.model';
import { AutoPolicyResponse } from '../../shared/api/auto-policy/auto-policy-response.model';
import { LienholderResponse } from '../../shared/api/auto-policy/lienholder-response.model';
import { ErrorMessage } from '../../errors/error/error-message.model';
import { ErrorMessages } from '../../shared/validation/error-messages';
import { LienholderService } from './lienholder.service';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AutoFinancialInstitutionResponse } from '../../shared/api/auto-policy/auto-financial-institution-response.model';
import { AutoFinancialInstitution } from '../../shared/api/auto-policy/auto-financial-institution.model';
import { CustomEventService } from '../../services/tealium/custom-event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lienholder-form',
  templateUrl: './lienholder-form.component.html',
  styleUrls: ['./lienholder-form.component.scss']
})
export class LienholderFormComponent implements OnChanges, OnInit, OnDestroy {

  /**
   * The instance of the {ErrorMessage} represents error message information.
   */
  errorMessage: ErrorMessage | any;

  /**
   * The instance of the {FormGroup} represents the lienholder form.
   */
  lienholderForm: FormGroup | any;

  /**
   * The value indicates whether the form is being submitted used for delayed validation.
   */
  isFormSubmitting = false;

  /**
   * The value incidates whether the API request is in progress and used for disabling the action button.
   */
  isActionInProgress = false;

  /**
   * The instnace of the {Vehicle} represents the vehicle.
   */
  @Input() vehicle: Vehicle | any;

  /**
   * The instance of the {AutoPolicyResponse} represents the auto policy response from HTTP API call.
   */
  @Input() policy: AutoPolicyResponse | any;

  /**
   * The current action invoked by the user.
   * The value can be 'ADD', 'UPDATE', 'DELETE', or 'REPLACE'.
   */
  @Input() action: string | any;

  /**
   * The cancelled event.
   */
  @Output() cancelEvent = new EventEmitter<string>();

  /**
   * The success event.
   */
  @Output() success = new EventEmitter<string>();

  /**
   * The failure event.
   */
  @Output() failure = new EventEmitter<string>();

  showModel:boolean =false;

  /**
   * Messages sent to analytics
   */
  readonly TealiumMessages = {
    LIENHOLDER_DETAILS_TRACKING: 'Lienholder Details Tracking',
    SUCCESS: 'Success',
    FAILURE: 'Failure',
    AUTO_POLICY: 'Auto Policy',
    AUTO_SUCCESS_MESSAGE:
      'Your request has been submitted but still needs to be verified. Please check back in 3-4 business days to confirm your changes'
  };

  /**
   * The input field lengths.
   */
  readonly InputLength = {
    COMPANY_NAME_MAX_LENGTH: 64,
    FIN_INSTI_MAX_LENGTH: 50,
    LOAN_NUMBER_MAX_LENGTH: 10,
    ADDRESS_MAX_LENGTH: 50,
    CITY_MAX_LEGNTH: 50,
    STATE_MAX_LENGTH: 2,
    ZIPCODE_MAX_LENGTH: 5,
    OWNERSHIP_TYPE_MAX_LENGTH: 1
  };

  /**
   * The vehicle owndership codes/types.
   */
  readonly OwnershipType = {
    LEASED: 'L',
    FINANCED: 'F'
  };

  /**
   * The action.
   */
  readonly Action = {
    ADD: 'ADD',
    REPLACE: 'REPLACE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    CANCEL: 'CANCEL',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
  };

  /**
   * The current ownership type.
   */
  currentOwnershipType: string | any;

  /**
   * The list of US states in abbreviated form.
   */
  readonly states: string[] = ['AL',
    'AK',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WI',
    'WV',
    'WY'];

  /**
   * The current state value.
   */
  currentState: string | any;

  /**
   * The instance of the {BsModalRef} represents the modal popup reference.
   */
  modalRef: BsModalRef | any;

  /**
   * The instance of the {AutoFinancialInstitutionResponse}
   */
  financialInstitutionResponse: AutoFinancialInstitutionResponse | any;

  /**
   * The collection of the {AutoFinancialInstitution}.
   */
  financialInstitutions: AutoFinancialInstitution[] | any;

  /**
   * The current financial institution name.
   */
  currentFinancialInstitution: string | any;

  private Subscribers:Array<Subscription> = [];

  /**
   * Initializes a new instance of the {LienholderFormComponent}.
   * @param formBuilder The instance of the {LvpFormService} represents the form service.
   * @param formService The instance of the {LvpFormService} represents the form service.
   * @param customEvent The instance of the {CustomEventService} represents the Tealium custom service.
   * @param formValidationService The instance of the {LvpFormValidationService} represents the form validation service.
   * @param lienholderService The instance of the {LienholderService} represents the lienholder service.
   * @param modalService The instance of the {BsModalService} represents the modal service.
   */
  constructor(private formBuilder: FormBuilder,
    private formService: LvpFormService,
    private customEvent: CustomEventService,
    private formValidationService: LvpFormValidationService,
    private lienholderService: LienholderService,
    private modalService: BsModalService) {
  }

  /**
   * Handles component initialization event.
   */
  ngOnInit(): void {
    this.fetchFinancialInstitutions();
    this.createForm();
  }

  ngOnDestroy(): void {
    this.Subscribers.forEach(sub => sub.unsubscribe());
  }

  /**
   * Fetches the financial institutions from the restul service.
   */
  private fetchFinancialInstitutions(): void {
    this.Subscribers.push(this.lienholderService.getFinancialInstitutions().subscribe(
      response => this.handleFinancialInstitutionResponse(response),
      error => this.handleResponseError(error)
    ));
  }

  /**
  * Handles the financial institution response by inspecting the for errors and success indicators.
  * @param response The instance of the {AutoFinancialInstitutionResponse} represents the financial institution response.
  */
  private handleFinancialInstitutionResponse(response: AutoFinancialInstitutionResponse): void {
    if (response.errorDTO && response.errorDTO.errorDesc.length !== 0) {
      //console.error(`Error: ${JSON.stringify(response.errorDTO)}`);
      this.errorMessage = new ErrorMessage(
        response.errorDTO.errorDesc,
        response.errorDTO.errorCd,
        response.errorDTO.httpStatusCd);
      this.failure.emit(this.Action.FAILURE);
      return;
    }
    if (response.hasOwnProperty('error')) {
      //console.error(`Error: ${JSON.stringify(response)}`);
      const errorResponse : any  = JSON.stringify(response);
      this.errorMessage = new ErrorMessage(errorResponse['error']);
      this.failure.emit(this.Action.FAILURE);
      return;
    }
    this.financialInstitutionResponse = response;
    this.financialInstitutions = this.financialInstitutionResponse.financeInstList;
    if (this.financialInstitutions && this.financialInstitutions.length !== 0) {
      this.currentFinancialInstitution = this.financialInstitutions[0].financialCompName;
    }
   // console.log(`Financial institution response: ${JSON.stringify(this.financialInstitutions)}`);
  }

  /**
   * Shows the company name if the current company name has value of 'Other'.
   */
  showCompanyNameField(): boolean {
    return this.currentFinancialInstitution
      && this.currentFinancialInstitution.toLowerCase() === 'other'? true : false;
  }

  /**
   * Handles financial institution select change event.
   * @param event The selected event object.
   */
  onFinancialInstitutionChange(event:any): void {
    this.currentFinancialInstitution = this.formService.getControlValue(this.lienholderForm, 'financialInstitution');
    if (this.currentFinancialInstitution) {
      const financialInstitution = this.financialInstitutions?.find((x:any) => x.financialCompName === this.currentFinancialInstitution);
      if (financialInstitution) {
        this.populateLienholderForm(financialInstitution);
      }
    }
    if (this.showCompanyNameField()) {
      this.resetLienholderFields();
    }
    this.resetCompanyNameValidators();
  }

  /**
   * Populates the lienholder form based on the selected value from the dropdown.
   * @param financialInstitution The instance of the {AutoFinancialInstitution} represents the lienholder.
   */
  private populateLienholderForm(financialInstitution: AutoFinancialInstitution): void {
    this.formService.setControlValue(this.lienholderForm, 'address1', financialInstitution.financialCompAddress);
    this.formService.setControlValue(this.lienholderForm, 'address2', financialInstitution.financialCompStreet);
    this.formService.setControlValue(this.lienholderForm, 'city', financialInstitution.financialCompCity);
    this.formService.setControlValue(this.lienholderForm, 'state', financialInstitution.financialCompState);
    this.currentState = financialInstitution.financialCompState;
    this.formService.setControlValue(this.lienholderForm, 'zipcode', financialInstitution.financialCompZipCd);
  }

  /**
   * Resets the lienholder fields.
   */
  private resetLienholderFields(): void {
    this.formService.setControlValue(this.lienholderForm, 'address1', null);
    this.formService.setControlValue(this.lienholderForm, 'address2', null);
    this.formService.setControlValue(this.lienholderForm, 'city', null);
    this.formService.setControlValue(this.lienholderForm, 'state', null);
    this.currentState = "";
    this.formService.setControlValue(this.lienholderForm, 'zipcode', null);
  }

  /**
   * Resets the company name validators.
   */
  private resetCompanyNameValidators(): void {
    this.formValidationService.clearControlValidators(this.lienholderForm, 'companyName');
    if (this.showCompanyNameField()) {
      this.formValidationService.setControlValidators(this.lienholderForm,
        'companyName', [Validators.required,
        Validators.maxLength(this.InputLength.COMPANY_NAME_MAX_LENGTH),
        Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA_PERIOD)]);
    }
  }

  /**
   * Opens the modal popup for confirmation.
   * @param template The instance of the {TemplateRef} represents the template.
   */
  openModal() {
    this.showModel = true;
  }
  
  closePopup() {
    this.showModel = false;
  }

  /**
   * Handles component change event.
   */
  ngOnChanges(): void {
    if (this.action === this.Action.UPDATE || this.action === this.Action.REPLACE) {
      this.prepareLienholderForEditing();
    }
    this.handleActionChanges();
  }

  /**
   * Handles action changes.
   */
  private handleActionChanges(): void {
    if (this.action === this.Action.FAILURE || this.action === this.Action.SUCCESS) {
      if (this.modalRef) {
        this.modalRef.hide();
      }
    }
  }

  /**
   * Creates an instance of the {FormGroup} represents the lienholder form.
   */
  private createForm(): void {
    const formFields: FormField[] = [
      new FormField(null, 'ownershipType', [Validators.required,
      Validators.maxLength(this.InputLength.OWNERSHIP_TYPE_MAX_LENGTH)]),

      new FormField(null, 'financialInstitution', [Validators.required,
      Validators.maxLength(this.InputLength.FIN_INSTI_MAX_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'companyName', [Validators.required,
      Validators.maxLength(this.InputLength.COMPANY_NAME_MAX_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA_PERIOD)]),

      new FormField(null, 'loanNumber', [Validators.maxLength(this.InputLength.LOAN_NUMBER_MAX_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'address1', [Validators.required,
      Validators.maxLength(this.InputLength.ADDRESS_MAX_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'address2', [Validators.maxLength(this.InputLength.ADDRESS_MAX_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'city', [Validators.required,
      Validators.maxLength(this.InputLength.CITY_MAX_LEGNTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'state', [Validators.required,
      Validators.maxLength(this.InputLength.STATE_MAX_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'zipcode', [Validators.required,
      Validators.maxLength(this.InputLength.ZIPCODE_MAX_LENGTH),
      Validators.pattern(ValidationPatterns.US_ZIPCODE)]),
    ];
    this.lienholderForm = this.formService.createForm(this.lienholderForm, this.formBuilder, formFields);
  }

  /**
   * Processes the add request action.
   */
  private processAddRequest(): void {
    if (!this.validateForm()) {
      return;
    }
    this.Subscribers.push(this.lienholderService.addLienholder(this.prepareLienholderRequest())
      .subscribe(response => this.handleLienholderResponse(response),
        error => this.handleResponseError(error)));
  }

  /**
   * Processes the update request action.
   */
  private processUpdateRequest(): void {
    if (!this.validateForm()) {
      return;
    }
    this.Subscribers.push(this.lienholderService.updateLienholder(this.prepareLienholderRequest())
      .subscribe(response => this.handleLienholderResponse(response),
        error => this.handleResponseError(error)));
  }

  /**
   * Processes the delete request action.
   */
  private processDeleteRequest(): void {
    this.Subscribers.push(this.lienholderService.deleteLienholder(this.prepareLienholderRequestForDeleteOperation())
      .subscribe(response => this.handleLienholderResponse(response),
        error => this.handleResponseError(error)));
  }

  /**
   * Validates the lienholder form.
   * @returns The boolean value indicates whether the form is valid.
   */
  private validateForm(): boolean {
    this.isFormSubmitting = true;
    this.isActionInProgress = true;
    this.lienholderForm = this.formValidationService.validateFormControls(this.lienholderForm);
    if (!this.lienholderForm.valid) {
      this.isActionInProgress = false;
      return false;
    }
    return true;
  }

  /**
   * Handles the lienholder response by inspecting the for errors and success indicators.
   * @param response The instance of the {LienholderResponse} represents the lienholder response.
   */
  private handleLienholderResponse(response: LienholderResponse): void {
    this.isActionInProgress = false;
    this.showModel = false;
    if (response.errorDTO && response.errorDTO.errorDesc.length !== 0) {
      this.customEvent.policyDetailsTracking(this.TealiumMessages.LIENHOLDER_DETAILS_TRACKING, this.action + this.TealiumMessages.FAILURE,
        this.TealiumMessages.AUTO_POLICY, response.messageDesc);
      this.errorMessage = new ErrorMessage(
        response.errorDTO.errorDesc,
        response.errorDTO.errorCd,
        response.errorDTO.httpStatusCd);
      this.failure.emit(this.Action.FAILURE);
      return;
    }

    if (response.hasOwnProperty('error')) {
      this.isActionInProgress = false;
      const errorResponse : any  = JSON.stringify(response);
      this.errorMessage = new ErrorMessage(errorResponse['error']);
      this.failure.emit(this.Action.FAILURE);
      return;
    }

    if (response.messageCode === 'Success') {
      this.customEvent.policyDetailsTracking(this.TealiumMessages.LIENHOLDER_DETAILS_TRACKING, this.action + this.TealiumMessages.SUCCESS,
        this.TealiumMessages.AUTO_POLICY, this.TealiumMessages.AUTO_SUCCESS_MESSAGE);
      this.success.emit(this.Action.SUCCESS);
      this.resetForm();
    } else {
      this.isFormSubmitting = false;
      throw new Error('Unable to inspect the auto declaration page response.');
    }
  }

  /**
  * Handles the response error.
  * @param error The HTTP response error.
  */
  private handleResponseError(error: any): void {
    //console.error(`HTTP response error: ${JSON.stringify(error)}`);
    this.isActionInProgress = false;
    this.errorMessage = new ErrorMessage(ErrorMessages.SYSTEM_DOWNTIME);
    this.customEvent.policyDetailsTracking(this.TealiumMessages.LIENHOLDER_DETAILS_TRACKING, this.action + this.TealiumMessages.FAILURE,
      this.TealiumMessages.AUTO_POLICY, ErrorMessages.SYSTEM_DOWNTIME);
    this.failure.emit(this.Action.FAILURE);
    this.isFormSubmitting = false;
    return;
  }

  /**
   * Prepares the lienholder request for deleting auto lienholder.
   */
  private prepareLienholderRequestForDeleteOperation(): LienholderRequest {
    const request = new LienholderRequest();

    // Address
    const lienholder = new LienholderInfoRequest();
    lienholder.address = this.vehicle?.othFinInstStreet??"";
    lienholder.city = this.vehicle?.othFinInstCity??"";
    lienholder.stateProv = this.vehicle?.othFinInstState??"";
    lienholder.postalCode = this.vehicle?.othFinInstZip??"";
    lienholder.lienHolderName = this.vehicle?.financialInstitution??"";
    lienholder.loanNumber = this.vehicle?.loanNumber??"";
    request.lienholder = lienholder;

    // Vehicle
    const vehicle = new VehicleRequest();
    vehicle.vehicleyear = this.vehicle?.vehicleyear??"";
    vehicle.vehicleMake = this.vehicle?.vehicleMake??"";
    vehicle.vehicleModel = this.vehicle?.vehicleModel??"";
    vehicle.vinNumber = this.vehicle?.vinNumber??"";
    request.vehicle = vehicle;

    // Policy information
    request.guid = this.policy?.guid??"";
    request.policyNumber = this.policy?.policyNumber??"";
    request.policyStartDate = this.policy?.policyStartDate??"";
    request.policyExpiryDate = this.policy?.policyExpiryDate??"";
    request.policyHolderFirstName = this.policy?.policyHolderFirstName??"";
    request.policyHolderLastName = this.policy?.policyHolderSurName??"";
    request.lob = this.policy?.lOBCd || 'Auto';
    request.propertyType = 'AUTO';

    // Address at policy level
    request.zipcode = this.policy?.addressDTO.postalCode??"";
    request.city = this.policy?.addressDTO.city??"";
    request.address1 = this.policy?.addressDTO.address??"";
    request.address2 = this.policy?.addressDTO.streetName??"";
    request.state = this.policy?.addressDTO.state??"";

    // Misc
    request.marketSegmentDesc = this.policy?.marketSegmentDesc??"";
    request.ratingState = this.policy?.addressDTO.state??"";

    request.lenderFirstName = this.policy?.lenderFirstName??"";
    request.lenderLastName = this.policy?.lenderLastName??"";

    //console.log(`Lienholder request: ${JSON.stringify(request)}`);
    return request;
  }

  /**
   * Prepares the lienholder request for adding or updating auto lienholder.
   */
  private prepareLienholderRequest(): LienholderRequest {
    const request = new LienholderRequest();

    // Address
    const lienholder = new LienholderInfoRequest();
    lienholder.address = this.formService.getControlValue(this.lienholderForm, 'address1');
    lienholder.address2 = this.formService.getControlValue(this.lienholderForm, 'address2');
    lienholder.city = this.formService.getControlValue(this.lienholderForm, 'city');
    lienholder.stateProv = this.formService.getControlValue(this.lienholderForm, 'state');
    lienholder.postalCode = this.formService.getControlValue(this.lienholderForm, 'zipcode');
    lienholder.lienHolderName = this.getCompanyName();
    lienholder.loanNumber = this.formService.getControlValue(this.lienholderForm, 'loanNumber');
    request.lienholder = lienholder;

    // Vehicle
    const vehicle = new VehicleRequest();
    vehicle.vehicleyear = this.vehicle?.vehicleyear??"";
    vehicle.vehicleMake = this.vehicle?.vehicleMake??"";
    vehicle.vehicleModel = this.vehicle?.vehicleModel??"";
    vehicle.vinNumber = this.vehicle?.vinNumber??"";
    request.vehicle = vehicle;

    // Policy information
    request.guid = this.policy?.guid??"";
    request.policyNumber = this.policy?.policyNumber??"";
    request.policyStartDate = this.policy?.policyStartDate??"";
    request.policyExpiryDate = this.policy?.policyExpiryDate??"";
    request.policyHolderFirstName = this.policy?.policyHolderFirstName??"";
    request.policyHolderLastName = this.policy?.policyHolderSurName??"";
    request.lob = this.policy?.lOBCd || 'Auto';
    request.propertyType = 'AUTO';

    // Address at policy level
    request.zipcode = this.policy?.addressDTO.postalCode??"";
    request.city = this.policy?.addressDTO.city??"";
    request.address1 = this.policy?.addressDTO.address??"";
    request.address2 = this.policy?.addressDTO.streetName??"";
    request.state = this.policy?.addressDTO.state??"";

    // Misc
    request.marketSegmentDesc = this.policy?.marketSegmentDesc??"";
    request.ratingState = this.policy?.addressDTO.state??"";

    request.lenderFirstName = this.policy?.lenderLastName??"";
    request.lenderLastName = this.policy?.lenderLastName??"";

    //console.log(`Lienholder request: ${JSON.stringify(request)}`);
    return request;
  }

  /**
   * Gets the company name from form field.
   * @returns The company name.
   */
  private getCompanyName(): string {
    if (this.showCompanyNameField()) {
      return this.formService.getControlValue(this.lienholderForm, 'companyName');
    }
    return this.formService.getControlValue(this.lienholderForm, 'financialInstitution');
  }

  /**
   * Prepares current lienholder data for editing.
   */
  private prepareLienholderForEditing(): void {
    this.formService.setControlValue(this.lienholderForm, 'companyName', this.vehicle?.financialInstitution);
    this.formService.setControlValue(this.lienholderForm, 'loanNumber', this.vehicle?.loanNumber);
    this.formService.setControlValue(this.lienholderForm, 'address1', this.vehicle?.othFinInstStreet);
    this.formService.setControlValue(this.lienholderForm, 'city', this.vehicle?.othFinInstCity);
    this.formService.setControlValue(this.lienholderForm, 'state', this.vehicle?.othFinInstState);
    this.formService.setControlValue(this.lienholderForm, 'zipcode', this.vehicle?.othFinInstZip);
    this.formService.setControlValue(this.lienholderForm, 'ownershipType', this.vehicle?.ownershipCd);
    this.currentOwnershipType = this.vehicle?.ownershipCd;
    this.currentState = this.vehicle?.othFinInstState;
  }

  /**
   * Handles the owndership type click event.
   * @param type The owndership type which can LEASED or FINANCED.
   */
  onOwnershipTypeClick(type:any): void {
    this.currentOwnershipType = type;
    console.log(`currentOwnershipType: ${this.currentOwnershipType}`);
    if (this.currentOwnershipType !== this.OwnershipType.LEASED &&
      this.currentOwnershipType !== this.OwnershipType.FINANCED) {
      throw new Error('Invalid ownership type.');
    }
    this.formService.setControlValue(this.lienholderForm, 'ownershipType', this.currentOwnershipType);
  }

  /**
   * Resets form.
   */
  private resetForm(): void {
    if (this.lienholderForm) {
      this.lienholderForm.reset();
    }
    this.currentOwnershipType = "";
    this.currentState = "";
    this.isFormSubmitting = false;
    if (this.financialInstitutions && this.financialInstitutions.length !== 0) {
      this.currentFinancialInstitution = this.financialInstitutions[0].financialCompName;
    } else {
      this.currentFinancialInstitution = "";
    }
  }

  /**
   * Handles the cancel click event.
   */
  onCancelClick(): void {
    this.cancelEvent.emit(this.Action.CANCEL);
    this.resetForm();
  }

  /**
   * Handles the add auto lienholder event.
   */
  onAddClick(): void {
    this.processAddRequest();
  }

  /**
   * Handles the update auto lienholder event.
   */
  onUpdateClick(): void {
    this.processUpdateRequest();
  }

  /**
   * Handles the delete auto lienholder event.
   */
  onDeleteClick(): void {
    this.processDeleteRequest();
  }

  /**
   * Checks whether a given form field is valid.
   * @param field The form field name.
   * @returns The boolean value indicates whether the validation is valid.
   */
  isFormFieldValid(field: string): boolean {
    return !this.lienholderForm.get(field)?.valid
      && this.lienholderForm.get(field)?.touched
      && !this.hasInputPatternError(field)
      && this.isFormSubmitting? true : false;
  }

  /**
   * Displays form field error if validation fails.
   * @param field The field control name.
   */
  displayFormFieldError(field: string): any {
    const hasError = this.isFormSubmitting && (this.isFormFieldValid(field) || this.hasInputPatternError(field));
    return {
      'has-error': hasError,
      'has-feedback': hasError
    };
  }

  /**
   * Checks whether the input pattern is valid.
   * @param field The form field/control name.
   * @returns False if invalid; otherwise, true.
   */
  hasInputPatternError(field: string): boolean {
    return this.formValidationService.hasPatternError(this.lienholderForm, field)
      && this.formService.getControlValue(this.lienholderForm, field)
      && this.isFormSubmitting;
  }

  /**
   * Checks action value whether the form should be displayed.
   */
  showLienholderForm(): boolean {
    return (this.action && this.action === this.Action.ADD
      || this.action === this.Action.UPDATE
      || this.action === this.Action.REPLACE)
      && !this.showActionMessage()
      && !this.showFailureMessage();
  }

  /**
   * Checks action value whether the form delete panel should be displayed.
   */
  showLienholderDeletePanel(): boolean {
    return (this.action && this.action === this.Action.DELETE)
      && !this.showActionMessage()
      && !this.showFailureMessage()? true : false;
  }

  /**
   * Checks whether action message should be displayed.
   */
  showActionMessage(): boolean {
    return this.action && this.action === this.Action.SUCCESS? true : false;
  }


  /**
   * Checks whether failure message should be displayed.
   */
  showFailureMessage(): boolean {
    return this.action && this.action === this.Action.FAILURE?true : false;
  }
}
