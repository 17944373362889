import { MortgageeAddress } from './mortgagee-address.model';

export class Mortgagee {
  loanNumber: string;
  companyName: string | null;
  companyNameCont: string;
  address: MortgageeAddress | any;
  isEscrowed: boolean | any;
  position: number | any;
    index: string | any;

  constructor(loanNumber?: string,
    companyName?: string,
    companyNameCont?: string,
    address?: MortgageeAddress,
    isEscrowed?: boolean,
    position?: number) {
    this.loanNumber = loanNumber??"";
    this.companyName = companyName??"";
    this.companyNameCont = companyNameCont??"";
    this.address = address;
    this.isEscrowed = isEscrowed;
    this.position = position;
  }
}
