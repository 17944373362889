import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TealiumModule } from '../services/tealium/tealium.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ErrorsModule } from '../errors/errors.module';
import { MortgageeFormComponent } from './mortgagee-form/mortgagee-form.component';
import { CustomPopupModule } from '../custom-popup/custom-popup.module';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    CommonModule,
    NgxCaptchaModule,
    TealiumModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ErrorsModule,
    CustomPopupModule,
    SharedModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    MortgageeFormComponent
  ],
  exports: [
    MortgageeFormComponent
  ],
  providers: []
})
export class LvpFormsModule { }
