<div class="col-xs-12 col-sm-12 col-md-12 home-policy-label">
  <h4>
    <b>Home Lender Dashboard</b>
  </h4>
  <p>Use your dashboard to:</p>
  <ul>
    <li>
      <p>Change Mortgagee information instantly</p>
    </li>
    <li>
      <p>Verify Policy Coverages and Billing Details</p>
    </li>
    <li>
      <p>Get Declaration Pages on-demand</p>
    </li>
  </ul>
  <app-policy-info [policy]="policy"></app-policy-info>
</div>
<app-panel [panelTitle]="PanelTitle.MORTGAGEE_DETAILS"
[isPanelBodyVisible]="DefaultPanelView.MORTGAGEE_DETAILS"
[panelBody]="mortgageeDetails"
[policyTypeCode]="policy.policyTypeCd"></app-panel>

<app-panel [panelTitle]="PanelTitle.POLICY_COVERAGES"
[isPanelBodyVisible]="DefaultPanelView.POLICY_COVERAGES"
[panelBody]="policyCoverages"
[policyTypeCode]="policy.policyTypeCd"></app-panel>

<app-panel [panelTitle]="PanelTitle.BILL_DOC_REQ"
[isPanelBodyVisible]="DefaultPanelView.BILL_DOC_REQ"
[panelBody]="billingDocRequests"
[policyTypeCode]="policy.policyTypeCd"></app-panel>
