<div id="header" [ngClass]="{'fade': enable}">
  <div class="navbar-brand">
    <img class="logo" alt="The Hartford" [src]="headerLogoDesk">
  </div>
  <header class="navbar navbar-default" id="navBar">
    <ul class="nav navbar-nav navbar-icons navbar-right">
      <li class="dropdown">
        <a data-toggle="dropdown">
          <i class="icon icon-settings icon-2x" data-dl='{"event":"mousedown", "event_parent" : "Page Tag","event_type" : "Link Click", "event_id" : "Settings","event_value" :"Settings", "da_track" : "true"}'></i>
          <span>Settings</span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a routerLink="/account-security" >Account & Security</a>
          </li>
        </ul>
      </li>
      <li>
        <a  routerLink="/logout" data-dl ='{ "event_parent" : "Page Tag","event_type": "Link Click","event_id" : "Logout", "event_value" : "Logout","event":"mousedown", "da_track" : "true"}'>
          <i class="icon icon-logout icon-2x"></i>
          <span >Log Out</span>
        </a>
      </li>
    </ul>
    <div class="navbar-primary" id="primaryNav"></div>
  </header>
</div>
