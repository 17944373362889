import {
  ValidatorFn,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

/**
 * The custom validators for a reactive form.
 */
export class CustomValidators {

  /**
   * Validates U.S. zipcode in 00000-0000 format.
   * @returns The validation errors or null.
   */
  static usZipcodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const pattern = /^(\d{5}(?:-\d{4})?|\d{9})$/;
      if (control && control.value && !pattern.test(control.value)) {
        return {
          pattern: true
        };
      }
      return null;
    };
  }

  /**
   * Validates Recaptcha value. False or null value will result in failure.
   */
  static recaptchaRequired(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value !== true) {
        return { recaptchaRequired: true };
      }
      return null;
    };
  }

  /**
   * Validates the effective given the valid date range.
   * @returns The validation error if there is any problem.
   */
  static effectiveDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateValue = control.value;
      const minDate = new Date();
      const maxDate = new Date();
      minDate.setDate(minDate.getDate() - 30);
      maxDate.setDate(maxDate.getDate() + 14);
      const inputDate: Date = new Date(dateValue);
      if (!inputDate || !(inputDate instanceof Date) || inputDate < minDate || inputDate > maxDate) {
        return { invalidEffectiveDate: true };
      }
      return null;
    };
  }

  /**
   * Confirms fields given are matching
   * @returns The validation errors or null if there are no errors.
   */
  static confirmMatchingFields(fieldLeft: string, fieldRight: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.compareTwoFields(control, fieldLeft, fieldRight);
    };
  }

  /**
   * Compares two form fields.
   * @param control The instance of {AbstractControl} which is the current form field.
   * @param fieldLeft The control/field name of the form.
   * @param fieldRight The control/field name of the form.
   * @returns The validation errors or null if there are no errors.
   */
  private static compareTwoFields(control: AbstractControl, fieldLeft: string, fieldRight: string): ValidationErrors | null {
    const form = control.parent;
    if (form) {
      const controlLeft = form.get(fieldLeft);
      const controlRight = form.get(fieldRight);
      if (!controlLeft || !controlRight || !controlLeft.value || !controlRight.value) {
        return null;
      }
      if (controlLeft.value.trim().toLowerCase() === controlRight.value.trim().toLowerCase()) {
        if (controlLeft.errors) {
          controlLeft.setErrors(null);
        }
        if (controlRight.errors) {
          controlRight.setErrors(null);
        }
        return null;
      }
    }
    return {
      mismatch: true
    };
  }
}
