<div class="container-fluid">
  <div class="row" id="content">
    <div class="col-md-6 col-sm-6 col-xs-12" id="firstcomponent">
      <div class="greet"> Welcome, {{firstName}}</div><br>
      <div class="container">
        <form [formGroup]="policySearchForm" (ngSubmit)="searchPolicy()">
          <div class="row">
            <div class="col-md-5 col-sm-5 col-xs-12">
              <div class="form-group has-feedback">
                <label for="policy" class="control-label" [class.label-error]="isFormFieldEntered('policyNumber') || policyError || isFormFieldValid('policyNumber') || freezePolicy">What policy are you looking for?</label>
                <div class="input-group">
                <span class="input-group-addon" [ngClass]="{'addon_group': (isFormFieldEntered('policyNumber')) || policyError || isFormFieldValid('policyNumber') || freezePolicy}">
                  <i class="icon icon-secure icon-2x" [ngClass]="{'addon_icon': (isFormFieldEntered('policyNumber')) || policyError || isFormFieldValid('policyNumber') || freezePolicy}"></i>
                </span>
                  <input id="policy" type="text"
                         (keyup)="checkPolicyError()"
                         [ngClass]="{'border': (isFormFieldEntered('policyNumber')) || policyError || isFormFieldValid('policyNumber') || freezePolicy}"
                         class="form-control gbx-unmasked" name="policy" formControlName="policyNumber" (blur) = "onFieldError('policyNumber')" aria-required="true" required   data-dl='{"event":"change","da_track":"true","event_parent":"Page Tag","event_id":"policy","event_value":"policy"}'>
                </div>
                <div *ngIf="isFormFieldEntered('policyNumber')">
                  <i class="icon icon-attention-alt"></i>
                  <span class="text-danger">
                  Please enter a policy number.
                </span>
                </div>
                <div *ngIf="isFormFieldValid('policyNumber')">
                  <i class="icon icon-attention-alt"></i>
                  <span class="text-danger">
                  Please enter a valid policy number.
                </span>
                </div>
                <div *ngIf="policyError">
                  <i class="icon icon-attention-alt"></i>
                  <span class="text-danger">
                  We can't find that customer. Please check your info and try again.
                </span>
                </div>
                <div *ngIf="freezePolicy">
                  <i class="icon icon-attention-alt"></i>
                  <span class="text-danger">
                  Try again in 24 hours. You can’t search this policy now.
                </span>
                </div>
                <div class="text-muted hint">
                  Enter a mix of 10 or 11 numbers and letters.<br> Examples: 55PHH123456, 55300123456.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="form-group has-feedback">
                <label for="zip" class="control-label" [class.label-error]="isFormFieldEntered('zipcode') || policyError || isFormFieldValid('zipcode') || freezePolicy">What's the customer's zip code?</label>
                <div class="input-group">
                <span class="input-group-addon" [ngClass]="{'addon_group': (isFormFieldEntered('zipcode')) || policyError || isFormFieldValid('zipcode') || freezePolicy}">
                  <i class="icon icon-house" [ngClass]="{'addon_icon': (isFormFieldEntered('zipcode')) || policyError || isFormFieldValid('zipcode') || freezePolicy}"></i>
                </span>
                  <input id="zip"
                         (keyup)="checkPolicyError()"
                         [ngClass]="{'border': (isFormFieldEntered('zipcode')) || policyError || isFormFieldValid('zipcode') || freezePolicy}"
                         type="text" class="form-control gbx-unmasked" name="zipcode" formControlName="zipcode" (blur) = "onFieldError('zipcode')"  data-dl='{"event":"change","da_track":"true","event_parent":"Page Tag","event_id":"zip","event_value":"zip"}' >
                </div>
                <div *ngIf="isFormFieldEntered('zipcode')">
                  <i class="icon icon-attention-alt"></i>
                  <span class="text-danger">
                  Please enter a 5-digit ZIP to continue.
                </span>
                </div>
                <div *ngIf="isFormFieldValid('zipcode')">
                  <i class="icon icon-attention-alt"></i>
                  <span class="text-danger">
                  Please enter a valid 5-digit ZIP to continue.
                </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" id="btn">
            <div class="col-md-5 col-sm-5 col-xs-12">
              <button type="submit" class="btn btn-success btn-lg btn-block" data-dl='{"event_parent":"Page Tag","event_type":"Button Click","event_id":"Search","event_value":"searchPolicy","event":"mousedown","da_track":"true"}'>
                <span>Search</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-6 col-sm-6" id="image">
      <object type="image/svg+xml" data="assets/images/bgImg_policySearch.svg"></object>
    </div>
  </div>
</div>
<div *ngIf="showModel">
  <div class="modal fade in" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">Try Again In 24 Hours</div>
        </div>
        <div class="modal-body">
          <p>You can't search this policy now.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-lg btn-block"
                  (click)="closePopup()">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
