<div class="action-message-failure" *ngIf="showFailureMessage">
  <div><i class="icon icon-attention"></i></div>
  <div class="message">We're Sorry...</div>
  <p>The system is currently unavailable. Please try again in an hour.</p>
</div>
<div class="action-message-success" *ngIf="!showFailureMessage && hideDecPageAfterSubmission">
  <div><i class="icon icon-check-mark"></i></div>
  <div class="message">Success!</div>
  <p>
    We'll send your documents within 2 business days.
  </p>
</div>
<div class="doc-requests col-md-12 col-xs-12" *ngIf="!hideDecPageAfterSubmission">
  <div>
    <h3>Declarations Page</h3>
    <p>
      The Declarations Page is a summary of the policy. It includes policy limits,
      coverages, effective dates, and policyholder
      information.
    </p>
  </div>

  <div class="request-button">
    <button
      data-dl='{"event": "mousedown","event_id": "Auto request declaration page","da_track":"true"}'
      (click)="onGetDeclarationsPageCopyClick()"
      class="btn btn-secondary-paired btn-block">
      Get Declarations Page Copy
    </button>
  </div>

  <div class="request-form">
    <div *ngIf="isDecFormVisible">
      <app-declaration-request-form
        (hasErrors)="onHasErrors($event)"
        [autoPolicy]='autoPolicyResponse'
        [policyType]='PolicyType.AUTO'
        (cancel)="onDecFormCancel($event)"
        (submit)="onDecFormSubmit($event)">
      </app-declaration-request-form>
    </div>
  </div>
</div>
