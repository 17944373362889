import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LogService } from './services/log/log.service';

@Component({
  selector: 'app-root',
  providers: [LogService],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(title: Title, private logService: LogService) {
    title.setTitle('Lender Verification Portal');
  }

  ngOnInit() {
    window.addEventListener('copy', (event) => {
      this.logService.logCopy(location.pathname);
    });

    window.addEventListener('paste', (event) => {
      this.logService.logPaste(location.pathname);
    });
  }

  ngOnDestroy() {
    sessionStorage.clear();
  }

  checkLoggedInURL() {
    if (window.location.href.indexOf('account-security') !== -1 || window.location.href.indexOf('edit-account') !== -1 ||
      window.location.href.indexOf('policy-details') !== -1 || window.location.href.indexOf('search-policy') !== -1
    ) {
      return true;
    }
    return false;
  }
}
