import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { HomePolicyComponent } from './home-policy/home-policy.component';
import { AutoPolicyComponent } from './auto-policy/auto-policy.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { MortgageeDetailsComponent } from './mortgagee-details/mortgagee-details.component';
import { PolicyCoveragesComponent } from './policy-coverages/policy-coverages.component';
import { CoverageLimitsComponent } from './policy-coverages/coverage-limits/coverage-limits.component';
import { BillingDocRequestsComponent } from './billing-doc-requests/billing-doc-requests.component';
import { MortgageeComponent } from './mortgagee/mortgagee.component';
import { CustomPopupModule } from '../custom-popup/custom-popup.module';
import { PolicyInfoComponent } from './policy-info/policy-info.component';

import { LvpFormsModule } from '../forms/lvp-forms.module';

import { AutoLienholderComponent } from './auto-lienholder/auto-lienholder.component';
import { PanelComponent } from './panel/panel.component';
import { AutoPanelComponent } from './auto-panel/auto-panel.component';
import { DeclarationRequestFormComponent } from './declaration-request-form/declaration-request-form.component';
import { LienholderFormComponent } from './lienholder-form/lienholder-form.component';
import { ErrorsModule } from '../errors/errors.module';
import { AutoDocRequestsComponent } from './auto-doc-requests/auto-doc-requests.component';
import { AlertsModule } from '../alerts/alerts.module';
import { SharedModule } from '../shared/shared.module';
import { NgxBootstrapModule } from '../shared/ngx-boostrap.module';
import { TealiumModule } from '../services/tealium/tealium.module';
import { PaidInFullReceiptFormComponent } from './paid-in-full-receipt-form/paid-in-full-receipt-form.component';
import { GetAnInvoiceFormComponent } from './get-an-invoice-form/get-an-invoice-form.component';
import { LayoutsModule } from '../layouts/layouts.module';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    CustomPopupModule,
    ReactiveFormsModule,
    LvpFormsModule,
    ReactiveFormsModule,
    ErrorsModule,
    TealiumModule.forRoot(),
    AlertsModule,
    SharedModule,
    NgxBootstrapModule,
    LayoutsModule
  ],
  // entryComponents: [
  //   MortgageeDetailsComponent,
  //   PolicyCoveragesComponent,
  //   BillingDocRequestsComponent,
  //   CoverageLimitsComponent,
  //   AutoDocRequestsComponent
  // ],
  declarations: [
    PolicyDetailsComponent,
    HomePolicyComponent,
    AutoPolicyComponent,
    MortgageeDetailsComponent,
    PolicyCoveragesComponent,
    BillingDocRequestsComponent,
    MortgageeComponent,
    CoverageLimitsComponent,
    PolicyInfoComponent,
    PolicyInfoComponent,
    AutoLienholderComponent,
    PanelComponent,
    AutoPanelComponent,
    DeclarationRequestFormComponent,
    LienholderFormComponent,
    AutoDocRequestsComponent,
    PaidInFullReceiptFormComponent,
    GetAnInvoiceFormComponent
  ],
  exports: [
    PolicyDetailsComponent,
    HomePolicyComponent,
    AutoPolicyComponent,
    MortgageeDetailsComponent,
    PolicyCoveragesComponent,
    BillingDocRequestsComponent,
    MortgageeComponent,
    CoverageLimitsComponent,
    PolicyInfoComponent,
    ReactiveFormsModule,
    PolicyInfoComponent,
    AutoLienholderComponent,
    PanelComponent,
    AutoPanelComponent,
    DeclarationRequestFormComponent,
    LienholderFormComponent,
    PaidInFullReceiptFormComponent,
    GetAnInvoiceFormComponent
  ],
  providers: [HomePolicyComponent]
})
export class PolicyModule { }
