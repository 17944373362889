<footer class="footer footer-thin" id="footer">
  <div class="container">
    <div class="row content-row" id="legalTextBottom">
      <div id="contact-us-wrapper" class="col-xs-8 col-xs-offset-2">
        <ul class="links list-inline">
          <li><a id="contactUsFooter" data-dl='{"da_track": "true", "event": "mousedown"}' href="{{contactUs}}" target="_blank">Contact Us</a></li>
          <li><a id="legalFooter" data-dl='{"da_track": "true", "event": "mousedown"}' href="{{legalNoticeLink}}" target="_blank">Legal Notice</a></li>
          <li><a id="accessibilityFooter" data-dl='{"da_track": "true", "event": "mousedown"}' href="{{statementLink}}" target="_blank">Accessibility Statement</a></li>
          <li><a id="privacyFooter" data-dl='{"da_track": "true", "event": "mousedown"}' href="{{privacyPolicyLink}}" target="_blank">Privacy Policy</a></li>
          <li><a id="caPrivacyChoices" [href]="caPrivacyChoices" target="_blank" data-dl='{"da_track": "true", "event": "mousedown"}'>Your California
            Privacy Choices <span class="cpra-icon"></span></a></li>
        </ul>
        <p class="copyright" id="copyright">{{copyrightText}}</p>
      </div>
    </div>
  </div>
</footer>
