import { Coverage } from '../coverage.model';
import { Vehicle } from './vehicle.model';
import { AutoAddress } from './auto-address.model';
import { AutoPolicyResponse } from './auto-policy-response.model';
import { ErrorDto } from '../error-dto.model';
import { AutoFinancialInstitution } from './auto-financial-institution.model';
import { AutoState } from './auto-state.model';
import { AutoFinancialInstitutionResponse } from './auto-financial-institution-response.model';

export class AutoPolicyDataFactory {

  static createAutoPolicyResponse(hasError: boolean = false): AutoPolicyResponse {
    return {
      guid: '7d4be55e2345bbc1a94127130d5f8e9a60e79c20',
      policyNumber: '99PHL141276',
      policyType: 'AUTOP',
      policyStatusCd: null,
      businessUnitId: '46',
      lOBCd: null,
      policyStartDate: '05/01/2018',
      policyExpiryDate: '5/01/2019',
      ratingState: 'MN',
      policyHolderFirstName: 'CALEB',
      policyHolderSurName: 'RAINING',
      ratePlan: 'PNG',
      marketSegmentDesc: 'AARP',
      stateCodeNumString: '22',
      policyLevelCovList: this.createCoverages(2),
      addressDTO: this.createAddress(1),
      vehicleList: this.createVehicles(2, true),
      errorDTO: hasError ? this.createErrorDto(1) : null,
      errorDTOList: hasError ? this.createErrorDtos(2) : null,
      lenderFirstName: 'John',
      lenderLastName: 'Joe'
    };
  }

  static createErrorDtos(n: number): ErrorDto[] {
    const errorDtos: ErrorDto[] = [];
    for (let x = 0; x < n; x++) {
      errorDtos.push(this.createErrorDto(x));
    }
    return errorDtos;
  }

  static createErrorDto(index: number): ErrorDto {
    return {
      errorCd: `ERR12344[${index}]`,
      errorDesc: `There is problem with your request.[${index}]`,
      httpStatusCd: `404[${index}]`
    };
  }

  static createAddress(index: number): AutoAddress {
    return {
      addressType: `Billing`,
      streetName: `Main St`,
      address: `500 BIELENBERG DR`,
      city: `SAINT PAUL`,
      state: `MN`,
      postalCode: `55125`,
      physicalPostalCode: `1234`,
      streetPostalCode: `555555`,
      garagingPostalCode: `77777`
    };
  }

  static createAddresses(n: number): AutoAddress[] {
    const autoAddresses: AutoAddress[] = [];
    for (let x = 0; x < n; x++) {
      autoAddresses.push(this.createAddress(x));
    }
    return autoAddresses;
  }

  static createCoverage(coverageCode: string,
    coverageType: string,
    coverageDescription: string,
    coverageLowerLimit: string,
    coverageUpperLimit: string): Coverage {
    return {
      coverageCode: coverageCode,
      coverageType: coverageType,
      coverageDescription: coverageDescription,
      coverageUpperLimit: coverageLowerLimit,
      coverageLowerLimit: coverageUpperLimit
    };
  }

  static createCoverages(n: number = 1): Coverage[] {
    const coverages: Coverage[] = [];
    for (let m = 0; m < n; m++) {
      const code = m % 2 === 0 ? `COLL` : `COMP`;
      const type = `VehicleCoverages[${m}]`;
      const description = `Collision[${m}]`;
      const lowerLimit = `500`;
      const uppperLimit = `10000`;
      coverages.push(this.createCoverage(code,
        type, description, lowerLimit, uppperLimit));
    }
    return coverages;
  }

  static createVehicle(index: number, hasLienholder: boolean = true): Vehicle {
    return {
      vehId: `Vehicle-01`,
      vinNumber: `1GCVKREC3HZ267857`,
      vehicleType: `PU`,
      vehicleyear: `2017`,
      vehicleMake: `CHEV`,
      vehicleMakeDisplayVal: `Test[${index}]`,
      vehicleModel: `SILVERADO K1500`,
      vehicleSubModel: `1GCVKREC3HZ267857`,
      vehicleSubModelLabel: `Test[${index}]`,
      financialInstitution: hasLienholder ? `VAULTRUST & GMAC` : null,
      othFinInstStreet: hasLienholder ? `P O BOX 650100` : null,
      othFinInstCity: hasLienholder ? `HUNT VALLEY` : null,
      othFinInstState: hasLienholder ? `NC` : null,
      ownershipCd: `L`,
      othFinInstZip: hasLienholder ? `21065` : null,
      vehicleCoverageDTOList: this.createCoverages(2),
      loanNumber: `Loan Number[${index}]`
    };
  }

  static createFinancialInstitutionResponse(hasError: boolean = false): AutoFinancialInstitutionResponse {
    return {
      errorDTO: hasError ? this.createErrorDto(1) : null,
      stateList: this.createStates(50),
      financeInstList: this.createFinancialInstitutions(30)
    };
  }

  static createVehicles(n: number, hasLienholder: boolean = true): Vehicle[] {
    const vehicles: Vehicle[] = [];
    for (let x = 0; x < n; x++) {
      vehicles.push(this.createVehicle(x, hasLienholder));
    }
    return vehicles;
  }

  static createStates(n: number): AutoState[] {
    const states: AutoState[] = [];
    for (let k = 0; k < n; k++) {
      states.push(this.createState(k));
    }
    return states;
  }

  static createState(n: number): AutoState {
    return {
      stateAbbr: `State ${n}`,
      stateCd: `TX ${n}`
    };
  }

  static createFinancialInstitutions(n: number): AutoFinancialInstitution[] {
    const institutions: AutoFinancialInstitution[] = [];
    for (let k = 0; k < n; k++) {
      institutions.push(this.createFinancialInstitution(k));
    }
    institutions.push({
      financialCompId: '00000',
      financialCompTypCd: `Other`,
      financialCompName: `Other`,
      financialCompNameAbbr: `Company Name Abbr`,
      financialCompAddress: `Main Street`,
      financialCompStreet: `Main Street`,
      financialCompCity: `City`,
      financialCompState: `HI`,
      financialCompZipCd: `43213`,
      financialCompDisplayInd: `Comp Display`
    });
    return institutions;
  }

  static createFinancialInstitution(n: number): AutoFinancialInstitution {
    return {
      financialCompId: `${123 + n}`,
      financialCompTypCd: `Code ${n}`,
      financialCompName: `Company Name ${n}`,
      financialCompNameAbbr: `Company Name Abbr ${n}`,
      financialCompAddress: `${123 + n} Main Street`,
      financialCompStreet: `${n} Main Street`,
      financialCompCity: `City ${n}`,
      financialCompState: `NC`,
      financialCompZipCd: `${12345 + n}`,
      financialCompDisplayInd: `Comp Display ${n}`
    };
  }
}
