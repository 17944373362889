import { Component, Input, OnChanges } from '@angular/core';
import { AutoPolicyResponse } from '../../shared/api/auto-policy/auto-policy-response.model';
import { AutoDocRequestsComponent } from '../auto-doc-requests/auto-doc-requests.component';
import { PolicyCoveragesComponent } from '../policy-coverages/policy-coverages.component';

@Component({
  selector: 'app-auto-policy',
  templateUrl: './auto-policy.component.html',
  styleUrls: ['./auto-policy.component.scss']
})
export class AutoPolicyComponent implements OnChanges {

  /**
   * The button states of the `Add`, `Delete`, and `Update` buttons.
   */
  buttonStates: any[] = [];

  /**
   * The instance of the {AutoPolicyResponse} represents the auto policy response.
   */
  @Input() policy: AutoPolicyResponse | any;

  /**
   * The instance of the {AutoDocRequestsComponent} represents the auto document requests.
   */
   autoDocRequests = AutoDocRequestsComponent;

  /**
   * The panel titles.
   */
  PanelTitle = {
    DOC_REQ: 'Document Requests',
    POLICY_COVERAGES: 'Policy Coverages',
  };

  /**
   * The defualt panel collapsible view values.
   */
  DefaultPanelView = {
    DOC_REQ: true,
    POLICY_COVERAGES: false,
  };

  /**
   * The policy coverages component.
   */
   policyCoverages = PolicyCoveragesComponent;

  /**
   * Initialized a new instance of the {AutoLienholderDetailsComponent}.
   * 
   */
  constructor() { }

  /**
   * Handles the component change event.
   */
  ngOnChanges(): void {
    this.generateButtonStates();
  }

  /**
   * Checks if the policy has a certain number of Lienholders.
   * @param num - The number of lienholders to check for.
   * @returns The boolean value indicates if policy has passed number of lienholders.
   */
  private getNumberOfLienholders(): number {
    if (this.policy && this.policy.vehicleList) {
      const financialInstitutions = this.policy.vehicleList.filter((v:any) => v.financialInstitution
        && v.financialInstitution.length !== 0);
      if (financialInstitutions) {
        return financialInstitutions.length;
      }
    }
    return 0;
  }

  /**
   * Generates button states for vehicle each lienholder.
   */
  private generateButtonStates(): void {
    this.buttonStates = [];
    if (this.policy && this.policy.vehicleList && this.policy.vehicleList.length !== 0) {
      for (let i = 0; i < this.policy.vehicleList.length; i++) {
        const hasLienholder = this.policy.vehicleList[i].financialInstitution
        && this.policy.vehicleList[i].financialInstitution.length !== 0;
        this.buttonStates.push({
          hasLienholder: hasLienholder,
          CAN_ADD: true,
          CAN_REPLACE: false,
          CAN_UPDATE: false,
          CAN_DELETE: false
        });
      }
    } else {
      throw new Error('There is not any vehicle on this policy.');
    }
    // There are no lienholders
    if (this.getNumberOfLienholders() === 0) {
      this.buttonStates.forEach(buttonState => {
        buttonState.CAN_ADD = true;
        buttonState.CAN_REPLACE = false;
        buttonState.CAN_UPDATE = false;
        buttonState.CAN_DELETE = false;
      });
    }
    // There is a lienholder
    if (this.getNumberOfLienholders() === 1) {
      this.buttonStates.forEach(buttonState => {
        if (buttonState.hasLienholder) {
          buttonState.CAN_ADD = false;
          buttonState.CAN_REPLACE = true;
          buttonState.CAN_UPDATE = true;
          buttonState.CAN_DELETE = true;
        }
      });
    }
    // There are two lienholders
    if (this.getNumberOfLienholders() === 2) {
      this.buttonStates.forEach(buttonState => {
        buttonState.CAN_ADD = false;
        buttonState.CAN_REPLACE = true;
        buttonState.CAN_UPDATE = true;
        buttonState.CAN_DELETE = true;
      });
    }
  }
}
