import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input
} from '@angular/core';
import { ModalData } from './modal-data.model';
import { Policy } from '../shared/policy.constants';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss']
})
/**
 * The custom popup component.
 */
export class CustomPopupComponent implements OnInit {

  /**
   * The boolean value which indicates whether the modal should be opened.
   */
  @Input() isOpenModal: boolean | any;

  /**
   * The current mortgagee name.
   */
  @Input() mortgageeName: string | any;

  /**
   * The other mortgagee name.
   */
  @Input() otherMortgageeName: string | any;

  /**
   * The current action requested by the user.
   */
  @Input() action: string | any;

  /**
   * The previous action requested by the user.
   */
  @Input() previousAction: string | any;

  /**
   * The number of mortgagee requests.
   */
  @Input() numberOfMortgageeRequests: number | any;

  /**
   * The number of existing mortgagees.
   */
  @Input() numberOfExistingMortgagees: number | any;

  /**
   * The current mortgagee index/position.
   */
  @Input() currentIndex: number | any;

  /**
   * The requested action.
   */
  @Input() requestedAction: string | any;

  /**
   * The modal action event emitter.
   */
  @Output() modalAction: EventEmitter<ModalData> = new EventEmitter<ModalData>();

  /**
   * Initializes new instance of the {CustomPopupComponent}.
   */
  constructor() { }

  /**
   * Handles the component initialization.
   */
  ngOnInit(): void {
  }

  /**
   * Gets modal title based on the given action.
   * @returns The modal title.
   */
  getModalTitle(): string | any {
    const MORTGAGEE_REPLACED = 'Mortgagee Replaced';
    const MORTGAGEE_DELETED = 'Mortgagee Deleted';
    const MORTGAGEE_ADDED = 'Mortgagee Added';
    const MORTGAGEE_UPDATED = 'Mortgagee Updated';

    const index = this.action?.indexOf('_');
    let parsedAction: string = this.action??"";
    if (index !== -1) {
      parsedAction = this.action?.substr(0, index)??"";
    }

    if (this.action === Policy.Action.CANCEL) {
      if (this.previousAction === Policy.Action.DELETE) {
        return MORTGAGEE_DELETED;
      } else if (this.previousAction === Policy.Action.UPDATE
        && this.numberOfExistingMortgagees === 1) {
        return MORTGAGEE_ADDED;
      } else if (this.previousAction === Policy.Action.UPDATE) {
        return MORTGAGEE_UPDATED;
      } else if (this.previousAction === Policy.Action.ADD_SECOND && this.numberOfExistingMortgagees === 0) {
        return MORTGAGEE_ADDED;
      }
      return "";
    }

    if (this.action === Policy.Action.CANCEL && this.previousAction === Policy.Action.ADD_SECOND) {
      if (this.requestedAction === Policy.Action.REPLACE) {
        return MORTGAGEE_REPLACED;
      } else if (this.requestedAction === Policy.Action.UPDATE) {
        return MORTGAGEE_UPDATED;
      } else if (this.requestedAction === Policy.Action.DELETE) {
        return MORTGAGEE_DELETED;
      }
      return "";
    }
    if (this.action === Policy.Action.ADD_SECOND && this.previousAction === Policy.Action.REPLACE) {
      return MORTGAGEE_REPLACED;
    }
    switch (parsedAction) {
      case Policy.Action.ADD:
        return MORTGAGEE_ADDED;
      case Policy.Action.UPDATE:
        return MORTGAGEE_UPDATED;
      case Policy.Action.DELETE:
        return MORTGAGEE_DELETED;
      case Policy.Action.REPLACE:
        return MORTGAGEE_REPLACED;
    }
    return "";
  }

  /**
   * Displays the add confirmation message.
   * @returns The boolean value indicates whether the add confirmation message should be displayed.
   */
  displayAddMessage(): boolean {
    return (this.action === Policy.Action.ADD
      || (this.action === Policy.Action.CANCEL
        && this.previousAction === Policy.Action.ADD_SECOND
        && this.numberOfExistingMortgagees === 0))
      && this.numberOfExistingMortgagees !== 2
      && !(this.action === Policy.Action.ADD
        && this.numberOfExistingMortgagees === 1);
  }

  /**
   * Displays the add confirmation message.
   * @returns The boolean value indicates whether the add confirmation message should be displayed.
   */
  displayAddMessage2(): boolean {
    return (this.action === Policy.Action.ADD_SECOND && this.previousAction !== Policy.Action.DELETE)
      || (this.action === Policy.Action.CANCEL && this.previousAction === Policy.Action.UPDATE)
      || (this.action === Policy.Action.ADD
        && this.numberOfExistingMortgagees === 1);
  }

  /**
   * Displays the delete confirmation message.
   * @returns The boolean value indicates whether the delete confirmation message should be displayed.
   */
  displayDeleteMessage(): boolean {
    return (this.action === Policy.Action.DELETE
      || (this.action === Policy.Action.CANCEL && this.previousAction === Policy.Action.DELETE)
      || (this.action === Policy.Action.CANCEL && this.previousAction === Policy.Action.ADD_SECOND
          && this.requestedAction === Policy.Action.DELETE))
      && this.numberOfExistingMortgagees !== 2;
  }

  /**
   * Displays delete mortgagee message.
   * @returns The boolean value {True} if the first mortgagee messages should be displayed.
   */
  displayDeleteMessage2(): boolean {
    return this.numberOfExistingMortgagees === 2
      && this.action === Policy.Action.DELETE;
  }

  /**
   * Displays the update confirmation message.
   * @returns The boolean value indicates whether the update confirmation message should be displayed.
   */
  displayUpdateMessage(): boolean {
    return (this.action === Policy.Action.UPDATE
      || (this.action === Policy.Action.CANCEL &&
          this.previousAction === Policy.Action.ADD_SECOND
          && this.requestedAction === Policy.Action.UPDATE)
      || (this.action === Policy.Action.CANCEL
        && this.previousAction === Policy.Action.UPDATE
        && this.currentIndex !== 2))
      && this.numberOfExistingMortgagees !== 2
      && (!(this.previousAction === Policy.Action.UPDATE
        && this.currentIndex === 1
        && this.numberOfExistingMortgagees === 1)
        || (this.previousAction === Policy.Action.UPDATE
          && this.action === Policy.Action.UPDATE));
  }

  /**
   * Displays the update confirmation message for two-existing mortgagee scenario.
   */
  displayUpdateMessage2(): boolean {
    return (this.action === Policy.Action.UPDATE
      || (this.action === Policy.Action.CANCEL && this.previousAction === Policy.Action.UPDATE))
      && this.numberOfExistingMortgagees === 2;
  }

  /**
   * Displays the replace confirmation message.
   * @returns The boolean value indicates whether the replace confirmation message should be displayed.
   */
  displayReplaceMessage(): boolean {
    return (this.action === Policy.Action.REPLACE
      || (this.action === Policy.Action.CANCEL && this.previousAction === Policy.Action.REPLACE)
      || (this.action === Policy.Action.CANCEL
        && this.previousAction === Policy.Action.ADD_SECOND
        && this.numberOfExistingMortgagees !== 0
        && this.requestedAction !== Policy.Action.UPDATE))
      && this.numberOfExistingMortgagees !== 2
      && this.requestedAction !== Policy.Action.DELETE;
  }

  /**
   * Displays the replace confirmatoin message.
   * @returns The boolean value indicates whether the replace confirmation message should be displayed.
   */
  displayReplaceMessage2(): boolean {
    return this.action === Policy.Action.REPLACE
      && this.numberOfExistingMortgagees === 2;
  }

  /**
 * Displays the edit other mortgagee button.
 * @returns The boolean value which indicates whether the "Edit Other Mortgagee" button should be displayed.
 */
  displayEditOtherMortgageeButton(): boolean {
    return (this.numberOfMortgageeRequests === 2
      || this.currentIndex === Policy.IndexPositions.POSITION_TWO
      || (this.currentIndex === 1 && this.numberOfExistingMortgagees === 1))
      && (this.action === Policy.Action.ADD || (this.action === Policy.Action.CANCEL && this.previousAction === Policy.Action.UPDATE))
      && this.numberOfExistingMortgagees !== 2;
  }

  /**
   * Displays the delete other mortgagee button.
   * @returns The boolean value indicates whether the "Delete Other Mortgagee" button should be displayed.
   */
  displayDeleteOtherMortgageeButton(): boolean {
    return (this.numberOfMortgageeRequests === 2
      || this.currentIndex === Policy.IndexPositions.POSITION_TWO
      || (this.currentIndex === 1 && this.numberOfExistingMortgagees === 1))
      && (this.action === Policy.Action.ADD || (this.action === Policy.Action.CANCEL && this.previousAction === Policy.Action.UPDATE))
      && this.numberOfExistingMortgagees !== 2;
  }

  /**
   * Displays add another mortgagee button.
   * @returns The boolean value indicates whether the "Add Another Mortgagee" button should be displayed.
   */
  displayAddAnotherMortgageeButton(): boolean {
    return this.numberOfMortgageeRequests !== 2
      && this.numberOfExistingMortgagees !== 2
      && !((this.action === Policy.Action.ADD || (this.action === Policy.Action.CANCEL &&
        this.previousAction === Policy.Action.UPDATE))
        && this.numberOfExistingMortgagees === 1);
  }

  /**
   * Displays the delete-2nd mortgagee button.
   * @returns The boolean value indicates whether the delete 2nd mortgagee button should be displayed.
   */
  displayDeleteTheOtherMortgageeButton(): boolean {
    return this.numberOfExistingMortgagees === 2
      && (this.action === Policy.Action.UPDATE || this.action === Policy.Action.REPLACE);
  }

  /**
   * Deletes both mortgagees of this policy.
   */
  onDeleteBothMortgagees(): void {
    if(this.currentIndex){
      this.modalAction.emit(new ModalData(true,
      this.action,
      Policy.Action.DELETE_DONE,
      this.getAlternateIndex(this.currentIndex)));
    }
  }

  /**
   * Deletes the other mortgagees.
   */
  deleteTheOtherMortgagee(): void {
    if(this.currentIndex){
      this.modalAction.emit(new ModalData(true,
        this.action,
        Policy.Action.DELETE_DONE,
        this.getAlternateIndex(this.currentIndex)));
    }
  }

  /**
   * Gets the other mortgagee button text.
   * @returns The button text for deleting the other mortgagee button.
   */
  getTheOtherMortgageeText(): string {
    return this.currentIndex === Policy.IndexPositions.POSITION_ONE ?
      'Delete Second Mortgagee' : 'Delete First Mortgagee';
  }

  /**
   * Handles add or add another mortgagee click event.
   */
  onAddAnohterClick(): void {
    this.modalAction.emit(new ModalData(false,
      this.action,
      Policy.Action.ADD_SECOND));
  }

  /**
   * Handles edit other mortgagee click event.
   */
  onEditOtherMortgagee(): void {
    this.modalAction.emit(new ModalData(false,
      this.action,
      Policy.Action.UPDATE));
  }

  /**
   * Handles delete other mortgagee click event.
   */
  onDeleteOtherMortgagee(): void {
    this.modalAction.emit(new ModalData(false,
      this.action,
      Policy.Action.DELETE_DONE));
  }

  /**
   * Handles done action click event.
   */
  onDoneClick(): void {
    this.modalAction.emit(new ModalData(true,
      this.action,
      Policy.Action.DONE));
  }

  /**
   * Handles modal close click event.
   */
  onCloseClick(): void {
    this.modalAction.emit(new ModalData(false,
      this.action,
      Policy.Action.CLOSE));
  }

  /**
  * Gets the alternate index number.
  * @param index The index number to be alternated.
  * @returns The alternate index number.
  */
  private getAlternateIndex(index: number): number {
    if (index === Policy.IndexPositions.POSITION_ONE) {
      return Policy.IndexPositions.POSITION_TWO;
    } else if (index === Policy.IndexPositions.POSITION_TWO) {
      return Policy.IndexPositions.POSITION_ONE;
    }
    throw new Error(`Can not alternate the index: ${index}`);
  }
}
