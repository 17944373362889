import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { SuccessComponent } from './success/success.component';

@NgModule({
  declarations: [
    SuccessComponent
  ],
  exports: [
    SuccessComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
  ],
  providers: [],
  bootstrap: []
})
export class AlertsModule {
}
