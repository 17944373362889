import { Component, Input, OnChanges } from '@angular/core';
import { Vehicle } from '../../shared/api/auto-policy/vehicle.model';
import { AutoAddress } from '../../shared/api/auto-policy/auto-address.model';
import { Coverage } from '../../shared/api/coverage.model';
import { AutoPolicyResponse } from '../../shared/api/auto-policy/auto-policy-response.model';

@Component({
  selector: 'app-auto-lienholder',
  templateUrl: './auto-lienholder.component.html',
  styleUrls: ['./auto-lienholder.component.scss']
})
export class AutoLienholderComponent implements OnChanges {
  /**
   * The button state of the current mortgagee as a component input.
   */
  @Input() buttonState: any;

  /**
   * The instance of {Vehicle} represents lienholder information for the vehicle.
   */
  @Input() vehicle: Vehicle | any;

  /**
   * The instance of the {AutoAddress} represents the address of the vehicle.
   */
  @Input() address: AutoAddress | any;

  /**
   * The instance of the {AutoPolicyResponse} represents the auto policy response as a component input data.
   */
  @Input() policy: AutoPolicyResponse | any;

  /**
   * The instance of the {Coverage} represents the comprehensive coverage.
   */
  comprehensiveCoverage: Coverage | any;

  /**
   * The instance of the {Coverage} represents the collision coverage.
   */
  collisionCoverage: Coverage | any;

  /**
   * The vehicle ownership types.
   */
  readonly OwnershipType = {
    LEASED: 'L',
    FINANCED: 'F',
    FINANCED2: 'P'
  };

  /**
   * The vehicle coverage codes.
   */
  readonly CoverageCode = {
    COMPREHENSIVE: 'COMP',
    COLLISION: 'COLL'
  };

  /**
   * The action.
   */
  readonly Action = {
    ADD: 'ADD',
    REPLACE: 'REPLACE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    CANCEL: 'CANCEL',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
  };

  /**
   * The current action.
   */
  currentAction: string | any;

  /**
   * Initializes a new instance of the {AutoLienholderComponent}.
   */
  constructor() { }

  /**
   * Handles the component changing event.
   */
  ngOnChanges(): void {
    this.comprehensiveCoverage = this.getCoverage(this.CoverageCode.COMPREHENSIVE);
    this.collisionCoverage = this.getCoverage(this.CoverageCode.COLLISION);
  }

  /**
   * Gets the ownership type based on the ownership code.
   * @return The owndership type.
   */
  getOwnershipType(): string {
    if (this.vehicle && this.vehicle.ownershipCd === this.OwnershipType.LEASED) {
      return 'Leased';
    } else if (this.vehicle && (this.vehicle.ownershipCd === this.OwnershipType.FINANCED
      || this.vehicle.ownershipCd === this.OwnershipType.FINANCED2)) {
      return 'Financed';
    }
    throw new Error('Unable to determine the vehicle ownership type.');
  }

  /**
   * Gets a coverage by coverage code.
   * @param coverageCode The coverage code.
   * @return The instance of the {Coverage} represent the covereage information.
   */
  private getCoverage(coverageCode: string): Coverage {
    const coverage_Obj : Coverage  = {
      coverageCode: "",
      coverageType: "",
      coverageDescription: "",     
      coverageUpperLimit: "" ,      
      coverageLowerLimit: ""
    }
    if (!this.vehicle || !this.vehicle.vehicleCoverageDTOList
      || this.vehicle.vehicleCoverageDTOList.length === 0) {
      return coverage_Obj;
    }
    return this.vehicle.vehicleCoverageDTOList.find((coverage:any) =>
      coverage.coverageCode === coverageCode
    );
  }

  /**
   * Sets the button color to gray if its state is disabled.
   * @param state The button state.
   * @return The `disabled` CSS class if the state is disabled.
   */
  setButtonStateDisplay(state: boolean): any {
    if (!state) {
      return {
        'disabled': true
      };
    }
  }

  /**
   * Checks whether there is any lienholder.
   * @return The boolean value indicates whether there is any lienholder.
   */
  hasAnyLienholder(): boolean {
    return this.vehicle
      && this.vehicle?.financialInstitution
      && this.vehicle?.financialInstitution?.length !== 0? true : false;
  }

  /**
   * Checks whether the current action is a SUCCESS
   * or FAILURE.
   * @return the boolean value indicates if action is success
   * or failure.
   */
  checkActionIsSuccessOrFailure(): boolean {
    return this.currentAction === this.Action.SUCCESS
      || this.currentAction === this.Action.FAILURE
      || this.currentAction === this.Action.ADD;
  }

  /**
     * Sets the current action based on user action.
     * @param action The select action.
     */
  setAction(action: string): void {
    this.currentAction = action;
  }

  /**
   * Handles adding lienholder click event.
   */
  onAddLiendholderClick(): void {
    this.setAction(this.Action.ADD);
  }

  /**
   * Handles updating lienholder click event.
   */
  onUpdateLienholderClick(): void {
    this.setAction(this.Action.UPDATE);
  }

  /**
   * Handles deleting lienholder click event.
   */
  onDeleteLienholderClick(): void {
    this.setAction(this.Action.DELETE);
  }

  /**
   * Handles cancel event.
   * @param action The cancel action.
   */
  onCancelled(action: string): void {
    this.setAction(this.Action.CANCEL);
  }

  /**
   * Handle successful event.
   * @param action The success action from event emitter.
   */
  onSuccess(action: string): void {
    this.setAction(this.Action.SUCCESS);
  }

  /**
   * Handle successful event.
   * @param action The success action from event emitter.
   */
  onFailure(action: string): void {
    this.setAction(this.Action.FAILURE);
  }
}
