<app-error [isInFlight]="isInFlight" [isNoAvailableDates]="datesLocked"></app-error>

<div class="row mortgagee-header">
  <div class="col-md-12">
    <div class="col-md-5 col-xs-10 mortgagee-heading">
      <h3>{{getMortgageHeading()}}</h3>
    </div>
    <div class="col-md-7 col-xs-12 mortgagee-button-panel" [ngClass]="formatMortgageeButtons()">
      <a class="btn btn-link" [ngClass]="setButtonStateDisplay(buttonState.CAN_REPLACE)"
         data-dl='{"event": "mousedown", "event_value": "home", "event_id": "replace","da_track":"true"}'
        (click)="onReplaceClick()"
        href="javascript:void(0)" title="Replace">Replace</a> |
      <a class="btn btn-link" [ngClass]="setButtonStateDisplay(buttonState.CAN_ADD)"
         data-dl='{"event": "mousedown", "event_value": "home", "event_id": "add", "da_track":"true"}'
        (click)="onAddClick()"
        href="javascript:void(0)" title="Add">Add</a> |
      <a class="btn btn-link" [ngClass]="setButtonStateDisplay(buttonState.CAN_UPDATE)"
         data-dl='{"event": "mousedown", "event_value": "home", "event_id": "update", "da_track":"true"}'
        (click)="onUpdateClick()"
        href="javascript:void(0)" title="Update">Update</a> |
      <a class="btn btn-link" [ngClass]="setButtonStateDisplay(buttonState.CAN_DELETE)"
         data-dl='{"event": "mousedown", "event_value": "home", "event_id": "delete", "da_track":"true"}'
        (click)="onDeleteClick()"
        href="javascript:void(0)" title="Delete">Delete</a>
    </div>
  </div>
</div>

<div class="mortgagee-body">
  <div *ngIf="shouldDisplayMessage()" class="col-md-12 col-xs-12 no-mortgagee">
    <p>{{message}}</p>
    <hr class="mortgagee-line" *ngIf="totalMortgagees > 0" />
  </div>
  <div *ngIf="mortgagee" class="col-md-12 col-xs-12">
    <div class="mortgagee-row row">
      <div class="col-md-12">
        <div class="col-md-4 col-xs-12">
          <label>Loan Number:</label>
        </div>
        <div class="col-md-8 col-xs-12">
          <span *ngIf="mortgagee.loanNumber">{{mortgagee.loanNumber}}</span>
        </div>
      </div>
    </div>

    <div class="mortgagee-row row">
      <div class="col-md-12">
        <div class="col-md-4 col-xs-12">
          <label>Mortgage Company Name:</label>
        </div>
        <div class="col-md-8">
          <span *ngIf="mortgagee.companyName">{{mortgagee.companyName}}</span>
        </div>
      </div>
    </div>

    <div class="mortgagee-row row">
      <div class="col-md-12">
        <div class="col-md-4 col-xs-12">
          <label>Mortgage Company Name cont (ISAOA/ATIMA):</label>
        </div>
        <div class="col-md-8">
          <span *ngIf="mortgagee.companyName">{{mortgagee.companyNameCont}}</span>
        </div>
      </div>
    </div>

    <div class="mortgagee-row row">
      <div class="col-md-12">
        <div class="col-md-4 col-xs-12">
          <label>Address:</label>
        </div>
        <div class="col-md-8 col-xs-12">
          <span *ngIf="mortgagee.address">
            <div *ngIf="mortgagee.address.streetNumber">{{this.mortgagee.address.streetNumber}}</div>
            <div *ngIf="mortgagee.address.streetName">{{mortgagee.address.streetName}}</div>
            <div>
              <span *ngIf="mortgagee.address.city" class="right-side-space">{{mortgagee.address.city}},</span>
              <span *ngIf="mortgagee.address.state" class="right-side-space">{{mortgagee.address.state}}</span>
              <span *ngIf="mortgagee.address.zipcode">{{mortgagee.address.zipcode}}</span>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="mortgagee-row row">
      <div class="col-md-12">
        <div class="col-md-4 col-xs-12">
          <label>Escrowed:</label>
        </div>
        <div class="col-md-8 col-xs-12">
          <span>{{getMortgageeEscrowed()}}</span>
        </div>
      </div>
    </div>
    <div class="line-row">
      <hr class="mortgagee-line"/>
    </div>
  </div>
  <div class="col-md-12 col-xs-12">
    <app-mortgagee-form
      [action]="currentAction"
      [mortgagee]="mortgagee"
      [mortgagees]="mortgagees"
      (userAction)="onUserAction($event)">
    </app-mortgagee-form>
  </div>
</div>
