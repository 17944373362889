import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
/**
 * The LVP form validation serivce that provides the flexible ability to validate forms.
 */
export class LvpFormValidationService {
  constructor() { }

  /**
   * Clears control validators of a given control whose name is provided.
   * @param form The instance of the FormGroup representing the HTML form.
   * @param controlName The control name.
   * @returns The updated instance of the FormGroup.
   */
  clearControlValidators(form: FormGroup, controlName: string): FormGroup {
    // form.get(controlName).clearValidators();
    // form.get(controlName).updateValueAndValidity();
    form.get(controlName)?.clearValidators();
    form.get(controlName)?.updateValueAndValidity();
    return form;
  }

  /**
   * Sets the given validators to a control.
   * @param form The instance of the FormGroup.
   * @param controlName The control name.
   * @param validators The array of validators.
   * @returns The updated instance of the FormGroup.
   */
  setControlValidators(form: FormGroup, controlName: string, validators: any[]): FormGroup {
    // form.get(controlName).setValidators(validators);
    // form.get(controlName).updateValueAndValidity();
    form.get(controlName)?.setValidators(validators);
    form.get(controlName)?.updateValueAndValidity();
    return form;
  }

  /**
   * Validates all form controls of an form group.
   * @param form The instance of the FormGroup.
   * @returns the updated instance of the FormGroup.
   */
  validateFormControls(form: FormGroup): FormGroup {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control && control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control && control instanceof FormGroup) {
        this.validateFormControls(control);
      }
    });
    return form;
  }

  /**
   * Checks whether the control passes all validations.
   * @param form The instance of the `FormGroup`.
   * @param controlName The control name.
   * @returns True if the control passes all the validations.
   */
  isControlValid(form: FormGroup, controlName: string): boolean {
    //return form && controlName && form.get(controlName)?.valid;
    if(form && controlName && form.get(controlName)?.valid){
      return true;
    }else{
      return false;
    }
  }

  /**
   * Checks if a control has a pattern error.
   * @param form The instance of the `FormGroup`.
   * @param controlName The control name.
   * @returns True if there is an error; otherwise, false.
   */
  hasPatternError(form: FormGroup, controlName: string): boolean {
    // return form.get(controlName).errors
    //   && (form.get(controlName).errors.hasOwnProperty('pattern')
    //     || form.get(controlName).errors.hasOwnProperty('email'))
    //   && !form.get(controlName).errors.hasOwnProperty('required');
    return (form.get(controlName)?.errors
      && (form.get(controlName)?.errors?.hasOwnProperty('pattern')
        || form.get(controlName)?.errors?.hasOwnProperty('email'))
      && !form.get(controlName)?.errors?.hasOwnProperty('required')) ? true : false;
  }

  /**
   * Checks if control has mismatch error.
   * @param form The instance of 'FormGroup'
   * @param controlName The control name.
   * return True if there is an error; otherwise, false.
   */
  hasMismatch(form: FormGroup, controlName: string): boolean {
    // return form && controlName && form.get(controlName).errors
    // && form.get(controlName).errors.hasOwnProperty('mismatch')
    // && !form.get(controlName).errors.hasOwnProperty('required')
    // && !(form.get(controlName).errors.hasOwnProperty('pattern')
    //   || form.get(controlName).errors.hasOwnProperty('email'));
    return (form && controlName && form.get(controlName)?.errors
    && form.get(controlName)?.errors?.hasOwnProperty('mismatch')
    && !form.get(controlName)?.errors?.hasOwnProperty('required')
    && !(form.get(controlName)?.errors?.hasOwnProperty('pattern')
      || form.get(controlName)?.errors?.hasOwnProperty('email'))) ? true : false;
  }

  /**
   * Checks  if a control/field has a minium or mixium error.
   * @param form The instance of the {FormGroup} represents the reactive form.
   * @param controlName The form field name.
   * @returns The boolean value indicates whether the date field is valid.
   */
  hasDateError(form: FormGroup, controlName: string): boolean {
    // return form && controlName && form.get(controlName).errors
    //   && form.get(controlName).errors.hasOwnProperty('bsDate')
    //   && !form.get(controlName).errors.hasOwnProperty('required')
    //   && !(form.get(controlName).errors.hasOwnProperty('pattern')
    //     || form.get(controlName).errors.hasOwnProperty('email'));
    return (form && controlName && form.get(controlName)?.errors
      && form.get(controlName)?.errors?.hasOwnProperty('bsDate')
      && !form.get(controlName)?.errors?.hasOwnProperty('required')
      && !(form.get(controlName)?.errors?.hasOwnProperty('pattern')
        || form.get(controlName)?.errors?.hasOwnProperty('email'))) ? true : false;
  }
}
