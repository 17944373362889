import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment-wrapper';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class DisassociationPostVerficationService {

  apiBaseEndpoint = environment.API_ENDPOINT;
  relinkingDisaasociatedResource = environment.RELINK_DISASSOCIATED_CIAM;
  isValidCiamSessionResource = environment.IS_VALID_CIAM;
  isValidTokenAndCiamSessionResource=environment.IS_VALID_TOKEN_CIAM;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {}

  
  isValidCiamSession() {   
    const endpoint=this.getApiEndpoint(this.isValidCiamSessionResource);  
    return this.http.get(endpoint);
  }
   
  isValidTokenAndCiamSession() {   
    const endpoint=this.getApiEndpoint(this.isValidTokenAndCiamSessionResource);  
    const payload ={
      token : sessionStorage.getItem('token')
    }   
    console.log('Payload', payload);  
    return this.http.post(endpoint, payload);

  }
  
  relinkingUserToCiam() {   
    const endpoint=this.getApiEndpoint(this.relinkingDisaasociatedResource);  
    return this.http.get(endpoint);
  }

  private getApiEndpoint(resource: string) {
    return `${this.apiBaseEndpoint}${resource}`;
  }
}
