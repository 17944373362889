export class MortgageeAddress {
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;

  constructor(streetNumber?: string,
    streetName?: string,
    city?: string,
    state?: string,
    zipcode?: string,
    country?: string) {
    this.streetName = streetName??"";
    this.streetNumber = streetNumber??"";
    this.city = city??"";
    this.state = state??"";
    this.zipcode = zipcode??"";
    this.country = country??"";
  }
}
