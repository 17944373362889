export class ErrorMessage {
  message: string | any;
  code: string | any;
  httpStatusCode: string | any;
  constructor(message: string, code?: string, httpStatusCode?: string) {
    this.message = message;
    this.code = code??"";
    this.httpStatusCode = httpStatusCode??"";
  }
}
