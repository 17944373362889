import { Component, Input, OnChanges } from '@angular/core';
import { ErrorMessage } from './error-message.model';
import { CustomEventService } from '../../services/tealium/custom-event.service';
import { ErrorDTO } from 'src/app/services/tealium/tealium-error.model';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnChanges {
   /**
   * The error message to be displayed.
   */
  @Input() errorMessage: ErrorMessage | any;

  /**
   * The in flight status used to display the info error.
   */
  @Input() isInFlight: boolean | any;

  /**
   * The in flight status used to display the info error.
   */
  @Input() isNoAvailableDates: boolean | any;

  /**
   * Initializes the new instance of the customEvent service which tracks analytic events
   * @param customEvent The instance of the {CustomEventService} represents the Tealium service.
   */
  constructor(private customEvent: CustomEventService) { }

  /**
   * The boolean value indicates whether the form field has an error.
   */
  hasError(): boolean {
    return this.errorMessage
    && this.errorMessage.message
    && this.errorMessage.message.length !== 0? true : false;
  }

  /**
   * Handles the error info close click event.
   */
  onCloseClick(): void {
    this.isInFlight = false;
    this.isNoAvailableDates = false;
  }

  /**
   * Returns boolean value indicating if the 2nd message
   * of the error template should be displayed.
   * @returns - boolean value indicating if error message
   *  should be displayed.
   */
  showNoAvailableDateError(): boolean {
    return !this.isInFlight && this.isNoAvailableDates? true : false;
  }

  /**
   * Handles tealium call for analytics
   */
  ngOnChanges(): void {
    const errorDTO : ErrorDTO = {
      errorCd: "",
      errorDesc: "",
      httpStatusCd: ""
    }
    this.customEvent.pageLevelError(errorDTO, this.isInFlight, this.isNoAvailableDates);
  }
}
