import { NgModule } from '@angular/core';
import { CustomPopupComponent } from './custom-popup.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { TealiumModule } from '../services/tealium/tealium.module';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    TealiumModule.forRoot()
  ],
  declarations: [
    CustomPopupComponent
  ],
  exports: [
    CustomPopupComponent
  ],
  providers: []
})
export class CustomPopupModule {}
