<div class="row policy-details">
  <h3>
    <strong>Lender Services</strong>
  </h3>
  <div class="card top xp">
    <div class="row" id="home-card" *ngIf="policyType === PolicyType.HOME">
      <app-home-policy [policy]="homePolicyResponse"></app-home-policy>
    </div>
    <div class="row" id="auto-card" *ngIf="policyType === PolicyType.AUTO">
      <app-auto-policy [policy]="autoPolicyResponse"></app-auto-policy>
    </div>
  </div>
  <div class="another-search-button">
    <button class="btn btn-primary btn-block perform-another-search-button" (click)="onAnotherSearchClick()" data-dl='{"da_track":"true","event":"mousedown","event_id":"Perform Another Search"}'>Perform Another Search</button>
  </div>
</div>
