import { Component, Input, OnChanges } from '@angular/core';
import { Coverage } from '../../../shared/api/coverage.model';
/**
 * The {CoverageLimitsComponent} represents the information for coverage limits and deductible limits.
 */
@Component({
  selector: 'app-coverage-limits',
  templateUrl: './coverage-limits.component.html',
  styleUrls: ['./coverage-limits.component.scss']
})
export class CoverageLimitsComponent implements OnChanges {
  /**
   * The instance of {Coverage} as a component input.
   */
  @Input() coverages: Coverage[] | any;

  /**
   * The variables used for the heading titles of two different tables.
   * By default value is assigned for 1st table.
   */
  headingTitle1 = 'Coverages';

  /**
   * The second heading title.
   */
  headingTitle2 = 'Coverage Limits';

  /**
   * The no coverage constant.
   */
  readonly NO_COVERAGE = 'No Coverage';

  /**
   * Initializes a new instance of the {CoverageLimitsComponent}.
   */
  constructor() { }

  /**
   * Handles on component change event.
   */
  ngOnChanges(): void {
    this.coverages?.forEach((coverage:any) => {
      if (coverage.coverageCode === 'AP' || coverage.coverageCode === 'WH') {
        this.headingTitle1 = 'Deductibles';
        this.headingTitle2 = 'Deductible Amounts';
        return;
      }
    });
  }

  /**
   * Displays coverage.
   * @param coverage The instance of the {Coverage} represents the coverage information.
   * @returns The boolean value indicates whether the coverage should be displayed.
   */
  displayCoverage(coverage: Coverage): boolean {
    return coverage.coverageLowerLimit !== this.NO_COVERAGE
      && (coverage.coverageCode !== 'AL'
      && coverage.coverageCode !== 'WH'
      && coverage.coverageCode !== 'UNDUM')
      && +coverage.coverageLowerLimit >= 100;
  }

  /**
   * Displays the `No Coverage`.
   * @param coverage The instance of {Coverage} represents the coverage information.
   * @returns The boolen value indicates whether the no coverage should be displayed.
   */
  displayNoCoverage(coverage: Coverage): boolean {
    return coverage.coverageLowerLimit === this.NO_COVERAGE;
  }

  /**
   * Displays the wind/hail, AL, or AP coverage.
   * @param coverage The instance of {Coverage} represents the coverage information.
   * @returns The boolean value indicates whether the coverage should be displayed.
   */
  displayWindHailCoverage(coverage: Coverage): boolean {
    return coverage.coverageLowerLimit !== this.NO_COVERAGE
      &&  coverage.coverageCode === 'WH'
      && +coverage.coverageLowerLimit <= 100;
  }

  /**
   * Display the Wind/hail amount.
   * @param {Coverage} coverage
   * @returns {boolean}
   */
  displayWindHailNumber(coverage: Coverage): boolean {
    return coverage.coverageLowerLimit !== this.NO_COVERAGE
      &&  coverage.coverageCode === 'WH'
      && +coverage.coverageLowerLimit > 100;
  }

  /**
   * Displays the additional limits information.
   * @param coverage The instance of the {Coverage} represent the additional coverage information.
   * @returns The boolean vlaue which indicates whether the additional coverage informations should be displayed.
   */
  displayAdditionalLimitsCoverage(coverage: Coverage): boolean {
    return coverage.coverageLowerLimit !== this.NO_COVERAGE
      && coverage.coverageCode === 'AL';
  }
}
