export class PolicyRequest {
  policyType: string | any;
  lenderType: string | any;
  lenderInstitution: string | any;
  firstName: string | any;
  lastName: string | any;
  policyNumber: string | any;
  zipcode: string | any;

  constructor(policyType?: string,
    lenderType?: string,
    lenderInstitution?: string,
    firstName?: string,
    lastName?: string,
    policyNumber?: string,
    zipcode?: string) {
      this.policyType = policyType;
      this.lenderType = lenderType;
      this.lenderInstitution = lenderInstitution;
      this.firstName = firstName;
      this.lastName = lastName;
      this.policyNumber = policyNumber;
      this.zipcode = zipcode;
  }
}
