export class DecPageRequestModel {
    changeNumber: string | any;
    policyNumber: string | any;
    policyType: string | any;
    policyEffectiveDate: string | any;
    businessUnitCode: string | any;
    fullName: string | any;
    deliveryType: string | any;
    emailAddress: string | any;
    faxNumber: string | any;
    faxATTN: string | any;
    zipCode: string | any;
    changeEffectiveDate: string | any;
    quoteIdentifier: string | any;
    producerCode: string | any;

    constructor(
        changeNumber?: string,
        policyNumber?: string,
        policyType?: string,
        policyEffectiveDate?: string,
        businessUnitCode?: string,
        fullName?: string,
        deliveryType?: string,
        emailAddress?: string,
        faxNumber?: string,
        faxATTN?: string,
        zipCode?: string,
        changeEffectiveDate?: string,
        quoteIdentifier?: string,
        producerCode?: string) {
        this.changeNumber = changeNumber;
        this.policyNumber = policyNumber;
        this.policyType = policyType;
        this.policyEffectiveDate = policyEffectiveDate;
        this.businessUnitCode = businessUnitCode;
        this.fullName = fullName;
        this.deliveryType = deliveryType;
        this.emailAddress = emailAddress;
        this.faxNumber = faxNumber;
        this.faxATTN = faxATTN;
        this.zipCode = zipCode;
        this.changeEffectiveDate = changeEffectiveDate;
        this.quoteIdentifier = quoteIdentifier;
        this.producerCode = producerCode;
    }
}
