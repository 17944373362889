import { HomeAddressRequest } from './home-address-request.model';

export class PolicyInfoModel {
    processDate: string | any;
    firstName: string | any;
    lastName: string | any;
    address: HomeAddressRequest | any;
    policyNumber: string | any;
    writingCompanyName: string | any;
    balanceDueAmount: string | any;
    policyRenewalDate: string | any;
    policyEffectiveDate: string | any;
    policyExpirationDate: string | any;
    paymentAmount: string | any;
    paymentDate: string | any;

    constructor(
        processDate?: string,
        policyNumber?: string,
        firstName?: string,
        lastName?: string,
        address?: HomeAddressRequest,
        writingCompanyName?: string,
        balanceDueAmount?: string,
        policyRenewalDate?: string,
        policyEffectiveDate?: string,
        policyExpirationDate?: string,
        paymentAmount?: string,
        paymentDate?: string) {
        this.policyNumber = policyNumber;
        this.processDate = processDate;
        this.firstName = firstName;
        this.lastName = lastName;
        this.address = address;
        this.writingCompanyName = writingCompanyName;
        this.balanceDueAmount = balanceDueAmount;
        this.policyRenewalDate = policyRenewalDate;
        this.policyEffectiveDate = policyEffectiveDate;
        this.policyExpirationDate = policyExpirationDate;
        this.paymentAmount = paymentAmount;
        this.paymentDate = paymentDate;
    }
}
