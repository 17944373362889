import { TransactionInfoRequest } from './transaction-info-request.model';
import { MortgageeRequest } from './mortgagee-request.model';

export class HomeChangeAmendRequest {
  transactionInfo: TransactionInfoRequest | any;
  mortgagees?: MortgageeRequest[] | null;

  constructor(transactionInfo?: TransactionInfoRequest,
    mortgagees?: MortgageeRequest[]) {
    this.transactionInfo = transactionInfo;
    this.mortgagees = mortgagees;
  }
}
