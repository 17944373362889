<div class="lienholder-form" *ngIf="showLienholderForm()" appBlockCopyPasteCut >
  <form appScrollToFirstInvalid [scrollToFirstInvalid]="lienholderForm" [formGroup]="lienholderForm">
    <div class="row">
      <div class="form-group col-md-6 col-sm-12 col-xs-12 lease-toggle-wrapper" [ngClass]="displayFormFieldError('ownershipType')">
        <div class="toggle-title control-label">Leased or Financed</div>
        <div class="btn-group btn-group-toggle" role="group">
          <button type="button" [ngClass]="{'btn-selected': currentOwnershipType === OwnershipType.LEASED}" class="btn btn-secondary"
            (click)="onOwnershipTypeClick(OwnershipType.LEASED)">Leased</button>
          <button type="button" [ngClass]="{'btn-selected': currentOwnershipType === OwnershipType.FINANCED}" class="btn btn-secondary"
            (click)="onOwnershipTypeClick(OwnershipType.FINANCED)">Financed</button>
        </div>
        <app-field-error [hasError]="isFormFieldValid('ownershipType')" errorMessage="Please select an ownership type."
          fieldName="ownershipType">
        </app-field-error>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12" [ngClass]="displayFormFieldError('financialInstitution')">
          <div class="col-md-4 col-sm-12 col-xs-12">
            <label for="state" class="center-block select-label control-label required">Lienholder</label>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 select-container">
          <select id="financialInstitution" [value]="currentFinancialInstitution"
              (change)="onFinancialInstitutionChange($event)"
              class="form-control form-control-lg" formControlName="financialInstitution">
              <span class="icon icon-attention form-control-feedback" aria-hidden="true"></span>
            <option *ngFor="let financeInstitution of financialInstitutions"
              [value]="financeInstitution.financialCompName">{{ financeInstitution.financialCompName }}</option>
          </select>
          <app-field-error [hasError]="isFormFieldValid('financialInstitution')"
            errorMessage="Please select a lienholder." fieldName="financialInstitution">
          </app-field-error>
        </div>
      </div>
    </div>

    <div *ngIf="showCompanyNameField()" class="row" [ngClass]="displayFormFieldError('companyName')">
      <div class="form-group col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-4 col-sm-12 col-xs-12">
          <label class="center-block control-label required">Company Name</label>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
          <input id="companyName" class="form-control" formControlName="companyName" [maxlength]="InputLength.COMPANY_NAME_MAX_LENGTH">
            <app-field-error [hasError]="isFormFieldValid('companyName')" errorMessage="Please enter the company name."
              fieldName="companyName">
            </app-field-error>
            <app-field-error [hasError]="hasInputPatternError('companyName')" errorMessage="Please enter a valid company name excluding special characters like #, /, \, (, )."
              fieldName="companyName">
            </app-field-error>
        </div>
      </div>
    </div>

    <div class="row" [ngClass]="displayFormFieldError('loanNumber')">
      <div class="form-group col-md-12">
        <div class="col-md-4 col-sm-12 col-xs-12">
          <label class="center-block control-label required">Loan Number</label>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
          <input id="loanNumber" class="form-control" formControlName="loanNumber" [maxlength]="InputLength.LOAN_NUMBER_MAX_LENGTH">
        <app-field-error [hasError]="hasInputPatternError('loanNumber')" errorMessage="Please enter valid loan number."
          fieldName="loanNumber">
        </app-field-error>
        </div>
      </div>
    </div>

    <div class="row" [ngClass]="displayFormFieldError('address1')">
      <div class="form-group col-md-12">
        <div class="col-md-4 col-sm-12 col-xs-12">
          <label class="center-block control-label required">Address</label>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
          <input id="address1" class="form-control" formControlName="address1" [maxlength]="InputLength.ADDRESS_MAX_LENGTH">
          <app-field-error [hasError]="isFormFieldValid('address1')" errorMessage="Please enter the address line 1."
            fieldName="address1">
          </app-field-error>
          <app-field-error [hasError]="hasInputPatternError('address1')" errorMessage="Please enter a valid address excluding special characters like #, /, \, (, ), ', :, &, -, ."
            fieldName="address1">
          </app-field-error>
        </div>
      </div>
    </div>

    <div class="row" [ngClass]="displayFormFieldError('address2')">
      <div class="form-group col-md-12">
          <div class="col-md-4 col-sm-12 col-xs-12">
            <label class="center-block control-label required">Address 2</label>
            </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
          <input id="address2" class="form-control" formControlName="address2" [maxlength]="InputLength.ADDRESS_MAX_LENGTH">
          <app-field-error [hasError]="hasInputPatternError('address2')" errorMessage="Please enter a valid address excluding special characters like #, /, \, (, ), ', :, &, -, ."
            fieldName="address2">
          </app-field-error>
        </div>
      </div>
    </div>

    <div class="row" [ngClass]="displayFormFieldError('city')">
      <div class="form-group col-md-12">
          <div class="col-md-4 col-sm-12 col-xs-12">
           <label class="center-block control-label required">City</label>
          </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
          <input id="city" class="form-control" formControlName="city" [maxlength]="InputLength.CITY_MAX_LEGNTH">
          <app-field-error [hasError]="isFormFieldValid('city')" errorMessage="Please enter the city." fieldName="city">
          </app-field-error>
          <app-field-error [hasError]="hasInputPatternError('city')" errorMessage="Please enter a valid city excluding special characters like #, /, \, (, ), ', :, ." fieldName="city">
          </app-field-error>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-12" [ngClass]="displayFormFieldError('state')">
          <div class="col-md-4 col-sm-12 col-xs-12">
            <label for="state" class="center-block select-label control-label required">State</label>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 select-container">
          <select id="state" [value]="currentState" class="form-control form-control-lg" formControlName="state">
              <span class="icon icon-attention form-control-feedback" aria-hidden="true"></span>
            <option *ngFor="let state of states" [value]="state">{{ state }}</option>
          </select>
          <app-field-error [hasError]="isFormFieldValid('state')" errorMessage="Please select a state." fieldName="state">
          </app-field-error>
        </div>
      </div>
    </div>

    <div class="row" [ngClass]="displayFormFieldError('zipcode')">
      <div class="form-group col-md-12">
          <div class="col-md-4 col-sm-12 col-xs-12">
            <label class="center-block control-label required">Zipcode</label>
          </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
          <input id="zipcode" class="form-control" formControlName="zipcode" [maxlength]="InputLength.ZIPCODE_MAX_LENGTH">
          <app-field-error [hasError]="isFormFieldValid('zipcode')" errorMessage="Please enter the zipcode." fieldName="zipcode">
          </app-field-error>
          <app-field-error [hasError]="hasInputPatternError('zipcode')" errorMessage="Please enter valid zipcode."
            fieldName="zipcode">
          </app-field-error>
        </div>
      </div>
    </div>
    <div class="row submit-panel">
      <div class="col-md-6 col-sm-12 col-xs-12 btn-col cancel-btn-wrapper">
        <button class="btn btn-link" title="Cancel" (click)="onCancelClick()">Cancel</button>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 btn-col submit-btns">
        <button *ngIf="action === Action.ADD" (click)="onAddClick()" class="btn btn-primary btn-lg btn-block btn-action"
          title="Add">Add</button>
        <button *ngIf="action === Action.UPDATE" (click)="onUpdateClick()" class="btn btn-primary btn-lg btn-block btn-action"
          title="Add">Update</button>
        <button *ngIf="action === Action.REPLACE" class="btn btn-primary btn-lg btn-block btn-action" title="Add">Replace</button>
      </div>
    </div>
  </form>
</div>

<div class="row submit-panel" *ngIf="showLienholderDeletePanel()">
  <div class="col-md-6 col-sm-12 col-xs-12 btn-col cancel-btn-wrapper">
    <button class="btn btn-link" title="Cancel" (click)="onCancelClick()">Cancel</button>
  </div>
  <div class="col-md-6 col-sm-12 col-xs-12 btn-col submit-btns">
    <button *ngIf="action === Action.DELETE" (click)="openModal()" class="btn btn-primary btn-lg btn-block btn-action"
      title="Add">Delete</button>
  </div>
</div>

<div class="action-message-success" *ngIf="showActionMessage()">
  <div><i class="icon icon-check-mark"></i></div>
  <div class="message">Success!</div>
  <p>Lienholder information - Update pending</p>
  <p>Note: Your request has been submitted but still needs to be verified. Please check back in 3-4 business days to confirm your changes</p>
</div>
<div class="action-message-failure" *ngIf="showFailureMessage()">
  <div><i class="icon icon-attention"></i></div>
  <div class="message">We're Sorry...</div>
  <p>The system is currently unavailable. Please try again in an hour.</p>
</div>
<div *ngIf="showModel">
  <div class="modal fade in" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Delete</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closePopup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to delete this Lienholder?</p>
        <button type="button" (click)="onDeleteClick()" class="btn btn-primary">Done</button>
      </div>
  </div>
  </div>
  </div>
  </div>

