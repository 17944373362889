import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent {

  @Input() contactInformation: { Name: string, userId?: string, email: string } | any;

  constructor() {
   // console.log(this.contactInformation);
  }
}