import { Component, Input } from '@angular/core';
import { AlertsService } from '../alerts.service';
import { PanelViewState } from '../panel-view-state.model';
import { Policy } from '../../shared/policy.constants';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent {
  /**
   * The success message to bed displayed.
   */
  @Input() message: string | any;

  /**
   * The boolean value which indicates whether the success alert should be displayed.
   */
  @Input() showSuccess: boolean | any;

  /**
   * Initializes a new instance of the {SuccessComponent}.
   * @param alertsService The instance of the {AlertsService}.
   */
  constructor(private alertsService: AlertsService) {

  }

  /**
   * Handles the view coverages click event.
   */
  onViewCoveragesClick(): void {
    this.alertsService.emitPanelViewState(new PanelViewState(Policy.PanelNames.POLICY_DETAILS, true));
  }

  /**
   * Handles the view documetns click event.
   */
  onViewDocumentsClick(): void {
    this.alertsService.emitPanelViewState(new PanelViewState(Policy.PanelNames.DOCUMENT_REQUEST, true));
  }
}
