import { Injectable } from '@angular/core';
import { TealiumDataService } from './tealium-data.service';
import { EnvConstants } from './env-constants';
import { Router, RoutesRecognized } from '@angular/router';

@Injectable()
export class TealiumUtagService {
    script_src = '';
    script_src_sync = '';
    // current active matched route url
    locationUrl = '';
    host: string;
    policyNumber: string |any;
    env = EnvConstants.DEV;
    account = 'thehartford';
    profile = 'lender-verification-portal';
    pageDetails: any = {};

    // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
    constructor(private tealiumDataSvc: TealiumDataService,
        private route: Router) {
        // Prevent viewing from working on angular pages
        (<any>window).utag_cfg_ovrd = { noview: true };

        (<any>window).utag_data = {};
        // tap into RoutesRecognized event to send page views immediately when route is matched and before templates begin to render
        // prevents undefined errors from template components calling event tracker before page view object exists for Tealium
        this.route.events.subscribe(event => {
            if (event instanceof RoutesRecognized) {
                // set the locationUrl to the current active matched route url
                this.setLocationUrl(event.url);
            }
        });

        this.host = this.getHostname();
        if (this.host.toLowerCase() === 'lender') {

            this.env = EnvConstants.PROD;
        }
        else if (this.host.toLowerCase() === 'qa-lender' || this.host.toLowerCase() === 'pte-lender') {
            this.env = EnvConstants.QA;
        } else {
            this.env = EnvConstants.DEV;
        }


        this.script_src =
            'https://tags.tiqcdn.com/utag/' + this.account + '/' + this.profile + '/' + this.env + '/utag.js';
        // Invoke script in constructor

        this.getScript(this.script_src);
    }

    setLocationUrl(v:any):any {
        if (v === '/') {
            return this.locationUrl = 'verify';
        }
        this.locationUrl = v;
    }

    /**
     * Checks if the trackable iframe is on the page
     * @returns {boolean}
     */
    isPageViewTrackable() {
        if (document.body.querySelectorAll('iframe[data-trackable=true]').length > 0) {
            return false;
        }
        return true;
    }

    // Generic script loader with callback
    getScript(src: string, callback?: Function) {
        // Kickout if testing environments
        if (window.hasOwnProperty('__karma__')) {
            return;
        }

        const d = document;
        let s:any, t:any;
        if (typeof src === 'undefined') {
            return;
        }
        s = d.createElement('script');
        s.language = 'javascript';
        s.type = 'text/javascript';
        s.async = 1;
        s.charset = 'utf-8';
        s.onload = (function () {
            // callback isn't really needed
            if (callback) {
                callback();
            }
        }).bind(this);
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
        s.src = src;
    }

    getHostname() {
        const checkHost = window.location.hostname.split('.')[0];
        return checkHost;
    }

    // Config settings used to build the path to the utag.js file
    getConfig() {
        return {
            site_name: "verification-portal",
            account: this.account,
            profile: this.profile,
            environment: this.env,
            locationUrl: this.locationUrl
        };
    }

    // Data layer is optional set of key/value pairs
    track(tealium_event: string, data?: any, delayed?: number) {
        if ((<any>window).utag === undefined) {
            // Delay event until loading is done
            if (!delayed) {
                delayed = 50;
            }
            // setTimeout((function () {
            //     if (delayed < 3000) { // Stop attempting to send data after 5 seconds of total delays 50+100+200+400+800+1600+3200
            //         this.track.call(this, tealium_event, data, delayed * 2);
            //     } else {
            //         console.log('TEALIUM: Dropping track ', tealium_event);
            //     }
            // }).bind(this), delayed);
            setTimeout((() => {
                if (delayed && delayed < 3000) { // Stop attempting to send data after 5 seconds of total delays 50+100+200+400+800+1600+3200
                    this.track.call(this, tealium_event, data, delayed * 2);
                } else {
                   // console.log('TEALIUM: Dropping track ', tealium_event);
                }
            }).bind(this), delayed);
        } else {
            (<any>window).utag.track(tealium_event, data);
        }
    }

    // set the active page details for tracking
    // set when route is first matched
    setDetails(policyNumber?: string, clientId?: string) {
        this.policyNumber = null;
        if (this.policyNumber) {
            this.policyNumber = policyNumber;
        }
        this.pageDetails = this.tealiumDataSvc.setDataLayer(this.getConfig(), policyNumber, clientId);
        Object.keys(this.pageDetails).map((key) => {
            (<any>window).utag_data[key] = this.pageDetails[key];
        });
    }

    // Trigger a page view
    view(pageName?: string) {
        if (this.isPageViewTrackable()) {
            if (pageName) {
                this.pageDetails.page_name = pageName;
                this.pageDetails.page_type = pageName;
            }
            this.track('view', this.pageDetails);
        } else {
           // console.log('TEALIUM: View invoked and denied on non-trackable page');
        }
    }

}

