import { Component, OnInit } from '@angular/core';
import { HomePolicyResponse } from '../../shared/api/home-policy-response.model';
import { Policy } from '../../shared/policy.constants';
import { AutoPolicyResponse } from '../../shared/api/auto-policy/auto-policy-response.model';
/**
 * The {PolicyCoveragesComponent} represents the information homepolicyresponse for coverage and deductible limits table.
 */
@Component({
  selector: 'app-policy-coverages',
  templateUrl: './policy-coverages.component.html'
})
export class PolicyCoveragesComponent implements OnInit {
  /**
   * The instance of the {HomePolicyResponse} represents the home policy HTTP response.
   */
  homePolicyResponse: HomePolicyResponse | any;
  /**
   * The instance of the {HomePolicyResponse} represents the home policy HTTP response.
   */
  autoPolicyResponse: AutoPolicyResponse | any;

  /**
   * Initializes a new instance of the {PolicyCoveragesComponent}.
   */
  constructor() { }

  /**
   * Called during Angular Life Cylce -- After view initializes
   */
  ngOnInit(): void {
    const homePolicyResponse:any = JSON.parse(sessionStorage.getItem(Policy.SessionKeys.HOME_POLICY_RESPONSE) ?? '""');
    this.homePolicyResponse =  homePolicyResponse as HomePolicyResponse;
    const autoPolicyResponse:any = JSON.parse(sessionStorage.getItem(Policy.SessionKeys.AUTO_POLICY_RESPONSE) ?? '""'); 
    this.autoPolicyResponse = autoPolicyResponse  as AutoPolicyResponse;
  }

  /**
   * Checks whether wind/hail coverage is excluded from this coverage.
   * @returns The boolean value incidates whether it is a wind/hail coverage.
   */
  isWindHailExcluded(): boolean {
    if (this.homePolicyResponse?.windHailExclusion) {
      return true;
    }
    return false;
  }

  /**
   * Checks whether hurricane coverage is excluded from this coverage.
   * @returns The boolean value indicates whether the hurricane is excluded from this coverage.
   */
  isHurricaneExcluded(): boolean {
    let excluded = false;
    this.homePolicyResponse?.sectionIICoverageList.forEach((coverage:any) => {
      if (coverage.coverageCode === 'TBD') {
        excluded = true;
      }
    });
    return excluded;
  }
  /**
   * Checks whether the policy is home policy.
   * @returns The boolean value indicates whether it is home policy.
   */
  isHomePolicyCoverage(): boolean {
    if (this.homePolicyResponse) {
      return true;
    }
    return false;
  }
  /**
   * Checks whether the policy is auuto policy.
   * @returns The boolean value indicates whether it is auto policy.
   */
  isAutoPolicyCoverage(): boolean {
    if (this.autoPolicyResponse) {
       return true;
    }
    return false;
  }
}
