import { Component, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../environments/environment-wrapper';

@Component({
  selector: 'app-password-card',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent {

  @Output() whenClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  onChange() {
    this.whenClicked.emit(environment.EDIT_PASSWORD);
  }
}
