import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment-wrapper';
import { ErrorMessage } from '../errors/error/error-message.model';
import { CookieService } from 'ngx-cookie-service';
import { CustomEventService } from '../services/tealium/custom-event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EditAccountService } from '../edit-account/edit-account.service';
import { RegistrationService } from '../registration/registration.service';
import { HomePolicyResponse } from '../shared/api/home-policy-response.model';
import { LvpUrls } from '../shared/url/lvp-urls';
import { AutoPolicyResponse } from '../shared/api/auto-policy/auto-policy-response.model';
import { AutoPolicyRequest } from '../shared/api/auto-policy/auto-policy-request.model';
import { PolicyRequest } from '../shared/api/policy-request.model';
import { UpdateProfileKeyModel } from '../search-policy/update-profile-key-model';
import { SearchPolicyService } from './search-policy.service';
import { ValidationPatterns } from '../shared/form/validation-patterns';
import { AuthenticateService } from '../services/auth/authenticate.service';
import { Subscription } from 'rxjs';
import { Console } from 'console';
import { AuthenticationRequest } from '../services/auth/authenticate.model';

@Component({
  selector: 'app-search-policy',
  templateUrl: './search-policy.component.html',
  styleUrls: ['./search-policy.component.scss']
})
export class SearchPolicyComponent implements OnInit, OnDestroy {

  policyType: string | any;
  policyNumber: string | any;
  isFormSubmitting = false;
  firstName: string | any;
  lastName: string | any;
  ciamUserName: string | any;
  email: string | any;
  policySearchForm: FormGroup | any;
  isSearchInProgress = false;
  policyError: boolean | any;
  freezePolicy: boolean | any;
  showModel: boolean | any;
  responseSatus: string | any;
  env = environment;
  @Output() hasErrors = new EventEmitter<ErrorMessage>();
  private Subscribe: Array<Subscription> = [];
  constructor(
    private cookieService: CookieService,
    private fb: FormBuilder,
    private customEvent: CustomEventService,
    private router: Router,
    private route: ActivatedRoute,
    private activeRoute: ActivatedRoute,
    private editAccountservice: EditAccountService,
    private registrationService: RegistrationService,
    private searchPolicyService: SearchPolicyService,
    private authenticateService: AuthenticateService) {
    this.policySearchForm = this.fb.group({
      policyNumber: ['', [Validators.required, Validators.minLength(10),
      Validators.maxLength(11)]],
      zipcode: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(10), Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]]
    }
    );
  }

  ngOnInit() {
    if (this.cookieService.get('CIAMSession') === '') {
      window.location.href = this.env.CIAM_LOGIN;
    }


    console.log("firstname::" + sessionStorage.getItem('firstName'));
    if (sessionStorage.getItem('firstName') == null || sessionStorage.getItem('firstName') === '') {
      console.log("inside firstname::");
      this.getProfileName();
    } else {
      this.firstName = sessionStorage.getItem('firstName');
    }
  }

  ngOnDestroy(): void {
    this.Subscribe.forEach(sub => sub.unsubscribe());
  }

  onFieldError(fieldName: string) {
    this.customEvent.policySearchFieldErrorTracking(this.policySearchForm, fieldName);
  }

  checkPolicyError() {
    if (this.policyError || this.freezePolicy) {
      this.policyError = false;
      this.freezePolicy = false;
    }
  }

  isNewCoPolicy() {
    this.policyNumber = this.policySearchForm.get('policyNumber')?.value;
    const isnum = /^\d+$/.test(this.policyNumber);
    return isnum;
  }

  isHomePolicy() {
    const index = this.policyNumber.search(/[a-zA-Z]/g);
    if (this.policyNumber[index].toUpperCase() === 'R' || this.policyNumber[index].toUpperCase() === 'J') {
      return true;
    } else {
      return false;
    }
  }

  searchPolicy() {
    if (!this.policySearchForm.valid) {
      this.isFormSubmitting = true;
      this.customEvent.policySearchPageErrorTracking(this.policySearchForm);
      return;
    }
    this.ciamUserName = sessionStorage.getItem('ciamUserName');
    this.policySearchForm.value['ciamUserName'] = this.ciamUserName;
    console.log("on searchPolicy::ciamUserName::" + this.ciamUserName);
    this.firstName = sessionStorage.getItem('firstName');
    this.lastName = sessionStorage.getItem('lastName');
    this.email = sessionStorage.getItem('email');
    
    if (this.isNewCoPolicy()) {
      this.policySearchForm.value['lob'] = 'Newco';
      this.Subscribe.push(this.searchPolicyService.invalidSearchPolicyAttempt(this.policySearchForm.value).subscribe(
        (response: any) => {
          if (response['body']['response'] === 'valid') {
            this.searchNewcoPolicy();
          } else {
            this.handleInvalidAttempt(response['body']);
          }
        }));
    } else {
      this.checkAutoOrHome();
    }
  }
  private checkAutoOrHome() {
    if (this.isHomePolicy()) {
      this.policySearchForm.value['lob'] = 'Home';
      this.Subscribe.push(this.searchPolicyService.invalidSearchPolicyAttempt(this.policySearchForm.value).subscribe(
        (response: any) => {
          if (response['body']['response'] === 'valid') {
            this.searchHomePolicy();
          } else {
            this.handleInvalidAttempt(response['body']);
          }
        }));
    } else {
      this.policySearchForm.value['lob'] = 'AUTOP';
      this.policySearchForm.value['ciamUserName'] = this.ciamUserName;
      this.Subscribe.push(this.searchPolicyService.invalidSearchPolicyAttempt(this.policySearchForm.value).subscribe(
        (response: any) => {
          if (response['body']['response'] === 'valid') {
            this.handleLvpAuthCall();
          } else {
            this.handleInvalidAttempt(response['body']);
          }
        }));
    }
  }
  private handleInvalidAttempt(response: any) {
    if (response['msgDtoList'].length > 1) {
      this.freezePolicy = true;
      this.searchPolicyService.setFade(true);
      this.showModel = true;
    } else {
      if (response['msgDtoList'][0]['messageCd'] === 'signout_user') {
        this.showModel = true;
        this.searchPolicyService.setFade(true);
      } else {
        this.freezePolicy = true;
      }
    }
  }

  public closePopup() {
    //console.log('Inside close popup')
    this.cookieService.delete('CIAMSession', '/', '.thehartford.com');
    this.router.navigate(['/']);
  }

  private handleHomePolicyResponse(response: HomePolicyResponse): void {
    //console.log('inside handle home policy');
    this.isSearchInProgress = false;
    if (response.errorDTO && response.errorDTO.errorDesc.length !== 0) {
      this.policyError = true;
      window.scrollTo(0, 0);
      return;
    }
    if (response.hasOwnProperty('error')) {
      this.isSearchInProgress = false;
      this.policyError = true;
      window.scrollTo(0, 0);
      return;
    }
    this.resetResponseInSession('homePolicyResponse', response);
    window.scrollTo(0, 0);
    this.navigateToPath(LvpUrls.POLICY_DETAILS);
  }

  private handleAutoPolicyResponse(response: AutoPolicyResponse): void {
    //console.log('inside handle Auto policy')
    this.isSearchInProgress = false;
    if (response.errorDTO && response.errorDTO.errorDesc.length !== 0) {
      this.policyError = true;
      window.scrollTo(0, 0);
      return;
    }
    if (response.hasOwnProperty('error')) {
      this.isSearchInProgress = false;
      this.policyError = true;
      window.scrollTo(0, 0);
      return;
    }
    this.resetResponseInSession('autoPolicyResponse', response);
    window.scrollTo(0, 0);
    this.navigateToPath(LvpUrls.POLICY_DETAILS);
  }

  private resetResponseInSession(key: string | null = null, response: any = null): void {
    sessionStorage.clear();
    sessionStorage.setItem('firstName', this.firstName);
    sessionStorage.setItem('lastName', this.lastName);
    sessionStorage.setItem('ciamUserName', this.ciamUserName);
    sessionStorage.setItem('email', this.email);
     
    if (key && response) {
      sessionStorage.setItem(key, JSON.stringify(response));
    }
  }

  private prepareAutoRequest(): AutoPolicyRequest {
    //console.log('Under prepare request for Auto');
    const request = new AutoPolicyRequest();
    request.lenderType = 'leased';
    request.policyNumber = this.policySearchForm.get('policyNumber')?.value;
    request.policytype = 'Auto';
    request.zipcode = this.policySearchForm.get('zipcode')?.value;
    request.companyName = 'Company';
    request.firstname = this.firstName.replace(ValidationPatterns.AUTO_FIRST_AND_LAST_NAMES, '');
    request.lastname = this.lastName.replace(ValidationPatterns.AUTO_FIRST_AND_LAST_NAMES, '');
    request.policyNumber = this.parseAutoPolicyNumber(this.policyNumber);
    //console.log(request.policyNumber);
    request.policySymbol = this.parseAutoPolicySymbol(this.policyNumber);
    //console.log(request.policySymbol);
    request.regionalOfficeNum = this.parseAutoRegionOfficeNumber(this.policyNumber);
    //console.log(request.regionalOfficeNum)
    request.operation = 'SEARCH';
    request.companyAddress = '830 GREENBRIER CI';
    request.state = 'VA';
    request.companyZipCode = '06230';
    request.enableLVPChat = 'false';
    request.lob = 'auto';
    //console.log(`Auto policy request: ${JSON.stringify(request)}`);
    return request;

  }

  private parseAutoPolicySymbol(policyNumber: string): string | null {
    const symbol = policyNumber.match(/[a-zA-Z]/g);
    return symbol && symbol.length !== 0 ? symbol.join('') : null;
  }

  private navigateToPath(path: string) {
    this.router.navigate([path]);
  }

  private parseAutoRegionOfficeNumber(policyNumber: string): string {
    return policyNumber.substring(0, policyNumber.search(/[a-zA-Z]/));
  }

  private prepareSearchRequest(): PolicyRequest {
    const request = new PolicyRequest();
    request.policyNumber = this.policySearchForm.get('policyNumber')?.value;
    request.policyType = this.policyType;
    request.zipcode = this.policySearchForm.get('zipCode')?.value;
    return request;
  }

  private parseAutoPolicyNumber(policyNumber: string): string {
    let index = policyNumber.search(/[a-zA-Z]/g);
    const symbol = policyNumber.match(/[a-zA-Z]/g);
    if (symbol && symbol.length !== 0) {
      index += symbol.length;
    }
    return policyNumber.substring(index).trim();
  }

  private handleResponseError(error: any): void {
    //console.error(`HTTP response error: ${JSON.stringify(error)}`);
    window.scrollTo(0, 0);
    this.isFormSubmitting = false;
    return;
  }

  isFormFieldEntered(field: string): boolean {
    return ((this.policySearchForm.get(field)?.touched &&
      this.policySearchForm.get(field)?.hasError('required')) ||
      (this.policySearchForm.get(field)?.untouched && this.isFormSubmitting)) ? true : false;
  }

  isFormFieldValid(field: string): boolean {
    return ((this.policySearchForm.get(field)?.touched &&
      this.policySearchForm.get(field)?.hasError('minlength')) ||
      (this.policySearchForm.get(field)?.touched &&
        this.policySearchForm.get(field)?.hasError('pattern')) ||
      (this.policySearchForm.get(field)?.touched &&
        this.policySearchForm.get(field)?.hasError('maxlength'))) ? true : false;
  }

  private handleNewCoPolicyResponse(response: any) {
    //console.log('inside handle Newco policy')
    if (response['body']['redirect'] === false) {
      window.scrollTo(0, 0);
      this.policyError = true;
      return;
    }
    if (response.hasOwnProperty('error')) {
      this.isSearchInProgress = false;
      this.policyError = true;
      window.scrollTo(0, 0);
      return;
    }
    if (response['body']['redirect'] === true) {
      window.location.href = this.env.REDIRECT_URL;
    }
  }

  private updateAppProfile() {
    const request = new UpdateProfileKeyModel();
    request.firstName = this.firstName;
    request.lastName = this.lastName;
    request.emailId = this.email;
    this.searchPolicyService.onLoadPolicySearch(request).subscribe(
      (response: any) => {
        this.responseSatus = response['body']['result'];
        console.log("updateprofile responseSatus:" + this.responseSatus);
        if (!!this.responseSatus && (this.responseSatus === 'active')) {
          this.getProfileNameWithoutUpdate();
        }
      },
      error => this.handleResponseError(error)
    );

  }

  private getProfileName() {
    //console.log('Inside get profile method');
    this.Subscribe.push(this.searchPolicyService.getLoggedInUser().subscribe(
      (response: any) => {
        this.firstName = response['body']['firstName'];
        this.lastName = response['body']['lastName'];
        this.ciamUserName = response['body']['ciamUserName'];
        this.email = response['body']['email'];
        sessionStorage.setItem('ciamUserName', this.ciamUserName);
        sessionStorage.setItem('firstName', this.firstName);
        sessionStorage.setItem('lastName', this.lastName);
        sessionStorage.setItem('email', this.email);
        console.log("inside getprofile::" + this.firstName +
          "::sessionstorage value::" + sessionStorage.getItem('firstName'));
        this.checkProfileUpdate();
      },
      error => this.handleResponseError(error)
    ));
  }

  private getProfileNameWithoutUpdate() {
    console.log('Inside get profile method');
    this.searchPolicyService.getLoggedInUser().subscribe(
      (response: any) => {
        this.firstName = response['body']['firstName'];
        this.lastName = response['body']['lastName'];
        this.ciamUserName = response['body']['ciamUserName'];
        this.email = response['body']['email'];
        sessionStorage.setItem('ciamUserName', this.ciamUserName);
        sessionStorage.setItem('firstName', this.firstName);
        sessionStorage.setItem('lastName', this.lastName);
        sessionStorage.setItem('email', this.email);
        console.log("inside getprofile::" + this.firstName);
      },
      error => this.handleResponseError(error)
    );
  }


  private searchNewcoPolicy() {
    this.Subscribe.push(this.searchPolicyService.lookupNewCoPolicy(this.policySearchForm.value)
      .subscribe(newcoResponse => this.handleNewCoPolicyResponse(newcoResponse),
        error => this.handleResponseError(error)));
  }

  private searchHomePolicy() {
    this.policySearchForm.value['policyType'] = 'HOME';
    this.policySearchForm.value['lenderType'] = 'Mortgagee';
    this.policySearchForm.value['firstName'] = this.firstName;
    this.policySearchForm.value['lastName'] = this.lastName;
    this.Subscribe.push(this.searchPolicyService.lookupHomePolicy(this.policySearchForm.value)
      .subscribe(homeResponse => this.handleHomePolicyResponse(homeResponse),
        error => this.handleResponseError(error)));

  }

  private searchAutoPolicy() {
    this.Subscribe.push(this.searchPolicyService.lookupAutoPolicy(this.prepareAutoRequest())
      .subscribe(response => this.handleAutoPolicyResponse(response),
        error => this.handleResponseError(error)));
  }

  private checkProfileUpdate() {
    if (this.route.snapshot.queryParamMap.get('token') !== null) {
      //console.log('Inside token value');
      this.updateAppProfile();
    }

  }

  private handleLvpAuthCall() {
    let authenticationRequest = new AuthenticationRequest();
    this.authenticateService.authenticateLvpAuto(authenticationRequest)
      .subscribe(
        (data: any) => {
          if (data) {
            const accessToken = data.access_token;
            if (accessToken) {
              this.cookieService.set('authToken', accessToken);             
            }      
            this.searchAutoPolicy();      
          }
        },
        () => {
        }
      );
  }  
}
