export class ValidationPatterns {
  static readonly ALPHA_NUMERIC_SPACE = /^[-\w\s]+$/;
  static readonly ALPHA_NUMERIC_POLICYNUMBER = /^\d{2}[a-zA-Z]{2}([a-zA-Z]|\s)?\d{6}$/;
  static readonly ALPHA_NUMERIC_SPACE_COMMA = /^[&,-\w\s]+$/;
  static readonly ALPHA_NUMERIC_SPACE_COMMA_PERIOD = /^[&'.,-\w\s]+$/;
  static readonly ALPHA_NUMERIC_SPECIAL_CHARACTERS = /^[&,/#-\w\s]+$/;
  static readonly ALPHA_SPACE_COMMA_PERIOD = /^[a-zA-Z&'.,\s]+$/;
  static readonly US_ZIPCODE = /^(\d{5}(?:-\d{4})?|\d{9})$/;
  static readonly FAX_NUMBER = /^\d{10}$/;
  static readonly AUTO_COMPANY_NAME = /[^\w-\+)(&#.,/\s]/gi;
  static readonly AUTO_FIRST_AND_LAST_NAMES = /[^A-Za-z'.&,-\s]/gi;
  static readonly AUTO_NEWCO_POLICYNUMBER = /^([1]{1})/;
  static readonly AUTO_NEWCO_POLICYNUMBER_TX = /^900$/;
  static readonly HOME_NEWCO_POLICYNUMBER = /^([2]{1})/;
}
