import { Component, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LvpFormService } from '../../shared/form/lvp-form.service';
import { LvpFormValidationService } from '../../shared/form/lvp-form-validation.service';
import { FormField } from '../../shared/form/form-field.model';
import { ValidationPatterns } from '../../shared/form/validation-patterns';
import { DecPageRequestModel } from '../../shared/api/dec-page-request.model';
import { DecPageResponseModel } from '../../shared/api/dec-page-response.model';
import { ErrorMessages } from '../../shared/validation/error-messages';
import { DecPageService } from '../billing-doc-requests/dec-page-service';
import { ErrorMessage } from '../../errors/error/error-message.model';
import { AutoDecRequest, RequestDeclaration } from '../../shared/api/auto-policy/auto-dec-request.model';
import { AutoPolicyResponse } from '../../shared/api/auto-policy/auto-policy-response.model';
import { AutoDecResponse } from '../../shared/api/auto-policy/auto-dec-response.model';
import { HomePolicyResponse } from '../../shared/api/home-policy-response.model';
import { CustomValidators } from '../../shared/form/custom-validators';
import { CustomEventService } from '../../services/tealium/custom-event.service';
import { formatDate } from '@angular/common';
import { Policy } from '../../shared/policy.constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-declaration-request-form',
  templateUrl: './declaration-request-form.component.html',
  styleUrls: ['./declaration-request-form.component.scss']
})
export class DeclarationRequestFormComponent implements OnDestroy {

  /**
   * The instance of the {FormGroup} represents the declaration page request form.
   */
  decForm: FormGroup | any;

  /**
   * The value indicates whether the sending request is in progress.
   */
  isSendingInProgress = false;

  /**
   * The value which indicates whether the form is submitting.
   */
  isFormSubmitting = false;

  /**
  * The policy type.
  */
  readonly CopyRequestType = {
    EMAIL: 'EMAIL',
    FAX: 'FAX',
    BOTH: 'BOTH'
  };

  /**
   * The current request type.
   */
  requestType: string | undefined;

  /**
   * The input length of the form fields.
   */
  readonly InputLength = {
    FULL_NAME_MAX_LENGTH: 50,
    FAX_MAX_LENGTH: 10,
    FAX_MIN_LENGTH: 10
  };

  /**
   * Messages sent to analytics
   */
  readonly TealiumMessages = {
    DECLARATION_PAGE_REQUEST: 'Declaration Page Request',
    SUCCESS: 'Success',
    FAILURE: 'Failure',
    HOME_POLICY: 'Home Policy',
    AUTO_POLICY: 'Auto Policy',
    HOME_FAILURE_MESSAGE: 'Exception Calling Output Hub.',
    HOME_SUCCESS_MESSAGE: 'We\'ll send your documents within an hour.',
    AUTO_SUCCESS_MESSAGE: 'We\'ll send your documents within 2 business days'
  };

  /**
   * The instance of the {AutoPolicyResponse} represents the home policy response.
   */
  @Input() autoPolicy: AutoPolicyResponse | undefined;

  /**
   * The instance of the {HomePolicyResponse} represents the home policy response.
   */
  @Input() homePolicy: HomePolicyResponse | undefined;

  /**
   * The policy type which can 'AUTO' or 'HOME'.
   */
  @Input() policyType: string | undefined;

  /**
 * The has errors event that notifies parent component about the
 * API consumption or network errors. The event emits the instance
 * of the `ErrorMessage` model that holds information about the error.
 * The parent component should be able to handle this event like this:
 * (hasErrors)="onHasErrors($event)"
 * onHasErrors(errorMessage: ErrorMessage): any { }
 */
  @Output() hasErrors = new EventEmitter<ErrorMessage>();

  /**
   * The cancel event which tells the parent component of cancelling action.
   */
  @Output() cancel = new EventEmitter<boolean>();

  /**
   * The submit event which tells the parent component of submitting action.
   */
  @Output() submit = new EventEmitter<boolean>();
  private Subscribe:Array<Subscription> = [];

  /**
   * Initializes a new instance of the {DeclarationRequestFormComponent}.
   * @param formBuilder The instance of the {FormBuilder} represents the form factory.
   * @param formService The instance of the {LvpFormService} represents the form-related service.
   * @param customEvent The instance of the {CustomEventService} represents the custom event service.
   * @param formValidationService The instnce of the {LvpFormValidationService} represents the form validation service.
   * @param decPageService The declaration page HTTP service.
   */
  constructor(private formBuilder: FormBuilder,
    private formService: LvpFormService,
    private customEvent: CustomEventService,
    private formValidationService: LvpFormValidationService,
    private decPageService: DecPageService) {
    this.createForm();
  }

  /**
   * Creates the instance of the {FormGroup} represent declaration page form.
   */
  private createForm(): void {
    const formFields: FormField[] = [
      new FormField(null, 'fullName', [Validators.required,
      Validators.maxLength(this.InputLength.FULL_NAME_MAX_LENGTH)]),

      new FormField(null, 'email', [Validators.required, Validators.email,
      CustomValidators.confirmMatchingFields('email', 'confirmEmail')]),
      new FormField(null, 'confirmEmail', [Validators.required, Validators.email,
      CustomValidators.confirmMatchingFields('email', 'confirmEmail')]),

      new FormField(null, 'fax', [Validators.required,
      Validators.pattern(ValidationPatterns.FAX_NUMBER),
      CustomValidators.confirmMatchingFields('fax', 'confirmFax'),
      Validators.minLength(this.InputLength.FAX_MIN_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'confirmFax', [Validators.required,
      CustomValidators.confirmMatchingFields('fax', 'confirmFax'),
      Validators.pattern(ValidationPatterns.FAX_NUMBER),
      Validators.minLength(this.InputLength.FAX_MIN_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'faxATTN', [Validators.required,
      Validators.maxLength(this.InputLength.FULL_NAME_MAX_LENGTH)])
    ];

    this.decForm = this.formService.createForm(this.decForm, this.formBuilder, formFields);
  }

  ngOnDestroy(): void {
    this.Subscribe.forEach(sub => sub.unsubscribe());
  }


  /**
   * Prepares declaration request by fetching values from submitted form.
   * @return The instance of the {DecPageRequestModel} represents the request payload.
   */
  private prepareHomeDeclarationRequest(): DecPageRequestModel {
    const decRequest = new DecPageRequestModel();

    const fullName = this.formService.getControlValue(this.decForm, 'fullName');
    decRequest.fullName = fullName;
    if(this.homePolicy){
      decRequest.changeNumber = this.homePolicy.changeNumber;
      decRequest.policyNumber = this.homePolicy.policyNumber;
      decRequest.policyType = this.homePolicy.policyType;
      decRequest.policyEffectiveDate = formatDate(this.homePolicy.policyStartDate, 'yyyy-MM-dd', 'en');
      decRequest.businessUnitCode = this.homePolicy.businessUnitId;
      decRequest.zipCode = this.homePolicy.dwellingAddress.postalCode;
    }
    if (this.requestType === this.CopyRequestType.EMAIL) {
      decRequest.deliveryType = this.CopyRequestType.EMAIL;
      decRequest.emailAddress = this.formService.getControlValue(this.decForm, 'email');
    } else if (this.requestType === this.CopyRequestType.FAX) {
      decRequest.fullName = this.formService.getControlValue(this.decForm, 'faxATTN');
      decRequest.deliveryType = this.CopyRequestType.FAX;
      decRequest.faxNumber = this.formService.getControlValue(this.decForm, 'fax');
      decRequest.faxATTN = this.formService.getControlValue(this.decForm, 'faxATTN');
    } else {
      decRequest.deliveryType = this.CopyRequestType.BOTH;
      decRequest.emailAddress = this.formService.getControlValue(this.decForm, 'email');
      decRequest.faxNumber = this.formService.getControlValue(this.decForm, 'fax');
      decRequest.faxATTN = this.formService.getControlValue(this.decForm, 'faxATTN');
    }
    decRequest.changeEffectiveDate = JSON.parse(sessionStorage.getItem(Policy.SessionKeys.CHANGE_EFFECTIVE_DATE) ?? '""');
    if (decRequest.changeEffectiveDate) {
      decRequest.changeEffectiveDate = formatDate(decRequest.changeEffectiveDate, 'yyyy-MM-dd', 'en');
    }
    decRequest.quoteIdentifier = JSON.parse(sessionStorage.getItem(Policy.SessionKeys.QUOTE_IDENTIFIER) ?? '""');
    decRequest.producerCode = this.homePolicy?.producerCode??'';
    return decRequest;
  }

  /**
   * Prepares the auto declartion requests by fetching the values from teh submitted form and auto policy response.
   */
  private prepareAutoDeclarationRequests(): AutoDecRequest[] {
    const autoDecRequests: AutoDecRequest[] = [];
    const requestDeclarations = this.prepareAutoRequestDeclarations();
    if (!requestDeclarations || requestDeclarations.length === 0) {
      throw new Error('Invalid request declarations.');
    }
    requestDeclarations.forEach(requestDeclaration => {
      const request = new AutoDecRequest();
      if(this.autoPolicy){
      request.policyHolderFirstName = this.autoPolicy.policyHolderFirstName;
      request.policyHolderLastName = this.autoPolicy.policyHolderSurName;
      request.address1 = this.autoPolicy.addressDTO.address;
      request.address2 = this.autoPolicy.addressDTO.streetName;
      request.city = this.autoPolicy.addressDTO.city;
      request.state = this.autoPolicy.addressDTO.state;
      request.zipcode = this.autoPolicy.addressDTO.postalCode;
      request.policyNumber = this.autoPolicy.policyNumber;
      request.policyStartDate = this.autoPolicy.policyStartDate;
      request.policyExpiryDate = this.autoPolicy.policyExpiryDate;
      request.guid = this.autoPolicy.guid;
      request.marketSegmentDesc = this.autoPolicy.marketSegmentDesc;
      request.ratingState = this.autoPolicy.ratingState;
      }
      request.lob = 'Auto';
      request.propertyType = 'AUTO';
      request.lenderFirstName = 'John';
      request.lenderLastName = 'Doe';
      request.requestDeclaration = requestDeclaration;
      autoDecRequests.push(request);
    });
    return autoDecRequests;
  }

  /**
   * Prepares the auto request declaration given a request type.
   * @returns The instances of the {RequestDeclaration} represents the request declaration.
   */
  private prepareAutoRequestDeclarations(): RequestDeclaration[] {
    const requestDeclaration = new RequestDeclaration();
    const requestDeclarations: RequestDeclaration[] = [];
    if (this.requestType === this.CopyRequestType.EMAIL) {
      requestDeclaration.deliveryType = this.CopyRequestType.EMAIL;
      requestDeclaration.email = this.formService.getControlValue(this.decForm, 'email');
    } else if (this.requestType === this.CopyRequestType.FAX) {
      requestDeclaration.deliveryType = this.CopyRequestType.FAX;
      requestDeclaration.fax = this.formService.getControlValue(this.decForm, 'fax');
    } else if (this.requestType === this.CopyRequestType.BOTH) {
      const emailRequest = new RequestDeclaration();
      emailRequest.deliveryType = this.CopyRequestType.EMAIL;
      emailRequest.email = this.formService.getControlValue(this.decForm, 'email');
      requestDeclarations.push(emailRequest);
      const faxRequest = new RequestDeclaration();
      faxRequest.deliveryType = this.CopyRequestType.FAX;
      faxRequest.fax = this.formService.getControlValue(this.decForm, 'fax');
      requestDeclarations.push(faxRequest);
      return requestDeclarations;
    } else {
      throw new Error('Unable to determine a proper request type.');
    }
    requestDeclarations.push(requestDeclaration);
    return requestDeclarations;
  }

  /**
   * Checks whether the input pattern is valid.
   * @param field The form field/control name.
   * @returns False if invalid; otherwise, true.
   */
  hasInputPatternError(field: string): boolean {
    return this.formValidationService.hasPatternError(this.decForm, field)
      && this.isFormSubmitting;
  }

  /**
   * Checks whether the confirmation field is mismatched
   * @param field The form field.
   * @param confirmField The form confirm field.
   * @returns True if is mismatched; otherwise False.
   */
  hasMismatch(field: string, confirmField: string = "") {
    return (this.formValidationService.hasMismatch(this.decForm, field)
      || this.formValidationService.hasMismatch(this.decForm, confirmField))
      && this.isFormSubmitting;
  }

  /**
   * Displays the correct form fields based on user selection
   * @param type The instance of type - how user wants dec page copy devliered
   */
  onRequestTypeClick(type:any) {
    this.resetFormFieldValidators(type);
    this.requestType = type;
    switch (type) {
      case this.CopyRequestType.EMAIL:
        this.requestType = this.CopyRequestType.EMAIL;
        break;
      case this.CopyRequestType.FAX:
        this.requestType = this.CopyRequestType.FAX;
        break;
      case this.CopyRequestType.BOTH:
        this.requestType = this.CopyRequestType.BOTH;
        break;
    }
  }

  /**
   * Resets the form field validators based on the current request type.
   * @param type The request type which can EMAIL, FAX, or BOTH.
   */
  private resetFormFieldValidators(type: string): void {
    this.clearValidators();
    switch (type) {
      case this.CopyRequestType.EMAIL:
        this.setEmailValidators();
        break;
      case this.CopyRequestType.FAX:
        this.setFaxValidators();
        break;
      case this.CopyRequestType.BOTH:
        this.setEmailValidators();
        this.setFaxValidators();
        break;
    }
  }

  /**
   * Clears control validators.
   */
  private clearValidators(): void {
    this.formValidationService.clearControlValidators(this.decForm, 'fullName');
    this.formValidationService.clearControlValidators(this.decForm, 'email');
    this.formValidationService.clearControlValidators(this.decForm, 'confirmEmail');
    this.formValidationService.clearControlValidators(this.decForm, 'fax');
    this.formValidationService.clearControlValidators(this.decForm, 'confirmFax');
    this.formValidationService.clearControlValidators(this.decForm, 'faxATTN');
  }

  /**
   * Sets email validators.
   */
  private setEmailValidators(): void {
    this.formValidationService.setControlValidators(this.decForm, 'fullName', [Validators.required,
    Validators.maxLength(this.InputLength.FULL_NAME_MAX_LENGTH)]);

    this.formValidationService.setControlValidators(this.decForm, 'email',
      [Validators.required, Validators.email, CustomValidators.confirmMatchingFields('email', 'confirmEmail')]);

    this.formValidationService.setControlValidators(this.decForm, 'confirmEmail',
      [Validators.required, Validators.email, CustomValidators.confirmMatchingFields('email', 'confirmEmail')]);
  }

  /**
   * Sets fax validators.
   */
  private setFaxValidators(): void {
    this.formValidationService.setControlValidators(this.decForm, 'fax',
      [Validators.required,
      CustomValidators.confirmMatchingFields('fax', 'confirmFax'),
      Validators.pattern(ValidationPatterns.FAX_NUMBER),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]);

    this.formValidationService.setControlValidators(this.decForm, 'confirmFax',
      [Validators.required,
      CustomValidators.confirmMatchingFields('fax', 'confirmFax'),
      Validators.pattern(ValidationPatterns.FAX_NUMBER),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]);

    this.formValidationService.setControlValidators(this.decForm, 'faxATTN',
      [Validators.required,
      Validators.maxLength(this.InputLength.FULL_NAME_MAX_LENGTH)]);
  }

  /**
   * Shows the fax form.
   * @returns The boolean value indicates whether the fax form should be displayed.
   */
  showFaxForm(): boolean {
    return this.requestType === this.CopyRequestType.FAX
      || this.showBothEmailAndFaxForms();
  }

  /**
   * Shows the email form.
   * @returns The boolean value indicates whether the email form should be displayed.
   */
  showEmailForm(): boolean {
    return this.requestType === this.CopyRequestType.EMAIL
      || this.showBothEmailAndFaxForms();
  }

  /**
   * Shows both the email and fax forms.
   * @returns The boolean value indicates whether the email and fax forms should be displayed.
   */
  showBothEmailAndFaxForms(): boolean {
    return this.requestType === this.CopyRequestType.BOTH;
  }

  /**
   * Checks whether a given form field is valid.
   * @param field The form field name.
   * @returns The boolean value indicates whether the validation is valid.
   */
  isFormFieldValid(field: string): boolean {
    return (this.decForm.get(field)?.errors
      && this.decForm.get(field)?.errors?.hasOwnProperty('required'))
      && this.decForm.get(field)?.touched
      && this.isFormSubmitting? true : false;
  }

  /**
   * Handles submit click event.
   */
  onSendClick(event:any) {
    event.stopPropagation();
    this.isSendingInProgress = true;
    this.isFormSubmitting = true;
    this.decForm = this.formValidationService.validateFormControls(this.decForm);
    if (!this.decForm.valid) {
      this.isSendingInProgress = false;
      return;
    }
    this.processDecRequest();
  }

  /**
   * Displays form field error if validation fails.
   * @param field The field control name.
   */
  displayFormFieldError(field: string): any {
    const hasFieldErrors = this.isFormFieldValid(field) || this.hasMismatch(field) || this.hasInputPatternError(field);
    return {
      'has-error': hasFieldErrors,
      'has-feedback': hasFieldErrors,
    };
  }

  /**
   * Processes the declaration page copy request by sending the request via HTTP API.
   * @param request The instance of the {DecPageRequestModel} represents the declaration page request.
   */
  private processDecRequest(): void {
    if (this.policyType === 'HOME') {
      this.Subscribe.push(this.decPageService.homeDecPageRequest(this.prepareHomeDeclarationRequest()).subscribe(
        response => this.handleHomeDecResponse(response),
        error => this.handleResponseError(error)
      ));
    } else if (this.policyType === 'AUTO') {
      const autoDecRequests = this.prepareAutoDeclarationRequests();
      autoDecRequests.forEach(autoDecRequest => {
        this.Subscribe.push(this.decPageService.autoDecpageRequest(autoDecRequest).subscribe(
          response => this.handleAutoDecResponse(response),
          error => this.handleResponseError(error)
        ));
      });
    } else {
      throw new Error('Unable to determine the policy type.');
    }
  }

  /**
   * Handles the auto declaration page copy response from sending event.
   * @param response The instance of the {DecPageResponseModel} represents the HTTP response.
   */
  private handleAutoDecResponse(response: AutoDecResponse): void {
    this.isSendingInProgress = false;
    if (response && response.errorDTO && response.errorDTO.errorDesc.length !== 0) {
      this.customEvent.policyDetailsTracking(this.TealiumMessages.DECLARATION_PAGE_REQUEST, this.TealiumMessages.FAILURE,
        this.TealiumMessages.AUTO_POLICY, response.messageDesc);
      this.hasErrors.emit(new ErrorMessage(
        response.errorDTO.errorDesc,
        response.errorDTO.errorCd));
      return;
    }
    if (response.hasOwnProperty('error')) {
      this.isSendingInProgress = false;
      const errorResponse : any  = JSON.stringify(response);
      this.hasErrors.emit(new ErrorMessage(errorResponse['error']));
      return;
    }
    if (response.messageCode === 'Success') {
      this.customEvent.policyDetailsTracking(this.TealiumMessages.DECLARATION_PAGE_REQUEST, this.TealiumMessages.SUCCESS,
        this.TealiumMessages.AUTO_POLICY, this.TealiumMessages.AUTO_SUCCESS_MESSAGE);
      this.submit.emit(true);
    } else {
      throw new Error('Unable to inspect the auto declaration page response.');
    }
  }

  /**
   * Handles the home declaration page copy response from sending event.
   * @param response The instance of the {DecPageResponseModel} represents the HTTP response.
   */
  private handleHomeDecResponse(response: DecPageResponseModel): void {
    this.isSendingInProgress = false;
    if (response && response.errorDesc !== null) {
      this.customEvent.policyDetailsTracking(this.TealiumMessages.DECLARATION_PAGE_REQUEST, this.TealiumMessages.FAILURE,
        this.TealiumMessages.HOME_POLICY, this.TealiumMessages.HOME_FAILURE_MESSAGE);
      this.hasErrors.emit(new ErrorMessage(
        response.errorDesc,
        response.errorCd));
      return;
    }
    if (response.hasOwnProperty('error')) {
      this.isSendingInProgress = false;
      const errorResponse : any  = JSON.stringify(response);
      this.hasErrors.emit(new ErrorMessage(errorResponse['error']));
      return;
    }
    this.customEvent.policyDetailsTracking(this.TealiumMessages.DECLARATION_PAGE_REQUEST, this.TealiumMessages.SUCCESS,
      this.TealiumMessages.HOME_POLICY, this.TealiumMessages.HOME_SUCCESS_MESSAGE);
    this.resetForm();
    this.submit.emit(true);
  }

  /**
   * Handles the response error.
   * @param error The HTTP response error.
   */
  private handleResponseError(error: any): void {
    //console.error(`HTTP response error: ${JSON.stringify(error)}`);
    this.isSendingInProgress = false;
    this.hasErrors.emit(new ErrorMessage(ErrorMessages.SYSTEM_DOWNTIME));
    this.customEvent.policyDetailsTracking(this.TealiumMessages.DECLARATION_PAGE_REQUEST, this.TealiumMessages.FAILURE,
      'Home Policy', ErrorMessages.SYSTEM_DOWNTIME);
    this.isFormSubmitting = false;
    return;
  }

  /**
   * Resets the policy form to clear out any data.
   */
  private resetForm(): void {
    this.decForm?.reset();
    this.isFormSubmitting = false;
  }

  /**
   * Handles cancelling of the declaration page copy request event.
   */
  onCancelClick(): void {
    this.resetForm();
    this.cancel.emit(true);
  }
}

