import { Component, OnInit } from '@angular/core';
import { DisassociationPostVerficationService } from './disassociation-post-verification.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../environments/environment-wrapper';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-disassociation-post-verification',
  templateUrl: './disassociation-post-verification.component.html',
  styleUrls: ['./disassociation-post-verification.component.scss']
})
export class DisassociationPostVerificationComponent implements OnInit {

  url: string | any;
  env = environment;
  payload: any;
  ciamSession: string | any;
  token: string | any;
  validCiam: string | any;
  responseStatus: any;
  flag = false;
  ciamResponseData: any;


  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private disassociationPostVerficationService: DisassociationPostVerficationService
  ) {
    console.log("Inside ngonit disassociation post-verification");
    this.route.queryParams.subscribe((params: Params) => {
      this.token = params['token'];
      this.token = decodeURI(this.token);
    });
    this.cookieService.set('token', this.token);
    sessionStorage.setItem('token', this.token);
    console.log("inside token validation::" + this.cookieService.get('token') +
      "::setsession token::" + sessionStorage.getItem("token"));
  }

  ngOnInit() {
    console.log("Inside disassociation post verification");
    if (this.cookieService.get('CIAMSession') === '') {
      window.location.href = this.env.CIAM_LOGIN;
    } else {
      this.relinkingAfterCiamSessionValidation();
    }
  }

  relinkingAfterCiamSessionValidation(): any {
    this.flag = false;
    if (this.cookieService.get('token') !== '' || sessionStorage.getItem('token') !== '') {
      this.disassociationPostVerficationService.isValidTokenAndCiamSession().subscribe(
        (response: any) => {
          this.ciamResponseData = response;
          if (this.ciamResponseData['body']['isCiamSessionValid'] === 'true') {
            if (this.ciamResponseData['body']['isTokenValid'] === 'true') {
              this.flag = true;
              return this.disassociationPostVerficationService.relinkingUserToCiam().subscribe(
                (response: any) => {
                  console.log(response);
                }
              );
            } else if (response['body']['isTokenValid'] === 'false') {
              window.scrollTo(0, 0);
              return this.router.navigate(['disassociation-email-expiry']);
            }
          } else if (this.ciamResponseData['body']['isCiamSessionValid'] === 'false') {
            return window.location.href = this.env.CIAM_LOGIN;
          } 
        }
      );
    }

  }
  policySearch(): void {
    this.disassociationPostVerficationService.isValidCiamSession().subscribe(
      (response: any) => {
        if (response['body']['isValid'] === 'true') {
          window.scrollTo(0, 0);
          this.router.navigate(['search-policy']);
        }
      });
  }
}
