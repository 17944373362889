export class ModalData {
  isDone: boolean | any;
  previousAction: string | any;
  action: string | any;
  targetIndex: number | any;

  constructor(isDone?: boolean, previousAction?: string, action?: string, targetIndex?: number) {
    this.isDone = isDone??false;
    this.previousAction = previousAction??"";
    this.action = action??"";
    this.targetIndex = targetIndex??0;
  }
}
