import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';

@Directive({ selector: '[appScrollToFirstInvalid]' })
export class ScrollToFirstInvalidDirective {
    /**
     * The Form Instance that the directive is bound to
     */
    // @Input('scrollToFirstInvalid') scrollToFirstInvalid: NgForm;
    @Input('scrollToFirstInvalid') scrollToFirstInvalid: NgForm | any;

    // Inject element reference to the form
    constructor(private el: ElementRef) { }

    /**
     * This fucntion listens for the 'submit' event via HostListener
     * and runs once event is fired. It will check the form to find
     * the first input that is marked invalid, and scrollTo and focus it.
     * @param event - Click Event - used to call preventDefault()
     */
    @HostListener('submit', ['$event'])
    //onSubmit(event) {
    onSubmit(event:any) {
        event.preventDefault();

        // Grab first input in the form that is marked invalid
        const target = this.el.nativeElement.querySelector('.ng-invalid');

        /**
         * If the target exists, scroll to it, and set focus on it.
         * jQuery is used, but in the future should be re-factored
         * to use Renderer2
         */
        if (target) {
           // $('html,body').animate({ scrollTop: $(target).offset().top }, 'slow');
           $('html,body').animate({ scrollTop: $(target).offset()?.top }, 'slow');
            $(target).focus();
        }
    }
}
