import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Log } from "./log";
import { environment } from '../../../environments/environment-wrapper';
import { Policy } from 'src/app/shared/policy.constants';

/*
* Inject this service in any component to log something to console and to the server.
* */
@Injectable()
export class LogService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private http: HttpClient;
  private log: Log;


  constructor(http: HttpClient) {
    this.http = http;
    this.log = new Log;
  }

  /*
  *Log to console.
   */
  public logToConsole(object: any, type: string): void {
    switch (type) {
      case 'ERROR':
        console.error(object);
        break;
      case 'FATAL':
        console.error(object);
        break;
      case 'INFO':
        console.info(object);
        break;
      case 'DEBUG':
        console.debug(object);
        break;
      case 'WARN':
        console.warn(object)
        break;
      default:
        console.log()
    }
  }

  /*
  Creates log message based on switch case passed in.
  */
  public createLogMessage(logcase: string, message?: string): any {

    let policyNumber;
    const apr = sessionStorage.getItem("autoPolicyResponse")
    const hpr = sessionStorage.getItem("homePolicyResponse")
    const email = sessionStorage.getItem("email")

    let selection = document.getSelection();
    let elementid = document.activeElement?.id
    let elementclass = selection?.anchorNode?.parentElement?.className
    let elementparentnodename = selection?.anchorNode?.parentNode?.nodeName
    let elementnodename = selection?.anchorNode?.nodeName
    let elementIdentifier;

    if (elementid && elementid != 'body') {
      elementIdentifier = elementid
    } else if (elementclass) {
      elementIdentifier = elementclass
    } else {
      elementIdentifier = elementparentnodename + ' ' + elementnodename
    }

    if (apr) {
      let aprparsed = JSON.parse(apr);
      policyNumber = aprparsed.policyNumber
    } else if (hpr) {
      let hprparsed = JSON.parse(hpr);
      policyNumber = hprparsed.policyNumber
    }


    switch (logcase) {

      case 'Copy': {
        let event = message;

        if (event == '/') {
          event = 'landing-page'
        }

        if (email && policyNumber) {
          const logmessage = 'User at email address ' + email + ' and Policy Number ' + policyNumber + ' used Copy function on ' + elementIdentifier + ' element on ' + event;
          return logmessage;
        } else if (email) {
          const logmessage = 'User at email address ' + email + ' used Copy function on ' + elementIdentifier + ' element on ' + event;
          return logmessage;
        } else {
          const logmessage = 'User in registration flow used Copy function on ' + elementIdentifier + ' element on ' + event;
          return logmessage;
        }
      }

      case 'Paste': {
        let event = message;

        if (event == '/') {
          event = 'landing-page'
        }

        if (email && policyNumber) {
          const logmessage = 'User at email address ' + email + ' and Policy Number ' + policyNumber + ' used Paste function on ' + elementIdentifier + ' element on ' + event;
          return logmessage;
        } else if (email) {
          const logmessage = 'User at email address ' + email + ' used Paste function on ' + elementIdentifier + ' element on ' + event;
          return logmessage;
        } else {
          const logmessage = 'User in registration flow used Paste function on ' + elementIdentifier + ' element on ' + event;
          return logmessage;
        }
      }

      case 'Login': {
        if (email) {
          const logmessage = 'User at email address ' + email + ' successfully logged in to LVP application.';
          return logmessage;
        }
      }
    }
  }

  /*
  Accepts message string and type string as parameters and send the message to server, used for logging INFO strings.
  */
  public logInfoToServer(message: string): void {
    const postBody = [{
      'logLevel': 'INFO',
      'logMessage': 'pl_lvp_ui | ' + message
    }];

    this.http.post(environment.LOGGER_EXPRESS, postBody, { headers: this.headers }).subscribe(
      (_httpResponse) => {
        this.logToConsole("success in posting message to server", 'INFO');
      },
      (httpError: any): void => {
        this.logToConsole(httpError, 'ERROR')
      })
  }

  logCopy(eventid: string) {
    this.logInfoToServer(this.createLogMessage('Copy', eventid));
  }

  logPaste(eventid: string) {
    this.logInfoToServer(this.createLogMessage('Paste', eventid));
  }
  logSuccessfulLogin() {
    this.logInfoToServer(this.createLogMessage('Login'));
  }
}
