import { DwellingAddress } from '../dwelling-address.model';
import { Lienholder } from '../lienholder.model';
import { Coverage } from '../coverage.model';
import { Billing } from '../billing/billing.model';
import { ErrorDto } from '../error-dto.model';
import { HomePolicyResponse } from '../home-policy-response.model';
import { PolicySearchRequestView } from '../policy-search-request-view.model';
import { ChangeAmendResponse, ChangePegaResponse } from './change-amend-response.model';
import { Mortgagee } from '../../../policy/mortgagee.model';
import { MortgageeAddress } from '../../../policy/mortgagee-address.model';

export class HomePolicyDataFactory {

  static getMockHomePolicy(hasError: boolean = false): HomePolicyResponse {
    return {
      clientId: 'J9KNDH9PQ4743',
      availableEffectiveDate: '2018-10-08',
      changeNumber: '0',
      windHailExclusion: true,
      guid: null,
      zipCode: null,
      emailAdress: null,
      policyNumber: '55PHB250098',
      policyType: 'HOME',
      policyStatusCd: 'active',
      businessUnitId: '46',
      lOBCd: 'home',
      policyStartDate: '6/11/2018',
      policyExpiryDate: '6/11/2019',
      ratingState: null,
      policyHolderName: 'Doe, John Alexander',
      producerCode: '55001287',
      marketSegment: 'ABC',
      contractNum: '55001287',
      statusMessage: null,
      policyStatus: 'Status',
      replacementCost: null,
      deductible: null,
      policyPremium: '100000',
      policyTypeCd: '04',
      lenderFirstName: 'John Alexander',
      lenderLastName: 'Doe',
      role: 'LENDER',
      inFlight: false,
      dwellingAddress: this.getDwellingAddress(),
      lienHolderList: this.getLienholders(2),
      sectionICoverageList: this.getCoverages(2),
      sectionIICoverageList: this.getCoverages(2),
      errorDTO: hasError ? this.createErrorDto() : null,
      billing: this.getBilling(),
      errorDTOList: [],
      policySearchRequestView: this.getPolicySearchRequestView(),
      writingCompanyName: 'BoA',
      policyRenewalDate: '10/15/2018',
	    homeDecPageEligibility: true,
      policyWithOldForms: true,
      isPolicyIssuedToday: true
    };
  }

  static getDwellingAddress(): DwellingAddress {
    return {
      addressType: 'Billing',
      streetName: null,
      address: '4135 Uppergate Ln',
      city: 'Charlotte',
      state: 'NC',
      postalCode: '28215'
    };
  }

  static getLienholders(n: number = 1): Lienholder[] {
    const lienholders: Lienholder[] = [];
    for (let x = 0; x < n; x++) {
      lienholders.push(this.getLienholder(x + 1)); // + 1 -> position 1; + 2 -> position: 2
    }
    return lienholders;
  }

  static getLienholder(position: number = 1): Lienholder {
    return {
      lienHolderName: `BANK OF AMERICA HOME ${position}`,
      lienHolderNameCont: `John E. Doe ${position}`,
      lienHolderNumber: null,
      lienHolderOtherName: null,
      address: 'PO BOX 879787',
      streetName: 'Main St',
      city: 'PORTLAND',
      postalCode: '02112',
      state: 'OR',
      country: 'USA',
      payor: true,
      loanNumber: '98B',
      lienHolderRank: position.toString()
    };
  }

  static getCoverages(n: number = 1): Coverage[] {
    const coverages: Coverage[] = [];
    for (let x = 0; x < n; x++) {
      coverages.push(this.getCoverage());
    }
    return coverages;
  }

  static getCoverage(): Coverage {
    return {
      coverageCode: 'ABC',
      coverageType: 'COLL',
      coverageDescription: 'Test Coverage',
      coverageUpperLimit: '5000',
      coverageLowerLimit: '40000'
    };
  }

  static getBilling(): Billing {
    return {
      policyNumber: '55 - RBF - 341544',
      billingInfo: {
        balanceInfo: {
          minimumDueAmt: '1.24',
          totalDueAmt: '1'
        }
      },
      denialCd: '1'
    };
  }

  static createErrorDtos(n: number): ErrorDto[] {
    const errorDtos: ErrorDto[] = [];
    for (let x = 0; x < n; x++) {
      errorDtos.push(this.createErrorDto(x));
    }
    return errorDtos;
  }

  static createErrorDto(index: number = 0): ErrorDto {
    return {
      errorCd: `ERR12344[${index}]`,
      errorDesc: `There is problem with your request.[${index}]`,
      httpStatusCd: `404[${index}]`
    };
  }

  static getPolicySearchRequestView(): PolicySearchRequestView {
    return {
      policyType: 'HOME',
      lenderType: 'Mortgagee',
      lenderInstitution: 'Bofa',
      firstName: 'John Alexander',
      lastName: 'Doe',
      policyNumber: '55RBF344850',
      zipCode: '35006',
      lobCd: 'null',
      requestType: 'null',
    };
  }

  static getChangePegaResponseMock(hasError: boolean = false): ChangePegaResponse {
    return {
      messageCode: hasError ? '500' : '200',
      messageDesc: hasError ? 'PL_CH_API-ERROR-RES-001' : 'SUCCESS-OR-WHATEVER',
      errorDTO: hasError ? {
        errorCd: '500',
        errorDesc: 'There is an error.',
        httpStatusCd: '500'
      } : null
    };
  }

  static getChangeAmendResponseMock(hasError: boolean = false): ChangeAmendResponse {
    return {
      statusCode: hasError ? '500' : '200',
      messageCode: hasError ? 'PL_CH_API-ERROR-RES-001' : 'SUCCESS-OR-WHATEVER',
      messageDesc: hasError ? 'Test: there is an error.' : 'There is no error',
      quoteIdentifier: hasError ? 'Test: there is an error.' : 'SXITCN1234',
    };
  }

  static mapMortgagees(lienHolderList: Lienholder[]): Mortgagee[] {
    const mortgagees: Mortgagee[] = [];
    if (lienHolderList
      && lienHolderList.length !== 0) {
        lienHolderList.forEach(lienHolder => {
        const mortgagee = new Mortgagee();
        mortgagee.loanNumber = lienHolder.loanNumber;
        mortgagee.companyName = lienHolder.lienHolderName || lienHolder.lienHolderOtherName;
        mortgagee.address = new MortgageeAddress(
          lienHolder.streetName,
          lienHolder.address,
          lienHolder.city,
          lienHolder.state,
          lienHolder.postalCode,
          lienHolder.country
        );
        mortgagee.isEscrowed = lienHolder.payor;
        mortgagee.position = this.parseLienHolderRank(lienHolder.lienHolderRank);
        mortgagees.push(mortgagee);
      });
    }
    return mortgagees;
  }

  private static parseLienHolderRank(lienHolderRank: string): number | null {
    if (lienHolderRank && lienHolderRank.length > 0) {
      if (!isNaN(Number(lienHolderRank))) {
        return +lienHolderRank;
      }
    }
    return null;
  }
}

