import { Component, OnInit } from '@angular/core';
import { HomePolicyResponse } from '../../shared/api/home-policy-response.model';
import { Policy } from '../../shared/policy.constants';
import { ErrorMessage } from '../../errors/error/error-message.model';
import { PanelViewState } from '../../alerts/panel-view-state.model';
import { AlertsService } from '../../alerts/alerts.service';
import { CustomEventService } from '../../services/tealium/custom-event.service';
import { includes } from 'lodash';

/**
 * The {BillingDocRequestsComponent} represents the billing & document requests functionality.
 */
@Component({
  selector: 'app-billing-doc-requests',
  templateUrl: './billing-doc-requests.component.html',
  styleUrls: ['./billing-doc-requests.component.scss']
})
export class BillingDocRequestsComponent implements OnInit {

  /**
   * The value indicates whether the error message gets displayed
   */
  showFailureMessage = false;

  /**
   * The instance of the {ErrorMessage} represents error message information.
   */
  errorMessage: ErrorMessage | any;

  displayMessage: String | any;
  /**
   * The value indicated whether the paid in full receipt button is displayed
   */
  showPaidInFullReceiptButton = false;

  mortgagee : any;
  /**
   * The policy type.
   */
  readonly PolicyType = {
    HOME: 'HOME'
  };

  /**
   * The instance of the home {HomePolicyResponse}.
   */
  homePolicyResponse: HomePolicyResponse | any;

  /**
   * The value indicates whether premium should be hidden.
   */
  hidePremiums = false;

  /**
   * The collection of the denial codes.
   */
  denialCodeList: string[] = [
    '17', '3', '5A', '5B', '5E', '5B', '6', '7',
    '8', '18', '21', '26', '32', '28', '5C', '5D', '18'];
   
  
   
   /**
    * The value indicates whether the get declaration copy button is enabled.
    */
    isDecPageCopyButtonDisable = false;

  /**
   * The value indicates whether the get declaration copy button is clicked.
   */
  isDecFormVisible = false;

  /**
   * The value indicates whether the get paid receipt form is visible
   */
  isPaidReceiptFormVisible = false;

  /**
   * The value indicates whether the get paid receipt form is visible
   */
  isGetInvoiceFormVisible = false;

  /**
   * The value indicates whether the declaration page is visible.
   */
  hideDecPageAfterSubmission = false;

  /**
   * The total due amount
   */
  totalDueAmtNumber = 0;

  /**
   * The value indicates whether or not to show the failure message.
   */
  showFailureMessagePaidInFullReceipt = false;

  /**
   * The value indicates whether or not to show the failure message.
   */
  showFailureMessageGetInvoice = false;

  /**
   * The value indicates whether to hide the button after submission.
   */
  hidePaidInFullButtonAfterSubmission = false;

  /**
   * The value indicates whether to hide the button after submission.
   */
  hideGetInvoiceButtonAfterSubmission = false;

  /**
   * The value indicates whether to start a new form.
   */
  isSubmitAnotherButtonForReceiptClicked = false;

  /**
   * The value indicates whether to start a new form.
   */
  isSubmitAnotherButtonForInvoiceClicked = false;

  /**
    * The max length values for form fields.
    */
  readonly InputLength = {
    NAME_MAX_LENGTH: 40,
    EMAIL_MAX_LENGTH: 60,
    FAX_MAX_LENGTH: 10
  };

  /**
   * Messages sent to analytics
   */
  readonly TealiumMessages = {
    DENIAL_MESSAGE: 'Denial Message',
    VIEW: 'View',
    HOME_POLICY: 'Home Policy',
    BILLING_AND_DOCUMENT: 'Billing and Document Request'
  };

  /**
   * Initizes the new instance of the lender verification form component.
   * @param alertsService The instance of the {AlertsService}.
   * @param customEvent The instance of the {CustomEventService} represents the Tealium custom service.
   */
  constructor(
    private alertsService: AlertsService,
    private customEvent: CustomEventService) {
  }

  /**
   * Called during Angular Life Cylce, after view initializes.
   */
  ngOnInit(): void {
    const homePolicyResponse:any = JSON.parse(sessionStorage.getItem(Policy.SessionKeys.HOME_POLICY_RESPONSE) ?? '""');
    this.homePolicyResponse = homePolicyResponse as HomePolicyResponse;
    
  }


  /**
   * Handles has error event of the declaration request form component.
   * @param message The instance of the {ErrorMessage} represents error message information.
   */
  onBillingReceiptHasErrors(message: ErrorMessage): void {
    this.showFailureMessagePaidInFullReceipt = true;
    this.errorMessage = message;
  }

  /**
   * Handles has error event of the declaration request form component.
   * @param message The instance of the {ErrorMessage} represents error message information.
   */
  onGetInvoiceHasErrors(message: ErrorMessage): void {
    this.showFailureMessageGetInvoice = true;
    this.errorMessage = message;
  }

  /**
   * Handles has error event of the declaration request form component.
   * @param message The instance of the {ErrorMessage} represents error message information.
   */
  onHasErrors(message: ErrorMessage): void {
    this.showFailureMessage = true;
    this.errorMessage = message;
  }
  /***
   * check data value
   * @param type can be any
   */
  isNullOrUndefined(value:any){
    return value === null || value === undefined;
  }

  /**
   * Displays no billing information message.
   */
  displayNoBillingInfoMessage(): boolean {
    if (this.isNullOrUndefined(this.homePolicyResponse?.billing)
      || includes(this.denialCodeList, this.homePolicyResponse?.billing?.denialCd)) {
      this.hidePremiums = true;
      this.customEvent.denialMessageTracking(this.TealiumMessages.DENIAL_MESSAGE,
        this.TealiumMessages.VIEW, this.TealiumMessages.HOME_POLICY, this.TealiumMessages.BILLING_AND_DOCUMENT);
      return true;
    }
    else{
      return false;
    }
  }

  /**
   * Displays Message for Invalid regional policies or policies with old forms.
   */
  displayMessageforHidingDecPageButton(): boolean {
    this.isDecPageCopyButtonDisable =false;
    if (!this.isNullOrUndefined(this.homePolicyResponse?.isPolicyIssuedToday) && this.homePolicyResponse?.isPolicyIssuedToday) {
      this.isDecPageCopyButtonDisable = true;
       this.displayMessage="Sorry, we are unable to retrieve that policy at this time. Please try again tomorrow. If you need immediate assistance, please contact us at 1-800-423-0567.";
      return true;
    }
    else if ((!this.isNullOrUndefined(this.homePolicyResponse?.homeDecPageEligibility) && !this.homePolicyResponse?.homeDecPageEligibility)
      ||(!this.isNullOrUndefined(this.homePolicyResponse?.policyWithOldForms) && this.homePolicyResponse?.policyWithOldForms)) {
      this.isDecPageCopyButtonDisable = true;
       this.displayMessage="Sorry, we are unable to retrieve that policy on the portal. Please contact us at 1-800-423-0567 for assistance.";
      return true;
    }
    else{
      return false;
    }
  }


  /**
   * Displays billing amount message.
   */
  displayBillingAmountMessage(): boolean {
    if (!this.isNullOrUndefined(this.homePolicyResponse?.billing)) {
      if (!includes(this.denialCodeList, this.homePolicyResponse?.billing?.denialCd)
        || this.isNullOrUndefined(this.homePolicyResponse?.billing?.denialCd)) {
        this.hidePremiums = false;
        return true;
      }
      return false;
    }
    else{
      return false;
    }
  }

  /**
   * Displays paid in full receipt button if the billing amount message is displayed
   * and if the current balance is less than or equal to 0
   */
  displayPaidInFullReceiptButton(): boolean {
    if(this.homePolicyResponse){
      this.totalDueAmtNumber = +this.homePolicyResponse?.billing?.billingInfo?.balanceInfo.totalDueAmt;
    }
    if (!this.isNullOrUndefined(this.homePolicyResponse?.billing)) {
      if (this.displayBillingAmountMessage() &&
        this.totalDueAmtNumber <= 0) {
        this.showPaidInFullReceiptButton = true;
        return true;
      }
      return false;
    }
    else{
      return false;
    }
  }

  /**
   * Displays get invoice button if the billing amount message is displayed
   * and if the current balance is greater than 0
   */
  displayGetInvoiceButton(): boolean {
    if(this.homePolicyResponse){
    this.totalDueAmtNumber = +this.homePolicyResponse?.billing?.billingInfo.balanceInfo.totalDueAmt;
    }
    if (!this.isNullOrUndefined(this.homePolicyResponse?.billing)) {
      if (this.displayBillingAmountMessage() &&
        this.totalDueAmtNumber > 0) {
        this.showPaidInFullReceiptButton = true;
        return true;
      }
      return false;
    }
    else{
      return false;
    }
  }

  /**
   * Handles declaration form cancel event.
   * @param cancelled The boolean value indicates whether the form is cancelled.
   */
  onDecFormCancel(cancelled: boolean): void {
    this.isDecFormVisible = !cancelled;
  }

  /**
   * Handles declaration form cancel event.
   * @param cancelled The boolean value indicates whether the form is cancelled.
   */
  onPaidInFullReceiptFormCancel(cancelled: boolean): void {
    this.isPaidReceiptFormVisible = !cancelled;
  }

  /**
   * Handles declaration form cancel event.
   * @param cancelled The boolean value indicates whether the form is cancelled.
   */
  onGetInvoicetFormCancel(cancelled: boolean): void {
    this.isGetInvoiceFormVisible = !cancelled;
  }

  /**
   * Handles declaration form submit event.
   * @param submitted The boolean value indicates whether the form is submitted.
   */
  onDecFormSubmit(submitted: boolean): void {
    this.hideDecPageAfterSubmission = submitted;
  }

  /**
   * Handles declaration form submit event.
   * @param submitted The boolean value indicates whether the form is submitted.
   */
  onPaidInFullReceiptFormSubmit(submitted: boolean): void {
    this.showFailureMessagePaidInFullReceipt = false;
    this.hidePaidInFullButtonAfterSubmission = submitted;
    this.isPaidReceiptFormVisible = false;
  }

  /**
   * Handles declaration form submit event.
   * @param submitted The boolean value indicates whether the form is submitted.
   */
  onGetInvoiceFormSubmit(submitted: boolean): void {
    this.showFailureMessageGetInvoice = false;
    this.hideGetInvoiceButtonAfterSubmission = submitted;
    this.isGetInvoiceFormVisible = false;
  }

  /**
   * Returns true if button is clicked
   */
  onGetDeclarationsPageCopyClick(): void {
    this.isDecFormVisible = true;
  }

  /**
   * Returns true if button is clicked
   */
  onPaidInFullButtonClick(): void {
    this.isPaidReceiptFormVisible = true;
  }

  /**
   * Returns true if button is clicked
   */
  onGetInvoiceButtonClick(): void {
    this.isGetInvoiceFormVisible = true;
  }

  /**
   * Returns true if user wants to submit another form
   */
  onSubmitAnotherButtonForReceiptClick(): void {
    this.hidePaidInFullButtonAfterSubmission = false;
    this.isPaidReceiptFormVisible = true;
  }

  /**
   * Returns true if user wants to submit another form
   */
  onSubmitAnotherButtonForInvoiceClick(): void {
    this.hideGetInvoiceButtonAfterSubmission = false;
    this.isGetInvoiceFormVisible = true;
  }

  /**
   * Handles the view coverages click event.
   */
  onViewCoveragesButtonClick(): void {
    this.alertsService.emitPanelViewState(new PanelViewState(Policy.PanelNames.POLICY_DETAILS, true));
    window.scrollTo(0, 600);
  }
}
