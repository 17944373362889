import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { RegistrationService } from './registration.service';
import { Router } from '@angular/router';
import { RegistrationResponseModel } from './registration-response.model';
import { environment } from '../../environments/environment-wrapper';
import { CustomEventService } from '../services/tealium/custom-event.service';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { AuthenticateService } from '../services/auth/authenticate.service';
import { AuthenticationRequest } from '../services/auth/authenticate.model';
import { CookieService } from 'ngx-cookie-service';
import { DisassociationEmailService } from '../disassociation-verification-email/disassociation-verification-email.service';
import { DisassociationEmailExpiryService } from '../disassociation-email-expiry/disassociation-email-expiry.service';
import { Subscription } from 'rxjs';
import { DisassociationPostVerficationService } from '../disassociation-post-verification/disassociation-post-verification.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  registraionForm: FormGroup | any;
  invalidDomain: boolean | any;
  invalidEmail: boolean | any;
  isFormSubmitting = false;
  token: string | any;
  companyList: [any] | any;
  sCompany: string | any;
  emailId: string | any;
  confirmEmail: string | any;
  disableButton: boolean | any;
  responseBody: RegistrationResponseModel | any;
  env = environment;
  responseStatus: string | any;

  //JWTChange
  recaptcha: any;
  @ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent | any;
  recaptchaSiteKey: string = environment.INVISIBLE_RECAPTCHA_SITE_KEY;
  authenticationRequest = new AuthenticationRequest();
  recaptchaEnabled: boolean = true;
  flag: boolean = false;
  private Subscribe: Array<Subscription> = [];
  constructor(
    private authenticateService: AuthenticateService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private router: Router,
    private cookieService: CookieService,
    private disassociationEmailService: DisassociationEmailService,
    private disassociationEmailExpiryService: DisassociationEmailExpiryService,
    private disassociationPostVerficationService: DisassociationPostVerficationService,
    private customEvent: CustomEventService) {
    this.registraionForm = fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50),
      Validators.pattern(/^[^\s][A-Za-z@\-.`'\s]*[^\s]$/)]],
      lastName: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50), Validators.pattern(/^[^\s][A-Za-z@\-.`'\s]*[^\s]$/)]],
      company: ['', [Validators.required, Validators.minLength(2), Validators.pattern(/^[^\s][A-Za-z0-9,&._'():|\-\/\s]*[^\s]$/)]],
      emailId: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/)]],
      confirmEmail: ['', Validators.required]
    },
      {
        validator: this.matchEmail('emailId', 'confirmEmail'),
      });
    this.getRecaptchaState();
  }

  ngOnInit() {
    console.log("inside registration::ciamsession::" + this.cookieService.get('CIAMSession') + "::this.cookieService.get('token')::" + this.cookieService.get('token'));
    if (this.cookieService.get('token') !== '' && this.cookieService.get('CIAMSession') !== '') {
      this.validateToken();
    }
    else if (this.cookieService.get('CIAMSession') !== '') {
      this.redirectDisassociationEmail();
    }
    else {
      this.flag = true;
      this.getLenderCompanyList();
      this.emailId = this.registraionForm.get('emailId').value;
      this.confirmEmail = this.registraionForm.get('confirmEmail').value;
    }

  }

  ngOnDestroy(): void {
    this.Subscribe.forEach(sub => sub.unsubscribe());
  }

  selectedCompany(result: any): void {
    this.sCompany = result;
  }

  isFormFieldEntered(field: string): boolean {
    return ((this.registraionForm.get(field)?.touched &&
      this.registraionForm.get(field)?.hasError('required')) ||
      (this.registraionForm.get(field)?.untouched && this.isFormSubmitting)) ? true : false;
  }

  isFormFieldValid(field: string): boolean {
    return ((this.registraionForm.get(field)?.touched &&
      this.registraionForm.get(field)?.hasError('minlength')) ||
      (this.registraionForm.get(field)?.touched &&
        this.registraionForm.get(field)?.hasError('pattern')) ||
      (this.registraionForm.get(field)?.touched &&
        this.registraionForm.get(field)?.hasError('maxlength'))) ? true : false;
  }
  eMailMisMatch() {
    return this.registraionForm.get('confirmEmail')?.touched
      && this.registraionForm.get('confirmEmail')?.hasError('emailMismatch');
  }

  onFieldError(fieldName: string) {
    this.customEvent.regFieldErrorTracking(this.registraionForm, fieldName);
  }


  verifyEmailAndSave() {
    if (!this.registraionForm.valid) {
      this.customEvent.regPageErrorTracking(this.registraionForm);
      this.isFormSubmitting = true;
      return;
    }
    this.authenticationRequest = new AuthenticationRequest();
    this.authenticationRequest.firstName = this.registraionForm.value.firstName;
    this.authenticationRequest.lastName = this.registraionForm.value.lastName;
    this.authenticationRequest.company = this.registraionForm.value.company;
    this.authenticationRequest.emailId = this.registraionForm.value.emailId;
    //JWTChange
    //make recaptcha call
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('recaptchaToken');
    this.handleReset();
    this.handleReady();
    if (this.recaptchaEnabled) {
      sessionStorage.setItem('recaptchaToken', 'null');
    }
  }

  lenderRegistration() {
    this.disableButton = true;
    this.registraionForm.value['clientIpAddress'] = '';
    //  this.registrationService.setIpInRegistrationModel(this.registraionForm.value);
    this.Subscribe.push(this.registrationService.getRegister(this.registraionForm.value).subscribe(
      (responseModel: any) => {
        this.disableButton = false;
        this.responseBody = responseModel['body'];
        //console.log(this.responseBody);
        //console.log(this.responseBody['code']);
        if (this.responseBody['code'] === 'invalid_domain' || this.responseBody['code'] === 'email_domain_invalid') {
          this.invalidDomain = true;
        } else if (this.responseBody['code'] === 'email_account_invalid' || this.responseBody['code'] === 'email_domain_invalid') {
          this.invalidEmail = true;
        } else if (this.responseBody['code'] === 'registration_success') {
          window.scrollTo(0, 0);
          this.router.navigate(['sent-email']);
        }
      }
    ));
  }

  getLenderCompanyList() {
    this.Subscribe.push(this.registrationService.getCompanyList().subscribe(
      (response: any) => this.companyList = response['body']['companies']
    ));
    //console.log(this.companyList);
  }

  matchEmail(email: string, confirmEmail: string): any {
    return (formGroup: FormGroup | any) => {
      const emailControl = formGroup.controls[email];
      const confirmEmailControl = formGroup.controls[confirmEmail];

      if (!emailControl || !confirmEmailControl) {
        return null;
      }
      if (confirmEmailControl.errors && !confirmEmailControl.errors.emailMismatch) {
        return null;
      }

      if (emailControl.value !== confirmEmailControl.value) {
        return confirmEmailControl.setErrors({ emailMismatch: true });
      } else {
        confirmEmailControl.setErrors(null);
      }
    };
  }

  checkDomainError() {
    if (this.invalidDomain) {
      this.invalidDomain = false;
    }
    if (this.invalidEmail) {
      this.invalidEmail = false;
    }
  }

  handleReset(): void {
    if (this.recaptchaEnabled) {
      console.log('handleReset :: reCAPTCHA Reset!!!');
      this.cdr.detectChanges();
      this.captchaElem.reloadCaptcha();
    }
  }

  handleSuccess(captchaResponse: string): void {
    if (this.recaptchaEnabled) {
      console.log('handleSuccess :: ' + captchaResponse);
      this.cdr.detectChanges();
      const recaptchaToken = captchaResponse;
      sessionStorage.setItem('recaptchaToken', recaptchaToken);
      this.handleAuthCall(this.authenticationRequest);
    }
  }

  handleLoad(): void {
    if (this.recaptchaEnabled) {
      console.log('handleLoad :: reCAPTCHA loaded successfully!!!');
      this.cdr.detectChanges();
    }
  }

  handleReady(): void {
    if (this.recaptchaEnabled) {
      this.cdr.detectChanges();
      setTimeout(() => {
        this.captchaElem.execute();
        console.log('handleReady :: reCAPTCHA ready!!!');
      }, 2000);
    }
  }

  getRecaptchaState() {
    this.authenticateService.getRecaptchaState().subscribe((response: any) => {
      console.log('Before::setting recaptcha value:' + response['recaptchaEnabled']);
      if (response['recaptchaEnabled'] === 'false') {
        this.recaptchaEnabled = false;
        console.log('After::setting recaptcha value>' + this.recaptchaEnabled);
      }
    });
  }

  handleAuthCall(authenticationRequest: AuthenticationRequest) {
    this.authenticateService.authenticate(authenticationRequest)
      .subscribe(
        (data: any) => {
          console.log('data>>' + data.status);
          var a = data;
          if (a.status != null && a.status === 200 && a.data) {
            const authToken = a.data.access_token;
            if (authToken) {
              console.log('authToken present ');
              sessionStorage.setItem('authToken', authToken);
            }
            this.lenderRegistration();
          }
        },
        () => {
        }
      );
  }

  validateToken() {
    this.disassociationEmailExpiryService.checkTokenExpiry().subscribe((response: any) => {
      console.log(response);
      this.responseStatus = response['body']['status'];
      if (response['body']['status'] === 'false') {
        window.scrollTo(0, 0);
        this.router.navigate(['disassociation-email-expiry']);
      }
      else {
        this.redirectDisassociationEmail();
      }
    }
    );
  }


  redirectDisassociationEmail() {
    this.disassociationPostVerficationService.isValidCiamSession().subscribe(
      (response: any) => {
        if (response['body']['isDisassociatedUser'] === 'true') {
          this.disassociationEmailService.resendVerificationEmail().subscribe((response: any) => {
            console.log(response);
          }
          );
          window.scrollTo(0, 0);
          this.router.navigate(['disassociation-email']);
        }
        else{
          this.cookieService.delete('CIAMSession', '/', '.thehartford.com');
          this.flag = true;
        }
      });

  }

}

