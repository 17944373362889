import {NgModule, ModuleWithProviders} from '@angular/core';
import {TealiumUtagService} from './utag.service';
import {TealiumDataService} from './tealium-data.service';
import {EventListenerService} from './event-listener.service';
import {CustomEventService} from './custom-event.service';
import {LinkDirective} from './tealium-track.directive';

@NgModule({
  declarations: [LinkDirective],
  exports: [LinkDirective]
})
export class TealiumModule {
  //static forRoot(): ModuleWithProviders {
  static forRoot(): ModuleWithProviders<TealiumModule> {
    return {
      ngModule: TealiumModule,
      providers: [
        TealiumUtagService,
        TealiumDataService,
        EventListenerService,
        CustomEventService
      ]
    };
  }
}

