import { Component, OnDestroy } from '@angular/core';
import { HomePolicyResponse } from '../../shared/api/home-policy-response.model';
import { Mortgagee } from '../mortgagee.model';
import { MortgageeAddress } from '../mortgagee-address.model';
import { Policy } from '../../shared/policy.constants';
import { AlertsService } from '../../alerts/alerts.service';
import { Subscription } from 'rxjs';
import { ErrorMessage } from '../../errors/error/error-message.model';

@Component({
  selector: 'app-mortgagee-details',
  templateUrl: './mortgagee-details.component.html'
})
export class MortgageeDetailsComponent implements OnDestroy {
  /**
   * Default mortgagee.
   */
  defaultMortgagee: Mortgagee | undefined;

  /**
   * The no-mortgagee message.
   */
  noMorgageeMessage: string | undefined;

  /**
   * The available effective date
   */
  startDate: Date | undefined;

  /**
   * The available date plus 15 days
   */
  futureDate: Date | undefined;

  /**
   * The instance of the home {HomePolicyResponse}.
   */
  homePolicyResponse: HomePolicyResponse | any;

  /**
   * The list of mortgagees.
   */
  mortgagees: Mortgagee[] = [];

  /**
   * The mortgagee button states.
   */
  mortgageeButtonStates :any = [];

  /**
   * The success message if there is any.
   */
  successMessage: string | undefined;

  /**
   * The instance of the {Subscription} represents the reactive subscription.
   */
  subscription: Array<Subscription> = [];

  /**
   * The current response stauts.
   */
  responseStatus: string | undefined;

  /**
   * The instance of the {ErrorMessage} represents the error message.
   */
  errorMessage: ErrorMessage | undefined;

  /**
   * The mortagee messages to be displayed when there is no mortagees on the policy or
   * there is a mortgagee in the second position.
   */
  readonly MortgageeMessages = {
    NO_MORTGAGEES_ON_POLICY: 'There are no Mortgagees listed on this policy',
    NO_MORTGAGEE_ON_FIRST_POLICY: 'No first Mortgagee listed on this policy',
  };

  /**
   * Initializes the new instance of the {PolicyDetailsComponent}.
   * @param alertsService The instance of the {AlertsService} represents the shared service for the alerts.
   */
  constructor(private alertsService: AlertsService) {
    const HOME_POLICY_RESPONSE:any = JSON.parse(sessionStorage.getItem(Policy.SessionKeys.HOME_POLICY_RESPONSE) ?? '""');
    this.homePolicyResponse = HOME_POLICY_RESPONSE as HomePolicyResponse;
    this.mapMortgagees();
    this.generateMortgageeButtonStates();
    this.enableMortgageeButtons();
    this.setNoMortgageeMessage();
    this.subscribeToResponseStatus();
    this.subscribeToResponseError();
  }

  /**
   * Subscribes to HTTP response status.
   */
  private subscribeToResponseStatus(): void {
    this.subscription.push(this.alertsService.responseStatus.subscribe(status => {
      setTimeout(() => this.responseStatus = status, 0);
    }));
  }

  /**
   * Subscribes to the network/HTTP repsonse error.
   */
  private subscribeToResponseError(): void {
    this.subscription.push(this.alertsService.error.subscribe(error => {
      setTimeout(() => this.errorMessage = error, 0);
    }));
  }

  /**
   * Checks response status if it is a success one.
   * @returns The boolean value which indicates whether the status is a success.
   */
  hasResponseSuccessStatus(): boolean {
    return this.responseStatus === Policy.Action.SUCCESS;
  }

  /**
   * Displays Mortgagees.
   * @return Boolean value which indicates whether the mortgagees should be displayed
   */
  displayMortgagees(): boolean {
    return !this.hasResponseSuccessStatus() && !this.errorMessage;
  }

  /**
   * Checks whether it should display the empty mortagee with no-mortgagee message.
   * @returns The boolean value True if message will be displayed.
   */
  shouldDisplayEmptyMortgagee(): boolean {
    return !this.mortgagees || this.mortgagees.length === 0 ||
      (this.mortgagees.length === 1 && this.mortgagees[0].position === 2);
  }

  /**
   * Checks whether the component should display the no-mortgagee message.
   */
  private setNoMortgageeMessage(): void {
      if (!this.mortgagees || this.mortgagees.length === 0) {
        this.noMorgageeMessage = this.MortgageeMessages.NO_MORTGAGEES_ON_POLICY;
      } else if (this.mortgagees.length === 1 && this.mortgagees[0].position === 2) {
        this.noMorgageeMessage = this.MortgageeMessages.NO_MORTGAGEE_ON_FIRST_POLICY;
      }
  }

  /**
   * Maps lienholders into {Mortgagee} objects.
   */
  private mapMortgagees(): void {
    this.mortgagees = [];
    if (this.homePolicyResponse
      && this.homePolicyResponse.lienHolderList
      && this.homePolicyResponse.lienHolderList.length !== 0) {
      this.homePolicyResponse.lienHolderList.forEach((lienHolder:any) => {
        const mortgagee = new Mortgagee();
        mortgagee.loanNumber = lienHolder.loanNumber;
        mortgagee.companyName = lienHolder.lienHolderName || lienHolder.lienHolderOtherName;
        mortgagee.companyNameCont = lienHolder.lienHolderNameCont;
        mortgagee.address = new MortgageeAddress(
          lienHolder.streetName,
          lienHolder.address,
          lienHolder.city,
          lienHolder.state,
          lienHolder.postalCode,
          lienHolder.country
        );
        mortgagee.isEscrowed = lienHolder.payor;
        mortgagee.position = this.parseLienHolderRank(lienHolder.lienHolderRank);

        this.mortgagees.push(mortgagee);
      });
    }
  }

  /**
   * Parses lienholder rank from string to the numeric value.
   * @param lienHolderRank The mortgagee position which can be 1 or 2.
   */
  private parseLienHolderRank(lienHolderRank: string): number {
    if (lienHolderRank && lienHolderRank.length > 0) {
      if (!isNaN(Number(lienHolderRank))) {
        return +lienHolderRank;
      }
    }
    return 0;
  }

  /**
   * Calculates the correct index for a button state.
   * @param i The button state index number.
   * @returns The correct index number.
   */
  calculateButtonStateIndex(i: number) {
    return (this.mortgagees.length === 1 && this.mortgagees[0].position === 2) ?
      ++i : i;
  }

  /**
   * Checks the policy available effective date
   * @return Boolean value which indicate whether it has available  dates
   */
  checkPolicyDatesLocked(): boolean {
    this.startDate = new Date(this.homePolicyResponse?.availableEffectiveDate ?? '');
    this.futureDate = new Date();
    this.futureDate.setDate(this.futureDate.getDate() + 14);
    return this.startDate > this.futureDate;
  }

  /**
   * Generates the button states for the mortgagees.
   */
  generateMortgageeButtonStates(): void {
    this.mortgageeButtonStates = [];
    // Scenario 1: if there is no mortgagees.
    if (!this.mortgagees || this.mortgagees.length === 0) {
      this.mortgageeButtonStates.push({
        CAN_ADD: true,
        CAN_REPLACE: false,
        CAN_UPDATE: false,
        CAN_DELETE: false
      });
    }
    if (this.mortgagees.length === 1 && this.mortgagees[0].position === 2) {
      this.mortgageeButtonStates.push({
        position: 1,
        CAN_ADD: false,
        CAN_REPLACE: true,
        CAN_UPDATE: true,
        CAN_DELETE: true
      });
    }
    for (let k = 0; k < this.mortgagees.length; k++) {
      this.mortgageeButtonStates.push({
        position: this.mortgagees[k].position,
        CAN_ADD: false,
        CAN_REPLACE: false,
        CAN_UPDATE: false,
        CAN_DELETE: false
      });
    }
    if ((this.homePolicyResponse?.inFlight ?? false) || this.checkPolicyDatesLocked()) {
      this.mortgageeButtonStates.forEach((buttonState:any) => {
        buttonState.CAN_ADD = false;
        buttonState.CAN_REPLACE = false;
        buttonState.CAN_UPDATE = false;
        buttonState.CAN_DELETE = false;
      });
    }
  }

  /**
   * Resets all or a set of the button states for one or more mortgagees.
   * @param index The numeric ordering of the button states.
   * @param value The boolean value indicates whether button is disabled or enabled.
   */
  private resetMortgageeButtonStates(index: number = -1, value: boolean = true): void {
    if (!this.mortgageeButtonStates || this.mortgageeButtonStates.length === 0
      || !this.mortgagees || this.mortgagees.length === 0) {
      return;
    }
    if (index === -1) {
      this.mortgageeButtonStates.forEach((state:any) => {
        state.CAN_ADD = value;
        state.CAN_REPLACE = value;
        state.CAN_UPDATE = value;
        state.CAN_DELETE = value;
      });
      return;
    }
    if (index < this.mortgageeButtonStates.length) {
      this.mortgageeButtonStates[index].CAN_ADD = value;
      this.mortgageeButtonStates[index].CAN_REPLACE = value;
      this.mortgageeButtonStates[index].CAN_UPDATE = value;
      this.mortgageeButtonStates[index].CAN_DELETE = value;
    }
  }

  /**
   * Scenario 1: if there is no mortgagees then can add one.
   */
  enableMortgageeButtons(): void {
    if ((this.homePolicyResponse?.inFlight ?? false) || this.checkPolicyDatesLocked()) {
      return;
    }
    // Scenario 2(A): If there is 1 mortgagee in position 1 on the policy,
    // then can replace, add, update, and delete mortgagee(s).
    if (this.mortgagees.length === 1 && this.mortgagees[0].position === 1) {
      this.resetMortgageeButtonStates(0);
      return;
    }

    // Scenario 2(B): If there is 1 mortgagee in position 2 on the policy
    if (this.mortgagees.length === 1 && this.mortgagees[0].position === 2) {
      // For mortgagee 1 in position 2
      this.resetMortgageeButtonStates(0);
      this.mortgageeButtonStates[0].CAN_ADD = true;
      this.mortgageeButtonStates[0].CAN_REPLACE = false;
      this.mortgageeButtonStates[0].CAN_UPDATE = false;
      this.mortgageeButtonStates[0].CAN_DELETE = false;

      this.resetMortgageeButtonStates(1);
      this.mortgageeButtonStates[1].CAN_ADD = false;
      return;
    }

    // Scenario 3: there are 2 mortgagees on the policy, then neither can add.
    if (this.mortgagees.length === 2) {
      this.resetMortgageeButtonStates(0);
      this.mortgageeButtonStates[0].CAN_ADD = false;
      this.resetMortgageeButtonStates(1);
      this.mortgageeButtonStates[1].CAN_ADD = false;
    }
  }

  /**
   * Cleans up resources.
   */
  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
