import { Injectable } from '@angular/core';

@Injectable()
export class TealiumDataService {
  events:any = [];
  udo:any = {};

  constructor() { };

  setDataLayer(config:any, policyNumber:any, clientId:any) {
    const view_id = config.locationUrl;
    let userID: any;
    if ((<any>window).v) {
      userID = ((<any>window).v).ai_scscuserid;
    }
    let pageName = '';
    if (view_id) {
      pageName = view_id.split('#').pop().split('/')[1];
      if ( pageName === undefined) {
        pageName = 'verify';
      }
    }
    if ( pageName === undefined) {
      pageName = 'verify';
    }

    // will become new udo object
    this.udo = {
      // "da_track": "true",
      'page_category': 'pageview',
      'site_name': 'verification-portal',
      'page_name': pageName,
      'page_type': pageName,
      'policy_number': policyNumber,
      'clientId': clientId,
      'events': this.events
    };
    if (pageName === 'registration' || pageName === 'sent-email' || pageName === 'edit-account' || pageName === 'search-policy' || pageName === 'account-security') {
      this.addSubSiteName('verification-portal-shared');
    } else {
      this.addSubSiteName('verification-portal-nowco');
    }


    this.events = [];
    return this.udo;
  }

  addPageLevelEvent(event:any) {
    this.events.push(event);
  }
  addSubSiteName(sub_site:any) {
    this.udo['sub_site_name'] = sub_site;
  }

  addEmptySubSiteName() {
    if (this.udo.hasOwnProperty('sub_site_name')) {
      this.udo['sub_site_name'].remove;
    }
  }

  addImpressionEvent(event:any) {
    const elementvars = {
      'da_track': 'true'
    };
    const elementAttrs = JSON.parse(JSON.stringify(elementvars));
    setTimeout(() => {
      this._appTrack(event, null, elementAttrs);
    }, 2000);
  }

  addEvent(event:any) {
    try {
      this.udo['events'].push(event);
      const elementvars = {
        'da_track': JSON.parse(JSON.stringify(event)).da_track
      };
      const elementAttrs = JSON.parse(JSON.stringify(elementvars));
      this._appTrack(this.udo, null, elementAttrs);
    } catch (e:any) {
      //console.error('TEALIUM: Tealium addEvent error : ', e.message);
    }
    finally {
      // commented as getting failed in jenkins build
      //this.udo['events'].length = 0;
    }
  }

  _appTrack(data:any, element:any, elementAttrs:any) {
    try {
      // Handle automatic events without data-dl
      if (elementAttrs == null) {
        if (typeof (<any>window)._trackEvents === 'function') {
          (<any>window)._trackEvents(data, element);
        }
      } else { // Handle automatic events with data-dl
        if (typeof (<any>window)._trackAnalytics === 'function') {
          (<any>window)._trackAnalytics(data, element, elementAttrs);
        }
      }
    } catch (e:any) {
      //console.error('TEALIUM: Tealium error : ', e.message);
    }
  }
}
