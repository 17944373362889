import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment-wrapper';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LienholderRequest } from '../../shared/api/auto-policy/lienholder-request.model';
import { LienholderResponse } from '../../shared/api/auto-policy/lienholder-response.model';
import { AutoFinancialInstitutionResponse } from '../../shared/api/auto-policy/auto-financial-institution-response.model';
import { AutoPolicyDataFactory } from '../../shared/api/auto-policy/auto-policy-data.factory';

@Injectable({
  providedIn: 'root'
})
export class LienholderService {

  /**
   * The API endpoint that will be changed for each environmet.
   */
  apiBaseEndpoint = environment.PEGA_API_ENDPOINT;

  /**
   * The add lienholder resource relative URI.
   */
  addLienholderResource = environment.ECM_ADD_LIENHOLDER_SERVICE_URI;

  /**
   * The update lienholder resource relative URI.
   */
  updateLienholderResource = environment.ECM_UPDATE_LIENHOLDER_SERVICE_URI;

  /**
   * The delete lienholder resource relative URI.
   */
  deleteLienholderResource = environment.ECM_DELETE_LIENHOLDER_SERVICE_URI;

  /**
   * The financial institution and state resources.
   */
  financialInstitutionResource = environment.ECM_FINANCIAL_INSTITUTION_RESOURCE;

  /**
   * Initializes new instance of the {HttpClient}
   * @param http The instance of the {HttpClient
   */
  constructor(private http: HttpClient) {
  }

  /**
   * Adds the auto lienholder information via the restful service API.
   * @param request The instance of the {LienholderRequest}.
   * @returns The populated instance of the {LienholderResponse}.
   */
  addLienholder(request: LienholderRequest): Observable<LienholderResponse> {
    return this.http.post<LienholderResponse>(this.getApiEndpoint(this.addLienholderResource),
      request, { headers: this.getCommonHttpHeaders() })
      .pipe(catchError(this.handleError));
  }

  /**
   * Updates the auto lienholder information via the restful service API.
   * @param request The instance of the {LienholderRequest}.
   * @returns The populated instance of the {LienholderResponse}.
   */
  updateLienholder(request: LienholderRequest): Observable<LienholderResponse> {
    return this.http.post<LienholderResponse>(this.getApiEndpoint(this.updateLienholderResource),
      request, { headers: this.getCommonHttpHeaders() })
      .pipe(catchError(this.handleError));
  }

  /**
   * Deletes the auto lienholder information via the restful service API.
   * @param request The instance of the {LienholderRequest}.
   * @returns The populated instance of the {LienholderResponse}.
   */
  deleteLienholder(request: LienholderRequest): Observable<LienholderResponse> {
    return this.http.post<LienholderResponse>(this.getApiEndpoint(this.deleteLienholderResource),
      request, { headers: this.getCommonHttpHeaders() })
      .pipe(catchError(this.handleError));
  }

  /**
   * Gets financial institutions from by making a POST api call.
   * @returns The populated instance of the {AutoFinancialInstitutionResponse}.
   */
  getFinancialInstitutions(): Observable<AutoFinancialInstitutionResponse> {
    let emptyRequest = { };
    const body = JSON.stringify(emptyRequest);
    return this.http.post<AutoFinancialInstitutionResponse>(this.getApiEndpoint(this.financialInstitutionResource),
      body, { headers: this.getCommonHttpHeaders() })
      .pipe(catchError(this.handleError));
  }

  /**
   * Gets financial institutions from by making a POST api call.
   * @returns The populated instance of the {AutoFinancialInstitutionResponse}.
   */
  getFinancialInstitutionsMock(): Observable<AutoFinancialInstitutionResponse> {
    return of(AutoPolicyDataFactory.createFinancialInstitutionResponse());
  }

  /**
   * Gets common HTTP request headers properties.
   */
  private getCommonHttpHeaders(): HttpHeaders {
    return new HttpHeaders({})
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json')
      .append('Access-Control-Allow-Origin', '*');
  }

  /**
   * Gets the full API resource URI.
   * @param resource The resource relative path without leading slash.
   * @returns The full API resource URI.
   */
  private getApiEndpoint(resource: string) {
    return `${this.apiBaseEndpoint}${resource}`;
  }

  /**
   * Handles HTTP service call error.
   * @param error The instance of the {HttpErrorResponse}.
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      //console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
