export class RequestDeclaration {
  deliveryType: string | any;
  email: string | any;
  fax: string | any;
  firstName: string | any;
  lastName: string | any;
}

export class AutoDecRequest {
  policyHolderLastName: string | any;
  policyHolderFirstName: string | any;
  address1: string | any;
  address2: string | any;
  city: string | any;
  state: string | any;
  policyNumber: string | any;
  policyStartDate: string | any;
  policyExpiryDate: string | any;
  guid: string | any;
  zipcode: string | any;
  lob: string | any;
  propertyType: string | any;
  lenderFirstName: string | any;
  lenderLastName: string | any;
  marketSegmentDesc: string | any;
  ratingState: string | any;
  requestDeclaration: RequestDeclaration | any;
}
