import { Component, SecurityContext } from '@angular/core';
import { HomePolicyResponse } from '../../shared/api/home-policy-response.model';
import { AutoPolicyResponse } from '../../shared/api/auto-policy/auto-policy-response.model';
import { Policy } from '../../shared/policy.constants';
import { Router } from '@angular/router';
import { TealiumUtagService } from '../../services/tealium/utag.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss']
})
export class PolicyDetailsComponent {
  /**
   * The policy type.
   */
  PolicyType = {
    AUTO: 'AUTOP',
    HOME: 'HOME'
  };

  /**
   * The current policy type.
   */
  policyType: string | undefined; /* Get it from the query string */

  /**
   * The instance of the home {HomePolicyResponse} represents home policy response.
   */
  homePolicyResponse: HomePolicyResponse | undefined;

  /**
   * The instance of the {AutoPolicyResponse} represents the auto policy response.
   */
  autoPolicyResponse: AutoPolicyResponse | undefined;

  /**
   * Initializes the new instance of the {PolicyDetailsComponent}.
   * @param sessionStorage
   * @param router
   * @param tealium
   */
  constructor(private router: Router, private tealium: TealiumUtagService, private sanitizer: DomSanitizer) {
    this.fetchPolicyFromSession();
    if (this.autoPolicyResponse && this.autoPolicyResponse.policyNumber) {
      const safeAutoPolicyNumber = this.sanitizer.sanitize(SecurityContext.HTML, this.autoPolicyResponse.policyNumber) || undefined;
      this.tealium.setDetails(safeAutoPolicyNumber);
    } else if (this.homePolicyResponse && this.homePolicyResponse.policyNumber) {
      const safeHomePolicyNumber = this.sanitizer.sanitize(SecurityContext.HTML, this.homePolicyResponse.policyNumber) || undefined;
      const safeHomeClientId = this.sanitizer.sanitize(SecurityContext.HTML, this.homePolicyResponse.clientId) || undefined;
      this.tealium.setDetails(safeHomePolicyNumber, safeHomeClientId);
    }
    this.tealium.view();
  }

  /**
   * Fetches the policy information from user session and discovers the policy type (AUTO or HOME).
   */
  private fetchPolicyFromSession(): void {
    const homePolicyResponse: any = JSON.parse(sessionStorage.getItem(Policy.SessionKeys.HOME_POLICY_RESPONSE) ?? '""');
    this.homePolicyResponse = homePolicyResponse as HomePolicyResponse;
    if (this.homePolicyResponse) {
      this.policyType = this.homePolicyResponse.policyType;
      this.homePolicyResponse.policyNumber = this.sanitizer.sanitize(SecurityContext.HTML, this.homePolicyResponse.policyNumber) || '';
      this.homePolicyResponse.clientId = this.sanitizer.sanitize(SecurityContext.HTML, this.homePolicyResponse.clientId) || '';
      this.homePolicyResponse.policyPremium = this.sanitizer.sanitize(SecurityContext.HTML, this.homePolicyResponse.policyPremium) || '';
      if (homePolicyResponse.billing?.billingInfo?.balanceInfo?.totalDueAmt) {
        this.homePolicyResponse.billing.billingInfo.balanceInfo.totalDueAmt = this.sanitizer.sanitize(SecurityContext.HTML, this.homePolicyResponse.billing?.billingInfo?.balanceInfo?.totalDueAmt) || '';
      }
      //console.log(`Home policy response: ${JSON.stringify(this.homePolicyResponse)}`);
    }
    const autoPolicyResponse: any = JSON.parse(sessionStorage.getItem(Policy.SessionKeys.AUTO_POLICY_RESPONSE) ?? '""');
    this.autoPolicyResponse = autoPolicyResponse as AutoPolicyResponse;
    if (this.autoPolicyResponse) {
      this.policyType = this.autoPolicyResponse.policyType;
      this.autoPolicyResponse.policyNumber = this.sanitizer.sanitize(SecurityContext.HTML, this.autoPolicyResponse.policyNumber) || '';
      //console.log(`Auto policy response: ${JSON.stringify(this.autoPolicyResponse)}`);
    }
  }

  /**
   * Navigates to a given routable path.
   * @param path The destination path.
   */
  private navigateToPath(path: string) {
    this.router.navigate([path]);
  }

  /**
   * Handles another search request event.
   */
  onAnotherSearchClick(): void {
    //  sessionStorage.setItem(Policy.SessionKeys.RECAPTCHA_VALIDATED, true);
    this.navigateToPath('/search-policy');
    window.scrollTo(0, 0);
  }
}
