import { Injectable } from '@angular/core';
import { TealiumDataService } from './tealium-data.service';
import { FormGroup } from '@angular/forms';
import { ErrorDTO } from './tealium-error.model';
import { MortgageeRequest } from './tealium-mortgagee.model';

@Injectable()
export class CustomEventService {
    event_value: string | any;
    inflight_event: boolean;
    noAvailableDates: boolean;
    denialMessage: boolean;

    FORM_ID = {
        customerZipCode: 'customerZipCode',
        companyName: 'companyName'
    };

    LENDER_REGISTRATION = {
        firstName: 'firstName',
        lastName: 'lastName',
        companyName: 'companyName',
        emailId: 'emailId',
        confirmEmail: 'confirmEmail'

    }

    EDIT_ACCOUNT = {
        firstName: 'firstName',
        lastName: 'lastName',
        emailId: 'emailId'
    }

    POLICY_SEARCH = {
        policyNumber: "policyNumber",
        zipcode: 'zipcode'
    }

    PLEASE_SELECT_ERRORS:any = {
        companyName: 'Please enter valid company name.',
        company: 'Please enter a company name',
        emailId: 'Please enter a validEmail',
        firstName: 'Please enter a first name.',
        lastName: 'Please enter a last name.',
        email: 'Please enter your email',
        confirmEmail: 'Please enter confirm email',
        policyNumber: 'Please enter the policy number.',
        customerZipCode: '',
        userIdentity: 'Please select a user type.',
        policyType: 'Please select a policy type.',
        recaptchaStatus: 'Please verify you are not a robot.'
    };

    REGFORM_SELECT_ERRORS = {
        companyName: 'Please enter a company name',
        emailId: 'Please enter a validEmail',
        firstName: 'Please enter your first name.',
        lastName: 'Please enter your last name.',
        confirmEmail: 'Please enter confirm email'
    };

    EDITACCOUNT_SELECT_ERRORS = {
        emailId: 'Please enter validEmail',
        firstName: 'Please enter your first name.',
        lastName: 'Please enter your last name.'
    }

    POLICY_SEARCH_ERRORS = {
        policyNumber: 'Please enter a policy number',
        zipcode: 'Please enter a 5-digit ZIP to continue'

    }

    INVALID_PATTERN_ERROR:any = {
        companyName: 'Please enter valid company name.',
        company: 'Please enter a company name',
        emailId: 'Please enter an  email with your lender domain(example:name@lenderdomain.com).',
        firstName: 'Please enter valid first name.',
        lastName: 'Please enter valid last name.',
        email: 'Please enter an valid email',
        confirmEmail: 'Please enter confirm email',
        policyNumber: 'Please enter valid policy number.',
        zip: 'Please enter valid zipCode',
        zipcode:'',
        customerZipCode: 'Please enter a 5-digit ZIP to continue',
        recaptchaStatus: ''
    };

    constructor(private tealiumdatasvc: TealiumDataService) {
        this.inflight_event = false;
        this.noAvailableDates = false;
        this.denialMessage = false;
    }


    mortgageDetailTracking(event_name: string, event_type: MortgageeRequest[], event_id: string, event_value: string) {
        if (event_type && event_type.length >= 1) {
            event_type.forEach(m => {
                this.tealiumdatasvc.addEvent({
                    'event_name': event_name,
                    'event_parent': event_name,
                    'event_type': m.index + ' ' + m.actionRequested,
                    'event_id': event_id,
                    'event_value': event_value,
                    'da_track': 'true'
                });
            });
        }
    }

    policyDetailsTracking(event_name: string, event_type: string, event_id: string, event_value: string) {
        this.tealiumdatasvc.addEvent({
            'event_name': event_name,
            'event_parent': event_name,
            'event_type': event_type,
            'event_id': event_id,
            'event_value': event_value,
            'da_track': 'true'
        });
    }

    denialMessageTracking(event_name: string, event_type: string, event_id: string, event_value: string) {
        if (event_name === 'Denial Message' && !this.denialMessage) {
            this.denialMessage = true;
            this.tealiumdatasvc.addEvent({
                'event_name': event_name,
                'event_parent': event_name,
                'event_type': event_type,
                'event_id': event_id,
                'event_value': event_value,
                'da_track': 'true'
            });
        }
    }
   
    serviceDownError(errorMessage: string) {
    this.tealiumdatasvc.addEvent({
        'event_name': 'Page Error',
        'event_parent': ' Page Error',
        'event_type': 'Service Down',
        'event_id': 'Application_Error',
        'event_value': errorMessage,
        'da_track': 'true'
    });
}

    pageLevelError(errorDTO?: ErrorDTO | null, inFlight?: boolean, dateLock?: boolean) {
        if (errorDTO) {
            if (errorDTO.errorCd === 'CANCELLED_POLICY') {
                this.tealiumdatasvc.addEvent({
                    'event_name': 'Page Error',
                    'event_parent': ' Page Error',
                    'event_type': errorDTO.errorCd,
                    'event_id': errorDTO.httpStatusCd,
                    'event_value': 'This policy has been cancelled',
                    'da_track': 'true'

                });
            } else {
                this.tealiumdatasvc.addEvent({
                    'event_name': 'Page Error',
                    'event_parent': ' Page Error',
                    'event_type': 'System Error',
                    'event_id': 'System Error',
                    'event_value': errorDTO.errorDesc,
                    'da_track': 'true'

                });
            }
        } else if (inFlight && !this.inflight_event) {
            this.inflight_event = true;
            this.tealiumdatasvc.addEvent({
                'event_name': 'Page Error',
                'event_parent': ' Page Error',
                'event_type': 'IN_FLIGHT_ERROR',
                'event_id': 'In Flight Error',
                'event_value': 'No changes can be made at this time as there are pending transactions on this policy.',
                'da_track': 'true'

            });
        } else if (dateLock && !this.noAvailableDates) {
            this.noAvailableDates = true;
            this.tealiumdatasvc.addEvent({
                'event_name': 'Page Error',
                'event_parent': ' Page Error',
                'event_type': 'NO_AVAILABLE_DATES',
                'event_id': 'No Available Dates',
                'event_value': 'No changes can be made at this time as there are pending transactions on this policy.',
                'da_track': 'true'

            });
        }
    }


    regPageErrorTracking(form: FormGroup): void {
        Object.keys(form.controls).forEach(field => {
            //const control = form.get(field);
            const control:any = form.get(field);
            if (control.value === null || control.value === '') {
                Object.keys(this.REGFORM_SELECT_ERRORS).forEach(fieldErrorMessage => {
                    if (field === this.LENDER_REGISTRATION.firstName && fieldErrorMessage === this.LENDER_REGISTRATION.firstName) {
                        console.log(this.REGFORM_SELECT_ERRORS.firstName);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.firstName
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.LENDER_REGISTRATION.lastName && fieldErrorMessage === this.LENDER_REGISTRATION.lastName) {
                        console.log(this.REGFORM_SELECT_ERRORS.lastName);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.lastName
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.LENDER_REGISTRATION.companyName && fieldErrorMessage === this.LENDER_REGISTRATION.companyName) {
                        console.log(this.REGFORM_SELECT_ERRORS.companyName);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.companyName
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.LENDER_REGISTRATION.emailId && fieldErrorMessage === this.LENDER_REGISTRATION.emailId) {
                        console.log(this.REGFORM_SELECT_ERRORS.emailId);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.emailId
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.LENDER_REGISTRATION.confirmEmail && fieldErrorMessage === this.LENDER_REGISTRATION.confirmEmail) {
                        console.log(this.REGFORM_SELECT_ERRORS.confirmEmail);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.confirmEmail
                            }],
                            'da_track': 'true'
                        });
                    }
                })
            }
            else if (control.value !== null && control.value !== '' && control.invalid === true) {
                Object.keys(this.INVALID_PATTERN_ERROR).forEach(fieldErrorMessage => {
                    if (fieldErrorMessage === field) {
                        const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                        console.log(fieldMessageInvalid);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': fieldMessageInvalid
                            }],
                            'da_track': 'true'
                        });
                    }
                })
            }
        })


    }

    regFieldErrorTracking(form: FormGroup, fieldName:string): void {
        Object.keys(form.controls).forEach(field => {
            if (field === fieldName) {
            //const control = form.get(field);
            const control:any = form.get(field);
            if (control.value === null || control.value === '') {
                Object.keys(this.REGFORM_SELECT_ERRORS).forEach(fieldErrorMessage => {
                    if (field === this.LENDER_REGISTRATION.firstName && fieldErrorMessage === this.LENDER_REGISTRATION.firstName) {
                        console.log(this.REGFORM_SELECT_ERRORS.firstName);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.firstName
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.LENDER_REGISTRATION.lastName && fieldErrorMessage === this.LENDER_REGISTRATION.lastName) {
                        console.log(this.REGFORM_SELECT_ERRORS.lastName);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.lastName
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.LENDER_REGISTRATION.companyName && fieldErrorMessage === this.LENDER_REGISTRATION.companyName) {
                        console.log(this.REGFORM_SELECT_ERRORS.companyName);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.companyName
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.LENDER_REGISTRATION.emailId && fieldErrorMessage === this.LENDER_REGISTRATION.emailId) {
                        console.log(this.REGFORM_SELECT_ERRORS.emailId);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.emailId
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.LENDER_REGISTRATION.confirmEmail && fieldErrorMessage === this.LENDER_REGISTRATION.confirmEmail) {
                        console.log(this.REGFORM_SELECT_ERRORS.confirmEmail);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.REGFORM_SELECT_ERRORS.confirmEmail
                            }],
                            'da_track': 'true'
                        });
                    }
                })
            }
            else if (control.value !== null && control.value !== '' && control.invalid === true) {
                Object.keys(this.INVALID_PATTERN_ERROR).forEach(fieldErrorMessage => {
                    if (fieldErrorMessage === field) {
                        const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': fieldMessageInvalid
                            }],
                            'da_track': 'true'
                        });
                    }
                })
            }
        }
        })


    }
    
    policySearchPageErrorTracking(form: FormGroup): void {
        Object.keys(form.controls).forEach(field => {
            //const control = form.get(field);
            const control:any = form.get(field);
            if (control.value === null || control.value === '') {
                Object.keys(this.POLICY_SEARCH_ERRORS).forEach(fieldErrorMessage => {
                    if (field === this.POLICY_SEARCH.policyNumber && fieldErrorMessage === this.POLICY_SEARCH.policyNumber) {
                        console.log(this.POLICY_SEARCH_ERRORS.policyNumber);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.POLICY_SEARCH_ERRORS.policyNumber
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.POLICY_SEARCH.zipcode && fieldErrorMessage === this.POLICY_SEARCH.zipcode) {
                        console.log(this.POLICY_SEARCH_ERRORS.zipcode);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.POLICY_SEARCH_ERRORS.zipcode
                            }],
                            'da_track': 'true'
                        });
                    }
                })


            }
            else if (control.value !== null && control.value !== '' && control.invalid === true) {
                Object.keys(this.INVALID_PATTERN_ERROR).forEach(fieldErrorMessage => {
                    if (fieldErrorMessage === field) {
                        const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                        console.log(fieldMessageInvalid);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': fieldMessageInvalid
                            }],
                            'da_track': 'true'
                        });
                    }
                })
            }
        })


    }

    policySearchFieldErrorTracking(form: FormGroup, fieldName:string): void {
        Object.keys(form.controls).forEach(field => {
          if (field === fieldName) {
            const control = form.get(field);
            if (control?.value === null || control?.value === '') {
                Object.keys(this.POLICY_SEARCH_ERRORS).forEach(fieldErrorMessage => {
                    if (field === this.POLICY_SEARCH.policyNumber && fieldErrorMessage === this.POLICY_SEARCH.policyNumber) {
                        console.log(this.POLICY_SEARCH_ERRORS.policyNumber);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.POLICY_SEARCH_ERRORS.policyNumber
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.POLICY_SEARCH.zipcode && fieldErrorMessage === this.POLICY_SEARCH.zipcode) {
                        console.log(this.POLICY_SEARCH_ERRORS.zipcode);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.POLICY_SEARCH_ERRORS.zipcode
                            }],
                            'da_track': 'true'
                        });
                    }
                })


            }
            else if (control?.value !== null && control?.value !== '' && control?.invalid === true) {
                Object.keys(this.INVALID_PATTERN_ERROR).forEach(fieldErrorMessage => {
                    if (fieldErrorMessage === field) {
                        const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                        console.log(fieldMessageInvalid);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': fieldMessageInvalid
                            }],
                            'da_track': 'true'
                        });
                    }
                })
            }
        }
        })


    }


    editAccountErrorTracking(form: FormGroup): void {
        Object.keys(form.controls).forEach(field => {
            const control = form.get(field);
            if (control?.value === null || control?.value === '') {
                Object.keys(this.EDITACCOUNT_SELECT_ERRORS).forEach(fieldErrorMessage => {
                    if (field === this.EDIT_ACCOUNT.firstName && fieldErrorMessage === this.EDIT_ACCOUNT.firstName) {
                        console.log(this.EDITACCOUNT_SELECT_ERRORS.firstName);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.EDITACCOUNT_SELECT_ERRORS.firstName
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.EDIT_ACCOUNT.lastName && fieldErrorMessage === this.EDIT_ACCOUNT.lastName) {
                        console.log(this.EDITACCOUNT_SELECT_ERRORS.lastName);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.EDITACCOUNT_SELECT_ERRORS.lastName
                            }],
                            'da_track': 'true'
                        });
                    }
                    else if (field === this.EDIT_ACCOUNT.emailId && fieldErrorMessage === this.EDIT_ACCOUNT.emailId) {
                        console.log(this.EDITACCOUNT_SELECT_ERRORS.emailId);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.EDITACCOUNT_SELECT_ERRORS.emailId
                            }],
                            'da_track': 'true'
                        });
                    }
                })


            }
            else if (control?.value !== null && control?.value !== '' && control?.invalid === true) {
                Object.keys(this.INVALID_PATTERN_ERROR).forEach(fieldErrorMessage => {
                    if (fieldErrorMessage === field) {
                        const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                        console.log(fieldMessageInvalid);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': fieldMessageInvalid
                            }],
                            'da_track': 'true'
                        });
                    }
                })
            }
        })


    }

    editAccountFieldErrorTracking(form: FormGroup, fieldName: string): void {
        Object.keys(form.controls).forEach(field => {
            if (field === fieldName) {
                const control = form.get(field);
                if (control?.value === null || control?.value === '') {
                    Object.keys(this.INVALID_PATTERN_ERROR).forEach(fieldErrorMessage => {
                        if (field === this.EDIT_ACCOUNT.firstName && fieldErrorMessage === this.EDIT_ACCOUNT.firstName) {
                            const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                            console.log(fieldMessageInvalid);
                            this.tealiumdatasvc.addEvent({
                                'event_name': 'form field validation error ',
                                'event_parent': 'form field validation error',
                                'form_errors': [{
                                    'event_type': 'form_error_message',
                                    'event_id': field,
                                    'event_value': fieldMessageInvalid
                                }],
                                'da_track': 'true'
                            });
                        }
                        else if (field === this.EDIT_ACCOUNT.lastName && fieldErrorMessage === this.EDIT_ACCOUNT.lastName) {
                            const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                            console.log(fieldMessageInvalid);
                            this.tealiumdatasvc.addEvent({
                                'event_name': 'form field validation error ',
                                'event_parent': 'form field validation error',
                                'form_errors': [{
                                    'event_type': 'form_error_message',
                                    'event_id': field,
                                    'event_value': fieldMessageInvalid
                                }],
                                'da_track': 'true'
                            });
                        }
                        else if (field === this.EDIT_ACCOUNT.emailId && fieldErrorMessage === this.EDIT_ACCOUNT.emailId) {
                            const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                            console.log(fieldMessageInvalid);
                            this.tealiumdatasvc.addEvent({
                                'event_name': 'form field validation error ',
                                'event_parent': 'form field validation error',
                                'form_errors': [{
                                    'event_type': 'form_error_message',
                                    'event_id': field,
                                    'event_value': fieldMessageInvalid
                                }],
                                'da_track': 'true'
                            });
                        }
                    })


                }
                else if (control?.value !== null && control?.value !== '' && control?.invalid === true) {
                    Object.keys(this.INVALID_PATTERN_ERROR).forEach(fieldErrorMessage => {
                        if (fieldErrorMessage === field) {
                            const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                            console.log(fieldMessageInvalid);
                            this.tealiumdatasvc.addEvent({
                                'event_name': 'form field validation error ',
                                'event_parent': 'form field validation error',
                                'form_errors': [{
                                    'event_type': 'form_error_message',
                                    'event_id': field,
                                    'event_value': fieldMessageInvalid
                                }],
                                'da_track': 'true'
                            });
                        }
                    })

                }
            }
        })


    }


    customerErrorTracking(form: FormGroup): void {
        const userIdentity = form.get('userIdentity')?.value;
        Object.keys(form.controls).forEach(field => {
            const control = form.get(field);
            if (control?.value === null || control?.value === '') {
                Object.keys(this.PLEASE_SELECT_ERRORS).forEach(fieldErrorMessage => {
                    if (fieldErrorMessage === field && fieldErrorMessage !== this.FORM_ID.companyName
                        && fieldErrorMessage !== this.FORM_ID.customerZipCode) {
                        const fieldMessagePleaseSelect = this.PLEASE_SELECT_ERRORS[fieldErrorMessage];
                        console.log(fieldMessagePleaseSelect);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': fieldMessagePleaseSelect
                            }],
                            'da_track': 'true'
                        });
                        console.log("Inside Custom Event ");
                    } else if (field === this.FORM_ID.companyName && fieldErrorMessage === this.FORM_ID.companyName
                        && (userIdentity === 'Mortgagee' || userIdentity === 'Lienholder')) {
                        console.log(this.PLEASE_SELECT_ERRORS.companyName);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': this.PLEASE_SELECT_ERRORS.companyName
                            }],
                            'da_track': 'true'
                        });
                    } else if (field === this.FORM_ID.customerZipCode && fieldErrorMessage === this.FORM_ID.customerZipCode) {
                        if (userIdentity === 'Mortgagee' || userIdentity === 'Lienholder') {
                            this.event_value = 'Please enter a customer zip code';
                            console.log(this.event_value);
                            this.tealiumdatasvc.addEvent({
                                'event_name': 'form field validation error ',
                                'event_parent': 'form field validation error',
                                'form_errors': [{
                                    'event_type': 'form_error_message',
                                    'event_id': field,
                                    'event_value': this.event_value
                                }],
                                'da_track': 'true'
                            });
                        } else {
                            this.event_value = 'Please enter your zip code';
                            console.log(this.event_value);
                            this.tealiumdatasvc.addEvent({
                                'event_name': 'form field validation error ',
                                'event_parent': 'form field validation error',
                                'form_errors': [{
                                    'event_type': 'form_error_message',
                                    'event_id': field,
                                    'event_value': this.event_value
                                }],
                                'da_track': 'true'
                            });
                        }
                    }
                });
            } else if (control?.value !== null && control?.value !== '' && control?.invalid === true) {
                Object.keys(this.INVALID_PATTERN_ERROR).forEach(fieldErrorMessage => {
                    if (fieldErrorMessage === field && fieldErrorMessage !== this.FORM_ID.customerZipCode) {
                        const fieldMessageInvalid = this.INVALID_PATTERN_ERROR[fieldErrorMessage];
                        console.log(fieldMessageInvalid);
                        this.tealiumdatasvc.addEvent({
                            'event_name': 'form field validation error ',
                            'event_parent': 'form field validation error',
                            'form_errors': [{
                                'event_type': 'form_error_message',
                                'event_id': field,
                                'event_value': fieldMessageInvalid
                            }],
                            'da_track': 'true'
                        });
                    } else if (field === this.FORM_ID.customerZipCode && fieldErrorMessage === this.FORM_ID.customerZipCode) {
                        if (userIdentity === 'Mortgagee' || userIdentity === 'Lienholder') {
                            this.event_value = 'Please enter a valid customer zip code.';
                            console.log(this.event_value);
                            this.tealiumdatasvc.addEvent({
                                'event_name': 'form field validation error ',
                                'event_parent': 'form field validation error',
                                'form_errors': [{
                                    'event_type': 'form_error_message',
                                    'event_id': field,
                                    'event_value': this.event_value
                                }],
                                'da_track': 'true'
                            });
                        } else {
                            this.event_value = 'Please enter a valid zip code.';
                            console.log(this.event_value);
                            this.tealiumdatasvc.addEvent({
                                'event_name': 'form field validation error ',
                                'event_parent': 'form field validation error',
                                'form_errors': [{
                                    'event_type': 'form_error_message',
                                    'event_id': field,
                                    'event_value': this.event_value
                                }],
                                'da_track': 'true'
                            });

                        }
                    }
                });
            }
        });
    }
}