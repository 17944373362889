/**
 * The legacy restful service require the request object to be specifically in
 * this format. We are violating Typescript/JavaScript code formatting to make the
 * the API happy.
 */
export class AutoPolicyRequest {
   firstname: string | any;
   lastname: string | any;
   policytype: string | any;
   lenderinstution: string | any;
   zipcode: string | any;
   regionalOfficeNum: string | any;
   policySymbol: string | any;
   policyNumber: string | any;
   lenderType: string | any;
   lob: string | any;
   companyName: string | any;
   operation: string | any;
   companyAddress: string | any;
   state: string | any;
   companyZipCode: string | any;
   enableLVPChat: string | any;
}
