import { Component, OnInit } from '@angular/core';
import { DisassociationEmailService } from './disassociation-verification-email.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment-wrapper';
import { CookieService } from 'ngx-cookie-service';
import { DisassociationEmailExpiryService } from '../disassociation-email-expiry/disassociation-email-expiry.service';

@Component({
  selector: 'app-disassociation-verification-email',
  templateUrl: './disassociation-verification-email.component.html',
  styleUrls: ['./disassociation-verification-email.component.scss']
})
export class DisassociationEmailComponent implements OnInit {

  url: string | any ;
  env = environment;
  payload: any;
  responseStatus: string | any;

  constructor(
    private router: Router,
    private disassociationEmailService: DisassociationEmailService,
    private cookieService: CookieService,
    private disassociationEmailExpiryService: DisassociationEmailExpiryService,
  ) { }

  ngOnInit() {
    console.log("Inside ngonit disassociation-verification-email");
    if (this.cookieService.get('token') !== '') {
      this.disassociationEmailExpiryService.checkTokenExpiry().subscribe( (response:any) => {
        console.log(response);
        this.responseStatus = response['body']['status'];
        console.log("emailExpiry responseSatus:" + this.responseStatus);
        if (!!this.responseStatus && (this.responseStatus === 'false')) {
          console.log("before routing expiry");
          window.scrollTo(0, 0);
          this.router.navigate(['disassociation-email-expiry']);
          console.log("after routing expiry");

        }
      }
      );    
    }
  }

  resendDisassociationEmail() {
    console.log('Inside Resend Email');
    return this.disassociationEmailService.resendVerificationEmail().subscribe(
      (response:any) => {
        console.log(response);
      }
    );
  }

}
