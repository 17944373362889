import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { FieldErrorComponent } from './field-error/field-error.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule
  ],
  declarations: [
    ErrorComponent,
    FieldErrorComponent
  ],
  exports: [
    ErrorComponent,
    FieldErrorComponent
  ],
  providers: []
})
export class ErrorsModule { }
