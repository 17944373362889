import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment-wrapper';
import { HomeChangeAmendRequest } from '../../shared/api/home/home-change-amend-request.model';
import { ChangeAmendResponse, ChangePegaResponse } from '../../shared/api/home/change-amend-response.model';
import { HomePolicyDataFactory } from '../../shared/api/home/home-policy-data.factory';

@Injectable({
  providedIn: 'root',
})

export class MortgageeService {
  /**
   * The API endpoint that will be changed for each environmet.
   */
  apiBaseEndpoint = environment.API_ENDPOINT;

  /**
   * The home policy resouce URI.
   */
  mortgageeResource = environment.EXPRESS_API_AMEND;

  /**
   * The API endpoint that will be changed for each environmet for PEGA.
   */
  apiPegaEndpoint = environment.PEGA_API_ENDPOINT;

  /**
   * The home policy resouce URI for PEGA.
   */
  mortgageeResourcePEGA = '/lvpservice/amendLienholder';

  /**
   * Initializes new instance of the {HttpClient}
   * @param http The instance of the {HttpClient
   */
  constructor(private http: HttpClient) {}

  /**
   * Gets common HTTP request headers properties.
   */
  private getCommonHttpHeaders(): HttpHeaders {
    return new HttpHeaders({})
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json')
      .append('Access-Control-Allow-Origin', '*');
  }

  /**
   * Modifies the restful service API to ADD/UPDATE/REPLACE/DELETE mortgagee.
   * @param request The instance of the {HomeChangeAmendRequest}.
   * @returns The populated instance of the {ChangeAmendResponse}.
   */
  modifyChangeAmendMortgagee(request: HomeChangeAmendRequest): Observable<ChangeAmendResponse> {
    return this.http.post<ChangeAmendResponse>(this.getApiEndpoint(this.mortgageeResource),
      request, { headers: this.getCommonHttpHeaders() })
      .pipe(catchError(this.handleError));
  }

  /**
   * Modifies the Pega restful service API to ADD/UPDATE/REPLACE/DELETE mortgagee.
   * @param request The instance of the {HomeChangeAmendRequest}.
   * @param isPegaCall The boolean value indicates whether it is a pega call.
   * @returns The populated instance of the {ChangeAmendResponse}.
   */
  modifyChangePegaMortgagee(request: HomeChangeAmendRequest): Observable<ChangePegaResponse> {
    return this.http.post<ChangePegaResponse>(this.getPegaEndpoint(this.mortgageeResourcePEGA),
      request, { headers: this.getCommonHttpHeaders() })
      .pipe(catchError(this.handleError));
  }

 /**
   * Modifies the restful service API to ADD/UPDATE/REPLACE/DELETE mortgagee.
   * @param request The instance of the {HomeChangeAmendRequest}.
   * @returns The populated mocked instance of the {ChangeAmendResponse}.
   */
  modifyChangeAmendMortgageeMock(request: HomeChangeAmendRequest): Observable<ChangeAmendResponse> {
    return of(HomePolicyDataFactory.getChangeAmendResponseMock(true));
  }

   /**
   * Modifies the restful service API to ADD/UPDATE/REPLACE/DELETE mortgagee.
   * @param request The instance of the {HomeChangeAmendRequest}.
   * @returns The populated mocked instance of the {ChangeAmendResponse}.
   */
  modifyChangePegaMortgageeMock(request: HomeChangeAmendRequest): Observable<ChangePegaResponse> {
    return of(HomePolicyDataFactory.getChangePegaResponseMock(true));
  }

  /**
   * Gets the full API resource URI.
   * @param resource The resource relative path without leading slash.
   * @returns The full API resource URI.
   */
  private getApiEndpoint(resource: string) {
    return `${this.apiBaseEndpoint}${resource}`;
  }

  /**
   * Gets the full API resource URI for PEGA.
   * @param resource The resource relative path without leading slash.
   * @returns The full API resource URI.
   */
  private getPegaEndpoint(resource: string) {
    return `${this.apiPegaEndpoint}${resource}`;
  }

  /**
   * Handles HTTP service call error.
   * @param error The instance of the {HttpErrorResponse}.
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      //console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
