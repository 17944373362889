/**
 * The policy applicaiton constants.
 */
export const Policy = {
  /**
   * The user actions.
   */
  Action: {
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    REPLACE: 'REPLACE',
    CANCEL: 'CANCEL',
    SUCCESS: 'SUCCESS',
    DONE: 'DONE',
    CLOSE: 'CLOSE',
    ADD_SECOND: 'ADD_SECOND',
    DELETE_DONE: 'DELETE_DONE',
    UPDATE_DONE: 'UPDATE_DONE',
    CHANGE: 'CHANGE'
  },
  /**
   * The mortgagee positions.
   */
  MortgageePosition: {
    FIRST_MORTGAGEE: 1,
    SECOND_MORTGAGEE: 2
  },
  /**
   * The mortgagee headings.
   */
  MortgageeHeading: {
    FIRST_MORTGAGEE: 'First Mortgagee',
    SECOND_MORTGAGEE: 'Second Mortgagee'
  },
  /**
   * The session keys.
   */
  SessionKeys: {
    HOME_POLICY_RESPONSE: 'homePolicyResponse',
    AUTO_POLICY_RESPONSE: 'autoPolicyResponse',
    MORTGAGEE_NAME: 'mortgageeName',
    QUOTE_IDENTIFIER: 'quoteIndentifier',
    CHANGE_EFFECTIVE_DATE: 'changeEffectiveDate',
    RECAPTCHA_VALIDATED: 'recaptchValidated'
  },
  /**
   * The panel names.
   */
  PanelNames: {
    POLICY_DETAILS: 'Policy Coverages',
    DOCUMENT_REQUEST: 'Billing & Documents'
  },
  /**
   * The index positions.
   */
  IndexPositions: {
    POSITION_ONE: 1,
    POSITION_TWO: 2
  }
};

