import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment-wrapper';
import { HttpClient } from '@angular/common/http';
import { RegistrationRequestModel } from './registration-request.model';
import { RegistrationResponseModel } from './registration-response.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  registration: RegistrationRequestModel | any;
  registrationResource = environment.LENDER_REGISTRATION_API;
  apiBaseEndpoint = environment.API_ENDPOINT;
  companyListResourcePath = environment.COMPANYLIST_RESOURCE;
  public registrationInfo = new BehaviorSubject<any>(null);
  public registrationInfoStream = this.registrationInfo.asObservable();

  constructor(private http: HttpClient) {}

   setRegistrationDetail(registrationModel: RegistrationRequestModel) {
     this.registrationInfo.next(registrationModel);
   }

  getRegister(registrationModel: RegistrationRequestModel) {
    this.setRegistrationDetail(registrationModel);
      return this.http.post<RegistrationResponseModel>(this.getApiEndpoint(this.registrationResource),
        registrationModel);
  }
  private getApiEndpoint(resource: string) {
    return `${this.apiBaseEndpoint}${resource}`;
  }

  getCompanyList() {
    return this.http.get(this.getApiEndpoint(this.companyListResourcePath));
  }
}
