import { Component } from '@angular/core';
import { environment } from 'src/environments/environment-wrapper';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  contactUs = 'https://www.thehartford.com/contact-the-hartford';
  statementLink = 'https://www.thehartford.com/accessibility-statement';
  privacyPolicyLink = 'https://www.thehartford.com/online-privacy-policy';
  legalNoticeLink = 'https://www.thehartford.com/legal-notice';
  termsOfUseLink = 'https://www.thehartford.com/contact-the-hartford';
  securityStatement = 'https://www.thehartford.com/security-statement';
  copyrightText = '© 2022 The Hartford. All Rights Reserved.';
  caPrivacyChoices = environment.CA_PRIVACY_CHOICES;
}

