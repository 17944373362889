// import { NgModule } from '@angular/core';
// import { BsModalService } from 'ngx-bootstrap/modal';
// import {
//   ButtonsModule,
//   TooltipModule,
//   ModalModule,
//   BsDatepickerModule
// } from 'ngx-bootstrap';

import { NgModule } from '@angular/core';
import { BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  exports: [
    ButtonsModule,
    TooltipModule,
    ModalModule,
    BsDatepickerModule
  ],
  providers: [BsModalService]
})
export class NgxBootstrapModule {}
