<form appScrollToFirstInvalid [scrollToFirstInvalid]="mortgageeForm" *ngIf="showMortgageeForm()" class="form-padding" [formGroup]="mortgageeForm" appBlockCopyPasteCut >
  <div class="row">
    <div class="col-sm-12 form-title">
      <h3 class="col-sm-6">{{getFormAction()}}</h3>
    </div>
  </div>

  <div class="row" [ngClass]="displayFormFieldError('loanNumber')">
    <div class="form-group col-md-12">
      <div class="col-md-4">
      <label class="center-block control-label required">Loan Number</label>
      </div>
      <div class="col-md-4">
        <input id="loanNumber" class="form-control"
        formControlName="loanNumber"
        [maxlength]="InputLength.LOAN_NUMBER_MAX_LENGTH"
        placeholder="XXXXX-XXXXXX">
      </div>
      <div class="col-md-8 col-md-offset-4">
        <app-field-error [hasError]="isFormFieldValid('loanNumber')" errorMessage="Please enter the loan number.">
        </app-field-error>
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="displayFormFieldError('effectiveDate')" *ngIf="showEffectiveDate()">
    <div class="form-group col-md-12">
      <div class="col-md-4">
      <label for="effectiveDate" class="center-block control-label required">Effective Date</label>
      </div>
      <div class="input-group date date-time-picker col-md-4">
        <input
        id="effectiveDate"
        type="text"
        class="form-control"
        required value="" name="date" placeholder="MM/DD/YYYY"
        #dpYMD="bsDatepicker"
        bsDatepicker
        [bsValue]="currentDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
        formControlName="effectiveDate"
        placement="top"
        [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', showWeekNumbers: false, containerClass: 'theme-dark-blue'}">
      <span class="input-group-addon date-picker-addon" id="datetimepicker">
        <i class="icon icon-date-picker" aria-hidden="true" (click)="dpYMD.toggle()" [attr.aria-expanded]="dpYMD.isOpen"></i>
      </span>
      </div>
      <div class="col-md-8 col-md-offset-4">
        <app-field-error [hasError]="isFormFieldValid('effectiveDate')"
          errorMessage="Please enter the effective date.">
        </app-field-error>
        <app-field-error [hasError]="hasDateError('effectiveDate')"
          errorMessage="Please enter a valid date within the acceptable date range.">
        </app-field-error>
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="displayFormFieldError('mortgageeName')">
    <div class="form-group col-md-12">
      <div class="col-md-4">
      <label class="center-block control-label required">Mortgage Company Name</label>
      </div>
      <div class="col-md-4">
        <input id="mortgageeName" class="form-control"
        formControlName="mortgageeName"
        [maxlength]="InputLength.COMPANY_NAME_MAX_LENGTH">
      </div>
      <div class="col-md-8 col-md-offset-4">
        <app-field-error [hasError]="isFormFieldValid('mortgageeName')"
          errorMessage="Please enter the mortgage company name.">
        </app-field-error>
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="displayFormFieldError('mortgageeNameCont')">
    <div class="form-group col-md-12">
      <div class="col-md-4">
      <label class="center-block control-label required">Mortgage Company Name cont (ISAOA/ATIMA)</label>
      </div>
      <div class="col-md-4">
        <input id="mortgageeNameCont" class="form-control"
        formControlName="mortgageeNameCont"
        [maxlength]="InputLength.COMPANY_NAME_MAX_LENGTH">
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="displayFormFieldError('addressLineOne')">
    <div class="form-group col-md-12">
      <div class="col-md-4">
      <label class="center-block control-label required">Company Address</label>
      </div>
      <div class="col-md-4">
        <input id="addressLineOne" class="form-control"
          formControlName="addressLineOne"
          [maxlength]="InputLength.ADDRESS_MAX_LENGTH">
      </div>
      <div class="col-md-8 col-md-offset-4">
        <app-field-error [hasError]="isFormFieldValid('addressLineOne')"
          errorMessage="Please enter the address line 1."
          fieldName="addressLineOne">
        </app-field-error>
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="displayFormFieldError('city')">
    <div class="form-group col-md-12">
      <div class="col-md-4">
      <label class="center-block control-label required">Company City</label>
      </div>
      <div class="col-md-4">
        <input id="city" class="form-control" formControlName="city" [maxlength]="InputLength.CITY_MAX_LENGTH">
      </div>
      <div class="col-md-8 col-md-offset-4">
        <app-field-error [hasError]="isFormFieldValid('city')" errorMessage="Please enter the city." fieldName="city">
        </app-field-error>
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="displayFormFieldError('state')">
    <div class="form-group col-md-12">
      <div class="col-md-4">
      <label for="state" class="center-block select-label control-label required">Company State</label>
      </div>
      <div class="col-md-2 select-container">
      <select id="state" [value]="currentState" class="form-control" formControlName="state">
        <option *ngFor="let state of states" [value]="state">{{ state }}</option>
      </select>
      </div>
      <div class="col-md-8 col-md-offset-4">
        <app-field-error [hasError]="isFormFieldValid('state')" errorMessage="Please select a state." fieldName="state">
        </app-field-error>
      </div>
    </div>
  </div>


  <div class="row" [ngClass]="displayFormFieldError('postalCode')">
    <div class="form-group col-md-12">
      <div class="col-md-4">
        <label class="center-block control-label required">Company Zip Code</label>
      </div>
      <div class="col-md-2">
        <!-- mask="00000-0000" giving error as per pattern-->
        <input id="postalCode" class="form-control"
               formControlName="postalCode"
               [maxlength]="InputLength.ZIPCODE_MAX_LENGTH">
      </div>
      <div class="col-md-8 col-md-offset-4">
        <app-field-error [hasError]="isFormFieldValid('postalCode')" errorMessage="Please enter the zip code.">
        </app-field-error>
        <app-field-error [hasError]="hasInputPatternError('postalCode')" errorMessage="Please enter valid zip code." fieldName="postalCode">
        </app-field-error>
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="displayFormFieldError('isPayor')">
    <div class="form-group col-md-12">
      <div class="col-md-4">
      <label class="center-block control-label required">Is this mortgage escrowed?</label>
      </div>
        <div class="col-md-4">
          <label class="radio-inline" for="payorYes">
            <input type="radio" id="payorYes"
              [checked]="currentPayor === Payors.YES"
              [value]="Payors.YES" formControlName="isPayor">
            Yes</label>
          <label for="payorNo" class="radio-inline">
              <input type="radio" id="payorNo"
              [checked]="currentPayor === Payors.NO"
              [value]="Payors.NO" formControlName="isPayor">
            No</label>
      </div>
      <div class="col-md-8 col-md-offset-4">
        <app-field-error [hasError]="isFormFieldValid('isPayor')"
          errorMessage="Please specify whether this mortgage is escrowed."
          fieldName="postalCode">
        </app-field-error>
      </div>
    </div>
  </div>

  <div class="row form-btn-wrapper">
    <div class="col-md-6 col-sm-12 cancel-btn-wrapper">
      <button data-dl='{"da_track":"true","event":"mousedown","event_id":"Cancel"}' class="btn btn-link" (click)="onCancelClick()">Cancel</button>
    </div>
    <div class="submit-panel col-md-6 col-sm-12">
      <button class="btn btn-primary btn-lg btn-block btn-search"
              (click)="onSubmitClick()"
              data-dl='{"da_track":"true","event":"mousedown","event_id":"formButton"}'
              [disabled]="false">{{getFormButtonText()}}</button>
    </div>
  </div>
</form>

<app-custom-popup
  (modalAction)="onModalAction($event)"
  [isOpenModal]="isOpenModal"
  [action]="action"
  [previousAction]="previousAction"
  [mortgageeName]="mortgageeName"
  [numberOfMortgageeRequests]="mortgageeRequests.length"
  [currentIndex]="currentIndex"
  [numberOfExistingMortgagees]="getNumberOfExistingMortgagees()"
  [otherMortgageeName]="otherMortgageeName"
  [requestedAction]="requestedAction">
</app-custom-popup>
