<div class="alert-messages-optional">
  <div class="alert-messages" *ngIf="hasError()">
    <div class="message-danger">
      <h4 class="message-header">
        <i class="message-header-icon icon-attention"></i>
      </h4>
      <p>{{errorMessage.message}}</p>
    </div>
  </div>
  <div class="alert-messages" *ngIf="isInFlight">
    <div class="message-info">
      <h4 class="message-header">
        <i class="message-header-icon icon-info"></i>
        <span class="message-header-text">No Changes can be made at this time as there are pending transactions on this policy.</span>
        <span class="pull-right btn-close" (click)="onCloseClick()"><i class="icon-close"></i></span>
      </h4>
    </div>
  </div>
  <div class="alert-messages" *ngIf="showNoAvailableDateError()">
    <div class="message-info">
      <h4 class="message-header">
        <i class="message-header-icon icon-info"></i>
        <span class="message-header-text">No changes can be made at this time as there are pending transactions on this policy.</span>
        <span class="pull-right btn-close" (click)="onCloseClick()"><i class="icon-close"></i></span>
      </h4>
    </div>
  </div>
</div>
