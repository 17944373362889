<div class="col-sm-12 col-md-12 col-xs-12 send-declarations-copy">
  <div class="declarations-row">
    <div class="copy-form-group">
      <form appScrollToFirstInvalid [scrollToFirstInvalid]="decForm" class="input-forms" (ngSubmit)="onSendClick($event)" [formGroup]="decForm">
        <h4 class="radio-title-horizontal">Send Declaration Page Copy via</h4>
        <div class="col-sm-12 col-md-12 col-xs-12 radio-buttons">
          <label class="radio-inline">
            <input type="radio" [checked]="requestType === CopyRequestType.EMAIL" (click)="onRequestTypeClick(CopyRequestType.EMAIL)"
              name="email" value="email" data-error="Please check one option" required data-dl='{"event": "change","da_track":"true","event_name": "Declaration Page – Delivery Option","event_id":"email"}'>Email</label>
          <label class="radio-inline">
            <input type="radio" [checked]="requestType === CopyRequestType.FAX" (click)="onRequestTypeClick(CopyRequestType.FAX)" name="fax"
              value="fax" data-error="Please check one option" required data-dl='{"event": "change","da_track":"true","event_name": "Declaration Page – Delivery Option","event_id":"fax"}'>Fax</label>
          <label class="radio-inline">
            <input type="radio" [checked]="requestType === CopyRequestType.BOTH" (click)="onRequestTypeClick(CopyRequestType.BOTH)" name="both"
              value="both" data-error="Please check one option" required data-dl='{"event": "change","da_track":"true","event_name": "Declaration Page – Delivery Option","event_id":"both"}'>Both</label>
        </div>
        <div class="form-fields">
          <div *ngIf="showEmailForm()" class="row email-forms">
            <div class="form-group col-md-12 field-top-offset" [ngClass]="displayFormFieldError('fullName')">
              <label class="center-block control-label required">Your Name</label>
              <div>
                <input id="fullName" class="form-control" formControlName="fullName" [maxlength]="InputLength.FULL_NAME_MAX_LENGTH">
              </div>
              <app-field-error [hasError]="isFormFieldValid('fullName')" errorMessage="Please enter your name." fieldName="fullName">
              </app-field-error>
              <app-field-error [hasError]="hasInputPatternError('fullName')" errorMessage="Please enter valid name." fieldName="fullName">
              </app-field-error>
            </div>

            <div class="form-group col-md-12 field-top-offset" [ngClass]="displayFormFieldError('email')">
              <label class="center-block control-label required">Email Address</label>
              <div>
                <input id="email" class="form-control" formControlName="email">
              </div>
              <app-field-error [hasError]="isFormFieldValid('email')" errorMessage="Please enter a valid email address." fieldName="email">
              </app-field-error>
              <app-field-error [hasError]="hasInputPatternError('email')" errorMessage="Please enter a valid email address." fieldName="email">
              </app-field-error>
            </div>

            <div class="form-group col-md-12 field-top-offset" [ngClass]="displayFormFieldError('confirmEmail')">
              <label class="center-block control-label required">Confirm Email Address</label>
              <div>
                <input id="confirmEmail" class="form-control" formControlName="confirmEmail">
              </div>
              <app-field-error [hasError]="isFormFieldValid('confirmEmail')" errorMessage="Please enter a valid email address." fieldName="confirmEmail">
              </app-field-error>
              <app-field-error [hasError]="hasInputPatternError('confirmEmail')" errorMessage="Please enter a valid email address." fieldName="confirmEmail">
              </app-field-error>
              <app-field-error [hasError]="hasMismatch('email', 'confirmEmail')" errorMessage="The re-entered email address does not match." fieldName="confirmEmail">
              </app-field-error>
            </div>
          </div>

          <div *ngIf="showEmailForm() && showFaxForm()" class="row gap-email-fax"></div>

          <div *ngIf="showFaxForm()" class="row fax-forms">
            <div class="form-group col-md-12 field-top-offset" [ngClass]="displayFormFieldError('faxATTN')">
              <label class="center-block control-label required">Fax Attn:</label>
              <div>
                <input id="faxATTN" class="form-control" formControlName="faxATTN" [maxlength]="InputLength.FULL_NAME_MAX_LENGTH">
              </div>
              <app-field-error [hasError]="isFormFieldValid('faxATTN')" errorMessage="Please enter the recipient of the fax." fieldName="faxATTN">
              </app-field-error>
              <app-field-error [hasError]="hasInputPatternError('faxATTN')" errorMessage="Please enter the recipient of the fax." fieldName="faxATTN">
              </app-field-error>
            </div>
            <div class="form-group col-md-12 field-top-offset" [ngClass]="displayFormFieldError('fax')">
              <label class="center-block control-label required">Fax Number</label>
              <div>
                <input id="fax" class="form-control" formControlName="fax" [minlength]="InputLength.FAX_MIN_LENGTH" [maxlength]="InputLength.FAX_MAX_LENGTH">
              </div>
              <app-field-error [hasError]="isFormFieldValid('fax')" errorMessage="Please enter a valid fax number (XXXXXXXXXX)." fieldName="fax">
              </app-field-error>
              <app-field-error [hasError]="hasInputPatternError('fax')" errorMessage="Please enter a valid fax number (XXXXXXXXXX)." fieldName="fax">
              </app-field-error>
            </div>

            <div class="form-group col-md-12 field-top-offset" [ngClass]="displayFormFieldError('confirmFax')">
              <label class="center-block control-label required">Confirm Fax Number</label>
              <div>
                <input id="confirmFax" class="form-control" formControlName="confirmFax" [minlength]="InputLength.FAX_MIN_LENGTH" [maxlength]="InputLength.FAX_MAX_LENGTH"
                  data-dl='{"event": "change","event_id":"confirmFax","da_track":"true"}'>
              </div>
              <app-field-error [hasError]="isFormFieldValid('confirmFax')" errorMessage="Please enter a valid fax number (XXXXXXXXXX)."
                fieldName="confirmFax">
              </app-field-error>
              <app-field-error [hasError]="hasInputPatternError('confirmFax')" errorMessage="Please enter a valid fax number (XXXXXXXXXX)."
                fieldName="confirmFax">
              </app-field-error>
              <app-field-error [hasError]="hasMismatch('fax', 'confirmFax')" errorMessage="The re-entered fax number does not match." fieldName="confirmFax">
              </app-field-error>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12 col-xs-12 row cancel-submit-buttons">
          <div class="div-cancel-button">
            <button type="button" class="btn btn-link cancel-button" (click)="onCancelClick()" data-dl='{"da_track":"true","event":"mousedown","event_name": "Cancel","event_id":"Cancel"}'>Cancel</button>
          </div>
          <div class="div-send-declaration-copy-button">
            <span>
              <button type="submit" class="btn btn-primary btn-block send-declaration-copy-button" [disabled]="isSendingInProgress" data-dl='{"da_track":"true","event":"mousedown","event_id":"Send Declaration Page Copy"}'>Send Declaration Page Copy</button>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
