import { Component, OnDestroy, OnInit } from '@angular/core';
import { SearchPolicyService } from '../search-policy/search-policy.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditAccountService } from './edit-account.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment-wrapper';
import { CustomEventService } from '../services/tealium/custom-event.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit, OnDestroy {

  editAccount: FormGroup | any;
  userId: string | any;
  ciamUserName: string | any;
  firstName: string | any;
  lastName: string | any;
  emailId: string | any;
  isFormSubmitting = false;
  invalidEmail: boolean | any;
  invalidDomain: boolean | any;
  env = environment;
  private Subscribe: Array<Subscription> = [];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private cookieService: CookieService,
    private searchPolicyService: SearchPolicyService,
    private editAccountservice: EditAccountService, private customEvent: CustomEventService) {

  }
  ngOnInit() {
    this.editAccount = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50),
      Validators.pattern(/^[A-Za-z](?!.*[!,&<>_])(?!.*])/)]],
      lastName: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50), Validators.pattern(/^[A-Za-z](?!.*[!,&<>_])(?!.*])/)]],
      emailId: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/)]]
    });
    if (this.cookieService.get('CIAMSession') === '') {
      window.location.href = this.env.CIAM_LOGIN;
    }
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.Subscribe.forEach(sub => sub.unsubscribe());
  }

  isFormFieldEntered(field: string): boolean {
    if (this.editAccount?.get(field)) {
      return (this.editAccount.get(field)!.touched &&
        this.editAccount.get(field)!.hasError('required')) ||
        (this.editAccount.get(field)!.untouched && this.isFormSubmitting);
    }
    else
      return false
  }

  onFieldError(fieldName: string) {
    if (this.editAccount)
      this.customEvent.editAccountFieldErrorTracking(this.editAccount, fieldName);
  }

  isFormFieldValid(field: string): boolean {
    if (this.editAccount?.get(field)) {
      return (this.editAccount.get(field)!.touched &&
        this.editAccount.get(field)!.hasError('minlength')) ||
        (this.editAccount.get(field)!.touched &&
          this.editAccount.get(field)!.hasError('pattern')) ||
        (this.editAccount.get(field)!.touched &&
          this.editAccount.get(field)!.hasError('maxlength'));
    }
    else
      return false;

  }

  checkDomainError() {
    if (this.invalidDomain) {
      this.invalidDomain = false;
    }
    if (this.invalidEmail) {
      this.invalidEmail = false;
    }
  }

  private getUserInfo() {
    this.Subscribe.push(this.searchPolicyService.getLoggedInUser().subscribe(
      (response: any) => {
        this.firstName = response['body']['firstName'];
        this.lastName = response['body']['lastName'];
        this.userId = response['body']['ciamUserName'];
        this.emailId = response['body']['email'];
        this.editAccount.controls.firstName.setValue(response['body']['firstName']);
        this.editAccount.controls.lastName.setValue(response['body']['lastName']);
        this.editAccount.controls.emailId.setValue(response['body']['email']);
        sessionStorage.clear();
        sessionStorage.setItem('firstName', this.firstName);
        sessionStorage.setItem('lastName', this.lastName);
        sessionStorage.setItem('ciamUserName', this.ciamUserName);
        sessionStorage.setItem('email', this.emailId);
      }
    ));
  }

  saveEditAccount() {
    if (this.editAccount && !this.editAccount?.valid) {
      this.isFormSubmitting = true;
      this.customEvent.editAccountErrorTracking(this.editAccount);
      return;
    }
    const payload = {
      givenName: this.editAccount?.get('firstName')?.value,
      sn: this.editAccount?.get('lastName')?.value,
      mail: this.editAccount?.get('emailId')?.value
    };
    this.Subscribe.push(this.editAccountservice.saveEditAccount(payload).subscribe(
      (response: any) => {
        const responseBody = response['body'];
        if (responseBody['code'] === 'invalid_domain') {
          this.invalidDomain = true;
        } else if (responseBody['code'] === 'email_account_invalid' || responseBody['code'] === 'email_domain_invalid') {
          this.invalidEmail = true;
        } else if (response['body']['code'] === 'ciam_updation_success') {
          this.editAccountservice.set(true);
          this.router.navigate(['account-security']);
        }
      }
    ));
  }

}
