import { VehicleRequest } from './vehicle-request.model';
import { LienholderInfoRequest } from './lienholder-info-request.model';

export class LienholderRequest {
  policyHolderFirstName: string | any;
  policyHolderLastName: string | any;
  guid: string | any;
  policyNumber: string | any;
  policyStartDate: string | any;
  policyExpiryDate: string | any;
  lob: string | any;
  propertyType: string | any;
  zipcode: string | any;
  city: string | any;
  address1: string | any;
  address2: string | any;
  state: string | any;
  marketSegmentDesc: string | any;
  lenderFirstName: string | any;
  lenderLastName: string | any;
  ratingState: string | any;
  vehicle: VehicleRequest | any;
  lienholder: LienholderInfoRequest | any;
}
