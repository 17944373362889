<div id="bg">
  <div class="container-fluid" id="stag-background">
    <div class="container" id="card">
      <div class="card" *ngIf="flag">
        <div class="card-header">
          <img alt="The Hartford" class="img-responsive" src="https://ts0.hfdstatic.com/higux/dist/images/logo.svg">
          <div class="text-center">
            <h2> Create Lender Account </h2>
          </div>
          <br>
          <div>
            <span class="register">Registered?</span> &nbsp;
            <span>
              <a class="login-here" [href]="env.CIAM_LOGIN" data-dl= '{"event_parent": "Page Tag","event":"mousedown","event_type":"Link Click","event_id":"Log_in_here","event_value":"Log in here","da_track":"true"}'>Log in here</a>
            </span>
          </div>
        </div>
        <br>
        <div>
          <form [formGroup]="registraionForm" (ngSubmit)="verifyEmailAndSave()">
            <div class="form-group has-feedback">
              <label for="firstName" class="control-label" [ngClass]="{'label-error': isFormFieldValid('firstName')
         || isFormFieldEntered('firstName') }">First name</label>
              <input id="firstName"
                     [ngClass]="{'text-capitalize': true, 'border': (isFormSubmitting && isFormFieldEntered('firstName'))}"
                     type="text" class="form-control gbx-unmasked" name="firstName" formControlName="firstName" (blur) = "onFieldError('firstName')" data-dl ='{"event":"change","event_parent" : "Page Tag","event_type" : "Input Field Click – (text)" ,"event_id" : "firstName","event_value" :"firstName", "da_track" : "true"}'/>
              <div *ngIf="isFormFieldEntered('firstName')">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
          Please enter a first name.
        </span>
              </div>
              <div *ngIf="isFormFieldValid('firstName')">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
          Please enter valid first name
        </span>
              </div>
            </div>
            <div class="form-group has-feedback">
              <label for="lastName" class="control-label" [ngClass]="{'label-error': isFormFieldValid('lastName')
         || isFormFieldEntered('lastName') }"
              >Last name</label>
              <input id="lastName" type="text"
                     [ngClass]="{'text-capitalize': true, 'border': (isFormSubmitting && isFormFieldEntered('lastName'))}" class="form-control gbx-unmasked" name="lastName" formControlName="lastName" (blur) = "onFieldError('lastName')"  data-dl ='{"event":"change","event_parent" : "Page Tag","event_type" : "Input Field Click – (text)" ,"event_id" : "lastName","event_value" :"lastName", "da_track" : "true"}'/>
              <div *ngIf="isFormFieldEntered('lastName')">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
            Please enter a last name.
          </span>
              </div>
              <div *ngIf="isFormFieldValid('lastName')">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
          Please enter valid last name
        </span>
              </div>
            </div>
            <div class="form-group has-feedback">
              <label for="companyName" class="control-label" [ngClass]="{'label-error': isFormFieldValid('company')
         || isFormFieldEntered('company') }">Lending company</label>
         <!-- [(ngModel)]="sCompany"-->
              <input id="companyName" type="text" ngxTypeahead
                     [taList]="companyList"
                     [taItemTpl]="myList"
                     [ngClass]="{'text-uppercase': true, 'border': (isFormSubmitting && isFormFieldEntered('company'))}"
                     (taSelected)="selectedCompany($event)" [(ngModel)]="sCompany"
                     class="form-control gbx-unmasked" formControlName="company" (blur) = "onFieldError('company')" data-dl ='{"event":"change","event_parent" : "Page Tag","event_type" : "Input Field Click – (text)" ,"event_id" : "companyName","event_value" :"companyName", "da_track" : "true"}'/>
              <div *ngIf="isFormFieldEntered('company')">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
            Please enter a company name.
          </span>
              </div>
              <div *ngIf="isFormFieldValid('company')">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
          Please enter valid company name
        </span>
              </div>
            </div>
            <ng-template #myList let-item>
              <div class="item-name">
                {{item.result}}
              </div>
            </ng-template>
            <div class="form-group has-feedback">
              <label for="email" class="control-label" [class.label-error]="isFormFieldValid('emailId')
                    || isFormFieldEntered('emailId') || invalidDomain || invalidEmail">Email address</label>
                    <!--[(ngModel)]="emailId"  -->
              <input id="email" (keyup)="checkDomainError()"
                     [ngClass]="{'border': (isFormSubmitting && isFormFieldEntered('emailId')) || invalidDomain || invalidEmail}"
                     type="email" class="form-control gbx-unmasked" formControlName="emailId" (blur) = "onFieldError('emailId')"  data-dl ='{"event":"change","event_parent" : "Page Tag","event_type" : "Input Field Click – (text)" ,"event_id" : "emailId","event_value" :"emailId", "da_track" : "true"}'/>
              <div  *ngIf="isFormFieldEntered('emailId') || isFormFieldValid('emailId')">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
                  Please enter a valid email.
          </span>
              </div>
              <div  *ngIf="invalidDomain">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
                  Please enter an email with your lender domain (example: name@lenderdomain.com)
          </span>
              </div>
              <div  *ngIf="invalidEmail">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
            Sorry, that’s an invalid email address. Please enter another (example: name@lenderdomain.com)).
          </span>
              </div>
            </div>
            <div class="form-group has-feedback">
              <label for="confirmEmail" class="control-label" [class.label-error]="isFormFieldEntered('confirmEmail')
                  || isFormFieldValid('confirmEmail') || eMailMisMatch() || (emailId && !confirmEmail)">Confirm email address</label>
                  <!--[(ngModel)]="confirmEmail"-->
              <input id="confirmEmail"
                     [ngClass]="{'border': (emailId && !confirmEmail) || (isFormSubmitting && isFormFieldEntered('confirmEmail'))}"
                      type="email" class="form-control gbx-unmasked"
                     formControlName="confirmEmail"  (paste)="(false)" data-dl ='{"event":"change","event_parent" : "Page Tag","event_type" : "Input Field Click – (text)" ,"event_id" : "confirmEmail","event_value" :"confirmEmail", "da_track" : "true"}'/>
              <div  *ngIf="isFormFieldEntered('confirmEmail') || (emailId && !confirmEmail)">
                <i class="icon icon-attention-alt"></i>
                <span class="text-danger">
            Please enter confirm email.
          </span>
              </div>
              <span class="text-danger"
                    *ngIf="eMailMisMatch()">
                                  Sorry, those emails don’t match. Please re-enter!
          </span>
            </div>
            <div class="form-group">
              <button type="submit" [disabled]="disableButton" class="btn btn-primary btn-lg btn-block" data-dl ='{"event":"mousedown","event_parent" : "Page Tag","event_type" : "Button Click " ,"event_id" : "Next_Create_Profile","event_value" :"Next", "da_track" : "true"}'>
                <span>Next</span>
              </button>
            </div>
            <div class="form-group cancel">
              <a [href]="env.CIAM_LOGIN" data-dl= '{"event_parent": "Page Tag","event":"mousedown","event_type":"Link Click","event_id":"Cancel","event_value":"Cancel","da_track":"true"}'> Cancel</a>
            </div>

            <div id="invisible-recaptcha">
              <div *ngIf="recaptchaEnabled">
                <ngx-invisible-recaptcha #captchaElem
                   [siteKey]="recaptchaSiteKey"
                   (reset)="handleReset()"
                   (load)="handleLoad()"
                   (success)="handleSuccess($event)"
                   [ngModel]="recaptcha"
                   [ngModelOptions]="{ standalone: true }">
                </ngx-invisible-recaptcha>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
</div>
