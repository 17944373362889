import { Component } from '@angular/core';
import { SearchPolicyService } from 'src/app/search-policy/search-policy.service'; 

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  homeLink = 'https://www.thehartford.com/';
  headerLogoDesk = 'https://ts0.hfdstatic.com/higux/dist/images/AARPCompressed.svg';
  enable: boolean | undefined;
  constructor(private searchPolicyService: SearchPolicyService) { }

  ngOnInit() {
    this.searchPolicyService.fadeStream.subscribe(
      (flag) => {
        this.enable = flag; 
      }
    );
    this.searchPolicyService.setFade(false);
  }

}
