import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment-wrapper';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class DisassociationEmailService {

  apiBaseEndpoint = environment.API_ENDPOINT;
  resendEmailResource = environment.LENDER_DISASSOCIATION_MAIL_API;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient
  ) {}

  
  resendVerificationEmail() {   
    const endpoint=this.getApiEndpoint(this.resendEmailResource);  
    return this.http.get(endpoint);
  }

  private getApiEndpoint(resource: string) {
    return `${this.apiBaseEndpoint}${resource}`;
  }
}
