export class HomeAddressRequest {
  addressLineOne: string | any;
  city: string | any;
  state: string | any;
  postalCode: string | any;
  constructor(addressLineOne?: string,
              city?: string,
              state?: string,
              postalCode?: string) {
    this.addressLineOne = addressLineOne;
    this.city = city;
    this.state = state;
    this.postalCode = postalCode;
  }
}
