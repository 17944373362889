<!-- The header, content, and footer -->
<div *ngIf="checkLoggedInURL()">
  <app-header></app-header>
</div>
<router-outlet></router-outlet>
<div *ngIf="!checkLoggedInURL()">
  <app-registration-footer></app-registration-footer>
</div>
<div *ngIf="checkLoggedInURL()">
  <app-footer></app-footer>
</div>
