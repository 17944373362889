<div class="container-fluid">
  <div class="header">
    <div class="page-title-container">
      <a routerLink="/search-policy" id="back"> < Find A Policy </a>
      <div *ngIf="headerMsg" class="page-title-container__header">{{ headerMsg }}</div>
    </div>
    <hr class="hz-rule-solid hidden-xs">
  </div>
    <div *ngIf="showAlert" class="myicon">
      <i class="icon icon-bullet-check-mark"></i>
      <span>{{alertMessage}}</span>
    </div>
<div class="alert-body">
  <app-contact-information [contactInformation]="contactInformation" (whenClicked)="onChange($event)">
  </app-contact-information>
  <app-password-card (whenClicked)="onChange($event)"></app-password-card>
</div>
</div>
