import {AfterViewInit, Directive} from '@angular/core';
import { TealiumDataService } from './tealium-data.service';
import { ElementRef } from '@angular/core';
import {CustomEventService} from './custom-event.service';

@Directive({
    selector: '[data-dl]'
})
export class LinkDirective implements AfterViewInit {
    eventInfo: ElementRef;

    constructor(private tealiumdatasvc: TealiumDataService, el: ElementRef ) {
        this.eventInfo = el;
    }
    // Setting up event data based on types of events
    trackEvent(elementAttrs: any, taggedObj: any) {
        let eventID = '';
        let eventvalue = '';
        let eventparent = 'Page Tag';
        let eventname = '';
        try {
            const element = taggedObj;
            let elemType = taggedObj.localName;
            /* Used when custom directives are needed to grab data from data-dl in a non-typical way.
             If a specific source is needed I.E. event_parent needs to equal the id of the html object
             use the prefix src: in data-dl. In this case the data-dl would contain "event_parent":"src:id".
             Also need to specify "custom":"true" in the data-dl to use this logic, else regular logic used.
             */
            if (elementAttrs.custom) {
                const event_type = elementAttrs.event_type;
                const event_id = elementAttrs.event_id;
                const event_parent = elementAttrs.event_parent;
                const event_value = elementAttrs.event_value;
                if (event_type) {
                    if (event_type.includes('src:')) {
                        elemType = element.getAttribute(event_type.replace('src:', '').value);
                    } else {
                        elemType = event_type;
                    }
                }
                if (event_id) {
                    if (event_id.includes('src:')) {
                        eventID = element.getAttribute(event_id.replace('src:', ''));
                    } else {
                        eventID = event_id;
                    }
                }
                if (event_value) {
                    if (event_value.includes('src:')) {
                        eventvalue = element.getAttribute(event_value.replace('src:', ''));
                    } else {
                        eventvalue = event_value;
                    }
                }
                if (event_parent) {
                    if (event_parent.includes('src:')) {
                        eventparent = element.getAttribute(event_parent.replace('src:', ''));
                    } else {
                        eventparent = event_parent;
                    }
                }
            } else {
                if (elemType === 'input') {
                    elemType = 'Input Field Click - (' + element.type + ')';
                    eventID = element.id;
                    eventvalue = element.value;
                }
                if (elemType === 'a') {
                    elemType = 'Link Click';
                    eventID = element.innerText;
                    eventvalue = element.href;
                }
                if (elemType === 'button') {
                    elemType = 'Button Click';
                    eventID = element.id;
                    eventvalue = element.innerText;
                }
                if (elemType === 'select') {
                    elemType = 'Select Click - (' + element.type + ')';
                    eventID = element.id;
                    if (element.type === 'select-multiple') {
                        for (let i = 0; i < element.options.length; i++) {
                            if (element.options[i].selected) {
                                eventvalue += element.options[i].innerText + '|';
                            }
                        }
                    } else {
                        eventvalue = (element.options[element.selectedIndex]).innerText;
                    }
                }
                /* even in typical scenarios that utilize the directive you can specify any of these 4 variables
                 directly. This only works for hard-coded variables and not sources from the html element.
                 */
                if (elementAttrs.event_id && elementAttrs.event_id !== '') {
                    if (eventID === 'companyName') {
                        eventID = 'company_name';
                        elementAttrs.event_id = eventID;
                    } else if (eventID === 'policyNumber') {
                        eventID = 'policy_number';
                        elementAttrs.event_id = eventID;
                    } else {
                        eventID = elementAttrs.event_id;
                    }
                }
                if (elementAttrs.event_value && elementAttrs.event_value !== '') {
                    eventvalue = elementAttrs.event_value;
                }
                if (elementAttrs.event_type && elementAttrs.event_type !== '') {
                    elemType = elementAttrs.event_type;
                }
                if (elementAttrs.event_parent && elementAttrs.event_parent !== '') {
                    eventparent = elementAttrs.event_parent;
                }
                if (elementAttrs.event_parent && elementAttrs.event_name !== '') {
                    eventname = elementAttrs.event_name;
                }
                if (elementAttrs.node_name) {
                    elemType = 'Link Click';
                    eventID = '';
                    eventvalue = '';
                    if (this.eventInfo && this.eventInfo.nativeElement && this.eventInfo.nativeElement.parentElement
                        && this.eventInfo.nativeElement.parentElement.previousElementSibling.innerText) {
                        eventID = this.eventInfo.nativeElement.parentElement.previousElementSibling.innerText;
                        eventvalue = this.eventInfo.nativeElement.innerText;
                        switch (eventvalue) {
                            case 'Show More':
                                eventvalue = 'Show Less';
                                break;
                            case 'Show Less':
                                eventvalue = 'Show More';
                                break;
                        }
                    }
                }
                if (elementAttrs.event_id === 'formButton') {
                    eventID = element.innerHTML;
                }
            }
            const data = {
                event_name: eventname,
                event_parent: eventparent,
                event_type: elemType,
                event_id: eventID,
                event_value: eventvalue
            };

            this.tealiumdatasvc._appTrack(data, element, elementAttrs);
        } catch (e:any) {
            //console.error('Tealium error : ', e.message);
        }

    }
    ngAfterViewInit() {
        // Capturing data-dl  event value rc-imageselect-payload
        let dl_eventValue = JSON.parse(this.eventInfo.nativeElement.attributes['data-dl'].nodeValue).event;
        // adding event listener to handle automatic events with data-dl
        this.eventInfo.nativeElement.addEventListener(dl_eventValue, this.onEventDetect.bind(this));

        
        // let controlType = JSON.parse(this.eventInfo.nativeElement.attributes['data-dl'].nodeValue).event_type;
        // if(controlType === "Input Field Click – (text)"){
        //     this.eventInfo.nativeElement.addEventListener("blur", this.onBlurDetect.bind(this));
        // }
    }

    // onBlurDetect(event) {
    //     let taggedObj = event.target;
       
    // }
    onEventDetect(event:any) {
        let taggedObj = event.target;
        while (taggedObj.attributes['data-dl'] === undefined) {
            taggedObj = taggedObj.parentNode;
            if (taggedObj === undefined) {
                return;
            }
        }
        const dataAttr = JSON.parse(taggedObj.attributes['data-dl'].nodeValue);
        if (dataAttr.event) {
            this.trackEvent(dataAttr, taggedObj);
        }
    }
}

