import { Component, OnInit } from '@angular/core';
import { CustomEventService } from 'src/app/services/tealium/custom-event.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  constructor(private customEvent: CustomEventService){
    
  }

  ngOnInit(){

  }
}
