<div *ngIf="policy" class="policy-info-card col-md-12">
  <h3>
    <i class="icon" [ngClass]="displayIcon()"></i>
    <span>
      <strong>{{ getPolicyInfoTitle() }}</strong>
    </span>
  </h3>
  <div class="col-md-4 col-sm-5 policy-address" *ngIf="policy.dwellingAddress || policy.addressDTO">
    <span id="dwelling-address-wrapper" *ngIf="policy.dwellingAddress">
      <span *ngIf="policy.policyHolderName">{{policy.policyHolderName}}
        <br>
      </span>
      <span id="dwelling-address1" *ngIf="policy.dwellingAddress.address || policy.dwellingAddress.streetName">
        {{policy.dwellingAddress.address}} {{policy.dwellingAddress.streetName}}
        <br>
      </span>
      <span id="dwelling-address2" *ngIf="policy.dwellingAddress.city || policy.dwellingAddress.state || policy.dwellingAddress.postalCode">
        {{policy.dwellingAddress.city}}, {{policy.dwellingAddress.state}} {{policy.dwellingAddress.postalCode}}
      </span>
    </span>
    <span id="addressDTO-wrapper" *ngIf="policy.addressDTO">
      <span *ngIf="policy.policyHolderFirstName && policy.policyHolderSurName">{{getPolicyHolderFullName()}}
        <br>
      </span>
      <span *ngIf="policy.policyHolderName">{{policy.policyHolderName}}
        <br>
      </span>
      <span *ngIf="policy.addressDTO.address || policy.addressDTO.streetName">
        {{policy.addressDTO.address}} {{policy.addressDTO.streetName}}
        <br>
      </span>
      <span *ngIf="policy.addressDTO.city || policy.addressDTO.state || policy.addressDTO.postalCode">
        {{policy.addressDTO.city}}, {{policy.addressDTO.state}} {{policy.addressDTO.postalCode}}
      </span>
    </span>
  </div>
  <div class="col-md-8 col-sm-7 policy-info">
    <div class="row" *ngIf="policy.policyNumber">
      <div class="col-md-4 col-sm-5 col-xs-12 policy-label">
        <strong *ngIf="policy.policyNumber">Policy #:</strong>
      </div>
      <div class="col-md-8 col-sm-7 col-xs-12 policy-value">
        <span *ngIf="policy.policyNumber">{{policy.policyNumber}}</span>
      </div>
    </div>
    <div class="row" *ngIf="policy.policyStartDate">
      <div class="col-md-4 col-sm-5 col-xs-12 policy-label">
        <strong *ngIf="policy.policyStartDate">Policy Term:</strong>
      </div>
      <div class="col-md-8 col-sm-7 col-xs-12 policy-value">
        <span *ngIf="policy.policyStartDate">{{policy.policyStartDate}} - {{policy.policyExpiryDate}}</span>
      </div>
    </div>
    <div class="row" *ngIf="policy.policyStatusCd">
      <div class="col-md-4 col-sm-5 col-xs-12 policy-label">
        <strong *ngIf="policy.policyStatusCd">Policy Status:</strong>
      </div>
      <div class="col-md-8 col-sm-7 col-xs-12 policy-value">
        <p *ngIf="policy.policyStatusCd">{{policy.policyStatusCd}}</p>
      </div>
    </div>
    <div class="row" *ngIf="policy.policyPremium">
      <div class="col-md-4 col-sm-5 col-xs-12 policy-label"><strong *ngIf="policy.policyPremium">Total Policy Premium:</strong></div>
      <div class="col-md-8 col-sm-7 col-xs-12 policy-value"><span *ngIf="policy.policyPremium">{{policy.policyPremium | currency}}</span></div>
  </div>
  </div>
</div>
