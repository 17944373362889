<div *ngIf="isOpenModal" class="modal fade in" id="modalWindow" role="dialog" style="display: block;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content card offset">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="onCloseClick()">
          <i class="icon icon-close" data-dl='{"da_track":"true","event":"click","event_type":"Button Click","event_value":"Close Button","event_id":"Close Button"}'></i></button>
        <div class="modal-title">{{ getModalTitle() }}</div>
      </div>
      <div class="modal-body modal-width">

        <p *ngIf="displayAddMessage()"><b>{{ mortgageeName }}</b> has been added.
          Click <b>Add Another Mortgagee</b> to add another mortgagee, otherwise click <b>Done</b>.
        </p>

        <p *ngIf="displayAddMessage2()">
          <b>{{ mortgageeName }}</b> has been added. To make changes to <b>{{ otherMortgageeName }}</b>, click
          the <b>Update Other Mortgagee</b> or <b>Delete Other Mortgagee</b> button below. Otherwise click <b>Done</b>.
        </p>

        <p *ngIf="displayDeleteMessage()">
          <b>{{ mortgageeName }}</b> has been deleted. If you need to add a new mortgagee, click the
          <b>Add Another Mortgagee</b> button below. Otherwise, click <b>Done</b>.
        </p>

        <p *ngIf="displayDeleteMessage2()">
          <b>{{ mortgageeName }}</b> has been deleted. Do you need to do anything else?
        </p>

        <p *ngIf="displayUpdateMessage()">
          <b>{{ mortgageeName }}</b> has been updated. If you need to add another Mortgagee, click
          the <b>Add Another Mortgagee</b> button below. Otherwise click <b>Done</b>.
        </p>

        <p *ngIf="displayUpdateMessage2()">
          <b>{{ mortgageeName }}</b> has been updated.
          To delete <b>{{ otherMortgageeName }}</b>
          click Delete Second Mortgagee, otherwise click Done.
        </p>

        <p *ngIf="displayReplaceMessage()">
          <b>{{ mortgageeName }}</b> has been replaced.
          Click <b>Add Another Mortgagee</b> to add another mortgagee, otherwise click <b>Done</b>.
        </p>

        <p *ngIf="displayReplaceMessage2()">
            <b>{{ mortgageeName }}</b> has been replaced.
            To delete <b>{{ otherMortgageeName }}</b>
            click <b>{{ getTheOtherMortgageeText() }}</b>, otherwise click <b>Done</b>.
        </p>
      </div>
      <div class="modal-footer">
        <button *ngIf="displayAddAnotherMortgageeButton()" class="btn btn-secondary-paired"
          (click)="onAddAnohterClick()" data-dl='{"event_id":"Add Another Mortgagee","da_track":"true","event":"mousedown"}'>Add Another Mortgagee</button>

        <button *ngIf="displayEditOtherMortgageeButton()" class="btn btn-secondary-paired"
          (click)="onEditOtherMortgagee()" data-dl='{"event_id":"Update Other Mortgagee","da_track":"true","event":"mousedown"}'>Update Other Mortgagee</button>

        <button *ngIf="displayDeleteOtherMortgageeButton()" class="btn btn-secondary-paired"
          (click)="onDeleteOtherMortgagee()" data-dl='{"event_id":"Delete Other Mortgagee","da_track":"true","event":"mousedown"}'>Delete Other Mortgagee</button>

        <button (click)="onDeleteBothMortgagees()"
          *ngIf="displayDeleteMessage2()"
          class="btn btn-secondary-paired" data-dl='{"event_id":"Delete Both Mortgagees","da_track":"true","event":"mousedown"}'>Delete Both Mortgagees</button>

        <button *ngIf="displayDeleteTheOtherMortgageeButton()"
          (click)="deleteTheOtherMortgagee()"
          class="btn btn-secondary-paired" data-dl='{"da_track":"true","event":"mousedown","event_id":"formButton"}'>{{ getTheOtherMortgageeText() }}</button>

        <button class="btn btn-block btn-primary"
          (click)="onDoneClick()" data-dl='{"event_id":"Done","da_track":"true","event":"mousedown"}'>Done</button>
      </div>
    </div>
  </div>
</div>

