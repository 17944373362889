import { Component, EventEmitter, Input, OnChanges, Output, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { MortgageeService } from './mortgagee.service';
import { States } from './state-static';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ValidationPatterns } from '../../shared/form/validation-patterns';
import { HomePolicyResponse } from '../../shared/api/home-policy-response.model';
import { LvpFormValidationService } from '../../shared/form/lvp-form-validation.service';
import { LvpFormService } from '../../shared/form/lvp-form.service';
import { HomeChangeAmendRequest } from '../../shared/api/home/home-change-amend-request.model';
import { TransactionInfoRequest } from '../../shared/api/home/transaction-info-request.model';
import { HomeAddressRequest } from '../../shared/api/home/home-address-request.model';
import { MortgageeRequest } from '../../shared/api/home/mortgagee-request.model';
import { ChangeAmendResponse, ChangePegaResponse } from '../../shared/api/home/change-amend-response.model';
import { ErrorMessage } from '../../errors/error/error-message.model';
import { ErrorMessages } from '../../shared/validation/error-messages';
import { Policy } from '../../shared/policy.constants';
import { FormField } from '../../shared/form/form-field.model';
import { Mortgagee } from '../../policy/mortgagee.model';
import { ModalData } from '../../custom-popup/modal-data.model';
import { AlertsService } from '../../alerts/alerts.service';
import { PayorValue } from './payor-value.model';

import { CustomEventService } from '../../services/tealium/custom-event.service';
import {DatePipe} from '@angular/common';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mortgagee-form',
  templateUrl: './mortgagee-form.component.html',
  styleUrls: ['./mortgagee-form.component.scss']
})

export class MortgageeFormComponent implements OnChanges, OnInit, OnDestroy {

  /**
   * Messages sent to analytics
   */
  readonly TealiumMessages = {
    MORTGAGEE_DETAILS_TRACKING: 'Mortgagee Details Tracking',
    HOME_POLICY: 'Home Policy',
    HOME_FAILED_MESSAGE: 'Change amend request failed',
    HOME_SUCCESS_MESSAGE: 'Change amend request successfully processed'
  };

  /**
   * Response code values
   */
  readonly responseCodes = {
    ERR_SYS_DOWN: 'ERR_SYS_DOWN'
  };

  /**
   * The input field lengths.
   */
  readonly InputLength = {
    LOAN_NUMBER_MIN_LENGTH: 10,
    LOAN_NUMBER_MAX_LENGTH: 25,
    COMPANY_NAME_MAX_LENGTH: 64,
    ADDRESS_MAX_LENGTH: 100,
    CITY_MAX_LENGTH: 20,
    ZIPCODE_MIN_LENGTH: 5,
    ZIPCODE_MAX_LENGTH: 10
  };

  /**
   * The payor values.
   */
  readonly Payor = {
    IN: 'IN',
    MORTG1: 'MORTG',
    MORTG2: 'MORTG2'
  };

  /**
   * The instance of the {FormGroup} represents the mortgagee form.
   */
  mortgageeForm: FormGroup | any;

  /**
   * The states.
   */
  readonly states = States.values;

  /**
   * The current state.
   */
  currentState: string | any;

  /**
   * The instance of the {HomePolicyResponse} represents the home policy response.
   */
  policy: HomePolicyResponse | any;

  /**
   * The current effective date.
   */
  currentDate: Date = new Date();

  /**
   * The Backdate effective.
   */
  minDate: Date | any;

  /**
   * The future effective date.
   */
  maxDate: Date | any;

  /**
   * The value which indicates whether the form is being submitted.
   */
  isFormSubmitting: boolean | any;

  /**
   * The value which indicates whether the action process in progress.
   */
  isActionInProgress: boolean | any;

  /**
   * The payor values.
   */
  readonly Payors = {
    YES: 'YES',
    NO: 'NO'
  };

  /**
   * The current payor value.
   */
  currentPayor: string | any;

  /**
   * The service API compatible payor value that will be sent to the server side.
   */
  payorValue: PayorValue | any;

  /**
   * The modified mortgagee requests.
   */
  mortgageeRequests: MortgageeRequest[] = [];

  /**
   * The boolean value which indicates whether the modal should be opened.
   */
  isOpenModal: boolean | any;

  /**
   * The current mortgagee name for displaying in the popup.
   */
  mortgageeName: string | any;

  /**
   * The existing mortgagee name. It is not the one that is being modified.
   */
  otherMortgageeName: string | any;

  /**
   * The current mortgagee index/position.
   */
  currentIndex: number | any;

  /**
   * The target index number.
   */
  targetIndex: number | any;

  /**
   * The previous action.
   */
  previousAction: string | any;

  /**
   * The requested action.
   */
  requestedAction: string | any;

  /**
   * The change effective date.
   */
  changeEffectiveDate: string | any;

  /**
   * The user action.
   */
  @Input() action: string | any;

  /**
   * The instance of the {Mortgagee} represents the current home lienholder.
   */
  @Input() mortgagee: Mortgagee | any;

  /**
   * The collection of the {Mortgagee} represents all mortgagee of this policy.
   */
  @Input() mortgagees: Mortgagee[] | any;

  /**
   * The user action event emitter.
   */
  @Output() userAction: EventEmitter<string> = new EventEmitter<string>();
  private Subscribe:Array<Subscription> = [];

  /**
   * Initializes a new instance of the {MortgageeFormComponent}.
   * @param formBuilder The instance of the {FormBuilder} represents the form builder factory.
   * @param mortgageeService The instance of the {MortgageeService} represents the API call service.
   * @param formService The instnace of the {LvpFormService} represents the form service.
   * @param customEvent The instance of the {CustomEventService} represents the custom event service.
   * @param formValidationService The instance of the {LvpFormValidationService} represents the validation service.
   * @param alertsService The instance of the {AlertsService} represents the alerts service.
   */
  constructor(private formBuilder: FormBuilder,
    private mortgageeService: MortgageeService,
    private formService: LvpFormService,
    private customEvent: CustomEventService,
    private formValidationService: LvpFormValidationService,
    private alertsService: AlertsService) {
    this.createForm();
  }

  /**
   * Handles component initialization event.
   */
  ngOnInit(): void {
    const policy:any = JSON.parse(sessionStorage.getItem(Policy.SessionKeys.HOME_POLICY_RESPONSE) ?? '""');
    this.policy =  policy as HomePolicyResponse;
    if (!this.policy) {
        throw new Error('Missing home policy response.');
    }
    this.setEffectiveDateRange();
    this.initializePayorValue();
  }

  ngOnDestroy(): void {
    this.Subscribe.forEach(sub => sub.unsubscribe());
  }

  /**
   * Handles the component changing event.
   */
  ngOnChanges(): void {
    this.resetForm();
    this.handleCancelAction();
    this.handleUpdateAction();
    this.handleDeleteAction();
    this.handleCloseAction();
    this.handleDoneAction();
    this.showEffectiveDate();
    this.resetEffectiveDateValidators();
  }

  /**
   * Creates the add morgagee form.
   */
  private createForm(): void {
    const formFields: FormField[] = [
      new FormField(null, 'loanNumber', [
        Validators.maxLength(this.InputLength.LOAN_NUMBER_MAX_LENGTH)]),

      new FormField(null, 'effectiveDate', [Validators.required]),

      new FormField(null, 'mortgageeName', [Validators.required,
      Validators.maxLength(this.InputLength.COMPANY_NAME_MAX_LENGTH)]),

      new FormField(null, 'mortgageeNameCont', [
        Validators.maxLength(this.InputLength.COMPANY_NAME_MAX_LENGTH)]),

      new FormField(null, 'addressLineOne', [Validators.required,
      Validators.maxLength(this.InputLength.ADDRESS_MAX_LENGTH)]),

      new FormField(null, 'city', [Validators.required,
      Validators.maxLength(this.InputLength.CITY_MAX_LENGTH)]),

      new FormField(null, 'state', [Validators.required]),

      new FormField(null, 'postalCode', [Validators.required,
      Validators.pattern(ValidationPatterns.US_ZIPCODE),
      Validators.maxLength(this.InputLength.ZIPCODE_MAX_LENGTH)]),

      new FormField(null, 'isPayor', [Validators.required])
    ];
    this.mortgageeForm = this.formService.createForm(this.mortgageeForm, this.formBuilder, formFields);
  }

  /**
   * Sets the range for the effective date.
   */
  setEffectiveDateRange() {
    // Backdate of effective date
    if (this.policy && this.policy.availableEffectiveDate) {
      this.minDate = new Date(this.policy.availableEffectiveDate ?? '');
      this.minDate.setDate(this.minDate.getDate());
    } else {
      this.minDate = new Date();
      this.minDate.setDate(this.minDate.getDate() - 30);
    }
    // Future effective date
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 14);
  }

  /**
   * Checks whether a given form field is valid.
   * @param field The form field name.
   * @returns The boolean value indicates whether the validation is valid.
   */
  isFormFieldValid(field: string): boolean {
    return !this.mortgageeForm.get(field)?.valid
      && this.mortgageeForm.get(field)?.touched
      && !this.hasInputPatternError(field)
      && this.isFormSubmitting
      && !this.hasDateError(field)? true : false;
  }

  /**
   * Displays form field error if validation fails.
   * @param field The field control name.
   */
  displayFormFieldError(field: string): any {
    const hasError = this.isFormFieldValid(field) || this.hasInputPatternError(field) || this.hasDateError(field);
    return {
      'has-error': hasError,
      'has-feedback': hasError
    };
  }

  /**
   * Checks whether the input pattern is valid.
   * @param field The form field/control name.
   * @returns False if invalid; otherwise, true.
   */
  hasInputPatternError(field: string): boolean {
    return this.formValidationService.hasPatternError(this.mortgageeForm, field)
      && this.formService.getControlValue(this.mortgageeForm, field)
      && this.isFormSubmitting;
  }

  /**
   * Checks whether the date field is valid.
   * @param field The form field name.
   * @returns The boolean value indicates whether a form date field has an invalid date.
   */
  hasDateError(field: string): boolean {
    return this.formValidationService.hasDateError(this.mortgageeForm, field);
  }

  /**
   * Handles the response error.
   * @param error The HTTP response error.
   */
  private handleResponseError(error: any): void {
   // console.error(`HTTP response error: ${JSON.stringify(error)}`);
    this.alertsService.emitError(new ErrorMessage(ErrorMessages.SYSTEM_DOWNTIME));
    this.customEvent.mortgageDetailTracking(this.TealiumMessages.MORTGAGEE_DETAILS_TRACKING, this.mortgageeRequests,
      this.TealiumMessages.HOME_POLICY, ErrorMessages.SYSTEM_DOWNTIME);
    window.scrollTo(0, 0);
    this.isFormSubmitting = false;
    return;
  }

  /**
   * Prepares change amend request.
   * @returns The instance of the {HomeChangeAmendRequest} represents the change amend request.
   */
  private prepareChangeAmendRequest(): HomeChangeAmendRequest {
    const request = new HomeChangeAmendRequest();
    request.transactionInfo = this.prepareTransactionRequest();
    const clonedMortgageeRequests = cloneDeep(this.mortgageeRequests);
    if (clonedMortgageeRequests && clonedMortgageeRequests.length !== 0) {
      clonedMortgageeRequests.forEach(mortageeRequest => {
        if (mortageeRequest.actionRequested === Policy.Action.UPDATE) {
          mortageeRequest.actionRequested = Policy.Action.CHANGE;
        }
        if (!mortageeRequest.loanNumber || mortageeRequest.loanNumber.trim().length === 0) {
          delete mortageeRequest.loanNumber;
        }
      });
    }
    request.mortgagees = clonedMortgageeRequests;
    return request;
  }

  /**
   * Prepares a mortgagee request from the submitted mortgagee form.
   * @param openModal The boolean value indicates whether we should open the popup window.
   * @returns The instance of the {MortgageeRequest}.
   */
  private prepareMortgageeRequests(openModal: boolean = true): void {
    const mortgagee = new MortgageeRequest();
    mortgagee.index = this.getMortgageeIndex().toString();
    if(this.action){
      this.requestedAction = this.parseRequestAction(this.action);
    }
    mortgagee.actionRequested = this.requestedAction;
    mortgagee.mortgageeName = this.formService.getControlValue(this.mortgageeForm, 'mortgageeName');
    mortgagee.mortgageeNameRemainder = this.formService.getControlValue(this.mortgageeForm, 'mortgageeNameCont');
    if (this.action === Policy.Action.DELETE) {
      mortgagee.mortgageeName = this.mortgagee?.companyName;
      mortgagee.mortgageeNameRemainder = this.mortgagee?.companyNameCont;
    }
    mortgagee.loanNumber = this.formService.getControlValue(this.mortgageeForm, 'loanNumber');
    mortgagee.address = this.prepareAddressRequest();
    mortgagee.isPayor = this.formService.getControlValue(this.mortgageeForm, 'isPayor');
    this.keepMortgageeRequests(mortgagee);

    this.currentIndex = +mortgagee.index;
    this.setCurrentMortgageeName(mortgagee.mortgageeName);
    this.setOtherMortgageeName();
    this.isOpenModal = openModal;
    this.changeEffectiveDate = this.getChangeEffectiveDate();
    this.setPayorValue();
  }

  /**
   * Sets the current mortgagee name for displaying in the popup modal.
   * @name The mortgagee name.
   * @returns The current morgagee name.
   */
  private setCurrentMortgageeName(name: string): void {
    if ((this.action === Policy.Action.REPLACE
      || (this.action === Policy.Action.CLOSE && this.previousAction === Policy.Action.REPLACE)) && this.mortgagees) {
      if (this.mortgagees.length === 1) {
        this.mortgageeName = this.mortgagees[0]?.companyName?.trim();
        return;
      } else if (this.mortgagees.length === 2) {
        const foundMortgagee = this.mortgagees.find((x:any) => x.position === this.currentIndex);
        if (foundMortgagee) {
          this.mortgageeName = foundMortgagee.companyName??"";
          return;
        }
      }
    }
    this.mortgageeName = name;
  }

  /**
   * Prepares a mortgagee request from the current mortgagee.
   * @param mortgagee The mortgagee whose values are being assigned to the mortgagee request.
   * @param openModal The boolean value which indicates whether the modal popup should be opened.
   */
  private prepareCurrentMortgageeRequests(mortgagee: Mortgagee, openModal: boolean = true): void {
    const mortgageeRequest = new MortgageeRequest();
    mortgageeRequest.index = this.getMortgageeIndex().toString();
    if(this.action){
      this.requestedAction = this.parseRequestAction(this.action);
    }
    mortgageeRequest.actionRequested = this.requestedAction;
    mortgageeRequest.mortgageeName = mortgagee.companyName;
    mortgageeRequest.mortgageeNameRemainder = mortgagee.companyNameCont;
    mortgageeRequest.loanNumber = mortgagee.loanNumber;

    const address = new HomeAddressRequest();
    address.addressLineOne = mortgagee.address.streetNumber || mortgagee.address.streetName;
    address.city = mortgagee.address.city;
    address.state = mortgagee.address.state;
    address.postalCode = mortgagee.address.zipcode;
    mortgageeRequest.address = address;

    mortgageeRequest.isPayor = this.parsePayorValue(mortgagee.isEscrowed);
    this.keepMortgageeRequests(mortgageeRequest);
    this.currentIndex = +mortgageeRequest.index;
    this.setCurrentMortgageeName(mortgageeRequest.mortgageeName);
    this.setOtherMortgageeName();
    this.isOpenModal = openModal;
    if (this.action === Policy.Action.DELETE) {
      this.currentDate = new Date();
      const datepipe: DatePipe = new DatePipe('en-US')
      this.changeEffectiveDate = datepipe.transform(this.currentDate, 'MM/dd/yyyy')??"";
  }else{

      this.changeEffectiveDate = this.getChangeEffectiveDate(); 
  }
    this.setPayorValue();
  }

  /**
   * Sets the other mortgagee name.
   */
  private setOtherMortgageeName(): void {
    if (this.mortgagees && this.mortgagees.length === 2) {
      const otherMortgagee = this.mortgagees.filter((x:any) =>
        x.position !== this.currentIndex || x.position === this.targetIndex)[0];
      if (otherMortgagee && otherMortgagee.companyName) {
        this.otherMortgageeName = otherMortgagee.companyName;
      }
    } else if (this.mortgagees && this.mortgagees.length === 1) {
      if (this.mortgagee && this.mortgagee.companyName) {
        this.otherMortgageeName = this.mortgagee.companyName;
      }
    } else {
      if (this.mortgageeRequests.length === 2) {
        const otherMortgageeRequest = this.mortgageeRequests.filter(x => +x.index !== this.currentIndex)[0];
        if (otherMortgageeRequest) {
          this.otherMortgageeName = otherMortgageeRequest.mortgageeName;
        }
      } else if (this.mortgageeRequests.length === 1 && this.mortgageeRequests[0]) {
        this.otherMortgageeName = this.mortgageeRequests[0].mortgageeName;
      }
    }
  }

  /**
   * Parses the action name to get the requested action.
   * @param action The action name.
   * @returns The parsed requested action.
   */
  private parseRequestAction(action: string): string {
    if (this.action === Policy.Action.CLOSE && this.mortgageeRequests.length === 1) {
      return this.mortgageeRequests[0].actionRequested;
    } else if (this.action === Policy.Action.CLOSE
      && this.mortgageeRequests.length === 0
      && this.previousAction === Policy.Action.ADD) {
      return Policy.Action.ADD;
    } else if (this.action === Policy.Action.CLOSE
      && this.mortgageeRequests.length === 0
      && this.previousAction === Policy.Action.REPLACE) {
      return Policy.Action.REPLACE;
    } else if (this.action === Policy.Action.CLOSE
      && this.mortgageeRequests.length === 0
      && this.previousAction === Policy.Action.UPDATE) {
      return Policy.Action.UPDATE;
    }
    if (this.action && this.action.indexOf('_') !== -1) {
      return this.action.substring(0, this.action.indexOf('_'));
    }
    return action;
  }

  /**
   * Keeps track of mortgagee requests by intelligently adding a modified mortgagee request into its collection.
   * @param mortgagee The instance of the {MortgageeRequest} represents the mortgagee requests.
   */
  private keepMortgageeRequests(mortgagee: MortgageeRequest): void {
    if (!mortgagee || !(mortgagee instanceof MortgageeRequest)
      || (+mortgagee.index !== 1 && +mortgagee.index !== 2)) {
      throw new Error('Invalid mortgagee.');
    } else if (this.mortgageeRequests.length === 0) {
      this.mortgageeRequests.push(mortgagee);
    } else if (this.mortgageeRequests.length === 2 || this.mortgageeRequests.length === 1) {
      if (this.mortgageeRequests[0].actionRequested === Policy.Action.DELETE
        && mortgagee.actionRequested === Policy.Action.ADD) {
        mortgagee.actionRequested = Policy.Action.REPLACE;
        this.mortgageeRequests = this.mortgageeRequests.filter(value => value.index !== mortgagee.index);
        this.mortgageeRequests.push(mortgagee);
      } else {
        this.mortgageeRequests = this.mortgageeRequests.filter(value => value.index !== mortgagee.index);
        this.mortgageeRequests.push(mortgagee);
      }
    } else {
      throw new Error('Invalid number of mortgagee requests.');
    }
  }

  /**
   * Gets the mortgagee index.
   * @returns The index number represents mortgagee 1 or mortgagee 2.
   */
  private getMortgageeIndex(): number {
    if (this.action === Policy.Action.CLOSE && this.mortgageeRequests.length === 1) {
      return +this.mortgageeRequests[0].index;
    }
    if (this.action === Policy.Action.CLOSE
      && this.previousAction === Policy.Action.ADD
      && this.mortgageeRequests.length === 0
      && this.mortgagees
      && this.mortgagees.length === 1) {
      return this.getAlternateIndex(this.mortgagees[0].position);
    }
    if (this.mortgagee && this.action === Policy.Action.CLOSE
      && this.mortgageeRequests.length === 0
      && (this.previousAction === Policy.Action.REPLACE
        || this.previousAction === Policy.Action.UPDATE)) {
      return this.mortgagee.position;
    }
    if (this.action === Policy.Action.DELETE_DONE
      && this.mortgagees && this.mortgagees.length === 2) {
      const foundMortgagee = this.mortgagees.filter((x:any) => x.position === this.targetIndex)[0];
      if (foundMortgagee) {
        return foundMortgagee.position;
      }
    }
    if (!this.mortgagee) {
      if (this.mortgageeRequests.length === 1) {
        return Policy.IndexPositions.POSITION_TWO;
      }
      if (!this.mortgagees || this.mortgagees.length === 0) {
        return Policy.IndexPositions.POSITION_ONE;
      }
      if (this.mortgagees.length === 1) {
        return this.getAlternateIndex(this.mortgagees[0].position);
      }
    }
    if (this.action === Policy.Action.ADD) {
      if (this.mortgageeRequests.length === 1
        && this.mortgageeRequests[0].actionRequested === Policy.Action.DELETE) {
        return +this.mortgageeRequests[0].index;
      }
      return this.getAlternateIndex(this.mortgagee?.position);
    } else if (this.action === Policy.Action.REPLACE
      || this.action === Policy.Action.UPDATE
      || this.action === Policy.Action.DELETE
      || this.action === Policy.Action.DELETE_DONE
      || this.action === Policy.Action.UPDATE_DONE) {
      return this.mortgagee?.position;
    } else if (this.action === Policy.Action.ADD_SECOND
      && this.mortgageeRequests && this.mortgageeRequests.length === 1) {
      if (this.previousAction === Policy.Action.DELETE) {
        return +this.mortgageeRequests[0].index;
      } else {
        return this.getAlternateIndex(+this.mortgageeRequests[0].index);
      }
    }
    throw new Error('Can not determine the proper index number.');
  }

  /**
   * Gets the alternate index number.
   * @param index The index number to be alternated.
   * @returns The alternate index number.
   */
  private getAlternateIndex(index: number): number {
    if (index === Policy.IndexPositions.POSITION_ONE) {
      return Policy.IndexPositions.POSITION_TWO;
    } else if (index === Policy.IndexPositions.POSITION_TWO) {
      return Policy.IndexPositions.POSITION_ONE;
    }
    throw new Error(`Can not alternate the index: ${index}`);
  }

  /**
   * Prepares dwelling address.
   * @returns The instance of the {HomeAddressRequest}.
   */
  private prepareDwellingAddressRequest(): HomeAddressRequest {
    const address = new HomeAddressRequest();
    address.addressLineOne = this.policy?.dwellingAddress.address;
    address.city = this.policy?.dwellingAddress.city;
    address.state = this.policy?.dwellingAddress.state;
    address.postalCode = this.policy?.dwellingAddress.postalCode;
    return address;
  }

  /**
   * Prepares mortgagee address.
   * @returns The instance of the {HomeAddressRequest}.
   */
  private prepareAddressRequest(): HomeAddressRequest {
    const address = new HomeAddressRequest();
    address.addressLineOne = this.formService.getControlValue(this.mortgageeForm, 'addressLineOne');
    address.city = this.formService.getControlValue(this.mortgageeForm, 'city');
    address.state = this.formService.getControlValue(this.mortgageeForm, 'state');
    address.postalCode = this.formService.getControlValue(this.mortgageeForm, 'postalCode');
    return address;
  }

  /**
   * Prepares the transaction request.
   * @returns The instance of the {TransactionInfoRequest} represent the transaction request.
   */
  private prepareTransactionRequest(): TransactionInfoRequest {
    const lob = 'Home';
    const transaction = new TransactionInfoRequest();
    transaction.role = this.policy?.role;
    transaction.policyNumber = this.policy?.policyNumber;
    transaction.lineOfInsurance = lob;
    transaction.changeEffectiveDate = this.getChangeEffectiveDate();
    transaction.businessUnit = this.policy?.businessUnitId;
    transaction.marketSegment = this.policy?.marketSegment;
    transaction.lienHolderFirstName = this.policy?.lenderFirstName;
    transaction.lienHolderLastName = this.policy?.lenderLastName;
    transaction.producerCode = this.policy?.producerCode;
    transaction.lob = lob;
    transaction.policyStartDate = this.policy?.policyStartDate;
    transaction.policyExpiryDate = this.policy?.policyExpiryDate;
    transaction.address = this.prepareDwellingAddressRequest();
    transaction.payor = this.payorValue?.value;
    if(this.policy)
    {
      const names = this.parsePolicyHolderName(this.policy.policyHolderName);
    if (names && names.length === 1) {
      transaction.lastName = names[0];
    } else if (names && names.length >= 2) {
      transaction.firstName = names[1];
      transaction.lastName = names[0];
    }
    }

    return transaction;
  }

  /**
   * Parses the policy holder full name.
   * @param fullName The policy holder full name.
   * @returns The array of the first name and last name.
   */
  private parsePolicyHolderName(fullName: string): string[] {
    if (!fullName || fullName.length === 0 || fullName.indexOf(',') === -1) {
      return [];
    }
    const names = fullName.trim().split(',');
    if (names && names.length !== 0) {
      return names.map(name => name.trim());
    }
    return [];
  }

  /**
   * Gets change effective date.
   * @returns The mm/dd/yyyy formatted effective date as string.
   */
  private getChangeEffectiveDate(): string {
    let effectiveDate = this.formService.getControlValue(this.mortgageeForm, 'effectiveDate');
    if (effectiveDate && effectiveDate.length !== 0) {
      effectiveDate = formatDate(effectiveDate, 'MM/dd/yyyy', 'en');
    } else if (!this.changeEffectiveDate || this.changeEffectiveDate.length === 0) {
      effectiveDate = formatDate(this.minDate || new Date(), 'MM/dd/yyyy', 'en');
    } else if (this.changeEffectiveDate && this.changeEffectiveDate.length !== 0) {
      effectiveDate = this.changeEffectiveDate;
    } else {
      throw new Error('Unable to determine the change effective date.');
    }
    if (!effectiveDate || effectiveDate.length === 0) {
      throw new Error('Unable to determine the change effective date.');
    }
    return effectiveDate;
  }

  /**
   * Prepares the mortgagee data for editing.
   */
  private prepareMortgageeForEditing(): void {
    let mortgagee = this.mortgagee;
    if (!mortgagee && this.mortgagees) {
      if (this.mortgagees.length === 1) {
        mortgagee = this.mortgagees[0];
      } else {
        throw new Error('Unable to determine a mortgagee for the editing operation.');
      }
    }
    this.formService.setControlValue(this.mortgageeForm, 'loanNumber', mortgagee?.loanNumber);
    this.formService.setControlValue(this.mortgageeForm, 'mortgageeName', mortgagee?.companyName);
    this.formService.setControlValue(this.mortgageeForm, 'mortgageeNameCont', mortgagee?.companyNameCont);
    this.formService.setControlValue(this.mortgageeForm, 'addressLineOne', mortgagee?.address.streetName);
    this.formService.setControlValue(this.mortgageeForm, 'city', mortgagee?.address.city);
    this.formService.setControlValue(this.mortgageeForm, 'state', mortgagee?.address.state);
    this.currentState = mortgagee?.address.state;
    this.formService.setControlValue(this.mortgageeForm, 'postalCode', mortgagee?.address.zipcode);
    this.currentPayor = this.parsePayorValue(mortgagee?.isEscrowed);
    this.formService.setControlValue(this.mortgageeForm, 'isPayor', this.currentPayor.toLowerCase());
    if (!this.changeEffectiveDate) {
      this.changeEffectiveDate = this.getChangeEffectiveDate();
    }
    this.currentDate = new Date(this.changeEffectiveDate);
    this.formService.setControlValue(this.mortgageeForm, 'effectiveDate', this.changeEffectiveDate);
  }

  /**
   * Parses the payor value.
   * @param isEscrowed The boolean value indicates whether the lienholder is an escrowed one.
   */
  private parsePayorValue(isEscrowed: boolean): string {
    return isEscrowed ? this.Payors.YES : this.Payors.NO;
  }

  /**
   * Sets the payor value. The possible values are INT, MORTG1, or MORTG2.
   */
  private setPayorValue(): void {
    const currentPayorValue = this.formService.getControlValue(this.mortgageeForm, 'isPayor');
    if (!this.showMortgageeForm() ||
      (currentPayorValue && currentPayorValue.toUpperCase().trim() === this.Payors.NO)) {
      if (this.requestedAction === Policy.Action.DELETE
        || this.requestedAction === Policy.Action.UPDATE
        || this.requestedAction === Policy.Action.REPLACE) {
        if (this.currentIndex === this.payorValue?.index) {
          this.payorValue = new PayorValue(this.Payor.IN, 0);
        }
      }
    } else if (currentPayorValue && currentPayorValue.toUpperCase().trim() === this.Payors.YES) {
      if (this.currentIndex === Policy.IndexPositions.POSITION_ONE) {
        this.payorValue = new PayorValue(this.Payor.MORTG1, this.currentIndex);
      } else if (this.currentIndex === Policy.IndexPositions.POSITION_TWO) {
        this.payorValue = new PayorValue(this.Payor.MORTG2, this.currentIndex);
      }
    }
  }

  /**
   * Initializes the payor value and index from existing mortgagee(s).
   */
  private initializePayorValue(): void {
    if (this.mortgagees && this.mortgagees.length > 0) {
      const payor = this.mortgagees.find((x:any) => x.isEscrowed === true);
      if (payor) {
        const value = payor.position === Policy.IndexPositions.POSITION_ONE ? this.Payor.MORTG1 : this.Payor.MORTG2;
        this.payorValue = new PayorValue(value, payor.position);
      } else {
        this.payorValue = new PayorValue(this.Payor.IN, 0);
      }
    } else {
      this.payorValue = new PayorValue(this.Payor.IN, 0);
    }
  }

  /**
   * Processes the submit request action.
   */
  private processSubmitRequest() {
    this.Subscribe.push(this.mortgageeService.modifyChangeAmendMortgagee(this.prepareChangeAmendRequest())
      .subscribe(
        response => {
          if (this.checkResponseIsPega(response)) {
            this.callPegaService();
          } else {
            this.handleChangeAmendResponse(response);
          }
        },
        amendError => {
          this.callPegaService();
        }));
  }

  /**
   * Calls pega service API.
   */
  private callPegaService(): void {
    this.Subscribe.push(this.mortgageeService.modifyChangePegaMortgagee(this.prepareChangeAmendRequest())
      .subscribe(response => this.handleChangePegaResponse(response),
        error => this.handleResponseError(error)));
  }

  /**
   * Validates the lienholder form.
   * @returns The boolean value indicates whether the form is valid.
   */
  private validateForm(): boolean {
    this.isFormSubmitting = true;
    this.isActionInProgress = true;
    this.mortgageeForm = this.formValidationService.validateFormControls(this.mortgageeForm);
    if (!this.mortgageeForm.valid) {
      this.isActionInProgress = false;
      return false;
    }
    return true;
  }

  /**
   * Handles change pega response.
   * @param response The instance of the {ChangePegaResponse}.
   */
  private handleChangePegaResponse(response: ChangePegaResponse): void {
    if ((response && response.errorDTO && response.errorDTO.errorDesc
      && response.errorDTO.errorDesc.length !== 0) && response.messageCode !== this.responseCodes.ERR_SYS_DOWN) {
      this.customEvent.mortgageDetailTracking(this.TealiumMessages.MORTGAGEE_DETAILS_TRACKING, this.mortgageeRequests,
        this.TealiumMessages.HOME_POLICY, this.TealiumMessages.HOME_FAILED_MESSAGE);
     // console.error(`HTTP response error: ${JSON.stringify(response)}`);
      this.alertsService.emitError(new ErrorMessage(response.errorDTO.errorDesc, response.errorDTO.errorCd));
      window.scrollTo(0, 0);
      return;
    } else if (response.hasOwnProperty('error') || response.messageCode === this.responseCodes.ERR_SYS_DOWN) {
      //console.error(`HTTP response error: ${JSON.stringify(response)}`);
      this.alertsService.emitError(new ErrorMessage(ErrorMessages.SYSTEM_DOWNTIME));
      window.scrollTo(0, 0);
      return;
    } else {
      this.customEvent.mortgageDetailTracking(this.TealiumMessages.MORTGAGEE_DETAILS_TRACKING, this.mortgageeRequests,
        this.TealiumMessages.HOME_POLICY, this.TealiumMessages.HOME_SUCCESS_MESSAGE);
      this.alertsService.emitResponseStatus(Policy.Action.SUCCESS);
      if(this.policy){
        this.policy.inFlight = true;
      }
      sessionStorage.setItem(Policy.SessionKeys.HOME_POLICY_RESPONSE, JSON.stringify(this.policy));
      this.resetForm();
    }
  }

  /**
   * Handles the Home change amend response from sending event.
   * @param response The instance of the {DecPageResponseModel} represents the HTTP response.
   */
  private handleChangeAmendResponse(response: ChangeAmendResponse): void {
    if ((response && +response.statusCode >= 400)
      || (response && response.messageCode && (response.messageCode.toLowerCase().indexOf('success') === -1)
      && response.messageCode !== this.responseCodes.ERR_SYS_DOWN)) {

      this.customEvent.mortgageDetailTracking(this.TealiumMessages.MORTGAGEE_DETAILS_TRACKING, this.mortgageeRequests,
        this.TealiumMessages.HOME_POLICY, this.TealiumMessages.HOME_FAILED_MESSAGE);
      //console.error(`HTTP response error: ${JSON.stringify(response)}`);
      this.alertsService.emitError(new ErrorMessage(response.messageDesc, response.statusCode));
      window.scrollTo(0, 0);
      return;
    } else if (response.hasOwnProperty('error') || response.messageCode === this.responseCodes.ERR_SYS_DOWN) {
      //console.error(`HTTP response error: ${JSON.stringify(response)}`);
      this.alertsService.emitError(new ErrorMessage(ErrorMessages.SYSTEM_DOWNTIME));
      window.scrollTo(0, 0);
      return;
    } else {
      this.customEvent.mortgageDetailTracking(this.TealiumMessages.MORTGAGEE_DETAILS_TRACKING, this.mortgageeRequests,
        this.TealiumMessages.HOME_POLICY, this.TealiumMessages.HOME_SUCCESS_MESSAGE);
      this.alertsService.emitResponseStatus(Policy.Action.SUCCESS);
      if(this.policy){
        this.policy.inFlight = true;
      }
      sessionStorage.setItem(Policy.SessionKeys.HOME_POLICY_RESPONSE, JSON.stringify(this.policy));
      sessionStorage.setItem(Policy.SessionKeys.QUOTE_IDENTIFIER, JSON.stringify(response.quoteIdentifier));
      sessionStorage.setItem(Policy.SessionKeys.CHANGE_EFFECTIVE_DATE, JSON.stringify(this.getChangeEffectiveDate()));
      this.resetForm();
    }
  }

  /**
   * Shows the add mortgagee form based on the given action.
   * @returns The boolean value indicates whether the add mortgagee form should be displayed.
   */
  showMortgageeForm(): boolean {
    return (this.action === Policy.Action.ADD
      || this.action === Policy.Action.UPDATE
      || this.action === Policy.Action.REPLACE
      || this.action === Policy.Action.ADD_SECOND
      || (this.action === Policy.Action.CLOSE && this.previousAction === Policy.Action.ADD)
      || (this.action === Policy.Action.CLOSE && this.previousAction === Policy.Action.REPLACE)
      || (this.action === Policy.Action.CLOSE && this.previousAction === Policy.Action.UPDATE)
      || (this.action === Policy.Action.CLOSE
        && (this.previousAction !== Policy.Action.CANCEL
          && this.previousAction !== Policy.Action.DELETE
          && this.mortgageeRequests.length !== 0))
      || this.action === Policy.Action.UPDATE_DONE);
  }

  /**
   * Handles cancel event.
   */
  onCancelClick(): void {
    this.previousAction = this.action;
    this.userAction.emit(Policy.Action.CANCEL);
  }

  /**
   * Handles cancel action.
   */
  private handleCancelAction(): void {
    if (this.action !== Policy.Action.CANCEL) {
      return;
    }
    if (this.mortgageeRequests.length === 1
      && this.previousAction !== Policy.Action.DELETE
      && this.previousAction !== Policy.Action.REPLACE) {
      this.isOpenModal = true;
      return;
    }
    this.resetForm();
  }

  /**
   * Handles submit event.
   */
  onSubmitClick(): void {
    if (!this.validateForm()) {
      return;
    }
    if (this.action === Policy.Action.ADD_SECOND
      || this.action === Policy.Action.UPDATE_DONE) {
      this.prepareMortgageeRequests(false);
      if (this.mortgageeRequests.length === 2
        || (this.mortgageeRequests.length === 1
          && (this.previousAction === Policy.Action.DELETE || this.previousAction === Policy.Action.CANCEL)
          && this.mortgageeRequests[0].actionRequested === Policy.Action.REPLACE)) {
        this.userAction.emit(Policy.Action.DONE);
        return;
      }
    } else if (this.action === Policy.Action.UPDATE
      && (this.previousAction === Policy.Action.CANCEL || this.previousAction === Policy.Action.ADD)) {
      this.prepareMortgageeRequests(false);
      if (this.mortgageeRequests.length === 1) {
        this.isOpenModal = true;
        return;
      } else if (this.mortgageeRequests.length === 2) {
        this.userAction.emit(Policy.Action.DONE);
        return;
      }
    } else if (this.action === Policy.Action.CLOSE) {
      if (this.mortgageeRequests.length === 1) {
        this.prepareMortgageeRequests();
        this.userAction.emit(this.mortgageeRequests[0].actionRequested);
      } else if (this.mortgageeRequests.length === 0 && this.previousAction === Policy.Action.ADD) {
        this.prepareMortgageeRequests();
        this.userAction.emit(Policy.Action.ADD);
      } else if (this.mortgageeRequests.length === 0 && this.previousAction === Policy.Action.REPLACE) {
        this.prepareMortgageeRequests();
        this.userAction.emit(Policy.Action.REPLACE);
      } else if (this.mortgageeRequests.length === 0 && this.previousAction === Policy.Action.UPDATE) {
        this.prepareMortgageeRequests();
        this.userAction.emit(Policy.Action.UPDATE);
      }
    } else {
      this.prepareMortgageeRequests();
    }
  }

  /**
   * Resets the mortgagee form to clear out any data.
   */
  resetForm(): void {
    if (this.mortgageeForm) {
      this.mortgageeForm.reset();
    }
    this.currentState = "";
    this.currentPayor = "";
  }

  /**
   * The form heading.
   * @returns The form heading.
   */
  getFormAction(): string {
    return this.createFormTitle();
  }

  /**
   * Gets form button text from action.
   * @returns The button text.
   */
  getFormButtonText(): string {
    if (this.action === Policy.Action.ADD
      || this.action === Policy.Action.ADD_SECOND) {
      return 'Add Mortgagee';
    } else if (this.action === Policy.Action.CLOSE) {
      return this.getTitleFromRequestedAction();
    }
    return this.createFormTitle();
  }

  /**
   * Creates the form title from the given current action.
   * @returns The normalized form title.
   */
  private createFormTitle(): string {
    if (this.action === Policy.Action.CLOSE) {
      return this.getTitleFromRequestedAction();
    }
    let userAction = `${this.action?.charAt(0).toUpperCase()}${this.action?.substr(1).toLowerCase()}`;
    if (userAction.indexOf('_') !== -1) {
      const tokens = userAction.split('_').filter(x => x !== 'done');
      if (tokens.length === 2) {
        tokens[1] = `${tokens[1].charAt(0).toUpperCase()}${tokens[1].substr(1)}`;
      }
      userAction = tokens.join(' ');
    }
    return `${userAction} Mortgagee`;
  }

  /**
   * Gets title from requested action.
   * @returns The form title or button text.
   */
  private getTitleFromRequestedAction(): string {
    if (this.mortgageeRequests.length === 1) {
      const actionText = this.capitalizeAction(this.mortgageeRequests[0].actionRequested);
      return `${actionText} Mortgagee`;
    } else if (this.previousAction && this.mortgageeRequests.length === 0) {
      return `${this.capitalizeAction(this.previousAction)} Mortgagee`;
    }
    return "";
  }

  /**
   * Capitalizes the action.
   * @param action The user action.
   * @returns The capitalized action.
   */
  private capitalizeAction(action: string): string {
    const lowerCaseAction = action.toLowerCase();
    return lowerCaseAction.charAt(0).toUpperCase() + lowerCaseAction.substr(1);
  }

  /**
   * Handles modal action event.
   * @param modalData The instance of the {ModalData} represent the modal data.
   */
  onModalAction(modalData: ModalData): void {
    this.isOpenModal = false;
    this.userAction.emit(modalData.action);
    this.previousAction = modalData.previousAction;
    this.targetIndex = modalData.targetIndex;
  }

  /**
   * Populates mortgagee form with the existing mortgagee request.
   */
  private populateMortgageeForm(): void {
    const currentMortgagee = this.mortgageeRequests[0];
    this.formService.setControlValue(this.mortgageeForm, 'loanNumber', currentMortgagee.loanNumber);
    this.formService.setControlValue(this.mortgageeForm, 'mortgageeName', currentMortgagee.mortgageeName);
    this.formService.setControlValue(this.mortgageeForm, 'mortgageeNameCont', currentMortgagee.mortgageeNameRemainder);
    this.formService.setControlValue(this.mortgageeForm, 'addressLineOne', currentMortgagee.address.addressLineOne);
    this.formService.setControlValue(this.mortgageeForm, 'city', currentMortgagee.address.city);
    this.formService.setControlValue(this.mortgageeForm, 'state', currentMortgagee.address.state);
    this.currentState = currentMortgagee.address.state;
    this.formService.setControlValue(this.mortgageeForm, 'postalCode', currentMortgagee.address.postalCode);
    this.currentPayor = currentMortgagee.isPayor;
    if (!this.changeEffectiveDate) {
      this.changeEffectiveDate = this.getChangeEffectiveDate();
    }
    this.currentDate = new Date(this.changeEffectiveDate);
    this.formService.setControlValue(this.mortgageeForm, 'isPayor', this.currentPayor?.toLowerCase());
  }

  /**
   * Handles close action by by preparing the data for editing.
   */
  private handleCloseAction(): void {
    if ((this.action === Policy.Action.CLOSE && this.previousAction !== Policy.Action.DELETE)
      && this.mortgageeRequests.length === 1) {
      this.populateMortgageeForm();
      this.mortgageeRequests = [];
    }
  }

  /**
   * Handles delete action by preparing the mortgagee request and add it to the mortgagee requests.
   */
  private handleDeleteAction(): void {
    if (this.mortgagee && this.action === Policy.Action.DELETE) {
      this.prepareCurrentMortgageeRequests(this.mortgagee);
    } else if (this.action === Policy.Action.DELETE_DONE && this.mortgagees?.length === 1) {
      const mortgagee = this.mortgagee || this.mortgagees[0];
      this.prepareCurrentMortgageeRequests(mortgagee, false);
      this.processSubmitRequest();
    } else if (this.action === Policy.Action.DELETE_DONE && this.mortgagees?.length === 2) {
      const foundMortgagee = this.mortgagees?.filter((x:any) => x.position === this.targetIndex)[0];
      if (foundMortgagee) {
        this.prepareCurrentMortgageeRequests(foundMortgagee, false);
        this.processSubmitRequest();
      } else {
        throw new Error('Invalid operation. Could not find a mortgagee to be deleted.');
      }
    }
  }

  /**
   * Handles update action by preparing the mortgagee request and add it to the mortgagee requests
   * or submit it to pega api if it is the update and done action.
   */
  private handleUpdateAction(): void {
    if (this.action === Policy.Action.UPDATE
      || this.action === Policy.Action.UPDATE_DONE) {
      this.prepareMortgageeForEditing();
    }
  }

  /**
   * Handles done action by submitting the mortgagee requests to the server.
   */
  private handleDoneAction(): void {
    if (this.action === Policy.Action.DONE) {
      this.isOpenModal = false;
      this.processSubmitRequest();
    }
  }

  /**
   * Gets the number of the existing mortgagees.
   * @returns The number of the existing mortgagess on this policy.
   */
  getNumberOfExistingMortgagees(): number {
    return !this.mortgagees ? 0 : this.mortgagees.length;
  }

  /**
   * Hides the date picker field if mortgagee length is one or changes in second mortgagee and sets the effective date.
   * @returns The boolean value indicates whether the form should display date picker field.
   */
  showEffectiveDate(): boolean {
    return !(this.action === Policy.Action.ADD_SECOND
      || (this.mortgagees?.length === 1
        && this.mortgageeRequests
        && this.mortgageeRequests.length === 1
        && this.action === Policy.Action.UPDATE));
  }

  /**
   * Resets the effctive date validators.
   */
  resetEffectiveDateValidators(): void {
    this.formValidationService.clearControlValidators(this.mortgageeForm, 'effectiveDate');
    if (this.showEffectiveDate()) {
      this.formValidationService.setControlValidators(this.mortgageeForm,
        'effectiveDate', [Validators.required]);
    }
  }

  /**
   * Checks the amend response to see if we need to call the Pega service API.
   * If the response from change Amend service is not successful, then we also need to call Pega service API.
   * @param response The instance of {ChangeAmendResponse} represents the change amend response.
   * @returns The boolean value indicates whether we need to call the Pega service API.
   */
  private checkResponseIsPega(response: ChangeAmendResponse): boolean {
    if ((response && +response.statusCode >= 400)
      || (response && response.messageCode && response.messageCode.toLowerCase().indexOf('success') === -1)) {
      return true;
    } else if (response.hasOwnProperty('error')) {
      return true;
    }
    return false;
  }
}
