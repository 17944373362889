import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PolicyDetailsComponent } from './policy/policy-details/policy-details.component';
import { ContentComponent } from './layouts/content/content.component';
import { ContainerComponent } from './layouts/container/container.component';
import { RegistrationComponent } from './registration/registration.component';
import { AccountSecurityComponent } from './account-security/account-security.component';
import { EditAccountComponent } from './edit-account/edit-account.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { DisassociationEmailComponent } from './disassociation-verification-email/disassociation-verification-email.component';
import { DisassociationPostVerificationComponent } from './disassociation-post-verification/disassociation-post-verification.component';
import { DisassociationEmailExpiryComponent } from './disassociation-email-expiry/disassociation-email-expiry.component';


const routes: Routes = [
  { path: '',
    loadChildren: () => import('./welcome-lender/welcome-lender.module').then(m => m.WelcomeLenderModule),
    pathMatch: 'full'
  },
  {
    path: 'search-policy',
    pathMatch: 'full',
    component: ContentComponent,
    children: [
      {
        path: '',
        component: ContainerComponent
      }]
  },
  {
    path: 'policy-details',
    component: PolicyDetailsComponent,
    pathMatch: 'full'
  },
  { path: 'registration',
    component: RegistrationComponent,
    pathMatch: 'full'
  },
  { path: 'sent-email',
    loadChildren: () => import('./sent-verification-email/sent-verification-email.module').then(m => m.SentVerificationEmailModule),
    pathMatch: 'full'
  },
  { path: 'logout',
    loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule),
    pathMatch: 'full'
  },
  { path: 'account-security',
    component: AccountSecurityComponent,
    pathMatch: 'full'
  },
  { path: 'edit-account',
    component: EditAccountComponent,
    pathMatch: 'full'
  },
  { path: 'error-page',
    loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule),
    pathMatch: 'full'
  },
  { path: 'disassociation-email',
    component: DisassociationEmailComponent,
    pathMatch: 'full'
  },
  { path: 'disassociation-post-verification',
    component: DisassociationPostVerificationComponent,
    pathMatch: 'full'
  },
  { path: 'disassociation-email-expiry',
    component: DisassociationEmailExpiryComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
