import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment-wrapper';
import { HomePolicyResponse } from '../shared/api/home-policy-response.model';
import { HomePolicyDataFactory } from '../shared/api/home/home-policy-data.factory';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { PolicyRequest } from '../shared/api/policy-request.model';
import { AutoPolicyRequest } from '../shared/api/auto-policy/auto-policy-request.model';
import { AutoPolicyResponse } from '../shared/api/auto-policy/auto-policy-response.model';
import { catchError } from 'rxjs/operators';
import { AutoPolicyDataFactory } from '../shared/api/auto-policy/auto-policy-data.factory';
import { NewcoSearchPolicyRequestModel } from './newco-search-policy-request.model';
import { UpdateProfileKeyModel } from '../search-policy/update-profile-key-model';

@Injectable({
  providedIn: 'root'
})
export class SearchPolicyService {

  /**
   * The API endpoint that will be changed for each environmet.
   */
  apiBaseEndpoint = environment.API_ENDPOINT;

  /**
   * The auto/pega base endpoint.
   */
  apiPegaEndpoint = environment.PEGA_API_ENDPOINT;

  /**
   * The home policy resource URI.
   */
  homePolicyResource = environment.EXPRESS_API_HOMEPOLICY;

  /**
   * The auto policy resource, update profile, get profile, newco search policy URI.
   */
  autoPolicyResource = environment.AUTO_SERVICE_URI;

  updateProfileUrlAPI = environment.UPDATE_PROFILE_RESOURCE;

  newCoResource = environment.NEWCO_RESOURCE;

  getProfileUrlAPI = environment.PROFILE_API;

  invalidsearchResource = environment.INVALD_SEARCH_ATTEMPT;

  /**
   * The mocked home policy response data.
   */
  homePolicyResponseMock: HomePolicyResponse = HomePolicyDataFactory.getMockHomePolicy();


  /**
   * The mocked home policy response data.
   */

  homePolicyResponseMock2: HomePolicyResponse = HomePolicyDataFactory.getMockHomePolicy(true);

  private fade = new BehaviorSubject(false);
  public fadeStream = this.fade.asObservable();


  /**
   * Initializes new instance of the {HttpClient}
   * @param http The instance of the {HttpClient
   */
  constructor(private http: HttpClient) {
  }

  setFade(flag: boolean) {
    this.fade.next(flag);
  }

  /**
   * Lookups the home policy information from the restful service API.
   * @param policyRequest The instance of the {PolicyRequest}.
   * @returns The populated instance of the {HomePolicyResponse}.
   */
  lookupHomePolicy(policyRequest: PolicyRequest): Observable<HomePolicyResponse> {
    return this.http.post<HomePolicyResponse>(this.getApiEndpoint(this.homePolicyResource),
      policyRequest);
  }

  /**
   * Mocks looking up the home policy information from the restful service API.
   * @param policyRequest The instance of the {PolicyRequest}.
   * @returns The populated instance of the {HomePolicyResponse}.
   */
  lookupHomePolicyMock(policyRequest: PolicyRequest): Observable<HomePolicyResponse> {
    //console.log(`policyRequest: ${JSON.stringify(policyRequest)}`);
    return of(this.homePolicyResponseMock);
  }

  /**
   * Mocks looking up the home policy information from the restful service API.
   * @param policyRequest The instance of the {PolicyRequest}.
   * @returns The populated instance of the {HomePolicyResponse}.
   */
  lookupHomePolicyMock2(policyRequest: PolicyRequest): Observable<HomePolicyResponse> {
    //console.log(`policyRequest: ${JSON.stringify(policyRequest)}`);
    return of(this.homePolicyResponseMock2);
  }

  /**
   * Lookups the auto policy by calling the REST api.
   * @param AutoPolicyRequest The instance of the {AutoPolicyRequest} represents the request information
   * of the auto policy.
   * @return The instance of the {AutoPolicyResponse} represents the auto policy information.
   */
  lookupAutoPolicy(policyRequest: AutoPolicyRequest): Observable<AutoPolicyResponse> {
    //console.log(`Auto api endpoint: ${this.getAutoApiEndpoint(this.autoPolicyResource)}`);
    return this.http.post<AutoPolicyResponse>(this.getAutoApiEndpoint(this.autoPolicyResource),
      policyRequest)
      .pipe(catchError(this.handleError));
  }

  /**
   * Mocks the auto policy response.
   * @param policyRequest The instance of the {AutoPolicyRequest} represents the auto policy request.
   * @param hasError The boolean value indicates whether the mocked data should include the error DTO.
   * @return The mocked instance of the {AutoPolicyResonse} represents the auto policy response.
   */
  lookupAutoPolicyMock(policyRequest: AutoPolicyRequest, hasError: boolean = false): Observable<AutoPolicyResponse> {
    //console.log(`policyRequest: ${JSON.stringify(policyRequest)}`);
    return of(AutoPolicyDataFactory.createAutoPolicyResponse(hasError));
  }

  /**
   * Gets common HTTP request headers properties.
   */
  private getCommonHttpHeaders(): HttpHeaders {
    return new HttpHeaders({})
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json')
      .append('Access-Control-Allow-Origin', '*');
  }

  /**
   * Gets the full API resource URI.
   * @param resource The resource relative path without leading slash.
   * @returns The full API resource URI.
   */
  private getApiEndpoint(resource: string) {
    return `${this.apiBaseEndpoint}${resource}`;
  }

  /**
   * Gets the auto apin endpoint.
   * @param resource The resource relative path to auto service.
   */
  private getAutoApiEndpoint(resource: string): string {
    return `${this.apiPegaEndpoint}${resource}`;
  }

  /**
   * Handles HTTP service call error.
   * @param error The instance of the {HttpErrorResponse}.
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      //console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  invalidSearchPolicyAttempt(payload:any) {
    return this.http.post(this.getApiEndpoint(this.invalidsearchResource), payload);
  }

  lookupNewCoPolicy( policyform: NewcoSearchPolicyRequestModel) {
    //console.log('Inside newco call');
    return this.http.post(this.getApiEndpoint(this.newCoResource),
      policyform)
      .pipe(catchError(this.handleError));
  }

  onLoadPolicySearch(updateProfileKeyRequest: UpdateProfileKeyModel) {
    //console.log(updateProfileKeyRequest);
    return this.http.post(this.getApiEndpoint(this.updateProfileUrlAPI),
      updateProfileKeyRequest);
  }

  getLoggedInUser() {
    //console.log('Inside getLoggedIn user');
    return this.http.get(this.getApiEndpoint(this.getProfileUrlAPI));
  }
}
