import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ErrorMessage } from '../errors/error/error-message.model';
import { PanelViewState } from './panel-view-state.model';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  /**
   * The instance of the {ErrorMessage} as the source of the reactive stream.
   */
  private errorSource = new Subject<ErrorMessage>();

  /**
   * The HTTP response status as the source of the stream.
   */
  private responseStatusSource = new Subject<string>();

  /**
   * The panel view state source of the stream.
   */
  private panelViewStateSource = new Subject<PanelViewState>();

  /**
   * The Observable {ErrorMessage} as the observable streams.
   */
  error = this.errorSource.asObservable();

  /**
   * The Observable string as the observable response status.
   */
  responseStatus = this.responseStatusSource.asObservable();

  /**
   * The Observable view states as the observable stream.
   */
  panelViewState = this.panelViewStateSource.asObservable();

  /**
   * Emits the error message to shared service.
   * @param error The instance of the {ErrorMessage} represents the error message.
   */
  emitError(error: ErrorMessage): void {
    this.errorSource.next(error);
  }

  /**
   * Emits the HTTP custom response status so the subscriber can subscribe to it.
   * @param status The Http custom response status code.
   */
  emitResponseStatus(status: string): void {
    this.responseStatusSource.next(status);
  }

  /**
   * Emits the panel view state.
   * @param viewState The instance of the {PanelViewState} represents the panel view state.
   */
  emitPanelViewState(viewState: PanelViewState): void {
    this.panelViewStateSource.next(viewState);
  }
}
