import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment-wrapper';
import { DecPageRequestModel } from '../../shared/api/dec-page-request.model';
import { DecPageResponseModel } from '../../shared/api/dec-page-response.model';
import { AutoDecRequest } from '../../shared/api/auto-policy/auto-dec-request.model';
import { AutoDecResponse } from '../../shared/api/auto-policy/auto-dec-response.model';

@Injectable({
    providedIn: 'root',
})
export class DecPageService {
    /**
     * The API endpoint that will be changed for each environmet.
     */
    apiBaseEndpoint = environment.API_ENDPOINT;

    /**
     * The auto declaration copy request API endpoint.
     */
    autoDecBaseEndpoint = environment.AUTO_ENDPOINT;

    /**
     * The home declaration copy requst resouce URI.
     */
    homeDecPageResource = environment.EXPRESS_API_DECPAGE;

    /**
     * The auto declaration copy request resource URI.
     */
    autoDecPageResource = environment.ECM_REQUEST_DECLARATION_SERVICE_URI;

    /**
     * The policy type which can be 'HOME' or 'AUTO'.
     */
    readonly PolicyType = {
        HOME: 'HOME',
        AUTO: 'AUTO'
    };

    /**
     * Initializes new instance of the {HttpClient}
     * @param http The instance of the {HttpClient
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Makes a restful call to the home declaration service to send fax or email requested by the user.
     * @param request The instance of the {DecPageRequestModel}.
     * @returns The populated instance of the {DecPageResponseModel}.
     */
    homeDecPageRequest(request: DecPageRequestModel): Observable<DecPageResponseModel> {
        return this.http.post<DecPageResponseModel>(this.getApiEndpoint(this.homeDecPageResource, this.PolicyType.HOME),
            request, { headers: this.getCommonHttpHeaders() })
            .pipe(catchError(this.handleError));
    }

    /**
     * Makes a restful call to the auto declaration service to send fax or email requested by the user.
     * @param request The instance of the {AutoDecResponse} represents the auto declaration request.
     */
    autoDecpageRequest(request: AutoDecRequest): Observable<AutoDecResponse> {
        return this.http.post<AutoDecResponse>(this.getApiEndpoint(this.autoDecPageResource, this.PolicyType.AUTO),
        request, { headers: this.getCommonHttpHeaders() })
        .pipe(catchError(this.handleError));
    }

    /**
     * Gets common HTTP request headers properties.
     */
    private getCommonHttpHeaders(): HttpHeaders {
        return new HttpHeaders({})
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Access-Control-Allow-Origin', '*');
    }

    /**
     * Gets the full API resource URI.
     * @param resource The resource relative path without leading slash.
    * @param policyType to determine if request is for HOME or AUTO
    * @returns The full API resource URI.
     */
    private getApiEndpoint(resource: string, policyType: string): string {
        if (policyType === this.PolicyType.HOME) {
            return `${this.apiBaseEndpoint}${resource}`;
        } else if (policyType === this.PolicyType.AUTO) {
            return `${this.autoDecBaseEndpoint}${resource}`;
        } else {
            throw new Error('Unable to determine policy type.');
        }
    }

    /**
     * Handles HTTP service call error.
     * @param error The instance of the {HttpErrorResponse}.
     */
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            //console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
