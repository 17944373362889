import { Component, OnInit } from '@angular/core';
import { ErrorMessage } from '../../errors/error/error-message.model';
import { CustomEventService } from 'src/app/services/tealium/custom-event.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit  {
  /**
   * The instance of the {ErrorMessage} represents error message information.
   */
  errorMessage: ErrorMessage | undefined;

  constructor(private customEvent: CustomEventService) { }
  /**
   * Handles has error event of the policy lookup form component.
   * @param message The instance of the {ErrorMessage} represents error message information.
   */
  onHasErrors(message: ErrorMessage): void {
    this.errorMessage = message;
  }

  ngOnInit(){

  }
}
