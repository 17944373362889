<div class="contact-card">
  <div class="contact-card__header">
    <div class="contact-card__title">Account Information
    <p class="contact-card__title-msg">Changing your contact information here will only affect your online<br>
      account. It will not change your policy information</p></div>
    <button routerLink = "/edit-account" class="btn btn-secondary-paired btn-block" data-dl='{"event":"mousedown", "da_track":"true", "event_name":"Change Password Button", "event_id":"Change_Account_Information", "event_parent" : "Page Tag", "event_type" : "Link Click","event_value" : "Change" }'>
      <span>Change</span>
    </button>
  </div>
  <div class="contact-card__contact-container">
    <ng-container *ngIf="contactInformation">
      <div class="contact-card__contact-listing">
        <div class="contact-card__contact-info">
        <div>Name</div>
        <div>{{contactInformation.Name}}</div>
      </div>
        <div class="contact-card__contact-info">
          <div>User ID</div>
          <div>{{contactInformation.userId}}</div>
        </div>
        <div class="contact-card__contact-info">
          <div>Email</div>
          <div>{{contactInformation.email}}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>