export class FormField {
  defaultValue: any = null;
  controlName = '';
  validators: any[] = [];

  constructor(defaultValue: any,
    controlName: string,
    validators: any[]) {
      this.defaultValue = defaultValue;
      this.controlName = controlName;
      this.validators = validators;
    }
}
