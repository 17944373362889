import { Component } from '@angular/core';
import { AutoPolicyResponse } from '../../shared/api/auto-policy/auto-policy-response.model';
import { ErrorMessage } from '../../errors/error/error-message.model';

@Component({
  selector: 'app-auto-doc-requests',
  templateUrl: './auto-doc-requests.component.html',
  styleUrls: ['./auto-doc-requests.component.scss']
})
export class AutoDocRequestsComponent  {

    /**
   * The policy type.
   */
  readonly PolicyType = {
    AUTO: 'AUTO',
  };

  /**
   * The value indicates whether the get declaration copy button is clicked.
   */
  isDecFormVisible = false;

  /**
   * The value indicates whether the declaration page is visible.
   */
  hideDecPageAfterSubmission = false;

  /**
   * The current policy type.
   */
  policyType: string | undefined; /* Get it from the query string */

  /**
   * The instance of the {AutoPolicyResponse} represents the auto policy response.
   */
  autoPolicyResponse: AutoPolicyResponse | undefined;

  /**
   * The value indicates whether the error message gets displayed
   */
  showFailureMessage = false;

  /**
   * The instance of the {ErrorMessage} represents error message information.
   */
  errorMessage: ErrorMessage | undefined;



  /**
   * Initializes the new instance of the {PolicyDetailsComponent}.
   */
  constructor() {
    this.fetchPolicyFromSession();
  }

  /**
   * Handles has error event of the declaration request form component.
   * @param message The instance of the {ErrorMessage} represents error message information.
   */
  onHasErrors(message: ErrorMessage): void {
    this.showFailureMessage = true;
    this.errorMessage = message;
  }

  /**
   * Fetches the policy information from user session and discovers the policy type (AUTO or HOME).
   */
  private fetchPolicyFromSession(): void {
    const autoPolicyResponse:any = JSON.parse(sessionStorage.getItem('autoPolicyResponse') ?? '""');
    this.autoPolicyResponse = autoPolicyResponse as AutoPolicyResponse;
    if (this.autoPolicyResponse) {
      this.policyType = this.autoPolicyResponse.policyType;
      //console.log(`Auto policy response: ${JSON.stringify(this.autoPolicyResponse)}`);
    }
  }

 /**
   * Handles declaration form cancel event.
   * @param cancelled The boolean value indicates whether the form is cancelled.
   */
  onDecFormCancel(cancelled: boolean): void {
    this.isDecFormVisible = !cancelled;
  }

  /**
   * Handles declaration form submit event.
   * @param submitted The boolean value indicates whether the form is submitted.
   */
  onDecFormSubmit(submitted: boolean): void {
    this.hideDecPageAfterSubmission = submitted;
  }

  /**
   * Returns true if button is clicked
   */
  onGetDeclarationsPageCopyClick(): boolean {
    return this.isDecFormVisible = true;
  }
}
