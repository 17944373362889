import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CustomEventService } from '../tealium/custom-event.service';
import { ErrorMessage } from 'src/app/errors/error/error-message.model';
import { ErrorMessages } from 'src/app/shared/validation/error-messages';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  @Output() hasErrors = new EventEmitter<ErrorMessage>();
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private customEvent: CustomEventService

  ) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log('inside intercept');
    return next.handle(httpRequest.clone({
      headers: this.buildHeaders(httpRequest)
    })
    ).pipe(catchError((error) => {
      //console.error(error);
      this.handleError(error);
      this.customEvent.serviceDownError('Application Error');
      return throwError(() => error);
    }));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      //console.error('An error occurred:', error.error.message);
      this.hasErrors.emit(new ErrorMessage(ErrorMessages.SYSTEM_DOWNTIME));
      this.naveToLenderError();

    } else {
      //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
      this.hasErrors.emit(new ErrorMessage(ErrorMessages.SYSTEM_DOWNTIME));
      this.naveToLenderError();
    }
    this.customEvent.serviceDownError(error.error.message);
    return throwError('Something bad happened; please try again later.');
  }

  private getHttpHeaders(): HttpHeaders {
    return new HttpHeaders({})
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json');
  }

  private naveToLenderError() {
    window.scrollTo(0, 0);
    this.router.navigate(['error-page']);
  }

  private checkResStatus(res: any): boolean {
    // for documents if res type = blob, return false as blob does not have status property
    if (!!res.type && res.type === 'application/octet-stream') {
      return false;
    }
    // for documents -> res.status.statusCode, others mostly -> res.status
    if (!!res && ((!!res.status && (res.status === 200 || res.status.statusCode === 200)) ||
      (!!res.statusCode && res.statusCode === 200))) {
      return false;
    }
    if (res.status !== 200) {
      return true;
    }

    return true;
  }

  private buildHeaders(httpRequest: HttpRequest<any>): HttpHeaders {
    const url = httpRequest.url;
    const ciamSessionCookie = this.cookieService.get('CIAMSession');
    let headers = this.getHttpHeaders();
    if (url.includes('/lenderPolicySearch') || url.includes('/updateProfile')
      || url.includes('/getProfileName') || url.includes('/updateAccountInfo')
      || url.includes('/invalidSearchAttempt') 
      || url.includes('/disassociationEmail')
      || url.includes('/relinking')
      || url.includes('/isValidCiamSession')
      || url.includes('/validateTokenCiamSession'))  {
      headers = headers.set('Cache-Control', 'no-cache');
      headers = headers.append('CIAMSession', ciamSessionCookie);
    } else if (url.includes('/lenderRegistration') || url.includes('/resendEmail') || url.includes('/decrypt')) {
      headers = headers.set('Cache-Control', 'no-cache');
      if ( sessionStorage.getItem('authToken') ) {
        headers = headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('authToken'));
      }
    } else if (url.includes('/homePolicy')) {
      headers = headers.set('Cache-Control', 'no-cache');
      if ( sessionStorage.getItem('ciamUserName') ) {
        headers = headers.set('userID',  (sessionStorage.getItem('ciamUserName') ?? ''));
      }
    } else if (this.allowedURIsForLvpAuto(url)) {
      headers = headers.set('Cache-Control', 'no-cache');
      headers = headers.set('CIAMSession', ciamSessionCookie);
      headers = headers.set('Authorization', 'Bearer ' + this.cookieService.get('authToken'));
      if ( sessionStorage.getItem('ciamUserName') ) {
        headers = headers.set('userID',  (sessionStorage.getItem('ciamUserName') ?? ''));
      }
    } else if ( url.includes('/authorize') ) {
      let httpHeaders = new HttpHeaders({})
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', '0');
        
        if (url.includes('recaptcha')) {
          if (sessionStorage.getItem('recaptchaToken') ) {
            httpHeaders = httpHeaders.set('recaptcha', (sessionStorage.getItem('recaptchaToken') ?? ''));
            return httpHeaders;
          }
        }

        if ( sessionStorage.getItem('ciamUserName') ) {
          httpHeaders = httpHeaders.set('userID',  (sessionStorage.getItem('ciamUserName') ?? ''));
        }
        httpHeaders = headers.append('CIAMSession', ciamSessionCookie);
        return httpHeaders;
    }
    return headers;
  }

  private allowedURIsForLvpAuto(inputUri: string) : boolean {
    const uriList = ['/refdata', '/auto', '/addLienholder', '/updateLienholder', '/deleteLienholder','/requestDeclaration'];
    if (inputUri) {
      for (let uri of uriList) { 
        if (inputUri.includes(uri)) {
          return true;
        }        
      }  
    }  
    return false;
  }
  
}
