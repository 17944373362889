import { Component, Input, OnChanges } from '@angular/core';
import { Vehicle } from '../../shared/api/auto-policy/vehicle.model';
import { AutoAddress } from '../../shared/api/auto-policy/auto-address.model';
import { AutoPolicyResponse } from '../../shared/api/auto-policy/auto-policy-response.model';

@Component({
  selector: 'app-auto-panel',
  templateUrl: './auto-panel.component.html',
  styleUrls: ['./auto-panel.component.scss']
})
export class AutoPanelComponent implements OnChanges {
  /**
   * The panel toggle title.
   */
  ToggleTitle = {
    SHOW_MORE: 'Show More',
    SHOW_LESS: 'Show Less'
  };

  /**
   * The value that indicates whether the panel body is visible.
   */
  @Input() isPanelBodyExpanded: boolean | undefined;

  /**
   * The policy type.
   */
  @Input() policyType: string | undefined;

  /**
  * The button state of the current mortgagee as a component input.
  */
 @Input() buttonState: any;

 /**
  * The instance of {Vehicle} represents lienholder information for the vehicle.
  */
 @Input() vehicle: Vehicle | undefined;

 /**
  * The instance of hte {AutoPolicyResponse} represents the auto policy response as a component input data.
  */
 @Input() policy: AutoPolicyResponse | undefined;

 /**
  * The instance of the {AutoAddress} represents the address of the vehicle.
  */
 @Input() address: AutoAddress | undefined;

  /**
   * The current toggle title.
   */
  currentToggleTitle: string | undefined;

  /**
  * The policy type.
  */
  readonly PolicyType = {
    AUTO: 'AUTOP',
    HOME: 'HOME'
  };

  /**
   * Initializes new instance of the {PanelComponent}.
   */
  constructor() {
  }

  /**
   * Handles on component changes.
   */
  ngOnChanges(): void {
    this.displayToggleTitle();
  }

  /**
   * Displays the panel icon based on the given panel title.
   */
  displayPanelIcon(): any {
    if (this.policyType === this.PolicyType.AUTO) {
      return { 'icon-car-primary-option': true };
    }
    return null;
  }

  /**
   * Displays the up or down caret icon.
   */
  displayCaret() {
    return this.isPanelBodyExpanded ? { 'icon-caret-up': true } : { 'icon-caret-down': true };
  }

  /**
   * Toggles body panel visiblity.
   */
  togglePanelVisibility() {
    this.isPanelBodyExpanded = !this.isPanelBodyExpanded;
    this.displayToggleTitle();
  }

  /**
   * Displays the current toggled panel title.
   */
  displayToggleTitle() {
    this.currentToggleTitle = !this.isPanelBodyExpanded ? this.ToggleTitle.SHOW_MORE : this.ToggleTitle.SHOW_LESS;
  }

  /**
   * Gets panel title.
   * @return The panel title which is consisted of year, make, and model.
   */
  getPanelTitle(): string {
    if (!this.vehicle) {
      return "";
    }
    return `${this.vehicle.vehicleyear} ${this.vehicle.vehicleMake} ${this.vehicle.vehicleModel}`;
  }

  /**
   * Gets panel subtitle.
   * @return The subtitle of the vehicle which is its VIN number.
   */
  getPanelSubtitle(): string {
    if (!this.vehicle) {
      return "  ";
    }
    return `VIN # ${this.vehicle.vinNumber}`;
  }
}
