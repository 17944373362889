import {
  Component,
  Input,
  OnChanges,
  Type
} from '@angular/core';
import { AlertsService } from '../../alerts/alerts.service';
import { PanelViewState } from '../../alerts/panel-view-state.model';

/**
 * The {PanelComponent} represents the panel component that
 * can be used as container for mortgagee details,
 * policy coverages, and billing & document requests.
 */
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnChanges {

  /**
   * The panel titles.
   */
  PanelTitle = {
    MORTGAGEE_DETAILS: 'Mortgagee Details',
    POLICY_COVERAGES: 'Policy Coverages',
    BILL_DOC_REQ: 'Billing & Documents',
    DOC_REQ: 'Document Requests'
  };

  /**
   * The panel toggle title.
   */
  ToggleTitle = {
    SHOW_MORE: 'Show More',
    SHOW_LESS: 'Show Less'
  };

  /**
   * The panel title inflow property.
   */
  @Input() panelTitle: string | undefined;

  /**
   * The panel subtitle.
   */
  @Input() panelSubtitle: string | undefined;

  /**
   * The panel body inflow property.
   */
  @Input() panelBody: Type<any> | any;

  /**
   * The value that indicates whether the panel body is visible.
   */
  @Input() isPanelBodyVisible: boolean | undefined;

  /**
   * The policy type code thats indicates
   * whether it is HO4 or HO4.
   */
  @Input() policyTypeCode: string | undefined;

  /**
   * The policy type.
   */
  @Input() policyType: string | undefined;

  /**
   * The current toggle title.
   */
  currentToggleTitle: string | undefined;

  /**
   * Home icons
   */
  readonly IconType = {
    CONDO_ICON_HO4: '04',
    CONDO_ICON_HO6: '06'
  };

  /**
  * The policy type.
  */
  readonly PolicyType = {
    AUTO: 'AUTOP',
    HOME: 'HOME'
  };

  /**
   * Initializes new instance of the {PanelComponent}.
   * @param The instance of the {AlertsService} represents the alerts service.
   */
  constructor(private alertsService: AlertsService) {
  }

  /**
   * Handles on component changes.
   */
  ngOnChanges(): void {
    this.displayToggleTitle();
  }

  /**
   * Displays the panel icon based on the given panel title.
   */
  displayPanelIcon(): any {
    if (this.policyType === this.PolicyType.AUTO) {
      return { 'icon-car-primary-option': true };
    }
    switch (this.panelTitle) {
      case this.PanelTitle.MORTGAGEE_DETAILS:
        return this.displayHomeIcon();
      case this.PanelTitle.POLICY_COVERAGES:
        return { 'icon-policy': true };
      case this.PanelTitle.BILL_DOC_REQ:
      case this.PanelTitle.DOC_REQ:
        return { 'icon-documents': true };
      default:
        return null;
    }
  }

  /**
   * Gets panel title.
   * @return The panel title.
   */
  getPanelTitle(): string {
    switch (this.panelTitle) {
      case this.PanelTitle.MORTGAGEE_DETAILS:
        return this.PanelTitle.MORTGAGEE_DETAILS;
      case this.PanelTitle.POLICY_COVERAGES:
        return this.PanelTitle.POLICY_COVERAGES;
      case this.PanelTitle.BILL_DOC_REQ:
        return this.PanelTitle.BILL_DOC_REQ;
      case this.PanelTitle.DOC_REQ:
        return this.PanelTitle.DOC_REQ;
      default:
        return this.panelTitle??"";
    }
  }

  /**
   * Displays home/condo icon based on the given policy type code.
   * The policy type code can be 'HO4' or 'HO6'.
   */
  private displayHomeIcon(): any {
    switch (this.policyTypeCode) {
      case this.IconType.CONDO_ICON_HO4:
      case this.IconType.CONDO_ICON_HO6:
        return { 'icon-condominium ': true };
      default:
        return { 'icon-house': true };
    }
  }

  /**
   * Displays the up or down caret icon.
   */
  displayCaret() {
    return this.isPanelBodyVisible ? { 'icon-caret-up': true } : { 'icon-caret-down': true };
  }

  /**
   * Toggles body panel visiblity.
   */
  togglePanelVisibility() {
    this.isPanelBodyVisible = !this.isPanelBodyVisible;
    this.displayToggleTitle();
    this.alertsService.emitPanelViewState(new PanelViewState(this.panelTitle, this.isPanelBodyVisible));
  }

  /**
   * Displays the current toggled panel title.
   */
  displayToggleTitle() {
    this.currentToggleTitle = !this.isPanelBodyVisible ? this.ToggleTitle.SHOW_MORE : this.ToggleTitle.SHOW_LESS;
  }
}
