import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HomePolicyResponse } from '../../shared/api/home-policy-response.model';
import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ErrorMessage } from '../../errors/error/error-message.model';
import { FormBuilder } from '@angular/forms';
import { LvpFormService } from '../../shared/form/lvp-form.service';
import { CustomEventService } from '../../services/tealium/custom-event.service';
import { LvpFormValidationService } from '../../shared/form/lvp-form-validation.service';
import { BillingInvoiceReceiptService } from '../billing-doc-requests/billing-invoice-receipt-service';
import { FormField } from '../../shared/form/form-field.model';
import { Validators } from '@angular/forms';
import { ValidationPatterns } from '../../shared/form/validation-patterns';
import { CustomValidators } from '../../shared/form/custom-validators';
import { BillingDetailsInvoiceReceiptRequestModel } from '../../shared/api/billing-details-invoice-receipt-request.model';
import { BillingDetailsInvoiceReceiptResponseModel } from '../../shared/api/billing-details-invoice-receipt-response.model';
import { Mortgagee } from '../mortgagee.model';
import { ErrorMessages } from '../../shared/validation/error-messages';
import { TransactionInfoRequest } from '../../shared/api/home/transaction-info-request.model';
import { PolicyInfoModel } from '../../shared/api/home/policy-info.model';
import { RecipientInfoModel } from '../../shared/api/home/receipient-info.model';
import { HomeAddressRequest } from '../../shared/api/home/home-address-request.model';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-paid-in-full-receipt-form',
  templateUrl: './paid-in-full-receipt-form.component.html',
  styleUrls: ['./paid-in-full-receipt-form.component.scss']
})
export class PaidInFullReceiptFormComponent implements OnDestroy {
  /**
   * The instance of the {FormGroup} represents the paid in full receipt form.
   */
  paidInFullReceiptForm: FormGroup | any;

  /**
   * The value indicates whether the sending request is in progress.
   */
  isSendingInProgress = false;

  /**
   * The value indicates whether the form is submitting.
   */
  isFormSubmitting = false;

  /**
   * The policy type
   */
  readonly CopyRequestType = {
    EMAIL: 'Email',
    FAX: 'Fax'
  };

  /**
   * Indicates the request type
   */
  requestType: string | undefined;

  /**
   * Indicates the current date
   */
  today = new Date();

  /**
   * The input length of the form fields.
   */
  readonly InputLength = {
    FULL_NAME_MAX_LENGTH: 50,
    FAX_MAX_LENGTH: 10,
    FAX_MIN_LENGTH: 10
  };

  /**
   * Messages sent to analytics
   */
  readonly TealiumMessages = {
    BILLING_RECEIPT_REQUEST: 'Paid In Full Receipt Request',
    SUCCESS: 'Success',
    FAILURE: 'Failure',
    HOME_POLICY: 'Home Policy',
    HOME_SUCCESS_MESSAGE: 'We\'ll send your documents within an hour.',
  };

  /**
   * Converts the string total due amount into a number
   */
  totalDueAmtNumber = 0;

  /**
   * The instance of the {HomePolicyResponse} represents the home policy response.
   */
  @Input() homePolicy: HomePolicyResponse | undefined;

  /**
   * The instance of the {Mortgagee} represents the mortgagee model.
   */
  @Input() mortgagee: Mortgagee | undefined;

  /**
   * The policy type which can 'AUTO' or 'HOME'.
   */
  @Input() policyType: string | undefined;

  /**
   * The has errors event that notifies parent component about the
   * API consumption or network errors. The event emits the instance
   * of the `ErrorMessage` model that holds information about the error.
   * The parent component should be able to handle this event like this:
   * (hasErrors)="onBillingReceiptHasErrors($event)"
   * onBillingReceiptHasErrors(errorMessage: ErrorMessage): any { }
   */
  @Output() hasErrors = new EventEmitter<ErrorMessage>();

  /**
   * The cancel event which tells the parent component of cancelling action.
   */
  @Output() cancel = new EventEmitter<boolean>();

  /**
   * The submit event which tells the parent component of submitting action.
   */
  @Output() submit = new EventEmitter<boolean>();
  private Subscribe:Array<Subscription> = [];

  /**
   * Initializes a new instance of the {DeclarationRequestFormComponent}.
   * @param formBuilder The instance of the {FormBuilder} represents the form factory.
   * @param formService The instance of the {LvpFormService} represents the form-related service.
   * @param customEvent The instance of the {CustomEventService} represents the custom event service.
   * @param formValidationService The instnce of the {LvpFormValidationService} represents the form validation service.
   * @param receiptPageService The billig receipt HTTP service.
   */
  constructor(private formBuilder: FormBuilder,
    private formService: LvpFormService,
    private customEvent: CustomEventService,
    private formValidationService: LvpFormValidationService,
    private billingReceiptService: BillingInvoiceReceiptService) {
    this.createForm();
  }

  /**
   * Creates the instance of the {FormGroup} represent billing receipt page form.
   */
  private createForm(): void {
    const formFields: FormField[] = [
      new FormField(null, 'fullName', [Validators.required,
      Validators.maxLength(this.InputLength.FULL_NAME_MAX_LENGTH)]),

      new FormField(null, 'email', [Validators.required, Validators.email,
      CustomValidators.confirmMatchingFields('email', 'confirmEmail')]),

      new FormField(null, 'confirmEmail', [Validators.required, Validators.email,
      CustomValidators.confirmMatchingFields('email', 'confirmEmail')]),

      new FormField(null, 'fax', [Validators.required,
      CustomValidators.confirmMatchingFields('fax', 'confirmFax'),
      Validators.pattern(ValidationPatterns.FAX_NUMBER),
      Validators.minLength(this.InputLength.FAX_MIN_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'confirmFax', [Validators.required,
      CustomValidators.confirmMatchingFields('fax', 'confirmFax'),
      Validators.pattern(ValidationPatterns.FAX_NUMBER),
      Validators.minLength(this.InputLength.FAX_MIN_LENGTH),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]),

      new FormField(null, 'faxATTN', [Validators.required,
      Validators.maxLength(this.InputLength.FULL_NAME_MAX_LENGTH)])
    ];

    this.paidInFullReceiptForm = this.formService.createForm(this.paidInFullReceiptForm, this.formBuilder, formFields);
  }

  ngOnDestroy(): void {
    this.Subscribe.forEach(sub => sub.unsubscribe());
  }

  /**
   * Prepares paid in full receipt request by fetching values from submitted form.
   * @return The instance of the {BillingDetailsInvoiceReceiptRequestModel} represents the request payload.
   */
  private preparePaidInFullReceiptRequest(): BillingDetailsInvoiceReceiptRequestModel {
    const paidInFullReceiptRequest = new BillingDetailsInvoiceReceiptRequestModel();
    const transactionInfo = new TransactionInfoRequest();
    const policyInfo = new PolicyInfoModel();
    const recipient = new RecipientInfoModel();
    const address = new HomeAddressRequest();
    const policyHolderName = this.homePolicy?.policyHolderName??"";
    if(this.homePolicy){
      this.totalDueAmtNumber = +this.homePolicy.billing.billingInfo.balanceInfo.totalDueAmt;
    }
    if (this.requestType === this.CopyRequestType.EMAIL) {
      transactionInfo.actionRequested = this.totalDueAmtNumber <= 0 ? 'W0106' : 'W0107';
      transactionInfo.messageDeveliveryType = this.CopyRequestType.EMAIL;
      recipient.messageDeliveryType = this.CopyRequestType.EMAIL;

      const fullNameFromForm = this.formService.getControlValue(this.paidInFullReceiptForm, 'fullName').replace(/\\/g, '').trim();
      policyInfo.writingCompanyName = fullNameFromForm;
      const parsedFullName = this.parseFullName(fullNameFromForm);
      if (parsedFullName && parsedFullName.length > 1) {
        recipient.firstName = this.getFirstName(parsedFullName);
        recipient.lastName = parsedFullName[parsedFullName.length - 1];
      } else {
        recipient.firstName = fullNameFromForm;
        recipient.lastName = "";
      }
      recipient.emailAddress = this.formService.getControlValue(this.paidInFullReceiptForm, 'email');
      const policyNames = this.parsePolicyHolderName(policyHolderName);
      if (policyNames && policyNames.length === 1) {
        policyInfo.lastName = policyNames[0];
      } else if (policyNames && policyNames.length >= 2) {
        policyInfo.firstName = policyNames[1];
        policyInfo.lastName = policyNames[0];
      }
      transactionInfo.distributionSegment = this.homePolicy?.marketSegment??"";
    } else if (this.requestType === this.CopyRequestType.FAX) {
      transactionInfo.actionRequested = this.totalDueAmtNumber <= 0 ? 'WF003' : 'WF004';
      transactionInfo.messageDeveliveryType = this.CopyRequestType.FAX;
      recipient.messageDeliveryType = this.CopyRequestType.FAX;

      const faxATTNFromForm = this.formService.getControlValue(this.paidInFullReceiptForm, 'faxATTN').replace(/\\/g, '').trim();
      policyInfo.writingCompanyName = faxATTNFromForm;
      const parsedFaxATTN = this.parseFullName(faxATTNFromForm);
      if (parsedFaxATTN && parsedFaxATTN.length > 1) {
        recipient.firstName = this.getFirstName(parsedFaxATTN);
        recipient.lastName = parsedFaxATTN[parsedFaxATTN.length - 1];
      } else {
        recipient.firstName = faxATTNFromForm;
        recipient.lastName = "";
      }
      recipient.faxNumber = this.formService.getControlValue(this.paidInFullReceiptForm, 'fax');
      const policyNames = this.parsePolicyHolderName(policyHolderName);
      if (policyNames && policyNames.length === 1) {
        policyInfo.lastName = policyNames[0];
      } else if (policyNames && policyNames.length >= 2) {
        policyInfo.firstName = policyNames[1];
        policyInfo.lastName = policyNames[0];
      }
      transactionInfo.distributionSegment = this.homePolicy?.marketSegment??"";
    }
    transactionInfo.businessSegment = this.homePolicy?.policyType??"";
    transactionInfo.policyNumber = this.homePolicy?.policyNumber??"";

    // saves given values into policyInfo
    address.addressLineOne = this.homePolicy?.dwellingAddress.address??"";
    address.city = this.homePolicy?.dwellingAddress.city??"";
    address.state = this.homePolicy?.dwellingAddress.state??"";
    address.postalCode = this.homePolicy?.dwellingAddress.postalCode??"";
    policyInfo.policyNumber = this.homePolicy?.policyNumber??"";
    policyInfo.balanceDueAmount = this.homePolicy?.billing.billingInfo.balanceInfo.totalDueAmt??"";
    policyInfo.policyEffectiveDate = this.homePolicy?.policyStartDate??"";
    policyInfo.policyExpirationDate = this.homePolicy?.policyExpiryDate??"";

    // method to get the current date
    policyInfo.processDate = formatDate(this.today, 'MM/dd/yyyy', 'en');
    policyInfo.policyRenewalDate = this.homePolicy?.policyRenewalDate??"";
    policyInfo.paymentAmount = this.homePolicy?.billing.billingInfo.balanceInfo.totalDueAmt??"";
    policyInfo.paymentDate = this.homePolicy?.policyRenewalDate??"";

    paidInFullReceiptRequest.transactionInfo = transactionInfo;
    paidInFullReceiptRequest.policyInfo = policyInfo;
    paidInFullReceiptRequest.policyInfo.address = address;
    paidInFullReceiptRequest.recipient = recipient;
    return paidInFullReceiptRequest;
  }

  /**
   * Checks whether the input pattern is valid.
   * @param field The form field/control name.
   * @returns False if invalid; otherwise, true.
   */
  hasInputPatternError(field: string): boolean {
    return this.formValidationService.hasPatternError(this.paidInFullReceiptForm, field)
      && this.isFormSubmitting;
  }

  /**
   * Parses the policy holder full name.
   * @param fullName The policy holder full name.
   * @returns The array of the first name and last name.
   */
  private parsePolicyHolderName(fullName: string): string[] {
    if (!fullName || fullName.length === 0 || fullName.indexOf(',') === -1) {
      return [];
    }
    const names = fullName.trim().split(',');
    if (names && names.length !== 0) {
      return names.map(name => name.trim());
    }
    return [];
  }

  /**
   * Parses the full name and separates using spaces.
   * @param fullName The name provided from the form.
   * @returns null if invalid; otherwise, an array of names.
   */
  private parseFullName(fullName: string): string[] {
    if (!fullName || fullName.length === 0 || fullName.indexOf(' ') === -1) {
      return [];
    }
    const names = fullName.trim().split(' ');
    if (names && names.length !== 0) {
      return names.map(name => name.trim());
    }
    return [];
  }

  /**
   * Retreives the first name and any middle names .
   * @param parsedFullName The parsed full name provided by parseFullName function.
   * @returns nothing if there is only one word; otherwise, string value with first name and middle names if available.
   */
  private getFirstName(parsedFullName: string[]): string {
    let firstName = '';
    parsedFullName.forEach((name, index) => {
      if (index === parsedFullName.length - 1) {
        return;
      }
      firstName += name + ' ';
    });
    return firstName.trim();
  }

  /**
   * Checks whether the confirmation field is mismatched
   * @param field The form field.
   * @param confirmField The form confirm field.
   * @returns True if is mismatched; otherwise False.
   */
  hasMismatch(field: string, confirmField: string = ""): boolean {
    return (this.formValidationService.hasMismatch(this.paidInFullReceiptForm, field)
      || this.formValidationService.hasMismatch(this.paidInFullReceiptForm, confirmField))
      && this.isFormSubmitting;
  }

  /**
   * Displays the correct form fields based on user selection
   * @param type The instance of type - how user wants dec page copy devliered
   */
  onRequestTypeClick(type:any): void {
    this.resetFormFieldValidators(type);
    this.resetForm();
    this.requestType = type;
    switch (type) {
      case this.CopyRequestType.EMAIL:
        this.requestType = this.CopyRequestType.EMAIL;
        break;
      case this.CopyRequestType.FAX:
        this.requestType = this.CopyRequestType.FAX;
        break;
    }
  }

  /**
   * Resets the form field validators based on the current request type.
   * @param type The request type which can EMAIL, FAX, or BOTH.
   */
  private resetFormFieldValidators(type: string): void {
    this.clearValidators();
    switch (type) {
      case this.CopyRequestType.EMAIL:
        this.setEmailValidators();
        break;
      case this.CopyRequestType.FAX:
        this.setFaxValidators();
        break;
    }
  }

  /**
   * Clears control validators.
   */
  private clearValidators(): void {
    this.formValidationService.clearControlValidators(this.paidInFullReceiptForm, 'fullName');
    this.formValidationService.clearControlValidators(this.paidInFullReceiptForm, 'email');
    this.formValidationService.clearControlValidators(this.paidInFullReceiptForm, 'confirmEmail');
    this.formValidationService.clearControlValidators(this.paidInFullReceiptForm, 'fax');
    this.formValidationService.clearControlValidators(this.paidInFullReceiptForm, 'confirmFax');
    this.formValidationService.clearControlValidators(this.paidInFullReceiptForm, 'faxATTN');
  }

  /**
   * Sets email validators.
   */
  private setEmailValidators(): void {
    this.formValidationService.setControlValidators(this.paidInFullReceiptForm, 'fullName', [Validators.required,
    Validators.maxLength(this.InputLength.FULL_NAME_MAX_LENGTH)]);

    this.formValidationService.setControlValidators(this.paidInFullReceiptForm, 'email',
      [Validators.required,
      Validators.email,
      CustomValidators.confirmMatchingFields('email', 'confirmEmail')]);

    this.formValidationService.setControlValidators(this.paidInFullReceiptForm, 'confirmEmail',
      [Validators.required, Validators.email, CustomValidators.confirmMatchingFields('email', 'confirmEmail')]);
  }

  /**
   * Sets fax validators.
   */
  private setFaxValidators(): void {
    this.formValidationService.setControlValidators(this.paidInFullReceiptForm, 'fax',
      [Validators.required,
      CustomValidators.confirmMatchingFields('fax', 'confirmFax'),
      Validators.pattern(ValidationPatterns.FAX_NUMBER),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]);

    this.formValidationService.setControlValidators(this.paidInFullReceiptForm, 'confirmFax',
      [Validators.required,
      CustomValidators.confirmMatchingFields('fax', 'confirmFax'),
      Validators.pattern(ValidationPatterns.FAX_NUMBER),
      Validators.pattern(ValidationPatterns.ALPHA_NUMERIC_SPACE_COMMA)]);

    this.formValidationService.setControlValidators(this.paidInFullReceiptForm, 'faxATTN',
      [Validators.required,
      Validators.maxLength(this.InputLength.FULL_NAME_MAX_LENGTH)]);
  }

  /**
   * Shows the fax form.
   * @returns The boolean value indicates whether the fax form should be displayed.
   */
  showFaxForm(): boolean {
    return this.requestType === this.CopyRequestType.FAX;
  }

  /**
   * Shows the email form.
   * @returns The boolean value indicates whether the email form should be displayed.
   */
  showEmailForm(): boolean {
    return this.requestType === this.CopyRequestType.EMAIL;
  }

  /**
   * Checks whether a given form field is valid.
   * @param field The form field name.
   * @returns The boolean value indicates whether the validation is valid.
   */
  isFormFieldValid(field: string): boolean {
    return (this.paidInFullReceiptForm.get(field)?.errors
      && this.paidInFullReceiptForm.get(field)?.errors?.hasOwnProperty('required'))
      && this.paidInFullReceiptForm.get(field)?.touched
      && this.isFormSubmitting? true : false;;
  }

  /**
   * Processes the billing receipt request by sending the request via HTTP API.
   * @param paidInFullReceiptRequest The instance of the {BillingDetailsInvoiceReceiptRequestModel} represents the receipt page request.
   */
  private processBillingReceiptRequest(): void {
    if (this.policyType === 'HOME') {
      this.Subscribe.push(this.billingReceiptService.BillingInvoiceReceiptRequest(this.preparePaidInFullReceiptRequest()).subscribe(
        response => this.handleBillingReceiptResponse(response),
        error => this.handleResponseError(error)
      ));
    } else {
      throw new Error('Unable to determine the policy type.');
    }
  }

  /**
   * Handles the billing receipt response from sending event.
   * @param response The instance of the {BillingDetailsInvoiceReceiptResponseModel} represents the HTTP response.
   */
  private handleBillingReceiptResponse(response: BillingDetailsInvoiceReceiptResponseModel): void {
    this.isSendingInProgress = false;
    if (response && response.statusCd && response.statusCd.toUpperCase() === 'SUCCESS') {
      this.customEvent.policyDetailsTracking(this.TealiumMessages.BILLING_RECEIPT_REQUEST, this.TealiumMessages.SUCCESS,
        this.TealiumMessages.HOME_POLICY, this.TealiumMessages.HOME_SUCCESS_MESSAGE);
      this.resetForm();
      this.submit.emit(true);
    } else {
      this.customEvent.policyDetailsTracking(this.TealiumMessages.BILLING_RECEIPT_REQUEST, this.TealiumMessages.FAILURE,
        this.TealiumMessages.HOME_POLICY, response.errorDesc);
      this.hasErrors.emit(new ErrorMessage(
        response.errorCd,
        response.errorDesc));
    }
  }

  /**
   * Handles the response error.
   * @param error The HTTP response error.
   */
  private handleResponseError(error: any): void {
    //console.error(`HTTP response error: ${JSON.stringify(error)}`);
    this.isSendingInProgress = false;
    this.hasErrors.emit(new ErrorMessage(ErrorMessages.SYSTEM_DOWNTIME));
    this.customEvent.policyDetailsTracking(this.TealiumMessages.BILLING_RECEIPT_REQUEST, this.TealiumMessages.FAILURE,
      'Home Policy', ErrorMessages.SYSTEM_DOWNTIME);
    this.isFormSubmitting = false;
  }

  /**
  * Handles submit click event.
  */
  onSendClick(event:any): void {
    event.stopPropagation();
    this.isSendingInProgress = true;
    this.isFormSubmitting = true;
    this.paidInFullReceiptForm = this.formValidationService.validateFormControls(this.paidInFullReceiptForm);
    if (!this.paidInFullReceiptForm.valid) {
      this.isSendingInProgress = false;
      return;
    }
    this.processBillingReceiptRequest();
  }

  /**
   * Displays form field error if validation fails.
   * @param field The field control name.
   */
  displayFormFieldError(field: string): any {
    const hasFieldErrors = this.isFormFieldValid(field) || this.hasMismatch(field) || this.hasInputPatternError(field);
    return {
      'has-error': hasFieldErrors,
      'has-feedback': hasFieldErrors,
    };
  }

  /**
   * Resets the policy form to clear out any data.
   */
  private resetForm(): void {
    this.paidInFullReceiptForm.reset();
    this.isFormSubmitting = false;
  }

  /**
   * Handles cancelling of the paid in full receipt request event.
   */
  onCancelClick(): void {
    this.resetForm();
    this.cancel.emit(true);
  }
}
