export class ErrorMessages {
  static readonly SYSTEM_DOWNTIME = 'The system is currently unavailable.  Please try again in an hour.';
  static POLICY_CANCELLED = 'This policy has been cancelled as of #{CANCELLED_DATE}';
  static readonly POLICY_NEED_SPECIAL_HANDLING = 'This policy requires special handling, please contact us.';
  static readonly RESTRICTED_POLICY = 'This policy has been recently issued, please check back in 48 hours.';
  static readonly UNSUCCESSFUL_POLICY_LOOKUP = 'We couldn\'t find a valid policy with that information. Please try again.';

  static getPolicyCancelledErrorMessage(cancelledDate: string) {
    return this.POLICY_CANCELLED.replace('#{CANCELLED_DATE}', cancelledDate);
  }
}
